export const styles = (theme) => ({
    ...theme.softpro.tableColumns,
    root: theme.softpro.safeBox,
    breadcrumb: {
        marginBottom: 0,
    },
    breadcrumbActive: {
        color: '#6089BD',
    },
    arrow: {
        width: 10,
        color: '#002244',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5,
    },
});
