import {
    FETCH_PACKAGES_BY_STATE_REQUEST_FAILED,
    FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED,
    FETCH_PACKAGES_BY_STATE_REQUESTED,
} from './actions';
import EDITIONS from '../../constants/editions';
import { BROWSE_PER_PAGE_DEFAULT } from '../../constants/api';
import { has } from '../../utils';
import { createApiPageData } from '../../utils/api';

export const byStateInitialState = {
    ...(EDITIONS.reduce((editionsState, edition) => {
        return {
            ...editionsState,
            [edition]: {},
        };
    }, {})),
};

export default function (state = byStateInitialState, action) {
    const { type, edition = null, stateCode = null } = action;

    if (
        type === FETCH_PACKAGES_BY_STATE_REQUESTED ||
        type === FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED ||
        type === FETCH_PACKAGES_BY_STATE_REQUEST_FAILED
    ) {
        const stateState = has(state[edition], stateCode)
            ? state[edition][stateCode]
            : createApiPageData({ perPage: BROWSE_PER_PAGE_DEFAULT, loading: false });

        if (action.type === FETCH_PACKAGES_BY_STATE_REQUESTED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    [stateCode]: {
                        ...stateState,
                        loading: true,
                        filters: action.filters || {},
                        order: action.order || {},
                    },
                },
            };
        } else if (action.type === FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    [stateCode]: {
                        ...stateState,
                        ...createApiPageData(action.data.result),
                        loading: false,
                        filters: action.filters || {},
                        order: action.order || {},
                    },
                },
            };
        } else if (action.type === FETCH_PACKAGES_BY_STATE_REQUEST_FAILED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    [stateCode]: {
                        ...stateState,
                        loading: false,
                    },
                },
            };
        }
    }

    return state;
}
