import { createSelector } from 'reselect';
import { ROLE_ADMIN } from '../constants/roles';

export const getCurrentUser = (state) => state.currentUser.data;

export const getCurrentUserEditions = (state) => (state.currentUser.editions || []);

export const getCurrentUserRoles = (state) => (state.currentUser.roles || []);

export const doesUserHaveMultipleEditions = (state) => {
    return getCurrentUserEditions(state).length > 1;
};

export const isOnboardingCompleted = (state) => {
    const user = getCurrentUser(state);
    return (user && user.onboardingCompleted);
};
export const doesCurrentUserHaveRole = (state, role) => {
    const roles = getCurrentUserRoles(state);
    return roles && roles.length > 0 && roles.includes(role);
};

export const isCurrentUserAdmin = (state) => {
    return doesCurrentUserHaveRole(state, ROLE_ADMIN);
};

const createUserAttributeSelector = (attribute) => createSelector(
    getCurrentUser,
    (user) => {
        return user ? user[attribute] : null;
    },
);

export const getCurrentUserEmail = createUserAttributeSelector('email');
export const getCurrentUserName = createUserAttributeSelector('name');
