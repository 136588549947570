export default (theme) => ({
    packageTable: {
        width: 'auto',
        marginTop: 10,
        marginLeft: 0,
    },
    emptyTable: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#DFDFDF',
        alignItems: 'center',
        padding: 20,
    },
    emptyTableText: {
        alignSelf: 'center',
    },
});
