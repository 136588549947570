import React from 'react';
import PropTypes from 'prop-types';
import AdminButton from '../AdminButton';

const BackButton = (props) => {
    return (
        <AdminButton variant="outlined" {...props}>
            Back
        </AdminButton>
    );
};
BackButton.propTyes = {
    onClick: PropTypes.func.isRequired,
};

export default BackButton;
