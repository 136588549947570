import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import classNames from 'classnames';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import MapSelectorApp from '../MapSelector';
import SoftwareVersionSelector from '../SoftwareVersionSelector';
import SoftProHeader from '../Header';
import { styles } from './styles';
import UnderwriterSelector from '../UnderwriterSelector';
import OnBoardingWatchlist from '../OnBoardingWatchlist';
import { fetchUnderwriters, updateUnderwriters } from '../../stores/underwriters/actions';
import { addSoftwareEdition } from '../../stores/editions/actions';
import { fetchStates, updateStates } from '../../stores/states/actions';
import {
    addPackagesToWatchlist,
    deselectPackagesForWatchlist,
    fetchSuggestedWatchlist,
    selectPackagesForWatchlist,
} from '../../stores/onBoardingWatchlist/actions';
import { getAvailableStates } from '../../selectors/states';
import { getAvailableUnderwriters } from '../../selectors/underwriters';
import { getOnboardingSuggestedWatchlist } from '../../selectors/onBoardingWatchlist';
import { updateUserSettings } from '../../stores/settings/actions';
import { DISCLAIMER_TEXT } from '../../constants/disclaimer';

function getSteps () {
    return [
        'Choose Software',
        'Choose States',
        'Choose Underwriters',
        'Finish up',
    ];
}

class OnBoardingStepper extends React.Component {
    state = {
        activeStep: 0,
        completedSteps: [],
    };

    getStepContent = (step, props) => {
        switch (step) {
            case 0:
                return (
                    <SoftwareVersionSelector
                        handleSelect={() => props.onEditionSelected('select')}
                        handleStandard={() => props.onEditionSelected(
                            'standard')}
                        editions={props.editions}
                    />
                );
            case 1:
                return (
                    <MapSelectorApp
                        handleStateUpdate={(newState) => props.onStateSelected(
                            newState)}
                        states={props.states}
                        fetchStates={() => props.fetchStates()}
                        availableStates={props.availableStates}
                        loading={props.statesLoading}
                    />
                );
            case 2:
                return (
                    <UnderwriterSelector
                        handleUnderwriterUpdate={(underwriter) => props.onUnderwriterSelected(underwriter)}
                        underwriters={props.underwriters}
                        fetchUnderwriters={() => props.fetchUnderwriters()}
                        selectedStates={props.states}
                        availableUnderwriters={props.availableUnderwriters}
                        loading={props.underwritersLoading}
                    />
                );
            case 3:
                return (
                    <OnBoardingWatchlist
                        fetchSuggestedWatchlist={() => props.fetchSuggestedWatchlist(props.editions, props.underwriters,
                            props.states)}
                        suggestedWatchlist={props.suggestedWatchlist}
                        loading={props.suggestedWatchlistLoading}
                        selectedPackagesForWatchlist={props.selectedPackagesForWatchlist}
                        onAddPackages={props.onSelectPackagesForWatchlist}
                        onRemovePackages={props.onDeselectPackagesForWatchlist}
                    />);
            default:
                return 'Unknown step';
        }
    };

    handleNext = () => {
        const { activeStep, completedSteps } = this.state;
        const { editions, states, underwriters } = this.props;
        let newCompletedSteps = [...completedSteps];
        if (activeStep === 0 && editions.length === 0) {
            this.setState({
                noEditionSelected: true,
            });
        } else if (activeStep === 1 && states.length === 0) {
            this.setState({
                noStateSelected: true,
            });
        } else if (activeStep === 2 && underwriters.length === 0) {
            this.setState({
                noUnderwriterSelected: true,
            });
        } else {
            if (!completedSteps.includes(activeStep)) {
                newCompletedSteps = [
                    completedSteps,
                    activeStep + 1];
            }
            this.setState({
                activeStep: activeStep + 1,
                completedSteps: newCompletedSteps,
            });
        }
        if (activeStep === 3) {
            // They just clicked Finish, so lets store their editions, states and watchlists now
            const {
                editions,
                states,
                underwriters,
                selectedPackagesForWatchlist,
                updateUserSettings,
                addPackagesToWatchlist,
            } = this.props;
            updateUserSettings({ editions, states, underwriters });
            addPackagesToWatchlist(selectedPackagesForWatchlist);
        }
    };

    handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    jumpToStep = (stepNumber) => {
        const { completedSteps, activeStep } = this.state;
        const { editions, states, underwriters } = this.props;
        const activeStepNumber = activeStep + 1;
        let newCompletedSteps = [...completedSteps];
        let currentActiveStepIsCompleted = false;
        if (activeStepNumber === 1 && (editions && editions.length > 0)) {
            /*
             * The step they are skipping from is step 1, "Choose Software" and they've selected at least one edition
             * so we can consider this step completed
             */
            currentActiveStepIsCompleted = true;
        } else if (activeStepNumber === 2 && (states && states.length > 0)) {
            /*
             * The step they are skipping from is step 2, "Choose States", and at least one states been selected
             * SO we can consider this step as completed
             */
            currentActiveStepIsCompleted = true;
        } else if (activeStepNumber === 3 && underwriters && underwriters.length > 0) {
            /*
             * OK OK one more time. We're on step 3, "Choose Underwriters" and they've selected at least one Underwriter
             * So once again, this step can be considered completed
             */
            currentActiveStepIsCompleted = true;
        }
        if (currentActiveStepIsCompleted) {
            if (!newCompletedSteps.includes(activeStepNumber)) {
                newCompletedSteps.push(activeStepNumber);
            }
        } else if (stepNumber > activeStepNumber) {
            /*
             * This step is not completed - they skipped forward, skipped back, and undid the selections, and /or they're trying to skip
             * forward. So wipe it out of the completed steps, along with any steps after that step. CHEATERS!
             */
            const index = newCompletedSteps.indexOf(activeStepNumber);
            newCompletedSteps = newCompletedSteps.splice(index, newCompletedSteps.length - index);
            this.setState({ completedSteps: newCompletedSteps });
            switch (activeStepNumber) {
                case 1: // They still need to pick an edition so lets show that dialog
                    this.setState({ noEditionSelected: true });
                    break;
                case 2: // They still need to pick at least one state, so lets show that dialog
                    this.setState({ noStateSelected: true });
                    break;
                case 3: // They still need to pick at least one underwriter, so show that dialog
                    this.setState({ noUnderwriterSelected: true });
                    break;
                default:
                    return;
            }
            return;
        }
        if (
            stepNumber < activeStepNumber ||
            newCompletedSteps.includes(stepNumber) ||
            newCompletedSteps.includes(stepNumber - 1)
        ) {
            /*
             * They've already completed this step or the one right before it, or they are going backwards, so let them jump to this
             * step by clicking the step
             */
            this.setState({
                activeStep: stepNumber - 1,
                completedSteps: newCompletedSteps,
            });
        }
    };

    render () {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;
        return (
            <div className={classes.root}>
                <SoftProHeader isUserLoggedIn />
                <div className={classNames('backgroundContainer',
                    classes.container)}
                >
                    <Typography variant="h3" style={styles.topText}>
                        Welcome to the SoftPro Documents Portal!
                    </Typography>
                    <Typography variant="h3" className={classNames([classes.whiteText, classes.letsGetStarted])}>
                        Let&apos;s Get Started.
                    </Typography>
                    <div className={classes.stepperContainer}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const isStepActive = activeStep === index;
                                return (
                                    <Step
                                        key={label}
                                        onClick={() => this.jumpToStep(index + 1)}
                                    >
                                        <StepLabel icon="">
                                            <Typography
                                                variant={isStepActive
                                                    ? 'subtitle1'
                                                    : 'subtitle2'}
                                            >
                                                {label}
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <Grid container direction="column" alignItems="stretch" className={classes.stepContainer}>
                            {activeStep === steps.length
                                ? (
                                    <Grid item style={styles.finalStepLoading}>
                                        <CircularProgress />
                                    </Grid>
                                )
                                : (
                                    <Fragment>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            className={classes.stepContent}
                                        >
                                            {this.getStepContent(activeStep, this.props)}
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            style={styles.navButtons}
                                            alignContent="center"
                                        >
                                            {activeStep > 0 && (
                                                <Grid item>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={this.handleBack}
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Back
                                                    </Button>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    onClick={this.handleNext}
                                                    className={classes.button}
                                                    variant="contained"
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </Grid>
                                            <Dialog
                                                open={this.state.noEditionSelected ||
                                                false}
                                            >
                                                <DialogTitle>Please Select an Edition.</DialogTitle>
                                                <DialogContentText>
                                                    Please select at least one edition to continue.
                                                </DialogContentText>
                                                <DialogActions
                                                    className={classes.dialogActions}
                                                >
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => this.setState(
                                                            { noEditionSelected: false })}
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Dialog
                                                open={this.state.noStateSelected ||
                                                false}
                                            >
                                                <DialogTitle>Please Select a State.</DialogTitle>
                                                <DialogContentText>
                                                    Please select at least one State to continue.
                                                </DialogContentText>
                                                <DialogActions
                                                    className={classes.dialogActions}
                                                >
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => this.setState(
                                                            { noStateSelected: false })}
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Dialog open={this.state.noUnderwriterSelected || false}>
                                                <DialogTitle>Please Select an Underwriter.</DialogTitle>
                                                <DialogContentText>
                                                    Please select at least one Underwriter to continue.
                                                </DialogContentText>
                                                <DialogActions
                                                    className={classes.dialogActions}
                                                >
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => this.setState(
                                                            { noUnderwriterSelected: false })}
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Grid>
                                    </Fragment>
                                )}
                        </Grid>
                    </div>
                    <Typography className={classes.disclaimerText}>
                        {DISCLAIMER_TEXT}
                    </Typography>
                </div>
            </div>
        );
    }
}

OnBoardingStepper.propTypes = {
    classes: PropTypes.object,
    editions: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    underwriters: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    selectedPackagesForWatchlist: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ).isRequired,
    addPackagesToWatchlist: PropTypes.func.isRequired,
};

const mapStateToProps = function (state) {
    return {
        editions: state.editions.editions,
        states: state.states.states,
        underwriters: state.underwriters.underwriters,
        watchlist: state.watchlist,
        availableStates: getAvailableStates(state),
        statesLoading: state.states.loading,
        availableUnderwriters: getAvailableUnderwriters(state),
        underwritersLoading: state.underwriters.loading,
        suggestedWatchlist: getOnboardingSuggestedWatchlist(state),
        suggestedWatchlistLoading: state.onBoardingWatchlist.loading,
        selectedPackagesForWatchlist: state.onBoardingWatchlist.selectedPackagesForWatchlist,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators({
            fetchStates,
            fetchUnderwriters,
            fetchSuggestedWatchlist,
            addPackagesToWatchlist,
            updateUserSettings,
        }, dispatch)),
        onStateSelected: (stateCode) => {
            dispatch(updateStates(stateCode));
        },
        onEditionSelected: (edition) => {
            dispatch(addSoftwareEdition(edition));
        },
        onUnderwriterSelected: (underwriter) => {
            dispatch(updateUnderwriters(underwriter));
        },
        onSelectPackagesForWatchlist: (packageIds) => {
            dispatch(selectPackagesForWatchlist(packageIds));
        },
        onDeselectPackagesForWatchlist: (packageIds) => {
            dispatch(deselectPackagesForWatchlist(packageIds));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
)(OnBoardingStepper);
