import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getAuthToken } from '../selectors/auth';
import { requestWithAuth } from '../utils/api';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import {
    USER_DATA_REQUESTED,
    USER_DATA_UPDATE_REQUESTED,
    USER_DOWNLOAD_HISTORY_REQUESTED,
    userDataRequestFailed,
    userDataRequestSucceeded,
    userDataUpdateRequestFailed,
    userDataUpdateRequestSucceeded,
    userDownloadHistoryRequestFailed,
    userDownloadHistoryRequestSucceeded,
    USERS_DATA_REQUESTED,
    usersDataRequestFailed,
    usersDataRequestSucceeded,
} from '../stores/users/actions';
import { DEFAULT_ROWS_PER_PAGE } from '../constants/admin';

function * fetchUsersData ({ page = 1, perPage = DEFAULT_ROWS_PER_PAGE, search, selectedOrganizations }) {
    const token = yield select(getAuthToken);
    const queryOptions = {
        page,
        perPage,
    };
    if (search) {
        queryOptions.search = search;
    }
    if (selectedOrganizations && selectedOrganizations.length > 0) {
        queryOptions.organizations = selectedOrganizations;
    }
    const response = yield call(
        requestWithAuth,
        '/admin/usersData',
        'GET',
        token,
        queryOptions,
    );
    if (response.status === 200) {
        const { users, organizations } = yield call([response, response.json]);
        yield put(usersDataRequestSucceeded(users.data, users.currentPage, users.perPage, users.total, organizations));
    } else {
        yield put(snackbar.show({ message: 'Loading Users data has failed' }));
        yield put(usersDataRequestFailed());
    }
}

function * fetchUserData ({ userId }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/admin/user/' + userId,
        'GET',
        token,
    );
    if (response.ok) {
        const userData = yield call([response, response.json]);
        yield put(userDataRequestSucceeded(userData));
    } else {
        yield put(snackbar.show({ message: 'Loading User data has failed' }));
        yield put(userDataRequestFailed());
    }
}

function * fetchUserDownloadHistory ({ userId, perPage, page = 1 }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/admin/user/' + userId + '/downloadHistory',
        'GET',
        token,
        {
            page,
            perPage,
        },
    );
    if (response.ok) {
        const { data, currentPage, perPage, total } = yield call([response, response.json]);
        yield put(userDownloadHistoryRequestSucceeded(data, currentPage, perPage, total));
    } else {
        yield put(snackbar.show({ message: 'Loading User data has failed' }));
        yield put(userDownloadHistoryRequestFailed());
    }
}

export function * listenFetchUsersData () {
    yield takeEvery(USERS_DATA_REQUESTED, fetchUsersData);
    yield takeLatest(USER_DATA_REQUESTED, fetchUserData);
    yield takeLatest(USER_DOWNLOAD_HISTORY_REQUESTED, fetchUserDownloadHistory);
}

function * updateUserData ({ userId, userData }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/admin/user/' + userId,
        'PUT',
        token,
        userData,
    );
    if (response.ok) {
        const userData = yield call([response, response.json]);
        yield put(userDataUpdateRequestSucceeded(userData));
    } else {
        const { validationErrors } = yield call([response, response.json]);
        const errorMessage = 'Saving User data has failed. '.concat(
            validationErrors && validationErrors.email ? validationErrors.email : '');
        yield put(snackbar.show({ message: errorMessage }));
        yield put(userDataUpdateRequestFailed(validationErrors));
    }
}

export function * listenUpdateUserData () {
    yield takeEvery(USER_DATA_UPDATE_REQUESTED, updateUserData);
}
