export default (theme) => ({
    ...theme.softpro.tableColumns,
    addAdminButton: {
        marginTop: 0,
    },
    searchButton: {
        marginTop: 0,
        width: 32,
        minWidth: 32,
        height: 32,
        minHeight: 32,
        padding: '0px 0px',
    },
    tableLoadingIndicator: {
        marginTop: 20,
    },
    overflow: {
        overflow: 'auto',
    },
    emailCol: {
        width: 'auto',
    },
});
