import { ViewButton } from '../../ViewButton';
import SoftProDataTable from '../../SoftProDataTable';
import DataTableColumn from '../../DataTable/DataTableColumn';
import React, { Fragment } from 'react';
import {
    ANALYTICS_FILTER_FIELD_STATE,
    ANALYTICS_FILTER_FIELD_TYPE,
    ANALYTICS_ORDER_BY_FIELD_NAME,
    ANALYTICS_ORDER_BY_FIELD_TYPE,
    ANALYTICS_ORDER_BY_FIELD_UPDATED_AT,
    PACKAGES_FILTER_FIELD_TYPE,
    PACKAGES_FILTER_FIELD_UNDERWRITER,
    PACKAGES_ORDER_BY_FIELD_DOWNLOAD_NOTES,
    PACKAGES_ORDER_BY_FIELD_DOWNLOAD_URL,
    PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS,
    PACKAGES_ORDER_BY_FIELD_RELEASE_NOTES_URL,
} from '../../../constants/api';
import { SORT_ASC, SORT_DESC } from '../../DataTable/constants';
import PropTypes from 'prop-types';
import TablesPropTypes from '../../../propTypes/tables';
import { withStyles } from '@mui/styles';
import ApiPropTypes from '../../../propTypes/api';
import { capitalizeFirstLetter, noop, withRouter } from '../../../utils';
import styles from './styles';
import { PACKAGE_TYPE_NAMES } from '../../../constants/packageTypes';
import EmptyTable from '../../EmptyTable';
import { StyledTrash } from '../../TableComponents';
import { compose } from 'redux';
import { DOWNLOADS, NOTIFICATIONS, USERS } from '../../../constants/analytics';
import { useNavigate } from 'react-router';

const STATES_FILTER = { key: ANALYTICS_FILTER_FIELD_STATE, label: 'State' };
const TYPE_FILTER = { key: ANALYTICS_FILTER_FIELD_TYPE, label: 'Type' };
const UNDERWRITER_FILTER = {
    key: PACKAGES_FILTER_FIELD_UNDERWRITER,
    label: 'Underwriter',
};

const ANALYTICS_TYPES_FILTER_OPTIONS = [
    { value: '', label: 'All Package Types' },
    ...Object.entries(PACKAGE_TYPE_NAMES).map(
        ([packageType, packageTypeName]) => ({
            label: packageTypeName,
            value: packageType,
        }),
    ),
];

class SoftProAdminDataTable extends React.Component {
    static propTypes = {
        // REQUIRED
        classes: PropTypes.object.isRequired,
        dataType: PropTypes.string.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        navigate: PropTypes.func.isRequired,

        // Not Required
        availableStatesForDataTableFilter: PropTypes.array,
        availableUnderwritersForDataTableFilter: PropTypes.array,
        onViewRequested: PropTypes.func,
        renderEmpty: PropTypes.func,
        title: PropTypes.node,
        hasNumberOfDownloadsColumn: PropTypes.bool,
        hasNumberOfNotificationsColumn: PropTypes.bool,
        hasUnderwriterColumn: PropTypes.bool,
        hasEditionColumn: PropTypes.bool,
        hasTrashColumn: PropTypes.bool,
        onTrashClick: PropTypes.func,
        deletingIds: PropTypes.array,
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.node,
            }),
        ),
        onActionSelect: PropTypes.func,
        getKey: PropTypes.func,
        viewPath: PropTypes.string,
        hasViewColumn: PropTypes.bool,
        hasReleaseNotesColumn: PropTypes.bool,
        hasDownloadNotesColumn: PropTypes.bool,
        hasZipContentColumn: PropTypes.bool,
        hasEndorsementLookupsIncludedColumn: PropTypes.bool,
        packageId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        onViewRequested: noop(),
        renderEmpty: () => null,
        title: null,
        hasNumberOfDownloadsColumn: false,
        hasNumberOfNotificationsColumn: false,
        hasUnderwriterColumn: false,
        hasEditionColumn: false,
        hasTrashColumn: false,
        onTrashClick: noop(),
        availableStatesForDataTableFilter: [],
        availableUnderwritersForDataTableFilter: [],
        deletingIds: [],
        actions: [],
        onActionSelect: noop(),
        getKey: ({ id }) => id,
        viewPath: '',
        hasViewColumn: true,
        hasReleaseNotesColumn: false,
        hasDownloadNotesColumn: false,
        hasZipContentColumn: false,
        hasEndorsementLookupsIncludedColumn: false,
    };

    getFilters = () => {
        const filters = [TYPE_FILTER, STATES_FILTER];
        if (this.props.hasUnderwriterColumn) {
            filters.push(UNDERWRITER_FILTER);
        }
        return filters;
    };

    getFilterOptions = ({ key }) => {
        if (key === ANALYTICS_FILTER_FIELD_STATE) {
            return this.props.availableStatesForDataTableFilter;
        } else if (key === PACKAGES_FILTER_FIELD_TYPE) {
            return ANALYTICS_TYPES_FILTER_OPTIONS;
        } else if (key === PACKAGES_FILTER_FIELD_UNDERWRITER) {
            return this.props.availableUnderwritersForDataTableFilter;
        }
        return [];
    };

    renderUpdatedAt = ({ updatedAt }) => updatedAt;
    renderType = ({ type }) => type;
    renderStates = ({ states }) => states;
    renderPackageName = ({ name }) => name;
    renderNumberOfDownloads = ({ downloadCount }) => downloadCount;
    renderNumberOfNotifications = ({ notificationCount }) => notificationCount;
    renderUnderwriterName = ({ underwriterName }) => underwriterName;
    renderEditions = ({ editionNames }) => editionNames;
    renderReleaseNotes = ({ hasReleaseNotesUploadId }) => {
        return hasReleaseNotesUploadId ? 'x' : '';
    };

    renderDownloadNotes = ({ hasDownloadNotes }) => {
        return hasDownloadNotes ? 'x' : '';
    };

    renderZipContent = ({ hasZipUploadId }) => {
        return hasZipUploadId ? 'x' : '';
    };

    renderEndorsementLookupsIncluded = ({ hasEndorsementLookupsIncluded }) => {
        return hasEndorsementLookupsIncluded ? 'x' : '';
    };

    renderViewButton = (row) => {
        const { viewPath, packageId } = this.props;
        const path = viewPath
            ? viewPath + this.props.getKey(row)
            : this.props.router.location.pathname;
        return (
            <ViewButton
                onClick={() => this.props.navigate(path)}
                disabled={this.props.deletingIds.includes(packageId)}
            />
        );
    };

    renderTrashColumn = ({ packageId }) => (
        <StyledTrash
            title="Remove Package"
            disabled={this.props.deletingIds.includes(packageId)}
            onClick={() => {
                this.props.onTrashClick(packageId);
            }}
        />
    );

    renderEmpty = () => {
        const { dataType } = this.props;
        return dataType === NOTIFICATIONS ||
            dataType === DOWNLOADS ||
            dataType === USERS
            ? (
                <EmptyTable
                    title={capitalizeFirstLetter(dataType)}
                    includeRangeInMessage
                />
            )
            : (
                <EmptyTable />
            );
    };

    render () {
        const {
            classes,
            tableContext,
            tableData,
            dataType,
            onFetchDataRequested,
            hasNumberOfDownloadsColumn,
            hasNumberOfNotificationsColumn,
            hasEditionColumn,
            hasUnderwriterColumn,
            hasTrashColumn,
            title,
            actions,
            onActionSelect,
            getKey,
            hasViewColumn,
            hasReleaseNotesColumn,
            hasDownloadNotesColumn,
            hasZipContentColumn,
            hasEndorsementLookupsIncludedColumn,
        } = this.props;
        return (
            <SoftProDataTable
                getKey={getKey}
                onFetchDataRequested={(context) =>
                    onFetchDataRequested(dataType, context)
                }
                tableContext={tableContext}
                tableData={tableData}
                filters={this.getFilters()}
                getFilterOptions={this.getFilterOptions}
                renderEmpty={this.renderEmpty}
                title={title}
                actions={actions}
                onActionSelect={onActionSelect}
                classes={{ input: classes.input }}
            >
                {({ createOnSort, createSortGetter }) => (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="Last Updated"
                            classes={{
                                headCell: classes.lastUpdatedCol,
                                bodyCell: classes.lastUpdatedCol,
                            }}
                            getSort={createSortGetter(
                                ANALYTICS_ORDER_BY_FIELD_UPDATED_AT,
                            )}
                            onSort={createOnSort(
                                ANALYTICS_ORDER_BY_FIELD_UPDATED_AT,
                            )}
                            initialSort={SORT_DESC}
                        >
                            {this.renderUpdatedAt}
                        </DataTableColumn>
                        {hasEditionColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Software Ed."
                                    classes={{
                                        headCell: classes.adminEditionCol,
                                        bodyCell: classes.adminEditionCol,
                                    }}
                                >
                                    {this.renderEditions}
                                </DataTableColumn>
                            )
                            : null}
                        <DataTableColumn
                            headCellContent="Type"
                            classes={{
                                headCell: classes.adminTypeCol,
                                bodyCell: classes.adminTypeCol,
                            }}
                            getSort={createSortGetter(
                                ANALYTICS_ORDER_BY_FIELD_TYPE,
                            )}
                            onSort={createOnSort(ANALYTICS_ORDER_BY_FIELD_TYPE)}
                            initialSort={SORT_DESC}
                        >
                            {this.renderType}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="State/Region"
                            classes={{
                                headCell: classes.adminStateCol,
                                bodyCell: classes.adminStateCol,
                            }}
                        >
                            {this.renderStates}
                        </DataTableColumn>
                        {hasUnderwriterColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Underwriter Name"
                                    classes={{
                                        headCell: classes.underwriterNameCol,
                                        bodyCell: classes.underwriterNameCol,
                                    }}
                                >
                                    {this.renderUnderwriterName}
                                </DataTableColumn>
                            )
                            : null}
                        <DataTableColumn
                            headCellContent="Package Name"
                            classes={{
                                headCell: classes.packageNameCol,
                                bodyCell: classes.packageNameCol,
                            }}
                            getSort={createSortGetter(
                                ANALYTICS_ORDER_BY_FIELD_NAME,
                            )}
                            onSort={createOnSort(ANALYTICS_ORDER_BY_FIELD_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {this.renderPackageName}
                        </DataTableColumn>
                        {hasNumberOfDownloadsColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Number Of Downloads"
                                    classes={{
                                        headCell: classes.countCol,
                                        bodyCell: classes.countCol,
                                    }}
                                >
                                    {this.renderNumberOfDownloads}
                                </DataTableColumn>
                            )
                            : null}
                        {hasNumberOfNotificationsColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Number Of Notifications"
                                    classes={{
                                        headCell: classes.countCol,
                                        bodyCell: classes.countCol,
                                    }}
                                >
                                    {this.renderNumberOfNotifications}
                                </DataTableColumn>
                            )
                            : null}
                        {hasReleaseNotesColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Release Notes"
                                    classes={{
                                        headCell: classes.releaseNotesCol,
                                        bodyCell: classes.releaseNotesCol,
                                    }}
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_RELEASE_NOTES_URL,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_RELEASE_NOTES_URL,
                                    )}
                                    initialSort={SORT_DESC}
                                >
                                    {this.renderReleaseNotes}
                                </DataTableColumn>
                            )
                            : null}
                        {hasDownloadNotesColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Download Notes"
                                    classes={{
                                        headCell: classes.downloadNotesCol,
                                        bodyCell: classes.downloadNotesCol,
                                    }}
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_DOWNLOAD_NOTES,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_DOWNLOAD_NOTES,
                                    )}
                                    initialSort={SORT_DESC}
                                >
                                    {this.renderDownloadNotes}
                                </DataTableColumn>
                            )
                            : null}
                        {hasEndorsementLookupsIncludedColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Endorsement Lookups Included"
                                    classes={{
                                        headCell: classes.downloadNotesCol,
                                        bodyCell: classes.downloadNotesCol,
                                    }}
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS,
                                    )}
                                    initialSort={SORT_DESC}
                                >
                                    {this.renderEndorsementLookupsIncluded}
                                </DataTableColumn>
                            )
                            : null}
                        {hasZipContentColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Zip Content"
                                    classes={{
                                        headCell: classes.zipContentCol,
                                        bodyCell: classes.zipContentCol,
                                    }}
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_DOWNLOAD_URL,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_DOWNLOAD_URL,
                                    )}
                                    initialSort={SORT_DESC}
                                >
                                    {this.renderZipContent}
                                </DataTableColumn>
                            )
                            : null}
                        {hasViewColumn
                            ? (
                                <DataTableColumn
                                    classes={{
                                        headCell: classes.viewCol,
                                        bodyCell: classes.viewCol,
                                    }}
                                >
                                    {this.renderViewButton}
                                </DataTableColumn>
                            )
                            : null}
                        {hasTrashColumn
                            ? (
                                <DataTableColumn
                                    classes={{
                                        headCell: classes.removeCol,
                                        bodyCell: classes.removeCol,
                                    }}
                                >
                                    {this.renderTrashColumn}
                                </DataTableColumn>
                            )
                            : null}
                    </Fragment>
                )}
            </SoftProDataTable>
        );
    }
}

const SoftProAdminDataTableFunction = (props) => {
    const navigate = useNavigate();
    return <SoftProAdminDataTable {...props} navigate={navigate} />;
};

export default compose(
    withStyles(styles),
    withRouter,
)(SoftProAdminDataTableFunction);
