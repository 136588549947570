export const UPDATE_STATES = 'UPDATE_STATES';
export const FETCH_STATES = 'FETCH_STATES';
export const FETCH_STATES_SUCCEEDED = 'FETCH_STATES_SUCCEEDED';

export function updateStates (stateCode) {
    return {
        type: UPDATE_STATES,
        stateCode,
    };
}

export function fetchStates () {
    return {
        type: FETCH_STATES,
    };
}

export function fetchStatesSucceeded (data) {
    return {
        type: FETCH_STATES_SUCCEEDED,
        data,
    };
}
