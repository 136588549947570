/**
 * The plural for singular "package" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_PACKAGE = 'packages';

/**
 * The plural for singular "state" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_STATE = 'states';

/**
 * The plural for singular "watchlist" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_WATCHLIST = 'watchlists';

/**
 * The plural for singular "underwriter" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_UNDERWRITER = 'underwriters';

/**
 * The plural for singular "entity" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_EDITION = 'editions';

/**
 * The plural for singular "FAQ" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_FAQ = 'faqs';

/**
 * The plural for singular "info video" used in entities dictionaries.
 * @constant
 * @type {string}
 */
export const PLURAL_INFO_VIDEO = 'infoVideos';
