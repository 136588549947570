export const UPDATE_UNDERWRITERS = 'UPDATE_UNDERWRITERS';
export const FETCH_UNDERWRITERS = 'FETCH_UNDERWRITERS';
export const FETCH_UNDERWRITERS_SUCCEEDED = 'FETCH_UNDERWRITERS_SUCCEEDED';

export function updateUnderwriters (underwriter) {
    return {
        type: UPDATE_UNDERWRITERS,
        underwriter,
    };
}

export function fetchUnderwriters () {
    return {
        type: FETCH_UNDERWRITERS,
    };
}

export function fetchUnderwritersSucceeded (data) {
    return {
        type: FETCH_UNDERWRITERS_SUCCEEDED,
        data,
    };
}
