import {
    BROWSE_ORDER_BY_DEFAULT_DIR,
    BROWSE_ORDER_BY_DEFAULT_FIELD,
    BROWSE_PER_PAGE_DEFAULT,
} from '../../constants/api';

export const PREFIX = 'packages/';

export const FETCH_SUGGESTED_DOWNLOADS_REQUESTED = `${PREFIX}FETCH_SUGGESTED_DOWNLOADS_REQUESTED`;
export const FETCH_SUGGESTED_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED = `${PREFIX}FETCH_SUGGESTED_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED`;
export const FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED = `${PREFIX}FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED`;
export const FETCH_SUGGESTED_DOWNLOADS_REQUEST_FAILED = `${PREFIX}FETCH_SUGGESTED_DOWNLOADS_REQUEST_FAILED`;

export const FETCH_PACKAGES_REQUESTED = `${PREFIX}FETCH_PACKAGES_REQUESTED`;
export const FETCH_PACKAGES_FOR_CURRENT_EDITION_REQUESTED = `${PREFIX}FETCH_PACKAGES_FOR_CURRENT_EDITION_REQUESTED`;
export const FETCH_PACKAGES_REQUEST_SUCCEEDED = `${PREFIX}FETCH_PACKAGES_REQUEST_SUCCEEDED`;
export const FETCH_PACKAGES_REQUEST_FAILED = `${PREFIX}FETCH_PACKAGES_REQUEST_FAILED`;

export const FETCH_PACKAGES_BY_STATE_REQUESTED = `${PREFIX}FETCH_PACKAGES_BY_STATE_REQUESTED`;
export const FETCH_PACKAGES_BY_STATE_FOR_CURRENT_EDITION_REQUESTED = `${PREFIX}FETCH_PACKAGES_BY_STATE_FOR_CURRENT_EDITION_REQUESTED`;
export const FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED = `${PREFIX}FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED`;
export const FETCH_PACKAGES_BY_STATE_REQUEST_FAILED = `${PREFIX}FETCH_PACKAGES_BY_STATE_REQUEST_FAILED`;

export function fetchSuggestedDownloadsRequested (
    edition,
    {
        pagination: {
            perPage = BROWSE_PER_PAGE_DEFAULT,
            page = 1,
        } = {},
        order: {
            orderBy = BROWSE_ORDER_BY_DEFAULT_FIELD,
            orderByDir = BROWSE_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            state = null,
            search = null,
            type = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: FETCH_SUGGESTED_DOWNLOADS_REQUESTED,
        edition,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, type, underwriter },
    };
}

export function fetchSuggestedDownloadsForCurrentEditionRequested (...args) {
    return {
        type: FETCH_SUGGESTED_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED,
        args,
    };
}

export function fetchSuggestedDownloadsRequestSucceeded (edition, packageType, data, order = {}, filters = {}) {
    return {
        type: FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED,
        edition,
        data,
        order,
        filters,
    };
}

export function fetchSuggestedDownloadsRequestFailed () {
    return { type: FETCH_SUGGESTED_DOWNLOADS_REQUEST_FAILED };
}

export function fetchPackagesRequested (
    edition,
    packageType,
    {
        pagination: {
            perPage = BROWSE_PER_PAGE_DEFAULT,
            page = 1,
        } = {},
        order: {
            orderBy = BROWSE_ORDER_BY_DEFAULT_FIELD,
            orderByDir = BROWSE_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            state = null,
            search = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: FETCH_PACKAGES_REQUESTED,
        edition,
        packageType,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, underwriter },
    };
}

export function fetchPackagesForCurrentEditionRequested (...args) {
    return {
        type: FETCH_PACKAGES_FOR_CURRENT_EDITION_REQUESTED,
        args,
    };
}

export function fetchPackagesRequestSucceeded (edition, packageType, data, order = {}, filters = {}) {
    return {
        type: FETCH_PACKAGES_REQUEST_SUCCEEDED,
        edition,
        packageType,
        data,
        order,
        filters,
    };
}

export function fetchPackagesRequestFailed (edition, packageType) {
    return {
        type: FETCH_PACKAGES_REQUEST_FAILED,
        edition,
        packageType,
    };
}

export function fetchPackagesByStateRequested (
    edition,
    stateCode,
    {
        pagination: {
            perPage = BROWSE_PER_PAGE_DEFAULT,
            page = 1,
        } = {},
        order: {
            orderBy = BROWSE_ORDER_BY_DEFAULT_FIELD,
            orderByDir = BROWSE_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            search = null,
            type = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: FETCH_PACKAGES_BY_STATE_REQUESTED,
        edition,
        stateCode,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { search, type, underwriter },
    };
}

export function fetchPackagesByStateForCurrentEditionRequested (...args) {
    return {
        type: FETCH_PACKAGES_BY_STATE_FOR_CURRENT_EDITION_REQUESTED,
        args,
    };
}

export function fetchPackagesByStateRequestSucceeded (edition, stateCode, data, order = {}, filters = {}) {
    return {
        type: FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED,
        edition,
        stateCode,
        data,
        order,
        filters,
    };
}

export function fetchPackagesByStateRequestFailed (edition, stateCode) {
    return {
        type: FETCH_PACKAGES_BY_STATE_REQUEST_FAILED,
        edition,
        stateCode,
    };
}
