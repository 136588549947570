import React from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    recentDownloadsForCurrentEditionRequested,
    recentDownloadsForUsersEditionsRequested,
} from '../../stores/recentDownloads/actions';
import { convertDataForRecentDownloads } from '../../utils/tableUtils';
import Pagination from '../../components/Pagination';
import {
    getRecentDownloadsData,
    getRecentDownloadsLoading,
    getRecentDownloadsPage,
    getRecentDownloadsPerPage,
    getRecentDownloadsTotal,
} from '../../selectors/recentDownloads';
import { styles } from './styles';
import { getAuthToken } from '../../selectors/auth';

const RecentDownloadsTableTitle = (props) => {
    return (
        <div>
            <Typography variant="h4" {...props} />
        </div>
    );
};

class RecentDownloadsTable extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        date: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
    };

    getOptions = () => ({
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: 'none',
        pagination: false,
    });

    getColumns = () => ([
        {
            name: 'Type',
            options: {
                sort: false,
                setCellProps: () => ({ className: this.props.classes.typeCol }),
            },
        },
        {
            name: 'State/Region',
            options: {
                sort: false,
                setCellProps: () => ({ className: this.props.classes.stateCol }),
            },
        },
        {
            name: 'Underwriter Name',
            options: {
                sort: false,
                setCellProps: () => ({ className: this.props.classes.underwriterNameCol }),
            },
        },
        {
            name: 'Package Name',
            options: {
                sort: false,
                setCellProps: () => ({ className: this.props.classes.packageNameCol }),
            },
        },
        {
            name: 'Downloaded At',
            options: {
                setCellProps: () => ({ className: this.props.classes.downloadedAtCol }),
                sort: false,
            },
        },
        {
            name: 'packageId',
            options: {
                display: false,
                filter: false,
                sort: false,
                download: false,
            },
        },
    ]);

    render () {
        const { classes, date, data } = this.props;
        return (
            <MUIDataTable
                columns={this.getColumns()}
                data={data}
                options={this.getOptions()}
                title={<RecentDownloadsTableTitle className={classes.dateTitle}>{date}</RecentDownloadsTableTitle>}
            />
        );
    }
}

const RecentDownloadsTables = ({ classes, groupedData = {}, token = null }) => {
    const keys = Object.keys(groupedData);
    if (keys.length > 0) {
        return keys.map((key) => {
            const groupData = groupedData[key];
            const data = convertDataForRecentDownloads(groupData, token);
            return (
                <RecentDownloadsTable
                    classes={classes}
                    date={key}
                    data={data}
                    key={groupData[0].id}
                />
            );
        });
    } else {
        return (
            <strong>
                You have no Recent Downloads.
            </strong>
        );
    }
};

RecentDownloadsTables.propTypes = {
    classes: PropTypes.object.isRequired,
    groupedData: PropTypes.object.isRequired,
    token: PropTypes.string,
};

class RecentDownloads extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        onRecentDownloadsForAllEditionsRequested: PropTypes.func.isRequired,
        onRecentDownloadsRequested: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        total: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
        perPage: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        token: PropTypes.string,
    };

    static defaultProps = {
        token: null,
    };

    componentDidMount () {
        this.props.onRecentDownloadsForAllEditionsRequested();
    }

    onPageChange = (page) => {
        this.props.onRecentDownloadsRequested(page);
    };

    render () {
        const { classes, loading, total, page, perPage, data, token } = this.props;
        return (
            <div className={classes.recentDownloads}>
                <Typography variant="h1" className={classes.title}>
                    Recent Downloads
                </Typography>
                <Pagination
                    page={page}
                    perPage={perPage}
                    total={total}
                    onChange={this.onPageChange}
                />
                {loading && <CircularProgress />}
                {!loading &&
                <div>
                    <RecentDownloadsTables
                        classes={classes}
                        groupedData={data}
                        total={total}
                        token={token}
                    />
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        loading: getRecentDownloadsLoading(state),
        total: getRecentDownloadsTotal(state),
        page: getRecentDownloadsPage(state),
        perPage: getRecentDownloadsPerPage(state),
        data: getRecentDownloadsData(state),
        token: getAuthToken(state),
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        onRecentDownloadsForAllEditionsRequested: (...args) => {
            dispatch(recentDownloadsForUsersEditionsRequested(...args));
        },
        onRecentDownloadsRequested: (...args) => {
            dispatch(recentDownloadsForCurrentEditionRequested(...args));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(RecentDownloads);
