import {
    FETCH_SUGGESTED_DOWNLOADS_REQUEST_FAILED,
    FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED,
    FETCH_SUGGESTED_DOWNLOADS_REQUESTED,
} from './actions';
import { createApiPageData } from '../../utils/api';
import { BROWSE_PER_PAGE_DEFAULT } from '../../constants/api';
import EDITIONS from '../../constants/editions';

export const suggestedPackagesInitialState = {
    ...(EDITIONS.reduce((editionsState, edition) => {
        return {
            ...editionsState,
            [edition]: createApiPageData({
                perPage: BROWSE_PER_PAGE_DEFAULT,
                loading: false,
                order: {},
                filters: {},
            }),
        };
    }, {})),
};

export default function (state = suggestedPackagesInitialState, action) {
    const { edition } = action;
    switch (action.type) {
        case FETCH_SUGGESTED_DOWNLOADS_REQUESTED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    loading: true,
                    filters: action.filters || {},
                    order: action.order || {},
                },
            };
        case FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    ...createApiPageData(action.data.result),
                    order: action.order || {},
                    filters: action.filters || {},
                    loading: false,
                },
            };
        case FETCH_SUGGESTED_DOWNLOADS_REQUEST_FAILED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    loading: false,
                },
            };
        default:
            return state;
    }
}
