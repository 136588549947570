import { createSelector } from 'reselect';
import { getEdition } from './edition';
import { denormalize } from 'normalizr';
import { Faqs, InfoVideos } from '../schemas';
import { getEntities } from './entities';

export const createHelpTypeRawDataSelector = (type) => {
    return createSelector(
        (state) => state.help,
        getEdition,
        (help, edition) => help[edition][type],
    );
};

export const getFaqRawData = createHelpTypeRawDataSelector('faq');
export const getVideosRawData = createHelpTypeRawDataSelector('videos');

export const isFaqLoading = createSelector(
    getFaqRawData,
    ({ loading }) => loading,
);
export const areVideosLoading = createSelector(
    getVideosRawData,
    ({ loading }) => loading,
);

export const getFaq = createSelector(
    getFaqRawData,
    getEntities,
    ({ data }, entities) => denormalize(data, Faqs, entities),
);
export const getVideos = createSelector(
    getVideosRawData,
    getEntities,
    ({ data }, entities) => denormalize(data, InfoVideos, entities),
);
