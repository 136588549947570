export const styles = {
    breadcrumb: {
        marginBottom: 0,
        display: 'inline-block',
    },
    breadcrumbActive: {
        color: '#6089BD',
    },
    breadcrumbsDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    arrow: {
        width: 10,
        height: 'auto',
        color: '#002244',
        marginLeft: 5,
        marginRight: 5,
    },
};
