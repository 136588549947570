export const styles = () => ({
    dropdown: {
        marginBottom: 8,
        marginTop: 9,
        marginLeft: -2,
        width: 350,
    },
    dropdownInputLabel: {
        marginLeft: -14,
        marginBottom: 0,
        marginTop: 7,
        width: 450,
        textTransform: 'uppercase',
    },
    preferences: {
        minWidth: '100%',
        width: '100%',
        paddingTop: '4px !important',
    },
    formBox: {
        paddingTop: '4px !important',
    },
});
