import { call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { requestWithAuth } from '../utils/api';
import {
    FETCH_UNDERWRITERS,
    fetchUnderwritersSucceeded,
} from '../stores/underwriters/actions';
import { getAuthToken } from '../selectors/auth';
import { parseAndNormalizeJsonResponse, Underwriters } from '../schemas';

function * fetchUnderwriters () {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/underwriters/all',
        'GET',
        token,
    );
    if (response.ok) {
        const data = yield call(
            parseAndNormalizeJsonResponse,
            response,
            Underwriters,
        );
        yield put(fetchUnderwritersSucceeded(data));
    } else {
        yield put(
            snackbar.show({
                message:
                    'Failed to fetch underwriters from server. Please try again later.',
            }),
        );
    }
}

export function * listenFetchUnderwriters () {
    yield takeEvery(FETCH_UNDERWRITERS, fetchUnderwriters);
}
