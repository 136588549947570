import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import MountainIcon from '../../icons/icon-mountains-light-blue.svg';
import FlagIcon from '../../icons/icon-flag-light-blue.svg';
import BuildingIcon from '../../icons/icon-building-light-blue.svg';
import QuillIcon from '../../icons/icon-quill-light-blue.svg';
import styles from './styles';
import ViewInfoDialog from '../ViewInfoDialog';

export const BrowseAllButton = withStyles(styles)(({ handleBrowseAllButtonClick, classes }) => {
    return (
        <Button
            variant="outlined"
            color="primary"
            className={classes.tableTitleButton}
            onClick={handleBrowseAllButtonClick}
        >
            BROWSE ALL
        </Button>
    );
});

BrowseAllButton.propTypes = {
    handleBrowseAllButtonClick: PropTypes.func.isRequired,
};

export const EmptyTableBrowseAllButton = withStyles(styles)(({ onClick, classes }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            className={classNames(classes.emptyTableButton, classes.emptyTableBrowseButton)}
        >
            BROWSE ALL
        </Button>
    );
});

EmptyTableBrowseAllButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export const EmptyTableResetButton = withStyles(styles)(({ onClick, classes }) => {
    return (
        <Button
            color="primary"
            onClick={onClick}
            className={classes.emptyTableButton}
        >
            RESET FILTERS
        </Button>
    );
});

EmptyTableResetButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export const ViewInfoButton =
    withStyles(styles)(({ classes, onClick, infoDialogOpen, onCloseInfoDialog, dialogTitle, dialogText }) => {
        return (
            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.tableTitleButton}
                    onClick={onClick}
                >
                    VIEW INFO
                </Button>
                <ViewInfoDialog
                    title={dialogTitle}
                    text={dialogText}
                    onClose={onCloseInfoDialog}
                    open={infoDialogOpen}
                />
            </div>
        );
    });

ViewInfoButton.propTypes = {
    classes: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    infoDialogOpen: PropTypes.bool.isRequired,
    onCloseInfoDialog: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    dialogText: PropTypes.string.isRequired,
};

export const UnderwriterTitle = withStyles(styles)(({ button, classes }) => {
    return (
        <div className={classes.packagesHeading}>
            <div className={classes.packagesTitle}>
                <img src={QuillIcon} alt="Underwriter Packages" className={classes.tableTitleIcon} />
                <Typography variant="h4" className={classes.packagesTitleTypography}>Underwriter Packages</Typography>
            </div>
            {button}
        </div>
    );
});

UnderwriterTitle.propTypes = {
    button: PropTypes.object.isRequired,
};

export const StateTitle = withStyles(styles)(({ button, classes }) => {
    return (
        <div className={classes.packagesHeading}>
            <div className={classes.packagesTitle}>
                <img src={BuildingIcon} alt="State Packages" className={classes.tableTitleIcon} />
                <Typography variant="h4" className={classes.packagesTitleTypography}>State Packages</Typography>
            </div>
            {button}
        </div>
    );
});

StateTitle.propTypes = {
    button: PropTypes.object.isRequired,
};

export const NationalTitle = withStyles(styles)(({ button, classes }) => {
    return (
        <div className={classes.packagesHeading}>
            <div className={classes.packagesTitle}>
                <img src={FlagIcon} alt="National Packages" className={classes.tableTitleIcon} />
                <Typography variant="h4" className={classes.packagesTitleTypography}>National Packages</Typography>
            </div>
            {button}
        </div>
    );
});

NationalTitle.propTypes = {
    button: PropTypes.object.isRequired,
};

export const LandTitleTitle = withStyles(styles)(({ button, classes }) => {
    return (
        <div className={classes.packagesHeading}>
            <div className={classes.packagesTitle}>
                <img src={MountainIcon} alt="Land Title Packages" className={classes.tableTitleIcon} />
                <Typography variant="h4" className={classes.packagesTitleTypography}>Land Title Packages</Typography>
            </div>
            {button}
        </div>
    );
});

LandTitleTitle.propTypes = {
    button: PropTypes.object.isRequired,
};

export const ByStateTitle = withStyles(styles)(({ stateName, classes }) => {
    return (
        <div className={classes.packagesHeading}>
            <div className={classes.packagesTitle}>
                <img src={BuildingIcon} alt="By State Packages" className={classes.tableTitleIcon} />
                <Typography variant="h4" className={classes.packagesTitleTypography}>
                    {stateName} Packages
                </Typography>
            </div>
        </div>
    );
});

ByStateTitle.propTypes = {
    stateName: PropTypes.string.isRequired,
};
