import PropTypes from 'prop-types';
import { apiPageDataShape } from './api';

export const tablePageData = PropTypes.shape({
    // Extends the API page data.
    ...apiPageDataShape,
    // Indicates whether the table data is loading.
    loading: PropTypes.bool.isRequired,
});

export const TablesPropTypes = {
    tablePageData,
};

export default TablesPropTypes;
