import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { LOGIN, loginFailed, loginSucceeded, LOGOUT, logout } from '../stores/auth/actions';
import { check, request } from '../utils/api';
import { getAuthToken } from '../selectors/auth';
import { normalize, States, Underwriters } from '../schemas';
import { isClosingPassportPreferred } from '../utils';
import { initializationSucceeded } from '../stores/app/actions';

function * doLogin ({ email, password }) {
    const response = yield call(request, '/auth/login', 'POST', { email, password });
    if (response.ok) {
        const { token, user, states, promptsFileUrl, underwriters } = yield call([response, response.json]);
        yield put(loginSucceeded(
            token,
            user,
            yield call(normalize, states, States),
            promptsFileUrl,
            yield call(normalize, underwriters, Underwriters),
        ));
    } else {
        yield put(loginFailed());
    }
}

export function * doCheck (token = null) {
    const checkResponse = yield call(check, token || (yield select(getAuthToken)));
    if (!checkResponse) {
        yield put(logout());
        return {};
    } else {
        const { token, user, states, editions, underwriters, promptsFileUrl } = checkResponse;
        yield put(loginSucceeded(
            token,
            user,
            yield call(normalize, states, States),
            promptsFileUrl,
            yield call(normalize, underwriters, Underwriters),
        ));
        return { states, editions, underwriters, promptsFileUrl };
    }
}

export function * login () {
    yield takeLatest(LOGIN, doLogin);
}

function * maybeRedirectToClosingPassport ({ logout = false } = {}) {
    const preferClosingPassport = `${process.env.REACT_APP_PREFER_CLOSING_PASSPORT}`;
    const closingPassportUrl = `${process.env.REACT_APP_CLOSING_PASSPORT_URL}?logout=true`;
    if (isClosingPassportPreferred()) {
        window.location.href = closingPassportUrl;
    } else {
        yield put(initializationSucceeded());
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.info(
                'Not redirecting to closing passport since REACT_APP_PREFER_CLOSING_PASSPORT' +
                `env variable is a false-like string value (${preferClosingPassport}), ` +
                `or REACT_APP_CLOSING_PASSPORT_URL (${closingPassportUrl}) is not set. `,
            );
        }
    }
}

function * redirectOnLogout () {
    yield call(maybeRedirectToClosingPassport, { logout: true });
}

export default function * authSaga () {
    yield all([
        call(login),
        takeLatest(LOGOUT, redirectOnLogout),
    ]);
}
