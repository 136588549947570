import {
    DOWNLOAD_PROMPTS_FILE_FAILED,
    DOWNLOAD_PROMPTS_FILE_REQUESTED,
    DOWNLOAD_PROMPTS_FILE_SUCCEEDED,
    UPLOAD_PROMPTS_FILE_FAILED,
    UPLOAD_PROMPTS_FILE_REQUESTED,
    UPLOAD_PROMPTS_FILE_SUCCEEDED,
} from './actions';
import { LOGOUT } from '../auth/actions';

const promptsFileInitialState = {
    upload: {},
    url: '',
    uploading: false,
    uploadSucceeded: false,
    downloading: false,
};

export const promptsFile = (state = promptsFileInitialState, action) => {
    const { type, upload, url } = action;
    switch (type) {
        case UPLOAD_PROMPTS_FILE_REQUESTED:
            return {
                ...state,
                uploading: true,
                uploadSucceeded: false,
            };
        case UPLOAD_PROMPTS_FILE_SUCCEEDED:
            return {
                ...state,
                uploading: false,
                upload,
                url,
                uploadSucceeded: true,
            };
        case UPLOAD_PROMPTS_FILE_FAILED:
            return {
                ...state,
                uploading: false,
                uploadSucceeded: false,
            };
        case DOWNLOAD_PROMPTS_FILE_REQUESTED:
            return {
                ...state,
                downloading: true,
            };
        case DOWNLOAD_PROMPTS_FILE_SUCCEEDED:
            return {
                ...state,
                downloading: false,
                upload,
                url,
            };
        case DOWNLOAD_PROMPTS_FILE_FAILED:
            return {
                ...state,
                downloading: false,
            };
        case LOGOUT:
            return promptsFileInitialState;
        default:
            return state;
    }
};
