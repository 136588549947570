export const styles = (theme) => ({
    settings: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        ...theme.softpro.safeBox,
        marginTop: 0,
    },
    settingsHeader: {
        marginBottom: 40,
    },
    innerSettings: {
        display: 'flex',
        flexDirection: 'column',
        flexFlow: 'row wrap',
        minHeight: 'calc(100% - 365px)',
        height: 'calc(100% - 365px)',
        overflowX: 'auto',
        overflowY: 'auto',
        alignContent: 'space-between',
        justifyContent: 'space-evenly',
    },
    divider: {
        margin: '20px 0',
    },
});
