import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { getEntities } from './entities';
import { Packages } from '../schemas';
import { convertBrowseTableData } from '../utils/tableUtils';
import { getEdition } from './edition';
import {
    PACKAGE_TYPE_LAND_TITLE,
    PACKAGE_TYPE_NATIONAL,
    PACKAGE_TYPE_STATE,
    PACKAGE_TYPE_UNDERWRITER,
} from '../constants/packageTypes';
import { has } from '../utils';
import { createApiPageData } from '../utils/api';
import { createContextHasFiltersSelector, transformApiPageToContext } from './api';
import { getAuthToken } from './auth';

export const createPackagesPageSelector = (rawPageSelector) => {
    return createSelector(
        rawPageSelector,
        getEntities,
        (page, entities) => ({
            ...page,
            data: denormalize(page.data, Packages, entities),
        }),
    );
};

const createPackagesPageForTableSelector = (pageSelector) => {
    return createSelector(
        pageSelector,
        getAuthToken,
        (packages, token) => ({
            ...packages,
            data: convertBrowseTableData(packages.data, token || null),
        }),
    );
};

export const createPackagesContextForTableSelector = (pageSelector) => createSelector(
    pageSelector,
    transformApiPageToContext,
);

// Suggested Packages
export const getRawSuggestedPackagesForCurrentEdition = createSelector(
    (state) => state.packages.suggested,
    getEdition,
    (suggested, edition) => suggested[edition],
);
export const getSuggestedPackages = createPackagesPageSelector(getRawSuggestedPackagesForCurrentEdition);
export const getSuggestedPackagesForTable = createPackagesPageForTableSelector(getSuggestedPackages);
export const getSuggestedPackagesContext = createPackagesContextForTableSelector(getSuggestedPackages);
export const doSuggestedPackagesHaveFilters = createContextHasFiltersSelector(getSuggestedPackagesContext);

// Browse All Packages
const createRawBrowseAllPageSelector = (packageType) => {
    return createSelector(
        (state) => state.packages.browseAll,
        getEdition,
        (browseAllPackages, edition) => browseAllPackages[edition][packageType],
    );
};
export const getRawUnderwritersPackages = createRawBrowseAllPageSelector(PACKAGE_TYPE_UNDERWRITER);
export const getRawStatePackages = createRawBrowseAllPageSelector(PACKAGE_TYPE_STATE);
export const getRawNationalPackages = createRawBrowseAllPageSelector(PACKAGE_TYPE_NATIONAL);
export const getRawLandTitlePackages = createRawBrowseAllPageSelector(PACKAGE_TYPE_LAND_TITLE);

export const getUnderwriterPackages = createPackagesPageSelector(getRawUnderwritersPackages);
export const getStatePackages = createPackagesPageSelector(getRawStatePackages);
export const getNationalPackages = createPackagesPageSelector(getRawNationalPackages);
export const getLandTitlePackages = createPackagesPageSelector(getRawLandTitlePackages);

export const getUnderwriterPackagesForTable = createPackagesPageForTableSelector(getUnderwriterPackages);
export const getStatePackagesForTable = createPackagesPageForTableSelector(getStatePackages);
export const getNationalPackagesForTable = createPackagesPageForTableSelector(getNationalPackages);
export const getLandTitlePackagesForTable = createPackagesPageForTableSelector(getLandTitlePackages);

export const getUnderwriterPackagesContext = createPackagesContextForTableSelector(getUnderwriterPackages);
export const getStatePackagesContext = createPackagesContextForTableSelector(getStatePackages);
export const getNationalPackagesContext = createPackagesContextForTableSelector(getNationalPackages);
export const getLandTitlePackagesContext = createPackagesContextForTableSelector(getLandTitlePackages);

export const doUnderwriterPackagesHaveFilters = createContextHasFiltersSelector(getUnderwriterPackagesContext);
export const doStatePackagesHaveFilters = createContextHasFiltersSelector(getStatePackagesContext);
export const doNationalPackagesHaveFilters = createContextHasFiltersSelector(getNationalPackagesContext);
export const doLandTitlePackagesHaveFilters = createContextHasFiltersSelector(getLandTitlePackagesContext);

// By State Packages
export const getRawByStatePackagesForCurrentEdition = createSelector(
    (state) => state.packages.byState,
    getEdition,
    (byStatePackages, edition) => byStatePackages[edition],
);
const createRawByStatePackagesSelector = (stateCodeSelector) => {
    return createSelector(
        stateCodeSelector,
        getRawByStatePackagesForCurrentEdition,
        (stateCode, currentEditionByStatePackages) => {
            return (
                has(currentEditionByStatePackages, stateCode)
                    ? currentEditionByStatePackages[stateCode]
                    : createApiPageData({ loading: false })
            );
        },
    );
};
export const createByStatePackagesSelectorsForTable = (stateCodeSelector) => {
    const getRawPackages = createRawByStatePackagesSelector(stateCodeSelector);
    const getPackages = createPackagesPageSelector(getRawPackages);
    const getTableDataForState = createPackagesPageForTableSelector(getPackages);
    const getTableContextForState = createPackagesContextForTableSelector(getPackages);
    const doesTableHaveFilters = createContextHasFiltersSelector(getTableContextForState);
    return {
        getPackages,
        getTableDataForState,
        getTableContextForState,
        doesTableHaveFilters,
    };
};
