import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
    fetchSuggestedDownloadsForCurrentEditionRequested,
    fetchSuggestedDownloadsRequested,
} from '../../stores/packages/actions';
import { addWatchlist, removeWatchlist, updateWatchlist } from '../../stores/watchlist/actions';
import {
    doSuggestedPackagesHaveFilters,
    getSuggestedPackagesContext,
    getSuggestedPackagesForTable,
} from '../../selectors/packages';
import TablesPropTypes from '../../propTypes/tables';
import styles from './styles';
import BrowseTable from '../BrowseTable';
import ApiPropTypes from '../../propTypes/api';
import { getAvailableStatesForDataTableFilter } from '../../selectors/states';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';
import { downloadMultiplePackages, downloadPackage } from '../../stores/download/actions';
import { EDITION_SELECT, EDITION_STANDARD } from '../../constants/editions';
import { getEdition } from '../../selectors/edition';

const Title = (props) => {
    const { typographyClassName, ...restProps } = props;
    return (
        <div {...restProps}>
            <Typography
                variant="h4"
                gutterBottom
                className={typographyClassName || null}
            >
                Suggested Downloads
            </Typography>
        </div>
    );
};

Title.propTypes = {
    typographyClassName: PropTypes.string,
};

class SuggestedDownloadsTable extends Component {
    static propTypes = {
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        hasFilters: PropTypes.bool,
        availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object),
        loadingUpdate: PropTypes.arrayOf(PropTypes.number),
        onFetchPackagesRequested: PropTypes.func.isRequired,
        onFetchPackagesForAllEditionsRequested: PropTypes.func.isRequired,
        onAddWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,
        availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object),
        classes: PropTypes.shape({
            suggestedDownloadsTable: PropTypes.string,
            suggestedDownloadsTitle: PropTypes.string,
            suggestedDownloadsTitleTypography: PropTypes.string,
        }).isRequired,
        edition: PropTypes.string.isRequired,
    };

    static defaultProps = {
        hasFilters: false,
        availableStatesForDataTableFilter: [],
        availableUnderwritersForDataTableFilter: [],
    };

    componentDidMount () {
        this.props.onFetchPackagesForAllEditionsRequested();
    }

    render () {
        const {
            classes,
            tableData,
            tableContext,
            hasFilters,
            loadingUpdate,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onUpdateWatchlistRequested,
            onDownloadRequested,
            onFetchPackagesRequested: onFetchDataRequested,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            edition,
        } = this.props;
        const tableProps = {
            tableData,
            tableContext,
            hasFilters,
            loadingUpdate,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onUpdateWatchlistRequested,
            onDownloadRequested,
            onFetchDataRequested,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
        };
        return (
            <BrowseTable
                {...tableProps}
                className={classes.suggestedDownloadsTable}
                displayTypeColumn
                edition={edition}
                emptyText="There are no suggested packages to show for now. Check back in later!"
                title={(
                    <Title
                        className={classes.suggestedDownloadsTitle}
                        typographyClassName={classes.suggestedDownloadsTitleTypography}
                    />
                )}
                searchable={false}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    tableData: getSuggestedPackagesForTable(state),
    tableContext: getSuggestedPackagesContext(state),
    hasFilters: doSuggestedPackagesHaveFilters(state),
    loadingUpdate: state.watchlist.loadingUpdate,
    availableStatesForDataTableFilter: getAvailableStatesForDataTableFilter(state),
    availableUnderwritersForDataTableFilter: getAvailableUnderwritersForDataTableFilter(state),
    edition: getEdition(state),
});

const mapDispatchToProps = function (dispatch) {
    return {
        onAddWatchlistRequested: (packageIds) => dispatch(addWatchlist(packageIds, false)),
        onRemoveWatchlistRequested: (packageIds) => dispatch(removeWatchlist(packageIds, false)),
        onUpdateWatchlistRequested: (...args) => dispatch(updateWatchlist(...args)),
        onDownloadRequested: (packageIds) => {
            return packageIds && packageIds.length > 1
                ? dispatch(downloadMultiplePackages(packageIds))
                : dispatch(downloadPackage(packageIds[0]));
        },
        onFetchPackagesForAllEditionsRequested: () => {
            dispatch(fetchSuggestedDownloadsRequested(EDITION_STANDARD));
            dispatch(fetchSuggestedDownloadsRequested(EDITION_SELECT));
        },
        onFetchPackagesRequested: (context = {}) => {
            dispatch(fetchSuggestedDownloadsForCurrentEditionRequested(context));
        },
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(SuggestedDownloadsTable);
