export const styles = () => ({
    dialogButton: {
        marginBottom: 10,
        marginLeft: 25,
    },
    dialogActions: {
        padding: '20px 0',
    },
    timeRangeSelect: {
        paddingBottom: 7,
        height: '32px',
        marginBottom: 7,
    },
    timeRangeSelectDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: 35,
    },
});
