export const PREFIX = 'promptsFile/';

export const UPLOAD_PROMPTS_FILE_REQUESTED = `${PREFIX}UPLOAD_PROMPTS_FILE_REQUESTED`;
export const UPLOAD_PROMPTS_FILE_FAILED = `${PREFIX}UPLOAD_PROMPTS_FILE_FAILED`;
export const UPLOAD_PROMPTS_FILE_SUCCEEDED = `${PREFIX}UPLOAD_PROMPTS_FILE_SUCCEEDED`;

export const DOWNLOAD_PROMPTS_FILE_REQUESTED = `${PREFIX}DOWNLOAD_PROMPTS_FILE_REQUESTED`;
export const DOWNLOAD_PROMPTS_FILE_FAILED = `${PREFIX}DOWNLOAD_PROMPTS_FILE_FAILED`;
export const DOWNLOAD_PROMPTS_FILE_SUCCEEDED = `${PREFIX}DOWNLOAD_PROMPTS_FILE_SUCCEEDED`;

export function uploadPromptsFileRequested (file) {
    return {
        type: UPLOAD_PROMPTS_FILE_REQUESTED,
        file,
    };
}

export function uploadPromptsFileFailed () {
    return {
        type: UPLOAD_PROMPTS_FILE_FAILED,
    };
}

export function uploadPromptsFileSucceeded (upload, url) {
    return {
        type: UPLOAD_PROMPTS_FILE_SUCCEEDED,
        upload,
        url,
    };
}

export function downloadPromptsFileRequested () {
    return {
        type: DOWNLOAD_PROMPTS_FILE_REQUESTED,
    };
}

export function downloadPromptsFileFailed () {
    return {
        type: DOWNLOAD_PROMPTS_FILE_FAILED,
    };
}

export function downloadPromptsFileSucceeded (upload = {}, url = '') {
    return {
        type: DOWNLOAD_PROMPTS_FILE_SUCCEEDED,
        upload,
        url,
    };
}
