export const PREFIX = 'onBoardingWatchlist/';
export const FETCH_SUGGESTED_WATCHLIST = `${PREFIX}FETCH_SUGGESTED_WATCHLIST`;
export const FETCH_SUGGESTED_WATCHLIST_SUCCEEDED = `${PREFIX}FETCH_SUGGESTED_WATCHLIST_SUCCEEDED`;
export const FETCH_SUGGESTED_WATCHLIST_FAILED = `${PREFIX}FETCH_SUGGESTED_WATCHLIST_FAILED`;
export const SELECT_PACKAGES_FOR_WATCHLIST = `${PREFIX}SELECT_PACKAGES_FOR_WATCHLIST`;
export const DESELECT_PACKAGES_FOR_WATCHLIST = `${PREFIX}DESELECT_PACKAGES_FOR_WATCHLIST`;
export const ADD_PACKAGES_TO_WATCHLIST = `${PREFIX}ADD_PACKAGES_TO_WATCHLIST`;
export const REDIRECT_TO_PORTAL = `${PREFIX}REDIRECT_TO_PORTAL`;

export function fetchSuggestedWatchlist (editions, underwriterIds, states) {
    return {
        type: FETCH_SUGGESTED_WATCHLIST,
        editions,
        underwriterIds,
        states,
    };
}

export function fetchSuggestedWatchlistSucceeded (data) {
    return {
        type: FETCH_SUGGESTED_WATCHLIST_SUCCEEDED,
        data,
    };
}

export function fetchSuggestedWatchlistFailed () {
    return {
        type: FETCH_SUGGESTED_WATCHLIST_FAILED,
    };
}

export function selectPackagesForWatchlist (packageIds) {
    return {
        type: SELECT_PACKAGES_FOR_WATCHLIST,
        packageIds,
    };
}

export function deselectPackagesForWatchlist (packageIds) {
    return {
        type: DESELECT_PACKAGES_FOR_WATCHLIST,
        packageIds,
    };
}

export function addPackagesToWatchlist (packageIds) {
    return {
        type: ADD_PACKAGES_TO_WATCHLIST,
        packageIds,
    };
}

export function redirectToPortalAction () {
    return { type: REDIRECT_TO_PORTAL };
}
