import { createPackagesContextForTableSelector, createPackagesPageSelector } from './packages';
import { createSelector } from 'reselect';
import { getAuthToken } from './auth';
import { convertAdminPackageData } from '../utils/tableUtils';
import { addTokenToUrl } from '../utils/api';

export const createAdminPackagesPageForTableSelector = (pageSelector) => {
    return createSelector(
        pageSelector,
        (packages) => ({
            ...packages,
            data: convertAdminPackageData(packages.data),
        }),
    );
};

export const getRawAdminPackages = (state) => state.adminPackages;
export const getAdminPackagesPageIds = createSelector(getRawAdminPackages, ({ data }) => data);
export const getAdminPackages = createPackagesPageSelector(getRawAdminPackages);
export const getAdminPackagesForTable = createAdminPackagesPageForTableSelector(getAdminPackages);
export const getAdminPackagesContext = createPackagesContextForTableSelector(getAdminPackages);

export const getPackageEditions = (state) => {
    return state.adminPackages.package.packageData
        ? state.adminPackages.package.packageData.editions.map((edition) => edition.name)
        : [];
};

const getPackageData = (state) => state.adminPackages.package.packageData;

export const getReleaseNotesUrl = createSelector(
    getPackageData,
    getAuthToken,
    (packageData, token) => {
        return packageData && packageData.releaseNotesUrl
            ? addTokenToUrl(packageData.releaseNotesUrl, token)
            : '';
    },
);

export const getDownloadUrl = createSelector(
    getPackageData,
    getAuthToken,
    (packageData, token) => {
        return packageData && packageData.downloadUrl
            ? addTokenToUrl(packageData.downloadUrl, token)
            : '';
    },
);

const getAdminPackageDetail = (state) => state.adminPackages.package;
const createAdminPackageSelector = (field) => createSelector(
    getAdminPackageDetail,
    (detail) => detail[field],
);

export const getAdminPackageSuccessMessages = createAdminPackageSelector(
    'formSuccessMessages',
);
