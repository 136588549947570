import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { styles } from './styles';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { dateTickFormatter, labelFormatter } from '../../utils/charts';

const EmptyChart = ({ headerText }) => {
    return (
        <Typography style={styles.emptyChart}>
            No {headerText} data available for the given range of days.
        </Typography>
    );
};

EmptyChart.propTypes = {
    headerText: PropTypes.string.isRequired,
};

class AnalyticsChart extends React.Component {
    renderChart () {
        const { data } = this.props;
        return (
            <Grid item style={styles.chartGridItem}>
                <ResponsiveContainer>
                    <BarChart data={data} style={styles.chart}>
                        <CartesianGrid vertical horizontal />
                        <XAxis
                            dataKey="occurredAt"
                            tickLine={false}
                            tickFormatter={dateTickFormatter}
                            type="number"
                            scale="time"
                            domain={['dataMin - 86400000', 'dataMax + 86400000']}
                        />
                        <YAxis tickLine={false} allowDecimals={false} />
                        <Tooltip labelFormatter={labelFormatter} />
                        <Bar dataKey="total" fill="#6089BD" barSize={35} />
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        );
    }

    render () {
        const { headerText, headerCount, range, data } = this.props;
        const dataExists = data.length > 0;
        return (
            <Grid container direction="column">
                <Grid container alignItems="flex-end">
                    <Grid item>
                        <Typography
                            variant="h1"
                            gutterBottom
                            style={styles.chartHeaderCountText}
                        >
                            {headerCount}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body1"
                            style={styles.chartHeaderText}
                        >
                            {headerText} in the selected {range} days
                        </Typography>
                    </Grid>
                </Grid>
                {
                    dataExists
                        ? this.renderChart()
                        : <Grid item><EmptyChart headerText={headerText} /></Grid>
                }
            </Grid>
        );
    }
}

AnalyticsChart.propTypes = {
    headerText: PropTypes.string.isRequired,
    headerCount: PropTypes.number.isRequired,
    range: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
};

export default AnalyticsChart;
