import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import FormPropTypes from '../../propTypes/forms';
import {
    StyledAdminCreateAdminEmail,
    StyledAdminCreateAdminName,
    StyledAdminCreateAdminOrganization,
    StyledAdminCreateAdminPassword,
    StyledAdminCreateAdminPasswordConfirmation,
} from '../FormComponents';
import SimpleForm from '../SimpleForm';

class AdminCreateAdminForm extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        data: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            organization: PropTypes.string,
            password: PropTypes.string,
            passwordConfirmation: PropTypes.string,
        }),
        fieldErrors: PropTypes.shape({
            name: FormPropTypes.errors,
            email: FormPropTypes.errors,
            organization: FormPropTypes.errors,
            password: FormPropTypes.errors,
            passwordConfirmation: FormPropTypes.errors,
        }),
        formErrors: FormPropTypes.errors,
        formSuccessMessages: FormPropTypes.messages,
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        loading: false,
        data: {},
        fieldErrors: {},
        formErrors: [],
        formSuccessMessages: [],
    };

    render () {
        const {
            data: {
                name = '',
                email = '',
                organization = '',
                password = '',
                passwordConfirmation = '',
            },
            loading,
            fieldErrors,
            formErrors,
            formSuccessMessages,
            onSubmit,
            onChange,
        } = this.props;

        const formProps = {
            loading,
            fieldErrors,
            formErrors,
            formSuccessMessages,
            onSubmit,
            onChange,
            backTo: '/admin/users',
        };

        return (
            <SimpleForm {...formProps}>
                {({ createTextOnChange, getFieldErrors }) => (
                    <Fragment>
                        <Grid item>
                            <StyledAdminCreateAdminName
                                value={name}
                                onChange={createTextOnChange('name')}
                                errors={getFieldErrors('name')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAdminCreateAdminEmail
                                value={email}
                                onChange={createTextOnChange('email')}
                                errors={getFieldErrors('email')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAdminCreateAdminOrganization
                                value={organization}
                                onChange={createTextOnChange('organization')}
                                errors={getFieldErrors('organization')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAdminCreateAdminPassword
                                value={password}
                                onChange={createTextOnChange('password')}
                                errors={getFieldErrors('password')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAdminCreateAdminPasswordConfirmation
                                value={passwordConfirmation}
                                onChange={createTextOnChange('passwordConfirmation')}
                                errors={getFieldErrors('passwordConfirmation')}
                                disabled={loading}
                            />
                        </Grid>
                    </Fragment>
                )}
            </SimpleForm>
        );
    }
}

export default AdminCreateAdminForm;
