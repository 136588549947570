export const styles = (theme) => ({
    watchlist: {
        display: 'flex',
        flexDirection: 'column',
        ...theme.softpro.safeBox,
        marginTop: 0,
    },
    watchlistTitle: {
        marginBottom: 32,
    },
});
