import { call, select, takeEvery } from 'redux-saga/effects';
import { generateUrlWithToken } from '../utils/api';
import { getAuthToken } from '../selectors/auth';
import { DOWNLOAD_MULTIPLE_PACKAGES, DOWNLOAD_PACKAGE } from '../stores/download/actions';

function * doDownload ({ packageId }) {
    const token = yield select(getAuthToken);
    const downloadUrl = yield call(generateUrlWithToken, `/download/package/${packageId}`, token, { packageId });
    yield call([window, window.open], downloadUrl);
}

function * downloadMultiple ({ packageIds }) {
    const token = yield select(getAuthToken);
    const downloadUrl = yield call(generateUrlWithToken, '/download/packages', token, { packageIds });
    yield call([window, window.open], downloadUrl);
}

export function * listenDownload () {
    yield takeEvery(DOWNLOAD_PACKAGE, doDownload);
    yield takeEvery(DOWNLOAD_MULTIPLE_PACKAGES, downloadMultiple);
}
