export const styles = (theme) => ({
    root: {
        width: 'auto',
        marginLeft: 50,
        marginRight: 50,
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
    stateChips: {
        display: 'flex',
        overflow: 'scroll',
        overflowY: 'auto',
        overflowX: 'auto',
        marginTop: 20,
        width: 500,
        maxWidth: 500,
        maxHeight: 200,
    },
    stateDropDown: {
        width: 300,
        marginTop: 20,
    },
});
