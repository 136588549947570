import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsChart from '../AnalyticsChart';
import { styles } from '../Analytics/styles';
import { CircularProgress, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { sumTotals } from '../../utils';
import { bindActionCreators, compose } from 'redux';
import { updateRange } from '../../stores/admin/actions';
import { analyticsDataRequested, analyticsExportRequested } from '../../stores/analytics/actions';
import { connect } from 'react-redux';
import { NOTIFICATIONS } from '../../constants/analytics';
import { getNotificationsContext, getNotificationsData, getRange } from '../../selectors/analytics';
import { getNotificationsChartData } from '../../selectors/adminDashboard';
import SoftProAdminDataTable from '../SoftProDataTable/SoftProAdminDataTable';
import { ExportButton } from '../ExportButton';
import { getAvailableStatesForDataTableFilter } from '../../selectors/states';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';
import TablesPropTypes from '../../propTypes/tables';

const NotificationsChart = (props) => {
    const { data, range } = props;
    return (
        <AnalyticsChart
            data={ data }
            headerText="Notifications"
            range={ range }
            headerCount={ sumTotals(data) }
        />
    );
};

NotificationsChart.propTypes = {
    data: TablesPropTypes.tablePageData.isRequired,
    range: PropTypes.number.isRequired,
};

class NotificationsTabContent extends React.Component {
    componentDidMount () {
        const { analyticsDataRequested } = this.props;
        analyticsDataRequested(NOTIFICATIONS);
    }

    render () {
        const {
            classes,
            range,
            data,
            counts,
            analyticsDataRequested,
            context,
            tableDataLoading,
            chartDataLoading,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            analyticsExportRequested,
        } = this.props;
        return (
            <Grid container className={classes.topGrid}>
                <Grid container spacing={8} direction="column">
                    <Grid item>
                        {chartDataLoading
                            ? <CircularProgress />
                            : <NotificationsChart range={ range } data={ counts } />}
                    </Grid>
                    <Grid item>
                        <SoftProAdminDataTable
                            dataType={ NOTIFICATIONS }
                            hasNumberOfNotificationsColumn
                            tableData={ data }
                            tableContext={ context }
                            onFetchDataRequested={ analyticsDataRequested }
                            availableStatesForDataTableFilter={ availableStatesForDataTableFilter }
                            hasUnderwriterColumn
                            availableUnderwritersForDataTableFilter={ availableUnderwritersForDataTableFilter }
                            viewPath={'/admin/notifications/package/'}
                        />
                    </Grid>
                    <Grid item>
                        <ExportButton
                            loading={ tableDataLoading }
                            onClick={ () => analyticsExportRequested(NOTIFICATIONS, context) }
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

NotificationsTabContent.propTypes = {
    range: PropTypes.number.isRequired,
    data: TablesPropTypes.tablePageData.isRequired,
    context: PropTypes.object.isRequired,
    counts: PropTypes.array.isRequired,
    tableDataLoading: PropTypes.bool.isRequired,
    chartDataLoading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    analyticsDataRequested: PropTypes.func.isRequired,
    availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
    analyticsExportRequested: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        range: getRange(state),
        counts: getNotificationsChartData(state),
        data: getNotificationsData(state),
        context: getNotificationsContext(state),
        tableDataLoading: state.analytics.notifications.loading,
        chartDataLoading: state.adminDashboardData.loading,
        availableStatesForDataTableFilter: getAvailableStatesForDataTableFilter(state),
        availableUnderwritersForDataTableFilter: getAvailableUnderwritersForDataTableFilter(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(bindActionCreators({
            updateRange,
            analyticsDataRequested,
            analyticsExportRequested,
        }, dispatch)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(NotificationsTabContent);
