import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import {
    CircularProgress,
    Chip,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    InputBase,
    Select,
    MenuItem,
    Button,
    Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Clear as ClearIcon } from '@mui/icons-material';
import deepEqual from 'deep-equal';
import { styles } from './styles';
import { getCurrentUserEmail } from '../../selectors/currentUser';
import { fetchUserSettings, updateUserSettings } from '../../stores/settings/actions';

export const NotificationStatus = ({ classes, notifications, onChange }) => {
    return (
        <FormControl variant="filled">
            <InputLabel
                variant="outlined"
                className={classes.dropdownInputLabel}
            >
                Notification Status
            </InputLabel>
            <Select
                disableUnderline
                value={notifications === 'off' ? 'disabled' : 'enabled'}
                variant="filled"
                className={classes.dropdown}
                onChange={onChange}
            >
                <MenuItem value="enabled">Enabled</MenuItem>
                <MenuItem value="disabled">Disabled</MenuItem>
            </Select>
        </FormControl>
    );
};

NotificationStatus.propTypes = {
    classes: PropTypes.object.isRequired,
    notifications: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const NotificationFrequency = ({ classes, notifications, onChange }) => {
    const value = notifications !== 'off' ? notifications : 'frequency';
    return (
        <FormControl variant="filled">
            <InputLabel
                variant="outlined"
                className={classes.dropdownInputLabel}
            >
                Notification Frequency
            </InputLabel>
            <Select
                disableUnderline
                value={value}
                variant="filled"
                className={classes.dropdown}
                onChange={onChange}
            >
                <MenuItem value="daily"> Daily </MenuItem>
                <MenuItem value="weekly"> Weekly </MenuItem>
                <MenuItem value="monthly"> Monthly </MenuItem>
            </Select>
        </FormControl>
    );
};

NotificationFrequency.propTypes = {
    classes: PropTypes.object.isRequired,
    notifications: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const RecipientEmailEntry = ({ classes, onEmailInputChange, addEmail }) => {
    return (
        <div className={classes.recipientsEmailEntry}>
            <FormControl variant="outlined">
                <InputLabel shrink>Email Address</InputLabel>
                <InputBase
                    fullWidth
                    id="email"
                    type="email"
                    onChange={onEmailInputChange}
                />
            </FormControl>
            <AddButton
                addEmail={addEmail}
                classes={classes}
            />
        </div>
    );
};

RecipientEmailEntry.propTypes = {
    classes: PropTypes.object.isRequired,
    onEmailInputChange: PropTypes.func.isRequired,
    addEmail: PropTypes.func.isRequired,
};

const AddButton = (props) => {
    const { classes } = props;
    return (
        <div className={classes.addButtonDiv}>
            <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.addButton}
                onClick={props.addEmail}
            >
                Add +
            </Button>
        </div>
    );
};

AddButton.propTypes = {
    classes: PropTypes.object.isRequired,
    addEmail: PropTypes.func.isRequired,
};

export const ResetButton = (props) => {
    const { classes } = props;
    return (
        <div className={classes.resetButtonDiv}>
            <InputLabel shrink className={classes.inputLabel}>
                Delete All Notification Preferences
            </InputLabel>
            <Button
                variant="contained"
                size="small"
                className={classes.resetButton}
                type="reset"
            >
                Reset
            </Button>
        </div>
    );
};

ResetButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

const SaveButton = (props) => {
    const { classes } = props;
    return (
        <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.saveButton}
            type="submit"
        >
            Save
        </Button>
    );
};

SaveButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

class ManageNotificationsForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        notificationEmails: PropTypes.array.isRequired,
        notifications: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        updateUserSettings: PropTypes.func.isRequired,
    };

    state = {
        emails: [...this.props.notificationEmails],
        inputEmail: '',
        notifications: this.props.notifications,
    };

    onEmailInputChange (event) {
        const newValue = event.target.value;
        this.setState({ ...this.state, inputEmail: newValue });
    }

    addEmail = () => {
        const { emails, inputEmail } = this.state;
        const usersEmail = this.props.email;
        if (inputEmail && !emails.includes(inputEmail) && usersEmail !== inputEmail) {
            this.setState({
                ...this.state,
                emails: [...emails, inputEmail],
            });
        }
    };

    removeEmail = (email) => {
        const { emails } = this.state;
        if (email && emails.includes(email)) {
            this.setState({
                ...this.state,
                emails: emails.filter((existingEmail) => existingEmail !== email),
            });
        }
    };

    onNotificationStatusChange = (value) => {
        if (value === 'disabled') {
            this.setState({ ...this.state, notifications: 'off' });
        } else if (value === 'enabled') {
            this.setState({ ...this.state, notifications: 'monthly' });
        }
    };

    onNotificationFrequencyChange = (value) => {
        this.setState({ ...this.state, notifications: value });
    };

    onSubmit = (event) => {
        event.preventDefault();
        const { notifications, emails } = this.state;
        const { updateUserSettings } = this.props;
        const storedEmails = this.props.notificationEmails;
        const storedNotifications = this.props.notifications;
        const notificationsChanged = notifications !== storedNotifications;
        const emailsChanged = !deepEqual(storedEmails, emails);
        if (emailsChanged || notificationsChanged) {
            updateUserSettings(
                {
                    notifications,
                    notificationEmails: emails,
                },
            );
        }
    };

    onReset = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            emails: [],
            notifications: 'daily',
        });
    };

    render () {
        const { classes, email } = this.props;
        const { emails, notifications } = this.state;
        /**
         * Add back in prompts for unsaved changes
         * const storedEmails = this.props.notificationEmails;
         * const storedNotifications = this.props.notifications;
         */
        return (
            <div>
                <Grid
                    container
                    item
                    direction="column"
                    component="form"
                    onSubmit={(event) => this.onSubmit(event)}
                    onReset={(event) => this.onReset(event)}
                >
                    <Grid item className="recipients">
                        <Typography
                            className={classes.subheading1}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Notification Recipients
                        </Typography>
                        <RecipientEmailEntry
                            addEmail={() => this.addEmail()}
                            onEmailInputChange={(newEmail) => this.onEmailInputChange(
                                newEmail)}
                            classes={classes}
                        />
                        <Grid item className="recipientChips">
                            <Chip
                                key={email}
                                label={email}
                                variant="outlined"
                                className={classes.disabledChipStyle}
                            />
                            {emails.map((email) => {
                                return (
                                    <Chip
                                        key={email}
                                        label={email}
                                        deleteIcon={<ClearIcon />}
                                        onDelete={() => this.removeEmail(email)}
                                        variant="outlined"
                                        className={classes.chipStyle}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.preferences}>
                        <Typography
                            className={classes.subheading2}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Notification Preferences
                        </Typography>
                        <Grid item>
                            <Grid item>
                                <NotificationStatus
                                    classes={classes}
                                    notifications={notifications}
                                    onChange={(event) => this.onNotificationStatusChange(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <NotificationFrequency
                                    classes={classes}
                                    notifications={notifications}
                                    onChange={(event) => this.onNotificationFrequencyChange(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <ResetButton classes={classes} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <SaveButton classes={classes} />
                    </Grid>
                </Grid>
                {/* TODO: add back Prompt for React Router v6
                <Prompt
                    message={
                        'Changes to Notification Preferences have not been saved. ' +
                        'Click Cancel to stop and Save your changes, or click OK to discard your changes.'
                    }
                    when={storedNotifications !== notifications || !deepEqual(storedEmails, emails)}
                /> */}
            </div>
        );
    }
}

class ManageNotifications extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        fetchUserSettings: PropTypes.func.isRequired,
        updateUserSettings: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        email: PropTypes.string,
        notificationEmails: PropTypes.array,
        notifications: PropTypes.string,
    };

    static defaultProps = {
        loading: false,
        email: '',
        notificationEmails: [],
        notifications: '',
    };

    componentDidMount () {
        const { fetchUserSettings } = this.props;
        fetchUserSettings();
    }

    render () {
        const { classes, loading, email, notificationEmails, notifications, updateUserSettings } = this.props;
        return (
            <Grid container item className={classes.manageNotifications}>
                <Typography variant="h1" gutterBottom>
                    Manage Notifications
                </Typography>
                {loading && <Grid item component={CircularProgress} />}
                {!loading && (
                    <ManageNotificationsForm
                        classes={classes}
                        email={email}
                        notificationEmails={notificationEmails}
                        notifications={notifications}
                        updateUserSettings={updateUserSettings}
                    />
                )}
            </Grid>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        email: getCurrentUserEmail(state),
        loading: state.settings.loading,
        notifications: state.settings.notifications,
        notificationEmails: state.settings.notificationEmails,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators(
            { fetchUserSettings, updateUserSettings }, dispatch)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(ManageNotifications);
