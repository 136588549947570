import {
    DOWNLOAD_PROMPTS_FILE_REQUESTED,
    downloadPromptsFileFailed,
    downloadPromptsFileSucceeded,
    UPLOAD_PROMPTS_FILE_REQUESTED,
    uploadPromptsFileFailed,
    uploadPromptsFileSucceeded,
} from '../stores/promptsFile/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getAuthToken } from '../selectors/auth';
import { requestWithAuth, uploadWithAuth } from '../utils/api';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { MAX_FILE_SIZE_MB } from '../constants/requests';

export function * doUploadPromptsFile ({ file }) {
    if (file.size / 1000000 > MAX_FILE_SIZE_MB) {
        yield put(snackbar.show(
            {
                message: 'File is too large, max size is ' + MAX_FILE_SIZE_MB +
                    ' MB. Please try again with a smaller file.',
            }));
        yield put(uploadPromptsFileFailed());
    } else {
        const formData = new FormData();
        formData.append('promptsFile', file);
        const token = yield select(getAuthToken);
        try {
            const response = yield call(
                uploadWithAuth,
                '/download/promptsFile',
                'POST',
                token,
                formData,
            );
            if (response.ok) {
                const { upload, url } = yield call([response, response.json]);
                yield put(uploadPromptsFileSucceeded(upload, url));
            } else {
                yield put(snackbar.show({
                    message: 'Uploading the Prompts File to the server has failed.',
                }));
                yield put(uploadPromptsFileFailed());
            }
        } catch (errors) {
            yield put(snackbar.show({
                message: 'Uploading the Prompts File to the server has failed. ' +
                    'Please ensure you only select a file and not folders or directories.',
            }));
            yield put(uploadPromptsFileFailed());
        }
    }
}

export function * doDownloadPromptsFile ({ open }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/download/promptsFile', 'GET',
        token);
    if (response.ok) {
        const { upload, url } = yield call([response, response.json]);
        if (open) {
            yield call([window, window.open], url);
        }
        yield put(downloadPromptsFileSucceeded(upload, url));
    } else {
        if (response.status === 404) {
            // This just means there's no prompts file currently set.
            yield put(downloadPromptsFileSucceeded());
        } else {
            yield put(snackbar.show({ message: 'Failed to download the Prompts File. Please try again later.' }));
            yield put(downloadPromptsFileFailed());
        }
    }
}

export function * listenPromptsFileActions () {
    yield takeEvery(UPLOAD_PROMPTS_FILE_REQUESTED, doUploadPromptsFile);
    yield takeEvery(DOWNLOAD_PROMPTS_FILE_REQUESTED, doDownloadPromptsFile);
}
