import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { styles } from './styles';
import { getDateString } from '../../utils';

const TimeRangeSelect = (props) => {
    const {
        classes,
        closeTimeRangeDialog,
        storeCustomStartDateValue,
        storeCustomEndDateValue,
        open,
        selectTimeRange,
        error,
        date,
        range,
    } = props;
    return (
        <div>
            <Typography
                className={classes.statsHeaderText}
                align="center"
                variant="body1"
            >
                { getDateString(date, range) }
            </Typography>
            <Button
                color="primary"
                variant="contained"
                size="small"
                className={classes.timeRangeSelect}
                value="custom"
                onClick={(event) => selectTimeRange(event)}
            >
                Select Dates
            </Button>
            <Dialog open={open} TransitionProps={{ onClose: () => closeTimeRangeDialog('cancel') }} >
                <DialogTitle>
                    Select Dates
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        autoFocus
                        id="customRangeStart"
                        label="Start Date"
                        type="date"
                        onChange={(event) => storeCustomStartDateValue(event)}
                        error={error}
                        helperText={error
                            ? 'Custom range cannot be empty and must have a valid Date.'
                            : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        autoFocus
                        id="customRangeEnd"
                        label="End Date"
                        type="date"
                        onChange={(event) => storeCustomEndDateValue(event)}
                        error={error}
                        helperText={error
                            ? 'Custom range cannot be empty and must have a valid Date.'
                            : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        onClick={() => closeTimeRangeDialog('cancel')}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => closeTimeRangeDialog('submit')}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

TimeRangeSelect.propTypes = {
    classes: PropTypes.object,
    storeCustomRangeValue: PropTypes.func.isRequired,
    storeCustomStartDateValue: PropTypes.func.isRequired,
    storeCustomEndDateValue: PropTypes.func.isRequired,
    closeTimeRangeDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectTimeRange: PropTypes.func.isRequired,
    customRange: PropTypes.number,
    date: PropTypes.string,
    range: PropTypes.number,
    startDate: PropTypes.string,
    error: PropTypes.bool,
};

export default withStyles(styles)(TimeRangeSelect);
