const item = {
    margin: '10px 0',
};

export default {
    container: {
        width: 'auto',
        height: '100%',
    },
    error: { ...item },
    loading: { ...item },
};
