export const ROLE_ADMIN = 'Admin';
export const ROLE_DOCUMENT_MGR = 'Document Manager'; // TODO add back to ROLES array when needed in the next phase
export const ROLE_MARKETER = 'Marketer'; // TODO add back to ROLES array when needed in the next phase
export const ROLE_CUSTOMER = 'Customer';
export const ROLES = [
    ROLE_ADMIN,
    ROLE_CUSTOMER,
];

export default ROLES;
