import {
    WATCHLIST_ORDER_BY_DEFAULT_DIR,
    WATCHLIST_ORDER_BY_DEFAULT_FIELD,
    WATCHLIST_PER_PAGE_DEFAULT,
} from '../../constants/api';

export const PREFIX = 'watchlist/';
export const FETCH_WATCHLIST = `${PREFIX}FETCH_WATCHLIST`;
export const FETCH_WATCHLIST_FOR_CURRENT_EDITION = `${PREFIX}FETCH_WATCHLIST_FOR_CURRENT_EDITION`;
export const FETCH_WATCHLIST_SUCCEEDED = `${PREFIX}FETCH_WATCHLIST_SUCCEEDED`;
export const FETCH_WATCHLIST_FAILED = `${PREFIX}FETCH_WATCHLIST_FAILED`;
export const UPDATE_WATCHLIST = `${PREFIX}UPDATE_WATCHLIST`;
export const UPDATE_WATCHLIST_SUCCEEDED = `${PREFIX}UPDATE_WATCHLIST_SUCCEEDED`;
export const UPDATE_WATCHLIST_FAILED = `${PREFIX}UPDATE_WATCHLIST_FAILED`;
export const REMOVE_WATCHLIST = `${PREFIX}REMOVE_WATCHLIST`;
export const REMOVE_WATCHLIST_SUCCEEDED = `${PREFIX}REMOVE_WATCHLIST_SUCCEEDED`;
export const REMOVE_WATCHLIST_FAILED = `${PREFIX}REMOVE_WATCHLIST_FAILED;`;
export const ADD_WATCHLIST = `${PREFIX}ADD_WATCHLIST`;
export const ADD_WATCHLIST_SUCCEEDED = `${PREFIX}ADD_WATCHLIST_SUCCEEDED`;
export const ADD_WATCHLIST_FAILED = `${PREFIX}ADD_WATCHLIST_FAILED;`;

export const FETCH_ACTIONS = [
    FETCH_WATCHLIST,
    FETCH_WATCHLIST_SUCCEEDED,
    FETCH_WATCHLIST_FAILED,
];

export const LOADING_UPDATE_START_ACTIONS = [
    ADD_WATCHLIST,
    REMOVE_WATCHLIST,
];

export const LOADING_UPDATE_DONE_ACTIONS = [
    UPDATE_WATCHLIST_SUCCEEDED,
    UPDATE_WATCHLIST_FAILED,
    ADD_WATCHLIST_SUCCEEDED,
    ADD_WATCHLIST_FAILED,
    REMOVE_WATCHLIST_SUCCEEDED,
    REMOVE_WATCHLIST_FAILED,
];

export function fetchWatchlist (
    edition,
    packageType,
    {
        pagination: {
            perPage = WATCHLIST_PER_PAGE_DEFAULT,
            page = 1,
        } = {},
        order: {
            orderBy = WATCHLIST_ORDER_BY_DEFAULT_FIELD,
            orderByDir = WATCHLIST_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            state = null,
            search = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: FETCH_WATCHLIST,
        edition,
        packageType,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, underwriter },
    };
}

export function fetchWatchlistForCurrentEdition (...args) {
    return {
        type: FETCH_WATCHLIST_FOR_CURRENT_EDITION,
        args,
    };
}

export function fetchWatchlistSucceeded (edition, packageType, data, order, filters) {
    return {
        type: FETCH_WATCHLIST_SUCCEEDED,
        edition,
        packageType,
        data,
        order,
        filters,
    };
}

export function fetchWatchlistFailed (edition, packageType) {
    return {
        type: FETCH_WATCHLIST_FAILED,
        edition,
        packageType,
    };
}

export function updateWatchlist (...args) {
    /*
     * If two arguments were provided, we convert it into the single argument form by considering the first argument
     * to be the ID of the package (or an array of package IDs) and the second argument to be the data to update
     * for that package in the watchlist (or a boolean as a shortcut to set the `notify` property)
     */
    let packages = {};
    if (args.length === 1) {
        packages = args[0];
    } else if (args.length > 1) {
        const packageIds = Array.isArray(args[0]) ? args[0] : [args[0]];
        const value = (typeof args[1] === 'object' ? args[1] : { notify: !!args[1] });
        packages = packageIds.reduce((packages, packageId) => ({
            ...packages,
            [packageId]: value,
        }), {});
    }
    return {
        type: UPDATE_WATCHLIST,
        packages,
    };
}

export function updateWatchlistSucceeded (data, packageIds) {
    return {
        type: UPDATE_WATCHLIST_SUCCEEDED,
        data,
        packageIds,
    };
}

export function updateWatchlistFailed (packageIds) {
    return {
        type: UPDATE_WATCHLIST_FAILED,
        packageIds,
    };
}

export function removeWatchlist (packageIds, reloadAffected = true) {
    return {
        type: REMOVE_WATCHLIST,
        packageIds: Array.isArray(packageIds) ? packageIds : [packageIds],
        reloadAffected,
    };
}

export function removeWatchlistSucceeded (data, packageIds) {
    return {
        type: REMOVE_WATCHLIST_SUCCEEDED,
        data,
        packageIds: Array.isArray(packageIds) ? packageIds : [packageIds],
    };
}

export function removeWatchlistFailed (packageIds) {
    return {
        type: REMOVE_WATCHLIST_FAILED,
        packageIds: Array.isArray(packageIds) ? packageIds : [packageIds],
    };
}

export function addWatchlist (packageIds, reloadAffected = true) {
    return {
        type: ADD_WATCHLIST,
        packageIds: Array.isArray(packageIds) ? packageIds : [packageIds],
        reloadAffected,
    };
}

export function addWatchlistSucceeded (data, packageIds) {
    return {
        type: ADD_WATCHLIST_SUCCEEDED,
        data,
        packageIds: Array.isArray(packageIds) ? packageIds : [packageIds],
    };
}

export function addWatchlistFailed (packageIds) {
    return {
        type: ADD_WATCHLIST_FAILED,
        packageIds: Array.isArray(packageIds) ? packageIds : [packageIds],
    };
}
