import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import USAMap from 'react-usa-map';
import { Clear as ClearIcon } from '@mui/icons-material';
import convertRegion, { TO_NAME } from '../../utils/usaStatesAPI';
import { styles } from './styles';

class StateDropDown extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleSelectedState: PropTypes.func.isRequired,
        states: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string.isRequired,
                id: PropTypes.any.isRequired,
                name: PropTypes.string.isRequired,
            }),
        ).isRequired,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        loading: false,
    };

    render () {
        const { classes, handleSelectedState, states, loading } = this.props;

        return (
            <FormControl variant="filled">
                {loading
                    ? (
                        <CircularProgress size={24} color="primary" />
                    )
                    : (
                        <Select
                            displayEmpty
                            value=""
                            variant="filled"
                            onChange={handleSelectedState}
                            className={classes.stateDropDown}
                        >
                            <MenuItem value="">Select a state.</MenuItem>
                            {states.map((state) => {
                                return (
                                    <MenuItem value={state.code} key={state.id}>
                                        {state.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
            </FormControl>
        );
    }
}

class MapSelectorApp extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        states: PropTypes.array.isRequired,
        fetchStates: PropTypes.func.isRequired,
        availableStates: PropTypes.array.isRequired,
        handleStateUpdate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        direction: PropTypes.string.isRequired,
        isLargeScreen: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: false,
    };

    constructor (props) {
        super(props);
        this.mapHandler = this.mapHandler.bind(this);
        this.handleSelectedState = this.handleSelectedState.bind(this);
    }

    mapHandler ({ target } = {}) {
        const selectedStateCode = target.getAttribute('data-name');
        if (selectedStateCode) {
            this.updateStateList(selectedStateCode);
        }
    }

    handleDelete = (data) => () => {
        this.props.handleStateUpdate(data);
    };

    handleSelectedState ({ target: { value: selectedStateCode } }) {
        if (selectedStateCode) {
            this.updateStateList(selectedStateCode);
        }
    }

    updateStateList = (stateCode) => {
        this.props.handleStateUpdate(stateCode);
    };

    statesCustomConfig = () => {
        const selections = this.props.states;
        const fillWithColor = { fill: '#6089BD' };
        const colors = {};
        for (const stateCode in selections) {
            colors[selections[stateCode]] = fillWithColor;
        }
        return colors;
    };

    componentDidMount () {
        this.props.fetchStates();
    }

    render () {
        const { classes, availableStates, loading, direction, isLargeScreen } =
            this.props;
        return (
            <Grid
                container
                wrap="nowrap"
                justifyContent="center"
                className={classes.root}
            >
                {isLargeScreen && (
                    <Grid item>
                        <USAMap
                            onClick={this.mapHandler}
                            width={600}
                            height={380}
                            customize={this.statesCustomConfig()}
                            title={null}
                        />
                    </Grid>
                )}
                <Grid
                    container
                    justifyContent="center"
                    spacing={8}
                    direction={direction}
                >
                    <Grid item>
                        <Typography variant="h5" align="center">
                            <b>
                                {' '}
                                Choose the state(s) <br /> you do business in.{' '}
                            </b>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <StateDropDown
                                classes={classes}
                                handleSelectedState={this.handleSelectedState}
                                states={availableStates}
                                loading={loading}
                            />
                        </Grid>
                        <Grid
                            container
                            justifyContent="center"
                            className={classes.stateChips}
                            alignContent="flex-start"
                        >
                            {this.props.states.map((stateCode) => {
                                const name =
                                    typeof stateCode === 'string'
                                        ? stateCode
                                        : stateCode?.code;
                                const label = convertRegion(name, TO_NAME);
                                return (
                                    <Grid item key={stateCode}>
                                        <Chip
                                            key={stateCode}
                                            label={label}
                                            onDelete={this.handleDelete(
                                                stateCode,
                                            )}
                                            deleteIcon={<ClearIcon />}
                                            className={classes.chip}
                                            variant="outlined"
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const MapSelectorAppFunction = (props) => {
    const direction = useMediaQuery((theme) => theme.breakpoints.down('lg'))
        ? 'row'
        : 'column';
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    return (
        <MapSelectorApp
            {...props}
            direction={direction}
            isLargeScreen={isLargeScreen}
        />
    );
};

export default withStyles(styles)(MapSelectorAppFunction);
