import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { getEntities } from './entities';
import { Watchlists } from '../schemas';
import {
    PACKAGE_TYPE_LAND_TITLE,
    PACKAGE_TYPE_NATIONAL,
    PACKAGE_TYPE_STATE,
    PACKAGE_TYPE_UNDERWRITER,
} from '../constants/packageTypes';
import { convertWatchlistTableData } from '../utils/tableUtils';
import { createContextHasFiltersSelector, transformApiPageToContext } from './api';
import { getAuthToken } from './auth';

const createCurrentEditionWatchlistSelector = (packageType) => {
    return createSelector(
        (state) => state.watchlist[state.edition.edition][packageType],
        getEntities,
        (watchlistPageEnvelope, entities) => {
            return {
                ...watchlistPageEnvelope,
                data: denormalize(watchlistPageEnvelope.data, Watchlists, entities),
            };
        },
    );
};

const createCurrentEditionWatchlistTableSelector = (watchlistSelector) => {
    return createSelector(
        watchlistSelector,
        getAuthToken,
        (watchlistData, token) => {
            return {
                ...watchlistData,
                data: convertWatchlistTableData(watchlistData.data, token || null),
            };
        },
    );
};

export const getUnderwriterPackages = createCurrentEditionWatchlistSelector(PACKAGE_TYPE_UNDERWRITER);
export const getStatePackages = createCurrentEditionWatchlistSelector(PACKAGE_TYPE_STATE);
export const getNationalPackages = createCurrentEditionWatchlistSelector(PACKAGE_TYPE_NATIONAL);
export const getLandTitlePackages = createCurrentEditionWatchlistSelector(PACKAGE_TYPE_LAND_TITLE);

export const getUnderwriterPackagesForTables = createCurrentEditionWatchlistTableSelector(getUnderwriterPackages);
export const getStatePackagesForTables = createCurrentEditionWatchlistTableSelector(getStatePackages);
export const getNationalPackagesForTables = createCurrentEditionWatchlistTableSelector(getNationalPackages);
export const getLandTitlePackagesForTables = createCurrentEditionWatchlistTableSelector(getLandTitlePackages);

/**
 * @function
 * @type {function(edition: string, packageType: string): function}
 */
export const createCurrentContextSelector = (() => {
    const cache = new Map();

    return (edition, packageType) => {
        const cacheKey = `${edition}.${packageType}`;
        let selector = cache.get(cacheKey);
        if (!selector) {
            selector = createSelector(
                (state) => state.watchlist[edition][packageType],
                transformApiPageToContext,
            );
            cache.set(cacheKey, selector);
        }
        return selector;
    };
})();

/**
 * @type {function(packageType: string): function}
 */
const createCurrentEditionCurrentContextSelector = (() => {
    const cache = new Map();

    return (packageType) => {
        let selector = cache.get(packageType);
        if (!selector) {
            selector = createSelector(
                (state) => state.watchlist[state.edition.edition][packageType],
                ({ currentPage: page, perPage, order, filters }) => ({
                    pagination: { perPage, page },
                    order,
                    filters,
                }),
            );
            cache.set(packageType, selector);
        }
        return selector;
    };
})();

export const getCurrentUnderwriterContext = createCurrentEditionCurrentContextSelector(PACKAGE_TYPE_UNDERWRITER);
export const getCurrentStateContext = createCurrentEditionCurrentContextSelector(PACKAGE_TYPE_STATE);
export const getCurrentNationalContext = createCurrentEditionCurrentContextSelector(PACKAGE_TYPE_NATIONAL);
export const getCurrentLandTitleContext = createCurrentEditionCurrentContextSelector(PACKAGE_TYPE_LAND_TITLE);

export const doesUnderwriterCurrentlyHaveFilters = createContextHasFiltersSelector(getCurrentUnderwriterContext);
export const doesStateCurrentlyHaveFilters = createContextHasFiltersSelector(getCurrentStateContext);
export const doesNationalCurrentlyHaveFilters = createContextHasFiltersSelector(getCurrentNationalContext);
export const doesLandTitleCurrentlyHaveFilters = createContextHasFiltersSelector(getCurrentLandTitleContext);
