export const styles = (theme) => ({
    addButton: {
        textTransform: 'uppercase',
        letterSpacing: 1,
        width: 140,
        height: 30,
        fontSize: 10,
        marginBottom: 0,
        marginRight: 0,
        [theme.breakpoints.down('xl')]: {
            width: 35,
            marginTop: '-3px',
            marginLeft: 0,
            fontSize: 8,
        },
    },
    downloadButtonWrapper: {},
    downloadButton: {
        textTransform: 'uppercase',
        letterSpacing: 1,
        width: 120,
        minHeight: 22,
        padding: '5px 10px',
        fontSize: 10,
        marginBottom: 0,
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    downloadIconButton: {
        display: 'none',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
        },
    },
    actions: {
        width: 250,
    },
    actionsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    actionsFormControl: {
        width: 'auto',
    },
    trash: {
        color: '#F33E36',
        [theme.breakpoints.down('xl')]: {
            marginLeft: 0,
        },
    },
    disabled: {
        opacity: 0.3,
        cursor: 'not-allowed',
    },
    releaseNotes: {
        display: 'flex',
        justifyContent: 'left',
        [theme.breakpoints.down('xl')]: {
            marginLeft: 5,
        },
    },
    notifyCheckbox: {
        display: 'flex',
        justifyContent: 'flex-start !important',
        minWidth: 100,
        [theme.breakpoints.down('xl')]: {
            minWidth: 0,
            justifyContent: 'center',
        },
    },
    notifyMeText: {
        marginLeft: 9,
        fontWeight: 'bold',
        color: '#002244',
        display: 'inline !important',
        [theme.breakpoints.down('xl')]: {
            display: 'none',
        },
    },
    releaseNotesDialogTitle: {
        backgroundColor: '#6089BD',
        color: '#fff',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    releaseNotesDialogButton: {
        width: 105,
        minHeight: 8,
        padding: '8px 10px',
        marginLeft: 10,
        fontSize: 10,
        fontWeight: 'bold',
        letterSpacing: 0,
    },
    releaseNotesDialogButtons: {
        justifyContent: 'flex-end',
        marginBottom: 10,
        paddingTop: 10,
        borderTop: '1px solid lightgrey',
        width: '90%',
        alignSelf: 'center',
    },
    releaseNotesDialogText: {
        width: '50%',
        alignSelf: 'center',
        maxHeight: 500,
    },
    releaseNotesDialogContent: {
        display: 'flex',
    },
});
