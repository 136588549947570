import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import createRootReducer from './reducers';
import rootSaga from '../sagas';
import createPersistConfig from '../config/persist';
import { createReduxHistoryContext } from 'redux-first-history';

/**
 *
 * @param preloadedState
 * @return {{store: any, persistor: Persistor, history: {} }}
 */
export default function (preloadedState) {
    // Create browser history object for tracking browser state
    const history = createBrowserHistory();

    // Create saga middleware for connecting the store to our root saga
    const sagaMiddleware = createSagaMiddleware();

    // Generate the config for redux-persist
    const persistConfig = createPersistConfig();

    // Create our root reducer which is dependent on the browser history state
    const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history });

    const rootReducer = createRootReducer(routerReducer);

    // Enhance our root reducer with redux-persist persistence capabilities
    const persistedRootReducer = persistReducer(persistConfig, rootReducer);

    // Gather a list of our middleware
    const middleware = [
        routerMiddleware,
        sagaMiddleware,
    ];

    // Create our store with our enhanced root reducer, all of our middleware, and dev tools
    const store = configureStore({
        reducer: persistedRootReducer,
        preloadedState,
        middleware: [...middleware],
    });

    // Create our store persistor
    const persistor = persistStore(store);

    // Run the root saga
    sagaMiddleware.run(rootSaga, persistor);

    // Return both our store and its persistor
    return { store, persistor, history: createReduxHistory(store) };
}
