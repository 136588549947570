import React, { Fragment } from 'react';
import { Button, IconButton, Paper, Switch as UISwitch, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectIcon from '../../icons/logo-edition-select.png';
import StandardIcon from '../../icons/logo-edition-standard.png';
import { Settings as SettingsIcon } from '@mui/icons-material';
import softProLogo from '../../icons/softpro-logo.png';
import PropTypes from 'prop-types';
import styles from './styles';
import { doesUserHaveMultipleEditions, getCurrentUserName } from '../../selectors/currentUser';
import { logout } from '../../stores/auth/actions';
import { updateEdition } from '../../stores/edition/actions';
import { EDITION_NAMES, EDITION_SELECT, EDITION_STANDARD } from '../../constants/editions';
import { withRouter } from '../../utils';

const BackToSoftProButton = () => {
    return (
        <Button variant="outlined" size="large" color="primary" href="http://www.softprocorp.com">
            BACK TO SOFTPROCORP.COM
        </Button>
    );
};

const SettingsIconButton = withStyles(styles)(({ classes, ...restProps }) => (
    <IconButton
        component={Link}
        className={classes.settingsIcon}
        {...restProps}
        size="large"
    >
        <SettingsIcon color="primary" />
    </IconButton>
));

class RightHeader extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        edition: PropTypes.string,
        userName: PropTypes.string,
        logout: PropTypes.func,
        editionUpdate: PropTypes.func.isRequired,
        hideEditionSwitch: PropTypes.bool,
        isUserLoggedIn: PropTypes.bool,
        isOnBoardingComplete: PropTypes.bool,
        path: PropTypes.string.isRequired,
    };

    static defaultProps = {
        hideEditionSwitch: false,
        isUserLoggedIn: false,
        isOnBoardingComplete: false,
    };

    editionSwitchChanged = () => {
        const edition = this.props.edition === 'standard' ? 'select' : 'standard';
        this.props.editionUpdate(edition);
    };

    render () {
        const { classes, hideEditionSwitch, edition, userName, logout, path } = this.props;
        const toggleSwitchClass = edition === 'standard'
            ? classes.toggleSwitchStandard
            : classes.toggleSwitchSelect;
        const greeting = userName
            ? <Fragment>Welcome, <strong>{userName}</strong>!</Fragment>
            : <Fragment>Welcome!</Fragment>;
        if (this.props.isUserLoggedIn) {
            return (
                <div className={classes.rightHeader2}>
                    {this.props.isOnBoardingComplete && !hideEditionSwitch &&
                    <div className={classes.editionToggle}>
                        <Typography> Show Files for: </Typography>
                        <div className={toggleSwitchClass}>
                            <img
                                src={SelectIcon}
                                alt={EDITION_NAMES[EDITION_SELECT]}
                                width="18"
                                height="18"
                                className={classes.switchIconLeft}
                            />
                            <Typography
                                className={classes.switchText}
                            >{EDITION_NAMES[EDITION_SELECT]}
                            </Typography>
                            <UISwitch
                                color="default"
                                onChange={this.editionSwitchChanged}
                                checked={edition === EDITION_STANDARD}
                            />
                            <Typography
                                className={classes.switchText}
                            > {EDITION_NAMES[EDITION_STANDARD]}
                            </Typography>
                            <img
                                src={StandardIcon}
                                alt={EDITION_NAMES[EDITION_STANDARD]}
                                width="18"
                                height="18"
                                className={classes.switchIconRight}
                            />
                        </div>
                    </div>
                    }
                    <div className={classes.rightHeader3}>
                        <Typography color="primary" className={classes.rightHeaderText}>
                            {greeting}
                        </Typography>
                        {path.includes('/portal') && <SettingsIconButton to="/portal/settings" />}
                        {path.includes('/admin/') && <SettingsIconButton to="/admin/changePassword" />}
                        <Button
                            variant="text"
                            color="primary"
                            className={classes.logoutButton}
                            onClick={logout}
                        >
                            LOGOUT
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.softProButton}>
                    <BackToSoftProButton />
                </div>
            );
        }
    }
}

class SoftProHeader extends React.Component {
    static propTypes = {
        classes: PropTypes.shape({
            header: PropTypes.string.isRequired,
            topLogo: PropTypes.string.isRequired,
            documentsPortal: PropTypes.string.isRequired,
            documentsPortalText: PropTypes.string.isRequired,
            rightHeader: PropTypes.string.isRequired,
        }),
    };

    render () {
        const {
            classes,
            editionUpdate,
            isUserLoggedIn,
            isOnBoardingComplete,
            hideEditionSwitchForAdmins,
            edition,
            userName,
            userHasMultipleEditions,
            logout,
            router,
        } = this.props;
        const hideEditionSwitch = hideEditionSwitchForAdmins || !userHasMultipleEditions;
        return (
            <div className={classes.header}>
                <Paper elevation={5} square>
                    <div className={classes.topLogo}>
                        <img src={softProLogo} alt="SoftPro" height="50%" width="60%" />
                        <div className={classes.documentsPortal}>
                            <Typography className={classes.documentsPortalText}>
                                Documents <br /> Portal
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.rightHeader}>
                        <RightHeader
                            isUserLoggedIn={isUserLoggedIn}
                            editionUpdate={editionUpdate}
                            isOnBoardingComplete={isOnBoardingComplete}
                            hideEditionSwitch={hideEditionSwitch}
                            edition={edition}
                            userName={userName}
                            logout={logout}
                            path={router.location.pathname}
                            {...this.props}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

SoftProHeader.propTypes = {
    editionUpdate: PropTypes.func,
    isUserLoggedIn: PropTypes.bool,
    isOnBoardingComplete: PropTypes.bool,
    hideEditionSwitchForAdmins: PropTypes.bool,
    edition: PropTypes.string,
    userName: PropTypes.string,
    userHasMultipleEditions: PropTypes.bool,
    logout: PropTypes.func,
    router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
    return {
        edition: state.edition.edition,
        userName: getCurrentUserName(state),
        userHasMultipleEditions: doesUserHaveMultipleEditions(state),
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        logout: () => dispatch(logout()),
        editionUpdate: (edition) => dispatch(updateEdition(edition)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
)(SoftProHeader);
