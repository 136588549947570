import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getAdminUnderwriterDetailData,
    getAdminUnderwriterDetailFieldErrors,
    getAdminUnderwriterDetailFormErrors,
    getAdminUnderwriterDetailFormSuccessMessages,
    isAdminUnderwriterDetailLoading,
} from '../../selectors/adminUnderwriters';
import {
    adminUnderwriterDetailFieldChanged,
    adminUnderwriterDetailFormClearRequested,
    createAdminUnderwriterRequested,
    fetchAdminUnderwriterRequested,
    updateAdminUnderwriterRequested,
} from '../../stores/adminUnderwriters/actions';
import FormPropTypes from '../../propTypes/forms';
import AdminUnderwriterForm from '../../components/AdminUnderwriterForm';

class AdminUnderwriterDetail extends Component {
    static propTypes = {
        id: PropTypes.number,

        // Connect -> mapStateToProps
        loading: PropTypes.bool.isRequired,
        formErrors: FormPropTypes.errors,
        formSuccessMessages: FormPropTypes.messages,
        fieldErrors: PropTypes.shape({
            name: FormPropTypes.errors,
        }),
        underwriterData: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
        }),

        // Connect -> mapDispatchToProps
        onFetchUnderwriterRequested: PropTypes.func.isRequired,
        onClearRequested: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
    };

    static defaultProps = {
        id: null,
        underwriterData: {},
    };

    componentDidMount () {
        const { id, underwriterData, onFetchUnderwriterRequested, onClearRequested } = this.props;
        if (id) {
            if ((!underwriterData || !underwriterData.id || underwriterData.id !== id)) {
                onFetchUnderwriterRequested(id);
            }
        } else {
            onClearRequested();
        }
    }

    onSubmit = () => {
        const { id } = this.props;
        this.props.onSave(id);
    };

    render () {
        const {
            loading,
            formErrors,
            formSuccessMessages,
            fieldErrors,
            underwriterData,
            onFieldChange,
        } = this.props;

        const formProps = {
            data: underwriterData,
            loading,
            formErrors,
            formSuccessMessages,
            fieldErrors,
            onSubmit: this.onSubmit,
            onChange: onFieldChange,
        };

        return <AdminUnderwriterForm {...formProps} />;
    }
}

const mapStateToProps = (state) => ({
    loading: isAdminUnderwriterDetailLoading(state),
    formErrors: getAdminUnderwriterDetailFormErrors(state),
    formSuccessMessages: getAdminUnderwriterDetailFormSuccessMessages(state),
    fieldErrors: getAdminUnderwriterDetailFieldErrors(state),
    underwriterData: getAdminUnderwriterDetailData(state),
});

const mapDispatchToProps = (dispatch) => ({
    onFetchUnderwriterRequested: (id) => dispatch(fetchAdminUnderwriterRequested(id)),
    onClearRequested: () => dispatch(adminUnderwriterDetailFormClearRequested()),
    onSave: (id = null) => {
        /*
         * TODO I don't like this. The only reason this is necessary is the sagas that are kicked off
         *      by these actions aren't aware of the current route. Since the router is connected, this should
         *      be pretty straightforward to fix so that this component doesn't need to be aware of the ID
         *      at all.
         */
        dispatch(id ? updateAdminUnderwriterRequested(id) : createAdminUnderwriterRequested());
    },
    onFieldChange: (field, value) => dispatch(adminUnderwriterDetailFieldChanged(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUnderwriterDetail);
