import { createSelector } from 'reselect';

const createAdminCreateAdminSelector = (field) => createSelector(
    (state) => state.adminCreateAdmin,
    (adminCreateAdminState) => adminCreateAdminState[field],
);

export const isAdminCreateAdminLoading = createAdminCreateAdminSelector('creating');
export const getAdminCreateAdminData = createAdminCreateAdminSelector('data');
export const getAdminCreateAdminFormErrors = createAdminCreateAdminSelector('formErrors');
export const getAdminCreateAdminFormSuccessMessages = createAdminCreateAdminSelector('formSuccessMessages');
export const getAdminCreateAdminFieldErrors = createAdminCreateAdminSelector('fieldErrors');
