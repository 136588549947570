import { createSelector } from 'reselect';

const createChangePasswordSelector = (field) => createSelector(
    (state) => state.changePassword,
    (changePassword) => changePassword[field],
);

export const isChangePasswordLoading = createChangePasswordSelector('saving');
export const getChangePasswordData = createChangePasswordSelector('data');
export const getChangePasswordFormErrors = createChangePasswordSelector('formErrors');
export const getChangePasswordFormSuccessMessages = createChangePasswordSelector('formSuccessMessages');
export const getChangePasswordFieldErrors = createChangePasswordSelector('fieldErrors');
