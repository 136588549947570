import { call, put, select, takeEvery } from 'redux-saga/effects';
import { requestWithAuth } from '../utils/api';
import { FETCH_FAQ, fetchFaqFailed, fetchFaqSucceeded } from '../stores/help/actions';
import { getAuthToken } from '../selectors/auth';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { Faqs, parseAndNormalizeJsonResponse } from '../schemas';

function * fetchFaq ({ edition }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/faq', 'GET', token, { edition });
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, Faqs);
        yield put(fetchFaqSucceeded(edition, data));
    } else {
        yield put(snackbar.show({ message: 'Retrieving the FAQ from the server has failed.' }));
        yield put(fetchFaqFailed());
    }
}

export function * listenFetchFaq () {
    yield takeEvery(FETCH_FAQ, fetchFaq);
}
