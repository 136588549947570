import { createSelector } from 'reselect';
import { convertDataForDownloadHistory, convertDataForUsers } from '../utils/tableUtils';
import ROLES from '../constants/roles';

const createUsersTableSelector = () => {
    return createSelector(
        (state) => state.users.data,
        (data) => convertDataForUsers(data),
    );
};

export const getUsersData = createUsersTableSelector();

const createUserDataSelector = () => {
    return createSelector(
        (state) => state.users.userData,
        (data) => data,
    );
};

export const getUserData = createUserDataSelector();

/*
 * We are assuming below that a user is only in ONE role
 * - which at this time means there are no uwers that are both Admin and Customer.
 * But that should be OK considering we already make that assumption
 * when redirecting users after successful login for example.
 */
export const getUserRole = (state) => {
    const { users: { userData = {} } } = state;
    const { roleNames } = userData;
    return roleNames
        ? roleNames.length === 1
            ? roleNames[0]
            : roleNames.filter((roleName) => ROLES.includes(roleName)).join('')
        : '';
};

export const getSpecificUserDataLoading = (state) => state.users.specificUserLoading;

export const getUserUpdating = (state) => state.users.userUpdating;

const createValidationErrorSelector = (field) => {
    return createSelector(
        (state) => state.users.validationErrors[field],
        (data) => data && data.length > 0,
    );
};

export const getEmailErrors = createValidationErrorSelector('email');

const createUserDownloadHistorySelector = () => {
    return createSelector(
        (state) => state.users.downloadHistory.data,
        (data) => convertDataForDownloadHistory(data),
    );
};

export const getUserDownloadHistory = createUserDownloadHistorySelector();

const createUserSelector = (field) => createSelector(
    (state) => state.users,
    (users) => users[field],
);

export const getUserSuccessMessages = createUserSelector(
    'formSuccessMessages',
);
