export const TABLE_ACTION_ADD = 'ADD';
export const TABLE_ACTION_REMOVE = 'REMOVE';
export const TABLE_ACTION_UPDATE_DO_NOT_NOTIFY = 'DO_NOT_NOTIFY';
export const TABLE_ACTION_UPDATE_DO_NOTIFY = 'DO_NOTIFY';
export const TABLE_ACTION_DOWNLOAD = 'DOWNLOAD';
export const TABLE_ACTION_DELETE = 'DELETE';

export const TABLE_ACTIONS = [
    TABLE_ACTION_ADD,
    TABLE_ACTION_REMOVE,
    TABLE_ACTION_UPDATE_DO_NOTIFY,
    TABLE_ACTION_UPDATE_DO_NOT_NOTIFY,
    TABLE_ACTION_DOWNLOAD,
];

export const WATCHLIST_TABLE_ACTIONS = [
    TABLE_ACTION_UPDATE_DO_NOTIFY,
    TABLE_ACTION_UPDATE_DO_NOT_NOTIFY,
    TABLE_ACTION_REMOVE,
    TABLE_ACTION_DOWNLOAD,
];

export const BROWSE_TABLE_ACTIONS = [
    TABLE_ACTION_ADD,
    TABLE_ACTION_REMOVE,
    TABLE_ACTION_UPDATE_DO_NOTIFY,
    TABLE_ACTION_UPDATE_DO_NOT_NOTIFY,
    TABLE_ACTION_DOWNLOAD,
];

export const ADMIN_PACKAGE_TABLE_ACTIONS = [
    TABLE_ACTION_DELETE,
];

export const TABLE_ACTIONS_LABELS = {
    [TABLE_ACTION_ADD]: 'Watch selected',
    [TABLE_ACTION_REMOVE]: 'Unwatch selected',
    [TABLE_ACTION_UPDATE_DO_NOTIFY]: 'Notify for selected',
    [TABLE_ACTION_UPDATE_DO_NOT_NOTIFY]: 'Don\'t notify for selected',
    [TABLE_ACTION_DOWNLOAD]: 'Download selected',
    [TABLE_ACTION_DELETE]: 'Delete selected',
};
