export default (theme) => ({
    ...theme.softpro.tableColumns,
    notificationsIcon: {
        width: '0.9em',
        height: '0.9em',
        verticalAlign: 'bottom',
    },
    input: {
        width: 175,
        maxWidth: 175,
    },
    addColDiv: {
        display: 'flex',
        justifyContent: 'center',
    },
    trashIconInAddCol: {
        marginLeft: 0,
    },
    noDownloadAllowedIcon: {
        fill: theme.palette.secondary.main,
    },
});
