import { LOGIN_FAILED, LOGIN_SUCCEEDED, LOGOUT, ON_BOARDING_COMPLETED } from '../auth/actions';
import { transformUser } from '../../utils/api';
import { UPDATE_USER_SETTINGS_SUCCEEDED } from '../settings/actions';

export const currentUserInitialState = {
    data: null,
    roles: [],
    editions: [],
    states: [],
    underwriters: [],
};

export default function (state = currentUserInitialState, action) {
    const { roles: oldRoles, editions: oldEditions, states: oldStates, underwriters: oldUnderwriters } = state;
    switch (action.type) {
        case LOGIN_SUCCEEDED: // Intentional fall-through to ser user from both LOGIN_SUCCEEDED and ONBOARDING_COMPLETED
        case ON_BOARDING_COMPLETED:
            return transformUser(action.user, oldRoles, oldEditions, oldStates, oldUnderwriters);
        case LOGIN_FAILED: // Intentional fall-through to reset state on login failed
        case LOGOUT:
            return currentUserInitialState;
        case UPDATE_USER_SETTINGS_SUCCEEDED: {
            const { settings } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    name: settings.name,
                },
                editions: settings.editions,
            };
        }
        default:
            return state;
    }
}
