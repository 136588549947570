import { PREFIX } from '../admin/actions';
import {
    ANALYTICS_ORDER_BY_DEFAULT_DIR,
    ANALYTICS_ORDER_BY_DEFAULT_FIELD,
    ANALYTICS_ORDER_BY_FIELD_EMAIL,
} from '../../constants/api';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';
import { USERS } from '../../constants/analytics';

export const ANALYTICS_DATA_REQUESTED = `${PREFIX}ANALYTICS_DATA_REQUESTED`;
export const ANALYTICS_DATA_REQUEST_FAILED = `${PREFIX}ANALYTICS_DATA_REQUEST_FAILED`;
export const ANALYTICS_DATA_REQUEST_SUCCEEDED = `${PREFIX}ANALYTICS_DATA_REQUEST_SUCCEEDED`;
export const ANALYTICS_USER_DATA_REQUEST_SUCCEEDED = `${PREFIX}ANALYTICS_USER_DATA_REQUEST_SUCCEEDED`;

export const ANALYTICS_DATA_FOR_PACKAGE_REQUESTED = `${PREFIX}ANALYTICS_DATA_FOR_PACKAGE_REQUESTED`;
export const ANALYTICS_DATA_FOR_PACKAGE_REQUEST_FAILED = `${PREFIX}ANALYTICS_DATA_FOR_PACKAGE_REQUEST_FAILED`;
export const ANALYTICS_DATA_FOR_PACKAGE_REQUEST_SUCCEEDED = `${PREFIX}ANALYTICS_DATA_FOR_PACKAGE_REQUEST_SUCCEEDED`;

export const DOWNLOADS_FOR_USER_REQUESTED = `${PREFIX}DOWNLOADS_FOR_USER_REQUESTED`;
export const DOWNLOADS_FOR_USER_REQUEST_FAILED = `${PREFIX}DOWNLOADS_FOR_USER_REQUEST_FAILED`;
export const DOWNLOADS_FOR_USER_REQUEST_SUCCEEDED = `${PREFIX}DOWNLOADS_FOR_USER_REQUEST_SUCCEEDED`;

export const ANALYTICS_EXPORT_REQUESTED = `${PREFIX}ANALYTICS_EXPORT_REQUESTED`;
export const ANALYTICS_EXPORT_FOR_PACKAGE_REQUESTED = `${PREFIX}ANALYTICS_EXPORT_FOR_PACKAGE_REQUESTED`;
export const DOWNLOADS_EXPORT_FOR_USER_REQUESTED = `${PREFIX}DOWNLOADS_EXPORT_FOR_USER_REQUESTED`;

export function analyticsDataRequested (
    dataType,
    {
        pagination: {
            perPage = DEFAULT_ROWS_PER_PAGE,
            page = 1,
        } = {},
        order: {
            orderBy = ANALYTICS_ORDER_BY_DEFAULT_FIELD,
            orderByDir = ANALYTICS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            state = null,
            search = null,
            type = null,
            underwriter = null,
        } = {},
    } = {},
) {
    if (USERS === dataType && ANALYTICS_ORDER_BY_DEFAULT_FIELD === orderBy) {
        // Special case. Only difference needed for users is the default sort by field so switch it here.
        orderBy = ANALYTICS_ORDER_BY_FIELD_EMAIL;
    }
    return {
        type: ANALYTICS_DATA_REQUESTED,
        dataType,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, type, underwriter },
    };
}

export function analyticsDataRequestFailed (dataType) {
    return {
        type: ANALYTICS_DATA_REQUEST_FAILED,
        dataType,
    };
}

export function analyticsDataRequestSucceeded (dataType, data, order = {}, filters = {}) {
    return {
        type: ANALYTICS_DATA_REQUEST_SUCCEEDED,
        dataType,
        data,
        order,
        filters,
    };
}

export function analyticsUserDataRequestSucceeded (users, data, order = {}, filters = {}) {
    return {
        type: ANALYTICS_USER_DATA_REQUEST_SUCCEEDED,
        users,
        data,
        order,
        filters,
    };
}

export function analyticsExportRequested (
    dataType,
    {
        order: {
            orderBy = ANALYTICS_ORDER_BY_DEFAULT_FIELD,
            orderByDir = ANALYTICS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            state = null,
            search = null,
            type = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: ANALYTICS_EXPORT_REQUESTED,
        dataType,
        order: { orderBy, orderByDir },
        filters: { state, search, type, underwriter },
    };
}

export function analyticsDataForPackageRequested (
    dataType,
    packageId,
    {
        pagination: {
            perPage = DEFAULT_ROWS_PER_PAGE,
            page = 1,
        } = {},
        order: {
            orderBy = ANALYTICS_ORDER_BY_FIELD_EMAIL,
            orderByDir = ANALYTICS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            search = null,
        } = {},
    } = {},
) {
    return {
        type: ANALYTICS_DATA_FOR_PACKAGE_REQUESTED,
        dataType,
        packageId,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { search },
    };
}

export function analyticsDataForPackageRequestSucceeded (dataType, name, data, order = {}, filters = {}) {
    return {
        type: ANALYTICS_DATA_FOR_PACKAGE_REQUEST_SUCCEEDED,
        dataType,
        name,
        data,
        order,
        filters,
    };
}

export function analyticsDataForPackageRequestFailed (dataType) {
    return {
        type: ANALYTICS_DATA_FOR_PACKAGE_REQUEST_FAILED,
        dataType,
    };
}

export function analyticsExportForPackageRequested (
    dataType,
    packageId,
    {
        order: {
            orderBy = ANALYTICS_ORDER_BY_FIELD_EMAIL,
            orderByDir = ANALYTICS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            search = null,
        } = {},
    } = {},
) {
    return {
        type: ANALYTICS_EXPORT_FOR_PACKAGE_REQUESTED,
        dataType,
        packageId,
        order: { orderBy, orderByDir },
        filters: { search },
    };
}

export function downloadsDataForUserRequested (
    userId,
    {
        pagination: {
            perPage = DEFAULT_ROWS_PER_PAGE,
            page = 1,
        } = {},
        order: {
            orderBy = ANALYTICS_ORDER_BY_DEFAULT_FIELD,
            orderByDir = ANALYTICS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            search = null,
            state = null,
            type = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: DOWNLOADS_FOR_USER_REQUESTED,
        userId,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, type, underwriter },
    };
}

export function downloadsForUserRequestSucceeded (email, data, order = {}, filters = {}) {
    return {
        type: DOWNLOADS_FOR_USER_REQUEST_SUCCEEDED,
        email,
        data,
        order,
        filters,
    };
}

export function downloadsForUserRequestFailed () {
    return {
        type: DOWNLOADS_FOR_USER_REQUEST_FAILED,
    };
}

export function downloadsExportForUserRequested (
    userId,
    {
        order: {
            orderBy = ANALYTICS_ORDER_BY_FIELD_EMAIL,
            orderByDir = ANALYTICS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            search = null,
            state = null,
            type = null,
            underwriter = null,
        } = {},
    } = {},
) {
    return {
        type: DOWNLOADS_EXPORT_FOR_USER_REQUESTED,
        userId,
        order: { orderBy, orderByDir },
        filters: { state, search, type, underwriter },
    };
}
