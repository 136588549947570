import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PlayArrow as ArrowIcon } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { bindActionCreators, compose } from 'redux';
import { getUrl, withRouter } from '../../utils';
import { styles } from './styles';
import { connect } from 'react-redux';
import { analyticsDataForPackageRequested, analyticsExportForPackageRequested } from '../../stores/analytics/actions';
import { DOWNLOADS } from '../../constants/analytics';
import {
    getDownloadsContextForPackage,
    getDownloadsDataForPackage,
    getDownloadsForPackageName,
} from '../../selectors/analytics';
import SoftProDataTable from '../../components/SoftProDataTable';
import { ANALYTICS_ORDER_BY_FIELD_EMAIL } from '../../constants/api';
import { SORT_DESC } from '../../components/DataTable/constants';
import DataTableColumn from '../../components/DataTable/DataTableColumn';
import { ViewButton } from '../../components/ViewButton';
import { ExportButton } from '../../components/ExportButton';
import TablesPropTypes from '../../propTypes/tables';
import EmptyTable from '../../components/EmptyTable';

class DownloadsTable extends React.Component {
    renderEmail = ({ email }) => email;
    renderLastDownloaded = ({ lastDownloaded }) => lastDownloaded;
    renderNumberOfDownloads = ({ numberOfDownloads }) => numberOfDownloads;
    renderViewButton = ({ userId }) => (
        <ViewButton
            onClick={() => this.props.navigate(
                '/admin/users/userDetails/' + userId + '/userEmail',
            )}
        />
    );

    render () {
        const {
            classes,
            tableData,
            tableContext,
            analyticsDataForPackageRequested,
            packageId,
        } = this.props;
        return (
            <SoftProDataTable
                tableData={tableData}
                tableContext={tableContext}
                getKey={({ userId }) => userId}
                onFetchDataRequested={(context) => analyticsDataForPackageRequested(DOWNLOADS, packageId, context)}
                renderEmpty={() => <EmptyTable />}
            >
                {({ createOnSort, createSortGetter }) => (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="Email"
                            classes={{ headCell: classes.emailAutoCol, bodyCell: classes.emailAutoCol }}
                            getSort={createSortGetter(ANALYTICS_ORDER_BY_FIELD_EMAIL)}
                            onSort={createOnSort(ANALYTICS_ORDER_BY_FIELD_EMAIL)}
                            initialSort={SORT_DESC}
                        >
                            {this.renderEmail}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Last Downloaded"
                            classes={{ headCell: classes.lastDownloadedCol, bodyCell: classes.lastDownloadedCol }}
                        >
                            {this.renderLastDownloaded}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Number of Downloads"
                            classes={{ headCell: classes.countCol, bodyCell: classes.countCol }}
                        >
                            {this.renderNumberOfDownloads}
                        </DataTableColumn>
                        <DataTableColumn classes={{ headCell: classes.viewCol, bodyCell: classes.viewCol }}>
                            {this.renderViewButton}
                        </DataTableColumn>
                    </Fragment>
                )}
            </SoftProDataTable>
        );
    }
}

DownloadsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    tableData: TablesPropTypes.tablePageData,
    tableContext: PropTypes.object.isRequired,
    analyticsDataForPackageRequested: PropTypes.func.isRequired,
    packageId: PropTypes.string.isRequired,
};

class DownloadsDetails extends React.Component {
    componentDidMount () {
        const { packageId, analyticsDataForPackageRequested } = this.props;
        analyticsDataForPackageRequested(DOWNLOADS, packageId);
    }

    render () {
        const {
            classes,
            navigate,
            tableData,
            tableContext,
            analyticsDataForPackageRequested,
            analyticsExportForPackageRequested,
            loading,
            packageId,
            name,
            router,
        } = this.props;
        const BreadcrumbDivider = () => <ArrowIcon className={classes.arrow} />;
        return (
            <Grid container direction="column" className={classes.root}>
                <Grid container justifyContent="space-between" wrap="nowrap">
                    <Grid container alignItems="center" alignContent="center">
                        <Grid item>
                            <Link
                                to="/admin/analytics"
                                className={classes.breadcrumb}
                            >
                                Analytics
                            </Link>
                        </Grid>
                        <Grid item component={BreadcrumbDivider} />
                        <Grid item>
                            <Link
                                to="/admin/analytics/downloads"
                                className={classes.breadcrumb}
                            >
                                Downloads
                            </Link>
                        </Grid>
                        <Grid item component={BreadcrumbDivider} />
                        <Grid item>
                            {loading
                                ? <CircularProgress size={14} />
                                : <Link to={getUrl(window, router)} className={classes.breadcrumb}> {name} </Link>}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BackButton onClick={() => navigate(-1)} />
                    </Grid>
                </Grid>
                <Grid item>
                    <DownloadsTable
                        tableContext={tableContext}
                        tableData={tableData}
                        classes={classes}
                        navigate={navigate}
                        packageId={packageId}
                        analyticsDataForPackageRequested={analyticsDataForPackageRequested}
                    />
                </Grid>
                <Grid item>
                    <ExportButton
                        loading={loading}
                        onClick={() => analyticsExportForPackageRequested(DOWNLOADS, packageId, tableContext)}
                    />
                </Grid>
            </Grid>
        );
    }
}

DownloadsDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    tableData: TablesPropTypes.tablePageData,
    tableContext: PropTypes.object.isRequired,
    analyticsDataForPackageRequested: PropTypes.func.isRequired,
    analyticsExportForPackageRequested: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    packageId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        loading: state.analytics.downloadsForPackage.loading,
        tableData: getDownloadsDataForPackage(state),
        tableContext: getDownloadsContextForPackage(state),
        name: getDownloadsForPackageName(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(
            bindActionCreators({
                analyticsDataForPackageRequested,
                analyticsExportForPackageRequested,
            },
            dispatch)
        ),
    };
};

const DownloadsDetailsFunction = (props) => {
    const { packageId } = useParams();
    const navigate = useNavigate();
    return <DownloadsDetails {...props} packageId={packageId} navigate={navigate} />;
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(DownloadsDetailsFunction);
