export const styles = {
    chartHeaderCountText: {
        fontWeight: 'bold',
        fontSize: 64,
        marginLeft: 35,
    },
    chartHeaderText: {
        marginLeft: 20,
        marginBottom: 30,
    },
    chart: {
        marginLeft: -35,
    },
    emptyChart: {
        backgroundColor: '#DFDFDF',
        padding: '100px 20px',
        textAlign: 'center',
    },
    chartGridItem: {
        height: 265,
    },
};
