import { createSelector } from 'reselect';
import { convertTopUsers } from '../utils/tableUtils';
import { convertChartData, convertDatesToTimestamps } from '../utils/charts';
import { DOWNLOADS, NOTIFICATIONS, UPLOADS } from '../constants/analytics';

const createTopUsersTableSelector = () => {
    return createSelector(
        (state) => state.adminDashboardData.topThree,
        (data) => convertTopUsers(data),
    );
};

export const getTopUsersData = createTopUsersTableSelector();

const createChartSelector = (dataType, dateKey = 'occurredAt') => {
    return createSelector(
        (state) => state.adminDashboardData[dataType],
        (data) => {
            data = convertDatesToTimestamps(data, dateKey);
            return convertChartData(data, dateKey);
        },
    );
};

export const getDownloadsChartData = createChartSelector(DOWNLOADS);
export const getUploadsChartData = createChartSelector(UPLOADS, 'createdAt');
export const getNotificationsChartData = createChartSelector(NOTIFICATIONS);

export const getDashboardLoading = (state) => state.adminDashboardData.loading;
