export const styles = (theme) => ({
    manageNotifications: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        ...theme.softpro.safeBox,
        marginTop: 0,
    },
    recipientsEmailEntry: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 495,
        width: 495,
        marginBottom: 8,
    },
    subheading1: {
        marginLeft: 0,
        marginBottom: 25,
        fontWeight: 'bold',
        color: '#002244',
        fontSize: '1.4em',
        marginTop: 20,
    },
    subheading2: {
        marginLeft: 0,
        fontWeight: 'bold',
        color: '#002244',
        fontSize: '1.4em',
        marginTop: 20,
    },
    dropdown: {
        marginBottom: 8,
        marginTop: 9,
        marginLeft: -2,
        width: 350,
    },
    inputLabel: {
        marginLeft: 7,
        textTransform: 'uppercase',
        left: 0,
    },
    resetButton: {
        backgroundColor: '#F33E36',
        color: 'white',
        fontSize: 18,
        marginTop: 12,
    },
    resetButtonDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 25,
        width: 450,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: 350,
        },
    },
    addButtonDiv: {
        paddingLeft: 25,
        justifySelf: 'center',
        alignSelf: 'flex-end',
    },
    addButton: {
        width: 100,
        height: 43,
    },
    saveButton: {
        fontSize: 18,
    },
    chipStyle: {
        marginRight: 8,
        marginBottom: 8,
    },
    disabledChipStyle: {
        marginRight: 8,
        marginBottom: 8,
        backgroundColor: '#DFDFDF',
    },
    dropdownInputLabel: {
        marginLeft: -14,
        marginBottom: 0,
        marginTop: 7,
        width: 450,
        textTransform: 'uppercase',
    },
    preferences: {
        minWidth: '100%',
        width: '100%',
    },
    divider: {
        margin: '40px 0 20px 0',
    },
});
