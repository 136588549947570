export default () => ({
    item: {
        width: 20,
        minWidth: 0,
        height: 'auto',
        minHeight: 0,
        fontSize: 20,
        letterSpacing: 0,
        lineHeight: 1,
        margin: '0 2px',
        fontWeight: 'normal',
        opacity: 0.3,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
    },
    activeItem: {
        fontWeight: 'bold',
        opacity: 1,
    },
    arrow: {
        width: 40,
        minWidth: 0,
    },
});
