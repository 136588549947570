import { createSelector } from 'reselect';
import { getEntities } from './entities';
import { denormalize } from 'normalizr';
import { Underwriters } from '../schemas';

export const getSettingsState = (state) => state.settings || {};

export const getSerialNumber = createSelector(
    getSettingsState,
    (settings) => settings.serialNumber || '',
);

export const areSettingsLoading = createSelector(
    getSettingsState,
    (settings) => settings.loading || false,
);

export const areStatesLoading = createSelector(
    getSettingsState,
    (settings) => settings.states.loading || false,
);

export const getEditions = createSelector(
    getSettingsState,
    (settings) => settings.editions || [],
);

export const getName = createSelector(
    getSettingsState,
    (settings) => settings.name || '',
);

export const getOrganization = createSelector(
    getSettingsState,
    (settings) => settings.organization || '',
);

export const getStates = createSelector(
    getSettingsState,
    (settings) => settings.states || [],
);

export const getRawUnderwriters = createSelector(
    getSettingsState,
    (settings) => settings.underwriters || [],
);

export const getUnderwriters = createSelector(
    getRawUnderwriters,
    getEntities,
    (underwriters, entities) => denormalize(underwriters, Underwriters, entities)
);

export const getValidationErrors = createSelector(
    getSettingsState,
    (settings) => settings.validationErrors || {},
);
