import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import styles from './styles';

const AdminButton = ({ className, classes, variant, color, size, to, onClick, ...rest }) => {
    const buttonProps = {
        variant,
        color,
        className: classNames(classes.button, className),
        size,
    };
    if (to) {
        buttonProps.to = to;
        buttonProps.component = Link;
    }
    if (onClick) {
        buttonProps.onClick = onClick;
    }
    return (
        <Button {...buttonProps} {...rest} />
    );
};

AdminButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    to: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};

AdminButton.defaultProps = {
    className: null,
    to: null,
    variant: 'contained',
    color: 'primary',
    size: 'large',
    onClick: null,
};

export default withStyles(styles)(AdminButton);
