import { LOGIN_SUCCEEDED, LOGOUT } from '../auth/actions';
import {
    PLURAL_EDITION,
    PLURAL_FAQ,
    PLURAL_INFO_VIDEO,
    PLURAL_PACKAGE,
    PLURAL_STATE,
    PLURAL_UNDERWRITER,
    PLURAL_WATCHLIST,
} from '../../constants/entities';
import { mergeEntityDatabaseForSchemas } from '../../schemas';
import { FETCH_FAQ_SUCCEEDED, FETCH_VIDEOS_SUCCEEDED } from '../help/actions';
import {
    ADD_WATCHLIST_SUCCEEDED,
    FETCH_WATCHLIST_SUCCEEDED,
    REMOVE_WATCHLIST_SUCCEEDED,
    UPDATE_WATCHLIST_SUCCEEDED,
} from '../watchlist/actions';
import { FETCH_STATES_SUCCEEDED } from '../states/actions';
import { FETCH_SUGGESTED_WATCHLIST_SUCCEEDED } from '../onBoardingWatchlist/actions';
import { FETCH_UNDERWRITERS_SUCCEEDED } from '../underwriters/actions';
import {
    FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED,
    FETCH_PACKAGES_REQUEST_SUCCEEDED,
    FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED,
} from '../packages/actions';
import { FETCH_ADMIN_PACKAGES_SUCCEEDED } from '../adminPackages/actions';
import { FETCH_ADMIN_UNDERWRITERS_SUCCEEDED } from '../adminUnderwriters/actions';

const entitiesInitialState = {
    [PLURAL_PACKAGE]: {},
    [PLURAL_EDITION]: {},
    [PLURAL_STATE]: {},
    [PLURAL_WATCHLIST]: {},
    [PLURAL_UNDERWRITER]: {},
    [PLURAL_FAQ]: {},
    [PLURAL_INFO_VIDEO]: {},
};

export default function (state = entitiesInitialState, action) {
    switch (action.type) {
        /*
         * All of these intentionally fall through to merge in action.data.entities since all of these
         * actions provide their already normalized entities in that location.
         */
        case FETCH_FAQ_SUCCEEDED:
        case FETCH_VIDEOS_SUCCEEDED:
        case FETCH_WATCHLIST_SUCCEEDED:
        case FETCH_STATES_SUCCEEDED:
        case FETCH_SUGGESTED_WATCHLIST_SUCCEEDED:
        case FETCH_UNDERWRITERS_SUCCEEDED:
        case FETCH_PACKAGES_REQUEST_SUCCEEDED:
        case FETCH_SUGGESTED_DOWNLOADS_REQUEST_SUCCEEDED:
        case FETCH_PACKAGES_BY_STATE_REQUEST_SUCCEEDED:
        case UPDATE_WATCHLIST_SUCCEEDED:
        case ADD_WATCHLIST_SUCCEEDED:
        case FETCH_ADMIN_PACKAGES_SUCCEEDED:
        case FETCH_ADMIN_UNDERWRITERS_SUCCEEDED:
        case REMOVE_WATCHLIST_SUCCEEDED:
            return mergeEntityDatabaseForSchemas(state, action.data.entities);
        case LOGIN_SUCCEEDED: {
            const mergedUnderwritersDB = mergeEntityDatabaseForSchemas(
                state,
                action.underwriters.entities
            );
            return mergeEntityDatabaseForSchemas(
                mergedUnderwritersDB,
                action.states.entities
            );
        }
        case LOGOUT:
            return entitiesInitialState;
        default:
            return state;
    }
}
