import { createSelector } from 'reselect';

export const createContextHasFiltersSelector = (getContext) => createSelector(
    getContext,
    (apiPageContext) => {
        if (apiPageContext.filters) {
            // Filter out all falsy values except 0
            const filterValues = Object.values(apiPageContext.filters)
                .filter((filterValue) => filterValue || filterValue === 0);
            if (filterValues.length > 0) {
                return true;
            }
        }
        return !!(apiPageContext.pagination && apiPageContext.pagination.page && apiPageContext.pagination.page > 1);
    },
);

export const transformApiPageToContext = ({ currentPage: page, perPage, order, filters }) => ({
    pagination: { perPage, page },
    order: order || {},
    filters: filters || {},
});
