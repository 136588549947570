import { groupData } from '../utils/tableUtils';
import { createSelector } from 'reselect';
import { convertDatesToLocal } from '../utils';

const createRecentDownloadsSelector = () => {
    return createSelector(
        (state) => state.recentDownloads[state.edition.edition].data,
        (data) => groupData(convertDatesToLocal(data)),
    );
};

export const getRecentDownloadsData = createRecentDownloadsSelector();

export const getRecentDownloadsLoading = (state) => state.recentDownloads[state.edition.edition].loading;

export const getRecentDownloadsTotal = (state) => state.recentDownloads[state.edition.edition].total;

export const getRecentDownloadsPage = (state) => state.recentDownloads[state.edition.edition].page;

export const getRecentDownloadsPerPage = (state) => state.recentDownloads[state.edition.edition].perPage;
