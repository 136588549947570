import { addStateToQuery, requestWithAuth } from '../utils/api';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import {
    FETCH_PACKAGES_BY_STATE_FOR_CURRENT_EDITION_REQUESTED,
    FETCH_PACKAGES_BY_STATE_REQUESTED,
    FETCH_PACKAGES_FOR_CURRENT_EDITION_REQUESTED,
    FETCH_PACKAGES_REQUESTED,
    FETCH_SUGGESTED_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED,
    FETCH_SUGGESTED_DOWNLOADS_REQUESTED,
    fetchPackagesByStateRequested,
    fetchPackagesByStateRequestFailed,
    fetchPackagesByStateRequestSucceeded,
    fetchPackagesRequested,
    fetchPackagesRequestFailed,
    fetchPackagesRequestSucceeded,
    fetchSuggestedDownloadsRequested,
    fetchSuggestedDownloadsRequestFailed,
    fetchSuggestedDownloadsRequestSucceeded,
} from '../stores/packages/actions';
import { getAuthToken } from '../selectors/auth';
import { PageOfPackages, parseAndNormalizeJsonResponse } from '../schemas';
import { getEdition } from '../selectors/edition';

function * fetchPackages (
    {
        edition,
        packageType,
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { state = null, search = null, underwriter = null },
    },
) {
    const token = yield select(getAuthToken);
    const query = yield call(
        addStateToQuery,
        {
            edition,
            type: packageType,
            page,
            perPage,
            orderBy,
            orderByDir,
            search: search || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    const response = yield call(
        requestWithAuth,
        '/packages',
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, PageOfPackages);
        yield put(fetchPackagesRequestSucceeded(
            edition,
            packageType,
            data,
            { orderBy, orderByDir },
            { state: query.state || state, search, underwriter },
        ));
    } else {
        yield put(fetchPackagesRequestFailed(edition, packageType));
    }
}

function * fetchPackagesForCurrentEdition ({ args }) {
    const currentEdition = yield select(getEdition);
    yield put(fetchPackagesRequested(currentEdition, ...args));
}

function * fetchSuggestedDownloadsForCurrentEdition ({ args }) {
    const currentEdition = yield select(getEdition);
    yield put(fetchSuggestedDownloadsRequested(currentEdition, ...args));
}

function * fetchPackagesByState (
    {
        edition,
        stateCode,
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { search = null, type = null, underwriter = null },
    },
) {
    const token = yield select(getAuthToken);
    const query = {
        edition,
        state: stateCode,
        perPage,
        page,
        orderBy,
        orderByDir,
        search: search || '',
        underwriter: underwriter || '',
    };
    if (type) {
        query.type = type;
    }
    const response = yield call(
        requestWithAuth,
        '/packages',
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, PageOfPackages);
        yield put(fetchPackagesByStateRequestSucceeded(
            edition,
            stateCode,
            data,
            { orderBy, orderByDir },
            { search, type, underwriter },
        ));
    } else {
        yield put(fetchPackagesByStateRequestFailed(edition, stateCode));
    }
}

function * fetchPackagesByStateForCurrentEdition ({ args }) {
    const currentEdition = yield select(getEdition);
    yield put(fetchPackagesByStateRequested(currentEdition, ...args));
}

function * fetchSuggestedDownloads (
    {
        edition,
        packageType,
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { state = null, search = null, type = null, underwriter = null },
    },
) {
    const token = yield select(getAuthToken);
    const query = yield call(
        addStateToQuery,
        {
            edition,
            type: packageType,
            page,
            perPage,
            orderBy,
            orderByDir,
            search: search || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    if (type) {
        query.type = type;
    }
    const response = yield call(
        requestWithAuth,
        '/packages/suggested',
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, PageOfPackages);
        yield put(fetchSuggestedDownloadsRequestSucceeded(
            edition,
            packageType,
            data,
            { orderBy, orderByDir },
            { state: query.state || state, search, type, underwriter },
        ));
    } else {
        yield put(snackbar.show({ message: 'Loading data for Suggested Downloads table has failed' }));
        yield put(fetchSuggestedDownloadsRequestFailed());
    }
}

export function * listenFetchPackages () {
    yield takeEvery(FETCH_PACKAGES_REQUESTED, fetchPackages);
    yield takeEvery(FETCH_PACKAGES_FOR_CURRENT_EDITION_REQUESTED, fetchPackagesForCurrentEdition);
    yield takeEvery(FETCH_PACKAGES_BY_STATE_REQUESTED, fetchPackagesByState);
    yield takeEvery(FETCH_PACKAGES_BY_STATE_FOR_CURRENT_EDITION_REQUESTED, fetchPackagesByStateForCurrentEdition);
    yield takeEvery(FETCH_SUGGESTED_DOWNLOADS_REQUESTED, fetchSuggestedDownloads);
    yield takeEvery(FETCH_SUGGESTED_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED, fetchSuggestedDownloadsForCurrentEdition);
}
