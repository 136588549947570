import { createApiPageData } from '../../utils/api';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';
import {
    CLEAR_SUCCESS_MESSAGES,
    CREATE_PACKAGE_FAILED,
    CREATE_PACKAGE_REQUESTED,
    CREATE_PACKAGE_SUCCEEDED,
    DELETE_MULTIPLE_PACKAGES_FAILED,
    DELETE_MULTIPLE_PACKAGES_REQUESTED,
    DELETE_MULTIPLE_PACKAGES_SUCCEEDED,
    DELETE_PACKAGE_FAILED,
    DELETE_PACKAGE_REQUESTED,
    DELETE_PACKAGE_SUCCEEDED,
    FETCH_ADMIN_PACKAGES_FAILED,
    FETCH_ADMIN_PACKAGES_REQUESTED,
    FETCH_ADMIN_PACKAGES_SUCCEEDED,
    FETCH_PACKAGE_FAILED,
    FETCH_PACKAGE_REQUESTED,
    FETCH_PACKAGE_SUCCEEDED,
    REDIRECT_TO_VIEW_EDIT_PACKAGE,
    UPDATE_PACKAGE_FAILED,
    UPDATE_PACKAGE_REQUESTED,
    UPDATE_PACKAGE_SUCCEEDED,
} from './actions';
import { LOGOUT } from '../auth/actions';

export const adminPackagesInitialState = createApiPageData({
    loading: false,
    perPage: DEFAULT_ROWS_PER_PAGE,
    filters: {},
    order: {},
    deletingIds: [],
    package: {
        loading: false,
        saving: false,
        redirecting: false,
        formSuccessMessages: [],
        packageData: {
            name: '',
            type: '',
            editions: [],
            stateCodes: [],
            underwriter: {
                id: 0,
            },
            downloadNotes: '',
            releaseNotesFileName: '',
            releaseNotesUrl: '',
            downloadUrl: '',
            packageFileNames: [],
        },
    },
});

const setDeletingIds = (state, packageIds, deleting = true) => ({
    ...state,
    deletingIds: deleting
        ? [...new Set(state.deletingIds.concat(packageIds))]
        : state.deletingIds.filter(
            (packageId) => !packageIds.includes(packageId),
        ),
});

export default function (state = adminPackagesInitialState, action) {
    const { packageData } = action;
    switch (action.type) {
        case FETCH_ADMIN_PACKAGES_REQUESTED:
            return {
                ...state,
                order: action.order || {},
                filters: action.filters || {},
                loading: true,
            };
        case FETCH_ADMIN_PACKAGES_SUCCEEDED:
            return {
                ...state,
                ...createApiPageData(action.data.result),
                order: action.order || {},
                filters: action.filters || {},
                loading: false,
            };
        case FETCH_ADMIN_PACKAGES_FAILED:
            return {
                ...state,
                loading: false,
            };
        case DELETE_PACKAGE_REQUESTED:
            return setDeletingIds(state, [action.packageId], true);
        case DELETE_PACKAGE_SUCCEEDED: // Intentional fall through
        case DELETE_PACKAGE_FAILED:
            return setDeletingIds(state, [action.packageId], false);
        case DELETE_MULTIPLE_PACKAGES_REQUESTED:
            return setDeletingIds(state, action.packageIds, true);
        case DELETE_MULTIPLE_PACKAGES_SUCCEEDED: // Intentional fall through
        case DELETE_MULTIPLE_PACKAGES_FAILED:
            return setDeletingIds(state, action.packageIds, false);
        case FETCH_PACKAGE_REQUESTED:
            return {
                ...state,
                package: {
                    ...state.package,
                    loading: true,
                    redirecting: false,
                    formSuccessMessages: [],
                },
            };
        case FETCH_PACKAGE_SUCCEEDED:
            return {
                ...state,
                package: {
                    ...state.package,
                    loading: false,
                    packageData,
                    formSuccessMessages: [],
                },
            };

        case FETCH_PACKAGE_FAILED:
            return {
                ...state,
                package: {
                    ...state.package,
                    loading: false,
                    formSuccessMessages: [],
                    packageData: {},
                },
            };
        case UPDATE_PACKAGE_REQUESTED:
            return {
                ...state,
                package: {
                    ...state.package,
                    saving: true,
                    formSuccessMessages: [],
                },
            };
        case CREATE_PACKAGE_REQUESTED:
            return {
                ...state,
                package: {
                    ...state.package,
                    saving: true,
                    formSuccessMessages: [],
                },
            };
        case UPDATE_PACKAGE_SUCCEEDED:
            return {
                ...state,
                package: {
                    ...state.package,
                    saving: false,
                    packageData,
                    formSuccessMessages: ['Successfully updated package.'],
                },
            };
        case CREATE_PACKAGE_SUCCEEDED:
            return {
                ...state,
                package: {
                    ...state.package,
                    saving: false,
                    packageData,
                    formSuccessMessages: ['Successfully created package.'],
                },
            };
        case UPDATE_PACKAGE_FAILED:
            return {
                ...state,
                package: {
                    ...state.package,
                    saving: false,
                    formSuccessMessages: [],
                },
            };
        case CREATE_PACKAGE_FAILED:
            return {
                ...state,
                package: {
                    ...state.package,
                    saving: false,
                    formSuccessMessages: [],
                },
            };
        case REDIRECT_TO_VIEW_EDIT_PACKAGE:
            return {
                ...state,
                package: {
                    ...state.package,
                    redirecting: true,
                },
            };
        case CLEAR_SUCCESS_MESSAGES:
            return {
                ...state,
                package: {
                    ...state.package,
                    formSuccessMessages: [],
                },
            };
        case LOGOUT:
            return adminPackagesInitialState;
        default:
            return state;
    }
}
