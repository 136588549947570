import { UPDATE_EDITION } from './actions';
import { LOGIN_SUCCEEDED, LOGOUT } from '../auth/actions';
import { transformUser } from '../../utils/api';
import { UPDATE_USER_SETTINGS_SUCCEEDED } from '../settings/actions';
import { DEFAULT_EDITION } from '../../constants/editions';

export const editionInitialState = {
    edition: DEFAULT_EDITION,
};

export function edition (state = editionInitialState, action) {
    switch (action.type) {
        case UPDATE_EDITION:
            return Object.assign({}, state, { edition: action.edition });
        case LOGIN_SUCCEEDED: {
            const { editions } = transformUser(action.user);
            if (editions.length > 0 && !editions.includes(state.edition)) {
                return {
                    ...state,
                    edition: editions[0],
                };
            }
            return state;
        }
        case UPDATE_USER_SETTINGS_SUCCEEDED: {
            const { settings } = action;
            if (!settings.editions.includes(state.edition)) {
                return {
                    ...state,
                    edition: settings.editions[0],
                };
            }
            return state;
        }
        case LOGOUT:
            return editionInitialState;
        default:
            return state;
    }
}
