export default (theme) => ({
    button: {
        width: 'auto',
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: '1em',
        letterSpacing: '0.2em',
        fontWeight: '600',
    },
});
