import { LOGIN, LOGIN_FAILED, LOGIN_SUCCEEDED, LOGOUT } from '../auth/actions';

export const loginPageInitialState = {
    loginFailed: false,
    loading: false,
};

export default function (state = loginPageInitialState, action) {
    switch (action.type) {
        case LOGIN:
            return { ...state, loading: true };
        case LOGIN_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                loginFailed: false,
            };
        }
        case LOGIN_FAILED:
            return {
                ...state,
                loginFailed: true,
                loading: false,
            };
        case LOGOUT:
            return loginPageInitialState;
        default:
            return state;
    }
}
