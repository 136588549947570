import { normalize as normalizrNormalize, schema } from 'normalizr';
import {
    PLURAL_EDITION,
    PLURAL_FAQ,
    PLURAL_INFO_VIDEO,
    PLURAL_PACKAGE,
    PLURAL_STATE,
    PLURAL_UNDERWRITER,
    PLURAL_WATCHLIST,
} from '../constants/entities';
import { mergeEntityDatabase } from '../utils/entities';

// Create the entity classes themselves first
export const Edition = new schema.Entity(PLURAL_EDITION);
export const State = new schema.Entity(PLURAL_STATE);
export const Underwriter = new schema.Entity(PLURAL_UNDERWRITER);
export const Watchlist = new schema.Entity(PLURAL_WATCHLIST);
export const Package = new schema.Entity(PLURAL_PACKAGE);
export const Faq = new schema.Entity(PLURAL_FAQ);
export const InfoVideo = new schema.Entity(PLURAL_INFO_VIDEO);

// Some shortcut schemas that we can just create ahead of time to be used by reference.
export const Editions = [Edition];
export const States = [State];
export const Underwriters = [Underwriter];
export const Watchlists = [Watchlist];
export const Packages = [Package];
export const Faqs = [Faq];
export const InfoVideos = [InfoVideo];

export const PageOfEditions = { data: Editions };
export const PageOfStates = { data: States };
export const PageOfUnderwriters = { data: Underwriters };
export const PageOfWatchlists = { data: Watchlists };
export const PageOfPackages = { data: Packages };
export const PageOfFaqs = { data: Faqs };
export const PageOfInfoVideos = { data: InfoVideos };

// And then define the schema relationships. Done after the fact to make circular references possible.
Watchlist.define({
    package: Package,
});
Package.define({
    editions: Editions,
    states: States,
    underwriter: Underwriter,
    userWatchlist: Watchlist,
});

const schemas = {
    [PLURAL_EDITION]: Edition,
    [PLURAL_FAQ]: Faq,
    [PLURAL_INFO_VIDEO]: InfoVideo,
    [PLURAL_PACKAGE]: Package,
    [PLURAL_STATE]: State,
    [PLURAL_UNDERWRITER]: Underwriter,
    [PLURAL_WATCHLIST]: Watchlist,
};

export default schemas;

// Normalization utils to make the store easier to break down and interact specifically with the SoftPro API

/**
 * Normalize data according to a schema using normalizr. Memoized to not renormalize if the exact data has already
 * been normalized against the exact data.
 *
 * @type {function(data: *, schema: {}): {entities: {}, result: *}}
 */
export const normalize = (() => {
    /*
     * We start a cache using a WeakMap which only allows keys to be objects and will only hold object references
     * weakly, meaning the object will be garbage collected if they're not being used elsewhere even if their
     * reference lives in the map still.
     */
    const cache = new WeakMap();
    return (data, schema) => {
        let cacheForData = cache.get(data);
        if (!cacheForData) {
            cacheForData = new WeakMap();
            cache.set(data, cacheForData);
        }

        const normalized = cacheForData.get(schema);
        if (normalized) {
            return normalized;
        } else {
            const normalized = normalizrNormalize(data, schema);
            cacheForData.set(schema, normalized);
            return normalized;
        }
    };
})();

/**
 * Take a response returned by `fetch`, parse the JSON out of it, and normalize the parsed JSON
 * according to the provided schema.
 *
 * @param {Body} response
 * @param {*} schema
 * @return {Promise<{entities: {}, result: *}>}
 */
export async function parseAndNormalizeJsonResponse (response, schema) {
    const body = await response.json();
    return normalize(body, schema);
}

/**
 * Merge entity databases using the SoftPro schemas.
 * @see mergeEntityDatabase
 * @param {{}} oldDatabase
 * @param {{}} newDatabase
 * @return {{}}
 */
export const mergeEntityDatabaseForSchemas = (oldDatabase, newDatabase) => {
    return mergeEntityDatabase(oldDatabase, newDatabase, schemas);
};
