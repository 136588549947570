export const PREFIX = 'auth/';
export const LOGIN = `${PREFIX}LOGIN`;
export const LOGOUT = `${PREFIX}LOGOUT`;
export const LOGIN_SUCCEEDED = `${PREFIX}LOGIN_SUCCEEDED`;
export const LOGIN_FAILED = `${PREFIX}LOGIN_FAILED`;
export const ON_BOARDING_COMPLETED = `${PREFIX}ON_BOARDING_COMPLETED`;

export function login (email, password) {
    return {
        type: LOGIN,
        email,
        password,
    };
}

export function logout () {
    return {
        type: LOGOUT,
    };
}

export function loginSucceeded (token, user, states, promptsFileUrl, underwriters) {
    return {
        type: LOGIN_SUCCEEDED,
        token,
        user,
        states,
        promptsFileUrl,
        underwriters,
        error: '',
    };
}

export function loginFailed (error = 'Login failed') {
    return {
        type: LOGIN_FAILED,
        error,
    };
}

export function onBoardingCompleted (user) {
    return {
        type: ON_BOARDING_COMPLETED,
        user,
    };
}
