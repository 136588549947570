import { ViewButton } from '../../ViewButton';
import SoftProDataTable from '../../SoftProDataTable';
import DataTableColumn from '../../DataTable/DataTableColumn';
import React, { Fragment } from 'react';
import { ANALYTICS_ORDER_BY_FIELD_EMAIL } from '../../../constants/api';
import { SORT_DESC } from '../../DataTable/constants';
import PropTypes from 'prop-types';
import TablesPropTypes from '../../../propTypes/tables';
import { withStyles } from '@mui/styles';
import ApiPropTypes from '../../../propTypes/api';
import { capitalizeFirstLetter, withRouter } from '../../../utils';
import styles from './styles';
import { USERS } from '../../../constants/analytics';
import EmptyTable from '../../EmptyTable';
import { compose } from 'redux';
import { useNavigate } from 'react-router';

class SoftProUsersDataTable extends React.Component {
    static propTypes = {
        // REQUIRED
        classes: PropTypes.object.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,

        // Not Required
        renderEmpty: PropTypes.func,
        title: PropTypes.node,
        hasNumberOfDownloadsColumn: PropTypes.bool,
        hasNumberOfNotificationsColumn: PropTypes.bool,
    };

    static defaultProps = {
        renderEmpty: () => null,
        title: null,
        hasNumberOfDownloadsColumn: false,
        hasNumberOfNotificationsColumn: false,
    };

    renderEmail = ({ email }) => email;
    renderLastDownloaded = ({ lastDownloaded }) => lastDownloaded;
    renderNumberOfDownloads = ({ numberOfDownloads }) => numberOfDownloads;
    renderViewButton = ({ userId }) =>
        (
            <ViewButton
                onClick={() => this.props.navigate(
                    '/admin/downloads/user/' + userId,
                )}
            />
        );

    renderEmpty = () => {
        return <EmptyTable title={capitalizeFirstLetter(USERS)} />;
    };

    render () {
        const {
            classes,
            tableContext,
            tableData,
            onFetchDataRequested,
        } = this.props;
        return (
            <SoftProDataTable
                getKey={({ id }) => id}
                onFetchDataRequested={(context) => onFetchDataRequested(USERS, context)}
                tableContext={tableContext}
                tableData={tableData}
                renderEmpty={this.renderEmpty}
            >
                {({ createOnSort, createSortGetter }) => (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="Email"
                            classes={{ headCell: classes.emailCol, bodyCell: classes.emailCol }}
                            getSort={createSortGetter(ANALYTICS_ORDER_BY_FIELD_EMAIL)}
                            onSort={createOnSort(ANALYTICS_ORDER_BY_FIELD_EMAIL)}
                            initialSort={SORT_DESC}
                        >
                            {this.renderEmail}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Last Downloaded"
                            classes={{ headCell: classes.packageNameCol, bodyCell: classes.packageNameCol }}
                        >
                            {this.renderLastDownloaded}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Number Of Downloads"
                            classes={{ headCell: classes.countCol, bodyCell: classes.countCol }}
                        >
                            {this.renderNumberOfDownloads}
                        </DataTableColumn>
                        <DataTableColumn classes={{ headCell: classes.viewCol, bodyCell: classes.viewCol }}>
                            {this.renderViewButton}
                        </DataTableColumn>
                    </Fragment>
                )}
            </SoftProDataTable>
        );
    }
}

const SoftProUsersDataTableFunction = (props) => {
    const navigate = useNavigate();
    return <SoftProUsersDataTable {...props} navigate={navigate} />;
};

export default compose(
    withStyles(styles),
    withRouter,
)(SoftProUsersDataTableFunction);
