import {
    DELETE_ADMIN_UNDERWRITER_FAILED,
    DELETE_ADMIN_UNDERWRITER_REQUESTED,
    DELETE_ADMIN_UNDERWRITER_SUCCEEDED,
    DELETE_MULTIPLE_ADMIN_UNDERWRITERS_FAILED,
    DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED,
    DELETE_MULTIPLE_ADMIN_UNDERWRITERS_SUCCEEDED,
} from './actions';
import { LOGOUT } from '../auth/actions';

const setDeleting = (state, underwriterIds, deleting = true) => ({
    ...state,
    deleting: deleting
        ? [...(new Set(state.deleting.concat(underwriterIds)))]
        : state.deleting.filter((underwriterId) => !underwriterIds.includes(underwriterId)),
});

const initialState = {
    deleting: [],
};

export default (state = initialState, { type, ...payload }) => {
    if (
        type === DELETE_ADMIN_UNDERWRITER_REQUESTED ||
        type === DELETE_ADMIN_UNDERWRITER_SUCCEEDED ||
        type === DELETE_ADMIN_UNDERWRITER_FAILED ||
        type === DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED ||
        type === DELETE_MULTIPLE_ADMIN_UNDERWRITERS_SUCCEEDED ||
        type === DELETE_MULTIPLE_ADMIN_UNDERWRITERS_FAILED
    ) {
        const underwriterIds = payload.underwriterIds || (payload.underwriterId ? [payload.underwriterId] : null) || [];
        if (underwriterIds.length > 0) {
            if (type === DELETE_ADMIN_UNDERWRITER_REQUESTED || type === DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED) {
                return setDeleting(state, underwriterIds, true);
            } else {
                return setDeleting(state, underwriterIds, false);
            }
        }
    } else if (type === LOGOUT) {
        return initialState;
    }
    return state;
};
