import { capitalizeFirstLetter, formatDate, has } from './index';
import { PACKAGE_TYPE_NAMES } from '../constants/packageTypes';
import { addTokenToUrl } from './api';

/**
 * @typedef {{
 *  name: string,
 *  id: number,
 *  releaseNotesUrl: string,
 *  type: string,
 *  latestRevision: {},
 *  watching: boolean,
 *  notify: null|boolean,
 *  updatedAt: string,
 *  states: string
 *  underwriterName: string,
 * }} PackageForDataTable
 */

/**
 * Transform package data into a simpler form for displaying in the tables.
 * @param {{}} packageData
 * @param {boolean?} notify
 * @param {string?} token
 * @return {PackageForDataTable}
 */
export const transformPackageForTable = (
    packageData,
    notify = null,
    token = null,
) => {
    const {
        id = null,
        name = '',
        updatedAt = '',
        latestRevision = {},
        states = [],
        type = '',
        releaseNotesUrl = '',
        downloadUrl: packageDownloadUrl = null,
        endorsementLookupsIncluded = false,
        underwriter = {},
    } = packageData;

    const { hasDownload = false } = latestRevision || {};
    const rawDownloadUrl =
        packageDownloadUrl ||
        (latestRevision && latestRevision.downloadUrl) ||
        null;
    const downloadUrlWithToken =
        rawDownloadUrl && token ? addTokenToUrl(rawDownloadUrl, token) : null;
    const releaseNotesUrlWithToken =
        releaseNotesUrl && token ? addTokenToUrl(releaseNotesUrl, token) : null;
    return {
        id,
        updatedAt: updatedAt ? formatDate(updatedAt) : '',
        states: states ? states.map(({ name }) => name).join(', ') : '',
        name,
        watching: notify !== null,
        notify: notify === null ? null : !!notify,
        latestRevision,
        releaseNotesUrl: releaseNotesUrlWithToken || releaseNotesUrl,
        type: PACKAGE_TYPE_NAMES[type],
        downloadUrl: downloadUrlWithToken || rawDownloadUrl,
        underwriterName: underwriter ? underwriter.name : '',
        hasDownload,
        hasEndorsementLookupsIncluded: endorsementLookupsIncluded,
        downloadNotAllowedMessage:
            (latestRevision && latestRevision.downloadNotAllowedMessage) ||
            packageData.downloadNotAllowedMessage,
    };
};

/**
 * Convert API data into data formatted for a Browse All datatable.
 *
 * @param {{}[]} data
 * @param {string?} token
 * @return {PackageForDataTable[]}
 */
export const convertBrowseTableData = (data = [], token = null) => {
    return data.map((packageData) => {
        const { userWatchlist = null } = packageData;
        const notify =
            userWatchlist !== null &&
            has(userWatchlist, 'notify') &&
            userWatchlist.notify !== null
                ? userWatchlist.notify
                : null;
        return transformPackageForTable(packageData, notify, token);
    });
};

/**
 * Convert API data into data formatted for a Watchlist datatable.
 *
 * @param {{}[]} data
 * @param {string?} token
 * @return {PackageForDataTable[]}
 */
export const convertWatchlistTableData = (data = [], token = null) => {
    return data.map((userWatchlist) => {
        const { notify = null, package: packageData } = userWatchlist;
        return transformPackageForTable(packageData, notify, token);
    });
};

/**
 * Get an array of selected package IDs from datatable data. To be used with mui-datatables and its
 * `customToolbarSelect` prop.
 *
 * @param selectedRows
 * @param displayData
 * @param {int} idIndex The index of the column containing the package ID.
 * @return {*}
 */
export const getSelectedIds = (selectedRows, displayData, idIndex) => {
    const ids = selectedRows.data.reduce((ids, selectedRow) => {
        const dataIndex = selectedRow.dataIndex;
        const rowData = displayData.find((data) => {
            return data.dataIndex === dataIndex;
        });
        if (rowData && rowData.data && rowData.data.length > idIndex) {
            return [...ids, parseInt(rowData.data[idIndex].key, 10)];
        } else {
            return ids;
        }
    }, []);
    return [...new Set(ids)];
};

/**
 * For grouping data by a specific property.
 * This function was created for use with the Recent Downloads table.
 *
 * @param {[]} data the data to sort
 * @param propertyName the property name to sort the data by
 * @returns {object} the sorted data
 */
export const groupData = (data = [], propertyName = 'occurredAt') => {
    const sortedData = {};
    for (let i = 0; i < data.length; i++) {
        const object = data[i];
        if (Object.keys(sortedData).indexOf(object[propertyName]) === -1) {
            sortedData[object[propertyName]] = [];
        }
        sortedData[object[propertyName]].push(object);
    }
    return sortedData;
};

/**
 * Convert API data into data formatted for the Recent Downloads tables.
 * @param {{}[]} data
 * @param {string?} token
 * @returns {*[][]}
 */
export const convertDataForRecentDownloads = (data = [], token = null) => {
    return data.map((value) => {
        const { packageRevision, occurredAtTime } = value;
        const thePackage = packageRevision.package;
        const rawDownloadUrl =
            thePackage && thePackage.downloadUrl
                ? thePackage.downloadUrl
                : packageRevision.downloadUrl;
        const downloadUrl =
            rawDownloadUrl && token
                ? addTokenToUrl(rawDownloadUrl, token)
                : null;
        const { type, id, name, states = [], underwriter = {} } = thePackage;
        const stateNames =
            states.length === 0
                ? []
                : states.map((state) => {
                    return state.name;
                });
        return [
            type,
            stateNames.join(', '),
            underwriter ? underwriter.name : '',
            name,
            occurredAtTime,
            packageRevision || null,
            id,
            downloadUrl || '',
        ];
    });
};

/**
 * Convert API data into data formatted for the Top Users table seen on the Admin Dashboard.
 * @param {{}[]} data
 * @returns {*[][]}
 */
export const convertTopUsers = (data = []) => {
    return data.map((value) => [
        value.email,
        value.organizationName,
        value.lastDownloaded,
        value.numberOfDownloads,
        value.userId,
    ]);
};

/**
 * Convert API data into data formatted for the Downloads table on the Downloads tab
 * seen on Admin Analytics.
 *
 * @param {{}[]} data
 *  * @returns {{editions, downloadCount: number, name, id, type, updatedAt: *, states, underwriterName}[]}
 */
export const convertDataForDownloadsTab = (data = []) => {
    return data.map((value) => {
        const {
            updatedAt,
            type,
            editions,
            states,
            name,
            downloadEventsOccurredAt,
            id,
            underwriter = {},
        } = value;
        return {
            updatedAt: formatDate(updatedAt.date),
            type,
            editions,
            states,
            name,
            downloadCount: downloadEventsOccurredAt
                ? downloadEventsOccurredAt.length
                : 0,
            id,
            underwriterName: underwriter ? underwriter.name : '',
        };
    });
};

/**
 * Convert API data into data formatted for the Users table on the Users tab
 * seen on Admin Analytics.
 *
 * @param {{}[]} data
 * @returns {{numberOfDownloads, lastDownloaded, userId, email}[]}
 */
export const convertDataForUsersTab = (data = []) => {
    return data.map((value) => {
        const { email, lastDownloaded, numberOfDownloads, userId } = value;
        return {
            email,
            lastDownloaded,
            numberOfDownloads,
            userId,
        };
    });
};

/**
 * Convert API data into data formatted for the Notifications table
 * on the Notifications tab seen on Admin Analytics.
 *
 * @param {{}[]} data
 * @returns {{editions, notificationCount: number, name, id, type, updatedAt: *, states, underwriterName}[]}
 */
export const convertDataForNotificationsTab = (data = []) => {
    return data.map((value) => {
        const {
            updatedAt,
            type,
            editions,
            states,
            name,
            notificationEventsOccurredAt,
            id,
            underwriter = {},
        } = value;
        return {
            updatedAt: formatDate(updatedAt.date),
            type,
            editions,
            states,
            name,
            notificationCount: notificationEventsOccurredAt
                ? notificationEventsOccurredAt.length
                : 0,
            id,
            underwriterName: underwriter ? underwriter.name : '',
        };
    });
};

/**
 *  Convert API data into data formatted for the Users table on the
 *  Users tab seen on Admin Analytics.
 * @param {{}[]} data
 * @returns {*[][]}
 */
export const convertDataForUsers = (data = []) => {
    return data.map((value) => [
        value.email,
        value.organizationName,
        value.serial,
        value.lastDownload,
        value.numberOfDownloads,
        value.id,
    ]);
};

/**
 *  Convert API data into data formatted for the Download History table on the
 *  (User) Download History tab seen on Admin Users page.
 *
 * @param {{}[]} data
 * @returns {*[][]}
 */
export const convertDataForDownloadHistory = (data = []) => {
    return data.map((value) => [
        formatDate(value.downloadDate.date),
        value.type,
        value.editions,
        value.states,
        value.underwriter ? value.underwriter.name : '',
        value.name,
        value.packageId,
    ]);
};

/**
 * Convert API data into data formatted for the Admin Packages table
 *
 * @param data
 * @returns {{}[]}
 */
export const convertAdminPackageData = (data = []) => {
    return data.map((value = {}) => {
        const {
            updatedAt = '',
            editions = [],
            type = '',
            states = [],
            underwriter = {},
            name = '',
            id = '',
            latestRevision = {},
            endorsementLookupsIncluded = false,
        } = value;
        const {
            releaseNotesUploadId = null,
            downloadNotes = null,
            zipUploadId = null,
        } = latestRevision || {};
        return {
            updatedAt: updatedAt ? formatDate(updatedAt) : '',
            type,
            name,
            underwriterName: underwriter ? underwriter.name : '',
            states: states ? states.map(({ name }) => name).join(', ') : '',
            editionNames: editions
                ? editions
                    .map(({ name }) => capitalizeFirstLetter(name))
                    .join(', ')
                : '',
            packageId: id,
            hasReleaseNotesUploadId: !!releaseNotesUploadId,
            hasDownloadNotes: !!downloadNotes,
            hasZipUploadId: !!zipUploadId,
            hasEndorsementLookupsIncluded: !!endorsementLookupsIncluded,
        };
    });
};

/**
 * Convert API data into data formatted for the Notifications table for a specific package.
 * Assumes the notificationsOccurredAt is already sorted by date descending and grabs the first one in the array.
 *
 * @param data
 * @returns {{date: string, userId, email}[]}
 */
export const convertDataForNotificationsForPackageTable = (data = []) => {
    return data.map((value) => {
        const { userId, email, notificationsOccurredAt = [] } = value;
        return {
            userId,
            email,
            date:
                notificationsOccurredAt && notificationsOccurredAt.length > 0
                    ? formatDate(notificationsOccurredAt[0].date)
                    : '',
        };
    });
};

/**
 * Convert API data into data formatted for the table of Packages downloaded by a specific user.
 * Admin > Analytics > Users tab, click View button.
 * @param data
 * @returns {{editionNames: string, underwriterName: string, name: string, packageId, type: string, updatedAt: string, states: string}[]}
 */
export const convertDataForUserDownloadsTable = (data = []) => {
    return data.map((value) => {
        const {
            updatedAt,
            editions = '',
            states = '',
            underwriterName = '',
            name = '',
            type = '',
            packageId,
        } = value;
        return {
            updatedAt: updatedAt ? formatDate(updatedAt.date) : '',
            editionNames: editions,
            states,
            underwriterName,
            name,
            type,
            packageId,
        };
    });
};
