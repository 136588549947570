import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import convertRegion, { stateCodes, TO_NAME } from '../../utils/usaStatesAPI';
import { ByStateTitle } from '../TableTitles';
import {
    fetchPackagesByStateForCurrentEditionRequested,
    fetchPackagesByStateRequested,
} from '../../stores/packages/actions';
import { addWatchlist, removeWatchlist, updateWatchlist } from '../../stores/watchlist/actions';
import { EDITION_SELECT, EDITION_STANDARD } from '../../constants/editions';
import { createByStatePackagesSelectorsForTable } from '../../selectors/packages';
import { getEdition, getEditionName } from '../../selectors/edition';
import TablesPropTypes from '../../propTypes/tables';
import BrowseTable from '../BrowseTable';
import styles from './styles';
import ApiPropTypes from '../../propTypes/api';
import { downloadMultiplePackages, downloadPackage } from '../../stores/download/actions';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';

class BrowseByState extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        editionName: PropTypes.string.isRequired,
        tableData: TablesPropTypes.tablePageData,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        hasFilters: PropTypes.bool,
        loadingUpdate: PropTypes.arrayOf(PropTypes.number).isRequired,
        onAddWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,
        onFetchPackagesByStateRequested: PropTypes.func.isRequired,
        onFetchAllPackagesByStateRequested: PropTypes.func.isRequired,
        availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object),
        stateCode: PropTypes.string.isRequired,
        edition: PropTypes.string.isRequired,
    };

    static defaultProps = {
        hasFilters: false,
        availableUnderwritersForDataTableFilter: [],
    };

    componentDidMount () {
        this.props.onFetchAllPackagesByStateRequested();
    }

    render () {
        const {
            editionName,
            tableData,
            tableContext,
            hasFilters,
            loadingUpdate,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onUpdateWatchlistRequested,
            edition,
            onDownloadRequested,
            onFetchPackagesByStateRequested: onFetchDataRequested,
            classes,
            availableUnderwritersForDataTableFilter,
            stateCode,
        } = this.props;

        if (!stateCode || !stateCodes.includes(stateCode)) {
            return <Navigate to="/portal/browseAll" />;
        }

        const tableProps = {
            tableData,
            tableContext,
            hasFilters,
            loadingUpdate,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onUpdateWatchlistRequested,
            onDownloadRequested,
            onFetchDataRequested,
            availableUnderwritersForDataTableFilter,
        };

        return (
            <div className={classes.container}>
                <BrowseTable
                    {...tableProps}
                    edition={edition}
                    displayTypeColumn
                    displayStatesColumn={false}
                    emptyText={
                        `There are no ${convertRegion(stateCode, TO_NAME)} Packages for the ${editionName} Edition.`
                    }
                    title={<ByStateTitle stateName={convertRegion(stateCode, TO_NAME)} />}
                />
            </div>
        );
    }
}

const createMapStateToProps = () => {
    const {
        getTableDataForState,
        getTableContextForState,
        doesTableHaveFilters,
    } = createByStatePackagesSelectorsForTable(
        (state, props) => props.stateCode,
    );
    return (state, props) => {
        return {
            editionName: getEditionName(state),
            edition: getEdition(state),
            tableData: getTableDataForState(state, props),
            tableContext: getTableContextForState(state, props),
            hasFilters: doesTableHaveFilters(state, props),
            loadingUpdate: state.watchlist.loadingUpdate,
            availableUnderwritersForDataTableFilter: getAvailableUnderwritersForDataTableFilter(state),
        };
    };
};

const mapDispatchToProps = function (dispatch, props) {
    return {
        onAddWatchlistRequested: (packageIds) => dispatch(addWatchlist(packageIds, false)),
        onRemoveWatchlistRequested: (packageIds) => dispatch(removeWatchlist(packageIds, false)),
        onUpdateWatchlistRequested: (...args) => dispatch(updateWatchlist(...args)),
        onDownloadRequested: (packageIds) => {
            return packageIds && packageIds.length > 1
                ? dispatch(downloadMultiplePackages(packageIds))
                : dispatch(downloadPackage(packageIds[0]));
        },
        onFetchAllPackagesByStateRequested: (context = {}) => {
            if (props.stateCode) {
                dispatch(fetchPackagesByStateRequested(EDITION_STANDARD, props.stateCode, context));
                dispatch(fetchPackagesByStateRequested(EDITION_SELECT, props.stateCode, context));
            }
        },
        onFetchPackagesByStateRequested: (context = {}) => {
            dispatch(fetchPackagesByStateForCurrentEditionRequested(props.stateCode, context));
        },
    };
};

const BrowseByStateFunction = (props) => {
    const params = useParams();
    return <BrowseByState stateCode={params.stateCode} {...props} />;
};

export default compose(
    connect(createMapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(BrowseByStateFunction);
