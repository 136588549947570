import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';
import FormPropTypes from '../../propTypes/forms';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import {
    getChangePasswordData,
    getChangePasswordFieldErrors,
    getChangePasswordFormErrors,
    getChangePasswordFormSuccessMessages,
    isChangePasswordLoading,
} from '../../selectors/changePassword';
import {
    changePasswordFieldChanged,
    changePasswordFormClearRequested,
    changePasswordRequested,
} from '../../stores/changePassword/actions';

class AdminChangePassword extends Component {
    static propTypes = {
        classes: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        formErrors: FormPropTypes.errors,
        formSuccessMessages: FormPropTypes.messages,
        fieldErrors: PropTypes.shape({
            oldPassword: FormPropTypes.errors,
            newPassword: FormPropTypes.errors,
            newPasswordConfirmation: FormPropTypes.errors,
        }),
        data: PropTypes.shape({
            oldPassword: PropTypes.string,
            newPassword: PropTypes.string,
            newPasswordConfirmation: PropTypes.string,
        }).isRequired,
    };

    componentDidMount () {
        const { loading, onReset } = this.props;
        if (!loading) {
            onReset();
        }
    }

    getFormData = () => {
        const {
            oldPassword = '',
            newPassword = '',
            newPasswordConfirmation = '',
        } = this.props.data;
        return {
            oldPassword,
            newPassword,
            newPasswordConfirmation,
        };
    };

    render () {
        const {
            classes,
            loading,
            formErrors,
            formSuccessMessages,
            fieldErrors,
            onFieldChange,
            onSubmit,
        } = this.props;

        const formProps = {
            data: this.getFormData(),
            loading,
            formErrors,
            formSuccessMessages,
            fieldErrors,
            onSubmit,
            onChange: onFieldChange,
        };

        return (
            <Grid container direction="column" alignItems="stretch" justifyContent="flex-start" className={classes.root}>
                <Grid item>
                    <Typography variant="h1" className={classes.title}>
                        Change Admin Password
                    </Typography>
                </Grid>
                <Grid item>
                    <ChangePasswordForm {...formProps} />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    data: getChangePasswordData(state),
    loading: isChangePasswordLoading(state),
    formErrors: getChangePasswordFormErrors(state),
    formSuccessMessages: getChangePasswordFormSuccessMessages(state),
    fieldErrors: getChangePasswordFieldErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(changePasswordRequested()),
    onFieldChange: (field, value) => dispatch(changePasswordFieldChanged(field, value)),
    onReset: () => dispatch(changePasswordFormClearRequested()),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(AdminChangePassword);
