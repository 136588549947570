import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { MAX_FILE_SIZE_MB } from '../constants/requests';
import { PageOfPackages, parseAndNormalizeJsonResponse } from '../schemas';
import { getAuthToken } from '../selectors/auth';
import {
    CREATE_PACKAGE_REQUESTED,
    createPackageFailed,
    createPackageSucceeded,
    DELETE_MULTIPLE_PACKAGES_REQUESTED,
    DELETE_PACKAGE_REQUESTED,
    deleteMultiplePackagesFailed,
    deleteMultiplePackagesSucceeded,
    deletePackageFailed,
    deletePackageSucceeded,
    FETCH_ADMIN_PACKAGES_REQUESTED,
    FETCH_PACKAGE_REQUESTED,
    fetchAdminPackagesFailed,
    fetchAdminPackagesRequested,
    fetchAdminPackagesSucceeded,
    fetchPackageFailed,
    fetchPackageSucceeded,
    REDIRECT_TO_VIEW_EDIT_PACKAGE,
    redirectToViewEditPackageAction,
    UPDATE_PACKAGE_REQUESTED,
    updatePackageFailed,
    updatePackageSucceeded,
} from '../stores/adminPackages/actions';
import { omit } from '../utils';
import { addStateToQuery, requestWithAuth, uploadWithAuth } from '../utils/api';
import { getAdminPackagesContext, getAdminPackagesPageIds } from '../selectors/adminPackages';
import { createReloadAfterDeleteSaga } from './api';

function * fetchAdminPackages (
    {
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { state = null, search = null, type = null, underwriter = null },
    },
) {
    const token = yield select(getAuthToken);
    const query = yield call(
        addStateToQuery,
        {
            page,
            perPage,
            orderBy,
            orderByDir,
            search: search || '',
            type: type || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    const response = yield call(
        requestWithAuth,
        '/packages',
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response,
            PageOfPackages);
        yield put(fetchAdminPackagesSucceeded(
            data,
            { orderBy, orderByDir },
            { state: query.state || state, search, type, underwriter },
        ));
    } else {
        yield put(snackbar.show({ message: 'Loading Packages has failed.' }));
        yield put(fetchAdminPackagesFailed());
    }
}

function * fetchPackage ({ packageId }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/packages/' + packageId,
        'GET', token, null);
    if (response.ok) {
        const packageData = yield call([response, response.json]);
        yield put(fetchPackageSucceeded(packageData));
    } else {
        yield put(
            snackbar.show({ message: 'Retrieving the Package has failed.' }));
        yield put(fetchPackageFailed());
    }
}

export function * listenFetchAdminPackages () {
    yield takeEvery(FETCH_ADMIN_PACKAGES_REQUESTED, fetchAdminPackages);
    yield takeEvery(FETCH_PACKAGE_REQUESTED, fetchPackage);
}

const reloadAdminPackagesAfterDelete = createReloadAfterDeleteSaga(
    getAdminPackagesContext,
    getAdminPackagesPageIds,
    fetchAdminPackagesRequested,
);

function * deletePackage ({ packageId }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/packages/' + packageId,
        'DELETE', token, null);
    if (response.ok) {
        yield put(deletePackageSucceeded(packageId));
        yield call(reloadAdminPackagesAfterDelete, packageId);
    } else {
        yield put(snackbar.show({ message: 'Deleting Package has failed.' }));
        yield put(deletePackageFailed(packageId));
    }
}

function * deletePackages ({ packageIds }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/packages', 'DELETE', token,
        null, { packageIds });
    if (response.ok) {
        yield put(deleteMultiplePackagesSucceeded(packageIds));
        yield call(reloadAdminPackagesAfterDelete, ...packageIds);
    } else {
        yield put(snackbar.show({ message: 'Deleting Packages has failed.' }));
        yield put(deleteMultiplePackagesFailed(packageIds));
    }
}

export function * listenDeletePackages () {
    yield takeEvery(DELETE_PACKAGE_REQUESTED, deletePackage);
    yield takeEvery(DELETE_MULTIPLE_PACKAGES_REQUESTED, deletePackages);
}

function * updatePackage ({ packageId, packageData }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/packages/' + packageId,
        'PUT',
        token,
        null,
        omit(packageData, []),
    );
    if (response.ok) {
        const newPackageData = yield call([response, response.json]);
        const {
            packageFiles = [], releaseNotes = [], releaseNotesNamesDeleted = [],
            packageFilesNamesDeleted = [], downloadNotes = '', updateRevision,
        } = packageData;
        if (releaseNotes.length > 0 || packageFiles.length > 0 ||
            releaseNotesNamesDeleted.length > 0 || packageFilesNamesDeleted.length > 0 ||
            downloadNotes || !updateRevision) {
            let totalFileSize = 0;
            const formData = new FormData();
            if (releaseNotes && releaseNotes.length > 0) {
                formData.append('releaseNotes', releaseNotes[0]);
                totalFileSize += releaseNotes[0].size;
            }
            if (packageFiles && packageFiles.length > 0) {
                packageFiles.forEach((packageFile) => {
                    formData.append('packageFiles[]', packageFile);
                    totalFileSize += packageFile.size;
                });
            }
            if (releaseNotesNamesDeleted && releaseNotesNamesDeleted.length > 0) {
                releaseNotesNamesDeleted.forEach((deleted) => {
                    formData.append('releaseNotesNamesDeleted[]', deleted[0]);
                });
            }
            if (packageFilesNamesDeleted && packageFilesNamesDeleted.length > 0) {
                packageFilesNamesDeleted.forEach((deleted) => {
                    formData.append('packageFilesNamesDeleted[]', deleted);
                });
            }
            if (downloadNotes) {
                formData.append('downloadNotes', downloadNotes);
            }
            if (updateRevision) {
                formData.append('updateRevision', '1');
            }
            if (totalFileSize / 1000000 > MAX_FILE_SIZE_MB) {
                yield put(snackbar.show(
                    {
                        message: 'Total File size is too large, total max size is ' +
                            MAX_FILE_SIZE_MB +
                            ' MB. Please try again with smaller files.',
                    }));
                yield put(updatePackageFailed());
            } else {
                const revisionId = newPackageData.latestRevision.id;
                try {
                    const uploadResponse = yield call(
                        uploadWithAuth,
                        '/packages/' + packageId + '/' + revisionId + '/upload',
                        'POST',
                        token,
                        formData,
                    );
                    if (uploadResponse.ok) {
                        const uploadedPackageData = yield call(
                            [uploadResponse, uploadResponse.json]);
                        yield put(updatePackageSucceeded(uploadedPackageData));
                    } else {
                        yield put(snackbar.show(
                            { message: 'Updating the Package with new files has failed.' }));
                        yield put(updatePackageFailed());
                    }
                } catch (errors) {
                    yield put(snackbar.show({
                        message: 'Failed to upload your file(s) / release notes files. ' +
                            'Please ensure you only select files and not folders or directories.',
                    }));
                    yield put(updatePackageFailed());
                }
            }
        } else {
            yield put(updatePackageSucceeded(newPackageData));
        }
    } else {
        yield put(snackbar.show({ message: 'Updating the Package has failed.' }));
        yield put(updatePackageFailed());
    }
}

function * createPackage ({ packageData }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/packages/',
        'POST',
        token,
        null,
        omit(packageData, ['releaseNotes', 'packageFiles']),
    );
    if (response.ok) {
        const newPackageData = yield call([response, response.json]);
        const { id } = newPackageData;
        const { packageFiles, releaseNotes } = packageData;
        if (releaseNotes.length > 0 || packageFiles.length > 0) {
            let totalFileSize = 0;
            const formData = new FormData();
            if (releaseNotes && releaseNotes.length > 0) {
                formData.append('releaseNotes', releaseNotes[0]);
                totalFileSize += releaseNotes[0].size;
            }
            if (packageFiles && packageFiles.length > 0) {
                packageFiles.forEach((packageFile) => {
                    formData.append('packageFiles[]', packageFile);
                    totalFileSize += packageFile.size;
                });
            }
            if (totalFileSize / 1000000 > MAX_FILE_SIZE_MB) {
                yield put(snackbar.show(
                    {
                        message: 'Total File size is too large, total max size is ' +
                            MAX_FILE_SIZE_MB +
                            ' MB. Please try again with smaller files.',
                    }));
                yield put(createPackageFailed());
                // Redirect because we've already created the package for them, they just need to fix the file sizes
                yield put(redirectToViewEditPackageAction(id));
            } else {
                const revisionId = newPackageData.latestRevision.id;
                try {
                    const uploadResponse = yield call(
                        uploadWithAuth,
                        '/packages/' + id + '/' + revisionId + '/upload',
                        'POST',
                        token,
                        formData,
                    );
                    if (uploadResponse.ok) {
                        const uploadedPackageData = yield call(
                            [uploadResponse, uploadResponse.json]);
                        yield putResolve(
                            createPackageSucceeded(uploadedPackageData));
                    } else {
                        yield put(snackbar.show(
                            { message: 'Uploading files for the new Package has failed.' }));
                        yield put(createPackageFailed());
                    }
                } catch (errors) {
                    yield put(snackbar.show({
                        message: 'Failed to upload your file(s) / release notes files for the new package. ' +
                            'Please ensure you only select files and not folders or directories.',
                    }));
                    yield put(createPackageFailed());
                }
                // No matter what occurred for the uploads, if the package was created we're here so we redirect to the view/edit screen now
                yield put(redirectToViewEditPackageAction(id));
            }
        } else {
            yield putResolve(createPackageSucceeded(newPackageData));
            yield put(redirectToViewEditPackageAction(id));
        }
    } else {
        yield put(snackbar.show({ message: 'Creating the Package has failed.' }));
        yield put(createPackageFailed());
    }
}

export function * listenCreateUpdatePackage () {
    yield takeEvery(UPDATE_PACKAGE_REQUESTED, updatePackage);
    yield takeEvery(CREATE_PACKAGE_REQUESTED, createPackage);
}

function * redirectToViewEditPackage ({ id }) {
    yield put(push('/admin/packages/package/' + id));
}

export function * listenRedirectToEditPackage () {
    yield takeEvery(REDIRECT_TO_VIEW_EDIT_PACKAGE, redirectToViewEditPackage);
}
