export const styles = () => ({
    documentsPortalApp: {
        width: 'auto',
        height: 'auto',
    },
    menuIcon: {
        height: '1em',
        width: '1em',
        fontSize: 24,
    },
    listItemText: {
        fontSize: '1.4em',
        padding: '16px 4px',
    },
    drawerPaper: {
        backgroundColor: '#6089BD',
        borderColor: '#6089BD',
    },
});
