const styles = (theme) => ({
    intro: {
        width: 'auto',
        maxWidth: '95%',
        textAlign: 'center',
        marginBottom: 20,
        padding: 50,
        border: 'none',
        borderRadius: 0,
        color: 'white',
        boxSizing: 'border-box',
        backgroundColor: '#00224444',

        [theme.breakpoints.up('lg')]: {
            width: 600,
            textAlign: 'left',
            padding: '50px 80px 50px 50px',
            marginRight: -10,
            marginBottom: 0,
        },

        [theme.breakpoints.up('xl')]: {
            width: 900,
            padding: '75px 100px 75px 75px',
        },

        [theme.breakpoints.only('md')]: {
            width: '50%',
        },

    },
    welcomeText: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 42,
    },
    welcomeParagraph: {
        marginTop: 10,
        marginLeft: 0,
    },
    loginHeader: {
        marginLeft: 0,
        marginRight: 0,
        color: '#fff',
    },
    subheading: {
        color: '#002244',
        fontSize: 24,
        fontWeight: 'bold',
        padding: '30px 0',
    },
    loginContainer: {
        width: 500,
        background: 'white',
        borderRadius: 5,
        overflow: 'hidden',
        boxSizing: 'border-box',

        [theme.breakpoints.up('xl')]: {
            width: 600,
        },
    },
    loginInnerContainer: {
        padding: '30px 0',
    },
    loginContainerRow: {
        padding: '0 40px',
    },
    loginContainerButtonRow: {
        padding: '0 40px 20px 40px',
    },
    loginTitle: {},
    loginFormInputContainer: {
        marginTop: 15,
    },
    loginFormInput: {
        height: '1.4em',
        fontSize: '1.2em',
    },
    loginFormInputLabel: {
        textTransform: 'uppercase',
        marginBottom: 5,
    },
    loginFailedText: {
        color: 'red',
    },
});

export default styles;
