export const PREFIX = 'download/';
export const DOWNLOAD_PACKAGE = `${PREFIX}DOWNLOAD_PACKAGE`;
export const DOWNLOAD_MULTIPLE_PACKAGES = `${PREFIX}DOWNLOAD_MULTIPLE_PACKAGES`;

export function downloadPackage (packageId) {
    return {
        type: DOWNLOAD_PACKAGE,
        packageId,
    };
}

export function downloadMultiplePackages (packageIds) {
    return {
        type: DOWNLOAD_MULTIPLE_PACKAGES,
        packageIds,
    };
}
