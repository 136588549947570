import { call, put, select, takeEvery } from 'redux-saga/effects';
import { requestWithAuth } from '../utils/api';
import { FETCH_VIDEOS, fetchVideosFailed, fetchVideosSucceeded } from '../stores/help/actions';
import { getAuthToken } from '../selectors/auth';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { InfoVideos, parseAndNormalizeJsonResponse } from '../schemas';

function * fetchVideos ({ edition }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/infoVideo', 'GET', token, { edition });
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, InfoVideos);
        yield put(fetchVideosSucceeded(edition, data));
    } else {
        yield put(snackbar.show({ message: 'Retrieving the Videos from the server has failed.' }));
        yield put(fetchVideosFailed());
    }
}

export function * listenFetchVideos () {
    yield takeEvery(FETCH_VIDEOS, fetchVideos);
}
