import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from '../../utils';
import { updatedDiff } from 'deep-object-diff';
import { styles } from './styles';
import { withSnackbar } from 'material-ui-snackbar-redux';
import {
    StyledAmsMember,
    StyledCompany,
    StyledEditions,
    StyledEmail,
    StyledName,
    StyledSaveButton,
    StyledSerialNumber,
    StyledStates, StyledUnderwriters,
    StyledUserType,
} from '../../components/FormComponents';
import { NotificationStatus, NotificationFrequency } from '../ManageNotifications';
import { getAvailableStates } from '../../selectors/states';
import {
    getEmailErrors,
    getSpecificUserDataLoading,
    getUserData,
    getUserRole,
    getUserSuccessMessages,
    getUserUpdating,
} from '../../selectors/users';
import { clearSuccessMessages, userDataRequested, userDataUpdateRequested } from '../../stores/users/actions';
import { ROLE_ADMIN, ROLE_CUSTOMER } from '../../constants/roles';
import MessagePaper from '../../components/MessagePaper';
import { useParams } from 'react-router';

class InfoTabContents extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        notifications: PropTypes.string,
    };

    static defaultProps = {
        notifications: '',
    };

    state = {
        name: this.props.data.name,
        email: this.props.data.email,
        organizationName: this.props.data.organizationName,
        states: this.props.data.stateCodes ? this.props.data.stateCodes.split(', ') : [],
        underwriters: this.props.data.userUnderwriter ? this.props.data.userUnderwriter.split(', ') : [],
        editions: this.props.data.editionNames ? this.props.data.editionNames.toLowerCase().split(', ') : [],
        serialNumber: this.props.data.serialNumber,
        isAmsMember: this.props.data.isAmsMember,
        role: this.props.role,
        emailError: false,
        statesError: false,
        editionsError: false,
        notifications: this.props.data.notifications,
    };

    onNotificationStatusChange = (value) => {
        if (value === 'disabled') {
            this.setState({ ...this.state, notifications: 'off' });
        } else if (value === 'enabled') {
            this.setState({ ...this.state, notifications: 'monthly' });
        }
    };

    onNotificationFrequencyChange = (value) => {
        this.setState({ ...this.state, notifications: value });
    };

    onStateSelected = (value) => {
        if (!this.state.states.includes(value)) {
            this.setState({
                states: [...this.state.states, value],
                statesError: false,
            });
        } else {
            const states = this.state.states.filter(
                (state) => state !== value);
            this.setState({
                states,
            });
        }
    };

    onEditionSelected = (value) => {
        if (!this.state.editions.includes(value)) {
            this.setState({
                ...this.state,
                editions: [...this.state.editions, value],
                editionsError: false,
            });
        } else {
            const editions = this.state.editions.filter(
                (edition) => edition !== value);
            this.setState({
                editions,
            });
        }
    };

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            emailError: false,
        });
    };

    onNameChange = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    onAmsClick = () => {
        this.setState({
            isAmsMember: !this.state.isAmsMember,
        });
    };

    onUserTypeChange = (event) => {
        this.setState({
            role: event.target.value,
        });
    };

    onSerialNumberChange = (event) => {
        this.setState({
            serialNumber: event.target.value,
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        const { email = '', states = [], editions = [], role } = this.state;
        if (!email || (role !== ROLE_ADMIN && (states.length === 0 || editions.length === 0))) {
            this.setState({
                emailError: email === '',
                statesError: states.length === 0,
                editionsError: editions.length === 0,
            });
            this.props.clearSuccessMessages();
        } else {
            const { userDataUpdateRequested, userId, data } = this.props;
            const previousUserData = {
                name: data.name,
                email: data.email,
                role: this.props.role,
                serialNumber: data.serialNumber,
                isAmsMember: data.isAmsMember ? 1 : 0,
                notifications: data.notifications,
            };
            const previousStates = data.stateCodes
                ? data.stateCodes.split(', ')
                : [];
            const previousEditions = data.editionNames
                ? data.editionNames.toLowerCase().split(', ')
                : [];
            const { name, email, role, serialNumber, isAmsMember, notifications } = this.state;
            const newUserData = {
                name,
                email,
                role,
                serialNumber,
                states,
                editions,
                isAmsMember: isAmsMember ? 1 : 0,
                notifications,
            };
            const differences = updatedDiff(previousUserData, newUserData);
            if (Object.keys(differences).length > 0 || !deepEqual(previousStates, states) ||
                !deepEqual(previousEditions, editions)) {
                // There are differences so update the user with those differences
                if (!deepEqual(previousStates, states)) {
                    differences.states = states;
                }
                if (!deepEqual(previousEditions, editions)) {
                    differences.editions = editions;
                }
                userDataUpdateRequested(userId, differences);
            } else {
                const { snackbar } = this.props;
                snackbar.show('There are no changes to be saved.');
            }
        }
    };

    render () {
        const {
            name,
            email,
            organizationName,
            states,
            underwriters,
            editions,
            serialNumber,
            isAmsMember,
            role,
            emailError,
            statesError,
            editionsError,
            notifications,
        } = this.state;
        const {
            availableStates,
            emailErrors,
            updating,
            formSuccessMessages,
            classes,
        } = this.props;
        return (
            <Grid container component="form" onSubmit={(event) => this.onSubmit(event)}>
                {formSuccessMessages.map((message) => (
                    <MessagePaper key={message} type="success">
                        {message}
                    </MessagePaper>
                ))}
                <Grid container direction="column" spacing={8}>
                    <Grid item>
                        <StyledEmail
                            email={email}
                            disabled={false}
                            onChange={this.onEmailChange}
                            error={emailError || emailErrors}
                        />
                    </Grid>
                    <Grid item className={classes.formBox}>
                        <StyledName
                            name={name}
                            onChange={this.onNameChange}
                        />
                    </Grid>
                    <Grid item className={classes.formBox}>
                        <StyledUserType
                            value={role}
                            onChange={this.onUserTypeChange}
                        />
                    </Grid>
                </Grid>
                {role === ROLE_CUSTOMER &&
                <Grid container direction="column" spacing={8}>
                    <Grid item className={classes.formBox}>
                        <StyledCompany
                            company={organizationName}
                            hidden={true}
                            error={false}
                        />
                    </Grid>
                    <Grid item>
                        <StyledStates
                            states={states}
                            availableStates={availableStates}
                            handleSelectedState={(event) => this.onStateSelected(event.target.value)}
                            handleDelete={(value) => this.onStateSelected(value)}
                            error={statesError}
                        />
                    </Grid>
                    <Grid item className={classes.formBox}>
                        <StyledUnderwriters
                            underwriters={underwriters}
                        />
                    </Grid>
                    <Grid item className={classes.formBox}>
                        <StyledEditions
                            editions={editions}
                            handleSelectedEdition={(event) => this.onEditionSelected(event.target.value)}
                            handleDelete={(value) => this.onEditionSelected(value)}
                            error={editionsError}
                        />
                    </Grid>
                    <Grid item className={classes.formBox}>
                        <StyledSerialNumber
                            disabled={false}
                            serialNumber={serialNumber}
                            onChange={this.onSerialNumberChange}
                        />
                    </Grid>
                    <Grid item className={classes.formBox}>
                        <StyledAmsMember
                            isAmsMember={isAmsMember}
                            onClick={this.onAmsClick}
                        />
                    </Grid>
                    <Grid item className={classes.preferences}>
                        <Grid item className={classes.formBox}>
                            <Grid item>
                                <NotificationStatus
                                    classes={classes}
                                    notifications={notifications}
                                    onChange={(event) => this.onNotificationStatusChange(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <NotificationFrequency
                                    classes={classes}
                                    notifications={notifications}
                                    onChange={(event) => this.onNotificationFrequencyChange(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid container direction="column">
                    <Grid item component={Divider} />
                    <Grid item>
                        <StyledSaveButton updating={updating} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

InfoTabContents.propTypes = {
    availableStates: PropTypes.array.isRequired,
    emailErrors: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    snackbar: PropTypes.object.isRequired,
    userDataUpdateRequested: PropTypes.func.isRequired,
    formSuccessMessages: PropTypes.array.isRequired,
    clearSuccessMessages: PropTypes.func.isRequired,
    role: PropTypes.string,
    userId: PropTypes.string.isRequired,
};

class InfoTab extends React.Component {
    render () {
        const {
            loading,
            data,
            availableStates,
            userId,
            userDataUpdateRequested,
            snackbar,
            emailErrors,
            updating,
            role,
            formSuccessMessages,
            clearSuccessMessages,
            classes,
        } = this.props;
        const tabProps = {
            data,
            availableStates,
            userId,
            snackbar,
            emailErrors,
            updating,
            role,
            formSuccessMessages,
            clearSuccessMessages,
            classes,
        };
        return (
            loading
                ? <Grid item component={CircularProgress} />
                : (
                    <InfoTabContents
                        {...tabProps}
                        userDataUpdateRequested={(userId, userData) => userDataUpdateRequested(userId, userData)}
                    />
                )
        );
    }
}

InfoTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    availableStates: PropTypes.array.isRequired,
    userDataUpdateRequested: PropTypes.func.isRequired,
    snackbar: PropTypes.object.isRequired,
    emailErrors: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    role: PropTypes.string,
    formSuccessMessages: PropTypes.array.isRequired,
    clearSuccessMessages: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        availableStates: getAvailableStates(state),
        loading: getSpecificUserDataLoading(state),
        data: getUserData(state),
        emailErrors: getEmailErrors(state),
        updating: getUserUpdating(state),
        role: getUserRole(state),
        formSuccessMessages: getUserSuccessMessages(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(bindActionCreators({
            userDataRequested,
            userDataUpdateRequested,
            clearSuccessMessages,
        }, dispatch)),
    };
};

const InfoTabFunction = (props) => {
    const { userId } = useParams();
    return <InfoTab {...props} userId={userId} />;
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withSnackbar(),
    withStyles(styles),
)(InfoTabFunction);
