import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getUserDownloadHistory } from '../../selectors/users';
import { userDownloadHistoryRequested } from '../../stores/users/actions';
import { withRouter } from '../../utils';
import { CircularProgress, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { styles } from './styles';
import { ViewButton } from '../../components/ViewButton';
import Pagination from '../../components/Pagination';
import EmptyTable from '../../components/EmptyTable';
import { useNavigate, useParams } from 'react-router';

const tableOptions = {
    pagination: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    filter: false,
};

class DownloadHistoryTab extends React.Component {
    tableColumns = [
        {
            name: 'Download Date',
            options: {
                setCellProps: () => ({ className: this.props.classes.lastUpdatedCol }),
            },
        },
        {
            name: 'Type',
            options: {
                setCellProps: () => ({ className: this.props.classes.typeCol }),
            },
        },
        {
            name: 'Software Ed.',
            options: {
                setCellProps: () => ({ className: this.props.classes.editionCol }),
            },
        },
        {
            name: 'State/Region',
            options: {
                setCellProps: () => ({ className: this.props.classes.stateCol }),
            },
        },
        {
            name: 'Underwriter Name',
            options: {
                sort: false,
                setCellProps: () => ({ className: this.props.classes.underwriterNameCol }),
            },
        },
        {
            name: 'Package Name',
            options: {
                setCellProps: () => ({ className: this.props.classes.packageNameCol }),
            },
        },
        {
            name: '',
            options: {
                filter: false,
                setCellProps: () => ({ className: this.props.classes.viewCol }),
                customBodyRender: (value) => {
                    return <ViewButton onClick={() => this.props.navigate('/admin/downloads/package/' + value)} />;
                },
            },
        },
    ];

    componentDidMount () {
        const { userId, userDownloadHistoryRequested } = this.props;
        userDownloadHistoryRequested(userId);
    }

    onPageChange = (page) => {
        const { userId, userDownloadHistoryRequested } = this.props;
        userDownloadHistoryRequested(userId, page);
    };

    render () {
        const { loading, data, page, total, perPage } = this.props;
        return loading
            ? <CircularProgress />
            : (
                <Grid container direction="column">
                    <Grid item>
                        <Pagination
                            page={ page }
                            perPage={ perPage }
                            total={ total }
                            onChange={ this.onPageChange }
                        />
                    </Grid>
                    <Grid item>
                        {data && data.length > 0
                            ? (
                                <MUIDataTable
                                    data={data}
                                    options={ tableOptions }
                                    columns={ this.tableColumns }
                                />
                            )
                            : <EmptyTable title="Download History" includeSearchFilterInMessage={ false } />}
                    </Grid>
                </Grid>
            );
    }
}

DownloadHistoryTab.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    userDownloadHistoryRequested: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    data: PropTypes.array,
};

const mapStateToProps = function (state) {
    return {
        loading: state.users.downloadHistory.loading,
        data: getUserDownloadHistory(state),
        page: state.users.downloadHistory.page,
        perPage: state.users.downloadHistory.perPage,
        total: state.users.downloadHistory.total,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators({ userDownloadHistoryRequested }, dispatch)),
    };
};

const DownloadHistoryTabFunction = (props) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    return <DownloadHistoryTab { ...props } navigate={ navigate } userId={ userId } />;
};

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(DownloadHistoryTabFunction);
