import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { withRouter } from '../../utils';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import Users from '../Users';
import UserDetails from '../UserDetails';
import CreateAdmin from '../CreateAdmin';
import styles from './styles';

class AdminUsers extends Component {
    static propTypes = {
        // WithStyles
        classes: PropTypes.object.isRequired,
    };

    render () {
        const { classes } = this.props;
        return (
            <Grid container direction="column" alignItems="stretch" justifyContent="flex-start" className={classes.root}>
                <Routes>
                    <Route path="/" element={<Users />} />
                    <Route path="userDetails/:userId/*" element={<UserDetails />} />
                    <Route path="createAdmin" element={<CreateAdmin />} />
                    <Route path="*" element={<Navigate to="/admin/users" />} />
                </Routes>
            </Grid>
        );
    }
}

export default compose(
    withStyles(styles),
    withRouter,
)(AdminUsers);
