import { call, put, putResolve, select, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import { doCheck } from './auth';
import { getAuthToken } from '../selectors/auth';
import {
    initializationFailed,
    initializationSucceeded,
} from '../stores/app/actions';
import { getPathname } from '../selectors/navigation';
import { requestWithAuth } from '../utils/api';
import { loginFailed, loginSucceeded } from '../stores/auth/actions';
import { normalize, States, Underwriters } from '../schemas';
import { debugError } from '../utils/log';

/**
 * Runs on app boot
 * @param { Persistor } persistor
 * @return { IterableIterator<*> }
 */
export function * init (persistor) {
    // Get the current persistor state
    const { bootstrapped = false } = persistor.getState();
    /*
     * If the store has not yet been rehydrated, we take on the rehyrdate action to
     * effectively block until the store is rehydrated.
     */
    if (!bootstrapped) {
        yield take(REHYDRATE);
    }
    const pathname = yield select(getPathname);
    /*
     * Now that we know if the store is rehydrated, we can grab the auth token
     * from the store and reauthenticate.
     */
    const token = yield select(getAuthToken);
    try {
        if (pathname.indexOf('/bridge/handoff') === 0) {
            const token = pathname.slice('/bridge/handoff'.length + 1,
                pathname.length);
            yield call(doHandoff, token);
        } else if (token) {
            yield call(doCheck);
        }
        yield put(initializationSucceeded());
    } catch (e) {
        debugError(e);
        yield put(initializationFailed(
            'Failed to reach the servers. Please try again later.'));
    }
}

function * doHandoff (handoffToken) {
    const response = yield call(
        requestWithAuth,
        '/auth/handoff',
        'POST',
        handoffToken,
    );
    if (response.ok) {
        const { token, user, states, promptsFileUrl, underwriters } = yield call(
            [response, response.json]);
        yield putResolve(loginSucceeded(
            token,
            user,
            yield call(normalize, states, States),
            promptsFileUrl,
            yield call(normalize, underwriters, Underwriters),
        ));
    } else {
        yield put(loginFailed());
    }
}
