import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import deepEqual from 'deep-equal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { noop } from '../../utils';
import { styles } from './styles';
import { fetchUserSettings, updateUserSettings } from '../../stores/settings/actions';
import { getCurrentUserEmail } from '../../selectors/currentUser';
import { fetchStates } from '../../stores/states/actions';
import { getAvailableStates } from '../../selectors/states';
import {
    StyledCompany,
    StyledEditions,
    StyledEmail,
    StyledName,
    StyledSaveButton,
    StyledSerialNumber,
    StyledStates,
    StyledUnderwriterSelector,
} from '../../components/FormComponents';
import {
    getAvailableUnderwritersForDataTableFilter,
} from '../../selectors/underwriters';
import {
    areSettingsLoading,
    areStatesLoading, getEditions, getName, getOrganization,
    getSerialNumber, getStates, getUnderwriters, getValidationErrors,
} from '../../selectors/settings';

class SettingsForm extends React.Component {
    constructor (props) {
        super(props);
        this.onStateSelected = this.onStateSelected.bind(this);
        this.onEditionSelected = this.onEditionSelected.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    }

    state = {
        companyName: this.props.storedOrganization,
        companyNameError: false,
        companyNameValidationError: !!this.props.validationErrors.organization,
        editions: [...this.props.storedEditions],
        editionsError: false,
        name: this.props.storedName,
        states: [...this.props.storedStates],
        statesError: false,
        underwriters: [...this.props.storedUnderwriters],
    };

    onUnderwriterSelected = ({ label, value }) => {
        const { underwriters } = this.state;
        this.setState({
            ...this.state,
            underwriters: [...underwriters, { name: label, id: value }],
        });
    };

    onUnderwriterRemoved = ({ id }) => {
        const { underwriters } = this.state;
        this.setState({
            ...this.state,
            underwriters: underwriters.filter(
                ({ id: storedId }) => storedId !== id),
        });
    };

    onStateSelected = (value) => {
        if (!this.state.states.includes(value)) {
            this.setState({
                ...this.state,
                states: [...this.state.states, value],
                statesError: false,
            });
        } else {
            const states = this.state.states.filter(
                (state) => state !== value);
            this.setState({
                ...this.state,
                states,
            });
        }
    };

    onEditionSelected = (value) => {
        if (!this.state.editions.includes(value)) {
            this.setState({
                ...this.state,
                editions: [...this.state.editions, value],
                editionsError: false,
            });
        } else {
            const editions = this.state.editions.filter(
                (edition) => edition !== value);
            this.setState({
                ...this.state,
                editions,
            });
        }
    };

    onNameChange = (event) => {
        this.setState({
            ...this.state,
            name: event.target.value,
        });
    };

    onCompanyNameChange = (event) => {
        this.setState({
            ...this.state,
            companyName: event.target.value,
            companyNameError: false,
            companyNameValidationError: false,
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        const {
            name = '',
            companyName = '',
            states = [],
            editions = [],
            underwriters = [],
        } = this.state;
        if (states.length === 0 || editions.length === 0) {
            this.setState({
                statesError: states.length === 0,
                editionsError: editions.length === 0,
            });
        } else {
            /*
             * All of the data that has been entered is valid. i.e. we have a value for name, company name, at least one state and at
             * least one edition. So now we will fire off the update or the settings. First let's check that they've actually changed
             * something - i.e. at least one new value !== current value?
             */
            const {
                storedEditions,
                storedName,
                storedOrganization,
                storedStates,
                storedUnderwriters,
                updateUserSettings,
            } = this.props;
            const nameChanged = storedName !== name;
            const companyNameChanged = storedOrganization !== companyName;
            const statesChanged = !deepEqual(storedStates, states);
            const editionsChanged = !deepEqual(storedEditions, editions);
            const underwritersChanged = !deepEqual(storedUnderwriters, underwriters);
            if (nameChanged || companyNameChanged || statesChanged || editionsChanged || underwritersChanged) {
                updateUserSettings({
                    name,
                    organization: companyName,
                    states,
                    editions,
                    underwriters,
                });
            }
        }
    };

    render () {
        const {
            availableStates,
            availableUnderwriters,
            classes,
            email,
            serialNumber,
            storedEditions,
            storedName,
            storedOrganization,
            storedStates,
            storedUnderwriters,
        } = this.props;
        const {
            companyName,
            companyNameError,
            companyNameValidationError,
            editions,
            editionsError,
            name,
            states,
            statesError,
            underwriters,
        } = this.state;
        window.onbeforeunload = function (event) {
            if (
                storedName !== name ||
                storedOrganization !== companyName ||
                !deepEqual(storedStates, states) ||
                !deepEqual(storedEditions, editions) ||
                !deepEqual(storedUnderwriters, underwriters)
            ) {
                event.returnValue('Changes to Settings have not been saved. Click Cancel to stop and Save your changes, ' +
                'or click OK to discard your changes.');
            }
        };
        return (
            <div>
                <Grid
                    item
                    container
                    direction="column"
                    component="form"
                    onSubmit={(event) => this.onSubmit(event)}
                >
                    <Grid item>
                        <StyledEmail email={email} />
                    </Grid>
                    <Grid item>
                        <StyledName
                            name={name}
                            onChange={this.onNameChange}
                        />
                    </Grid>
                    <Grid item>
                        <StyledCompany
                            company={companyName}
                            onChange={this.onCompanyNameChange}
                            error={companyNameError}
                            validationError={companyNameValidationError}
                            hidden={true}
                        />
                    </Grid>
                    <Grid item>
                        <StyledStates
                            availableStates={availableStates}
                            states={states}
                            handleSelectedState={(event) => this.onStateSelected(event.target.value)}
                            handleDelete={(value) => this.onStateSelected(value)}
                            error={statesError}
                        />
                    </Grid>
                    <Grid item>
                        <StyledUnderwriterSelector
                            availableUnderwriters={availableUnderwriters}
                            underwriters={underwriters}
                            handleSelectedUnderwriter={(event) => {
                                this.onUnderwriterSelected(event.target.value);
                            }}
                            handleDelete={this.onUnderwriterRemoved}
                        />
                    </Grid>
                    <Grid item>
                        <StyledEditions
                            editions={editions}
                            handleSelectedEdition={(event) => this.onEditionSelected(event.target.value)}
                            handleDelete={(value) => this.onEditionSelected(value)}
                            error={editionsError}
                        />
                    </Grid>
                    <Grid item>
                        <StyledSerialNumber serialNumber={serialNumber} />
                    </Grid>
                    <Grid item component={Divider} className={classes.divider} />
                    <Grid item>
                        <StyledSaveButton />
                    </Grid>
                </Grid>
                {/* TODO: add back Prompt for React Router v6
                <Prompt
                    message={
                        'Changes to Settings have not been saved. Click Cancel to stop and Save your changes, ' +
                        'or click OK to discard your changes.'
                    }
                    when={
                        storedName !== name ||
                        storedOrganization !== companyName ||
                        !deepEqual(storedStates, states) ||
                        !deepEqual(storedEditions, editions) ||
                        !deepEqual(storedUnderwriters, underwriters)
                    }
                /> */}
            </div>
        );
    }
}

SettingsForm.propTypes = {
    classes: PropTypes.object.isRequired,
    availableStates: PropTypes.array,
    availableUnderwriters: PropTypes.array,
    email: PropTypes.string,
    serialNumber: PropTypes.string,
    settingsLoading: PropTypes.bool,
    statesLoading: PropTypes.bool,
    storedEditions: PropTypes.array.isRequired,
    storedName: PropTypes.string.isRequired,
    storedOrganization: PropTypes.string.isRequired,
    storedStates: PropTypes.array.isRequired,
    storedUnderwriters: PropTypes.array.isRequired,
    validationErrors: PropTypes.object,
    updateUserSettings: PropTypes.func,
};

SettingsForm.defaultProps = {
    availableStates: [],
    availableUnderwriters: [],
    email: '',
    serialNumber: '',
    settingsLoading: false,
    statesLoading: false,
    validationErrors: {},
    updateUserSettings: noop,
};

class Settings extends React.Component {
    componentDidMount () {
        const { fetchStates, fetchUserSettings } = this.props;
        fetchStates();
        fetchUserSettings();
    }

    render () {
        const {
            availableStates,
            availableUnderwriters,
            classes,
            email,
            serialNumber,
            settingsLoading,
            statesLoading,
            storedEditions,
            storedName,
            storedOrganization,
            storedStates,
            storedUnderwriters,
            updateUserSettings,
            validationErrors,
        } = this.props;
        const loading = statesLoading || settingsLoading;
        return (
            <div className={classes.settings}>
                <Typography
                    variant="h1"
                    gutterBottom
                    className={classes.settingsHeader}
                >
                    Settings
                </Typography>
                {loading && <CircularProgress />}
                {!loading &&
                <div>
                    <SettingsForm
                        classes={classes}
                        availableStates={availableStates}
                        availableUnderwriters={availableUnderwriters}
                        storedName={storedName}
                        email={email}
                        storedOrganization={storedOrganization}
                        serialNumber={serialNumber}
                        storedStates={storedStates}
                        storedEditions={storedEditions}
                        storedUnderwriters={storedUnderwriters}
                        updateUserSettings={(settings) => updateUserSettings(settings)}
                        validationErrors={validationErrors}
                    />
                </div>
                }
            </div>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
    availableStates: PropTypes.array,
    availableUnderwriters: PropTypes.array,
    email: PropTypes.string,
    serialNumber: PropTypes.string,
    settingsLoading: PropTypes.bool,
    statesLoading: PropTypes.bool,
    storedEditions: PropTypes.array.isRequired,
    storedName: PropTypes.string.isRequired,
    storedOrganization: PropTypes.string.isRequired,
    storedStates: PropTypes.array.isRequired,
    storedUnderwriters: PropTypes.array.isRequired,
    validationErrors: PropTypes.object,
    fetchStates: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    updateUserSettings: PropTypes.func,
};

Settings.defaultProps = {
    availableStates: [],
    availableUnderwriters: [],
    email: '',
    serialNumber: '',
    settingsLoading: false,
    statesLoading: false,
    validationErrors: {},
    fetchStates: noop,
    fetchUserSettings: noop,
    updateUserSettings: noop,
};

const mapStateToProps = function (state) {
    return {
        availableStates: getAvailableStates(state),
        availableUnderwriters: getAvailableUnderwritersForDataTableFilter(state),
        email: getCurrentUserEmail(state),
        serialNumber: getSerialNumber(state),
        settingsLoading: areSettingsLoading(state),
        statesLoading: areStatesLoading(state),
        storedEditions: getEditions(state),
        storedName: getName(state),
        storedOrganization: getOrganization(state),
        storedStates: getStates(state),
        storedUnderwriters: getUnderwriters(state),
        validationErrors: getValidationErrors(state),
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators({ fetchStates, fetchUserSettings, updateUserSettings }, dispatch)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(Settings));
