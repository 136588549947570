import React, { Fragment } from 'react';
import { Link, Routes } from 'react-router-dom';
import { Navigate, Route, matchPath } from 'react-router';
import { Drawer, Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PersonOutline as PersonIcon, ShowChart as AnalyticsIcon } from '@mui/icons-material';
import SoftProHeader from '../Header';
import DashboardIconImage from '../../icons/icon-dashboard.svg';
import PackageIconImage from '../../icons/icon-packages.svg';
import UnderwritersIconImage from '../../icons/icon-quill-large-white.png';
import AdminDashboard from '../../components/AdminDashboard';
import Analytics from '../../components/Analytics';
import AdminPackages from '../AdminPackages';
import AdminUnderwriters from '../AdminUnderwriters';
import AdminUsers from '../AdminUsers';
import NotificationsDetails from '../NotificationsDetails';
import DownloadsDetails from '../DownloadsDetails';
import UserDownloads from '../UserDownloads';
import AdminChangePassword from '../AdminChangePassword';
import { styles } from './styles';
import { connect } from 'react-redux';
import { getPathname } from '../../selectors/navigation';
import { getUrl, withRouter } from '../../utils';
import PropTypes from 'prop-types';
import { compose } from 'redux';

const withAdminStyles = withStyles(styles);

const DashboardIcon = (props) => {
    return <img src={DashboardIconImage} alt="Dashboard" {...props} />;
};

const PackagesIcon = (props) => {
    return <img src={PackageIconImage} alt="Packages" {...props} />;
};

const UnderwritersIcon = (props) => {
    return <img src={UnderwritersIconImage} alt="Underwriters" {...props} />;
};

const AdminListItemLink = ({ to, itemProps }) => <Link to={to} {...itemProps} />;
AdminListItemLink.propTypes = {
    to: PropTypes.string.isRequired,
    itemProps: PropTypes.object.isRequired,
};

const AdminListItem = withAdminStyles(({ classes, icon, primary, to, path, extraMatchPaths = [] }) => {
    let selected = matchPath(new URL(to).pathname, window.location.pathname);

    if (extraMatchPaths.length > 0) {
        extraMatchPaths.forEach((path) => {
            if (matchPath(path, window.location.pathname)) {
                selected = true;
            }
        });
    }
    return (
        <li>
            <ListItemButton
                button
                selected={!!selected}
                component={(itemProps) => <AdminListItemLink to={to} itemProps={itemProps} />}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={primary}
                    className={classes.listItemText}
                />
            </ListItemButton>
        </li>
    );
});
const mapStateToProps = (state) => {
    return {
        path: getPathname(state),
    };
};
const ConnectedAdminListItem = connect(mapStateToProps)(AdminListItem);

const AdminPortalTabs = withAdminStyles(({ url, classes }) => {
    const allTabs = [
        {
            text: 'Dashboard',
            icon: <DashboardIcon className={classes.menuIcon} />,
            linkTo: `${window.location.origin}/admin/dashboard`,
        },
        {
            text: 'Analytics',
            icon: <AnalyticsIcon className={classes.menuIcon} />,
            linkTo: `${window.location.origin}/admin/analytics`,
            extraMatchPaths: [
                '/admin/analytics/downloads',
                '/admin/analytics/notifications',
                '/admin/analytics/users',
            ],
        },
        {
            text: 'Packages',
            icon: <PackagesIcon className={classes.menuIcon} />,
            linkTo: `${window.location.origin}/admin/packages`,
        },
        {
            text: 'Users',
            icon: <PersonIcon className={classes.menuIcon} />,
            linkTo: `${window.location.origin}/admin/users`,
        },
        {
            text: 'Underwriters',
            icon: <UnderwritersIcon className={classes.menuIcon} />,
            linkTo: `${window.location.origin}/admin/underwriters`,
        },
    ];

    return allTabs.map((tab) => (
        <div className="tab" key={tab.text}>
            <ConnectedAdminListItem
                to={tab.linkTo}
                primary={tab.text}
                icon={tab.icon}
                extraMatchPaths={tab.extraMatchPaths ? tab.extraMatchPaths : []}
            />
        </div>
    ));
});

class AdminPortal extends React.Component {
    static propTypes = {
        classes: PropTypes.shape({
            innerDrawer: PropTypes.string,
            drawerPaper: PropTypes.string,
        }).isRequired,
        staticContext: PropTypes.object,
        router: PropTypes.object.isRequired,
    };

    render () {
        // Use other to capture only the props you're not using in List, to avoid warnings in the console
        const { classes, staticContext, router, ...other } = this.props;
        return (
            <Fragment>
                <SoftProHeader
                    isUserLoggedIn
                    isOnBoardingComplete
                    hideEditionSwitchForAdmins
                />
                <Drawer
                    variant="permanent"
                    anchor="left"
                    classes={{ paper: classes.drawerPaper }}
                    PaperProps={{ elevation: 7 }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                        className={classes.innerDrawer}
                    >
                        <Grid item>
                            <List {...other}>
                                <AdminPortalTabs url={getUrl(window, router)} />
                            </List>
                        </Grid>
                    </Grid>
                </Drawer>
                <Routes>
                    <Route path="/" element={<Navigate to="/admin/dashboard" />} />
                    <Route path="changePassword" element={<AdminChangePassword />} />
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="analytics/*" element={<Analytics />} />
                    <Route path="notifications/package/:packageId" element={<NotificationsDetails />} />
                    <Route path="downloads/package/:packageId" element={<DownloadsDetails />} />
                    <Route path="downloads/user/:userId" element={<UserDownloads />} />
                    <Route path="packages/*" element={<AdminPackages />} />
                    <Route path="users/*" element={<AdminUsers />} />
                    <Route path="underwriters/*" element={<AdminUnderwriters />} />
                </Routes>
            </Fragment>
        );
    }
}

export default compose(withAdminStyles, withRouter)(AdminPortal);
