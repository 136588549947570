import { call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { requestWithAuth } from '../utils/api';
import { FETCH_STATES, fetchStatesSucceeded } from '../stores/states/actions';
import { getAuthToken } from '../selectors/auth';
import { parseAndNormalizeJsonResponse, States } from '../schemas';

function * fetchStates () {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/states', 'GET', token);
    if (response.ok) {
        const result = yield call(parseAndNormalizeJsonResponse, response, States);
        yield put(fetchStatesSucceeded(result));
    } else {
        yield put(snackbar.show({ message: 'Failed to load states and regions from the server. Try again later.' }));
    }
}

export function * listenFetchStates () {
    yield takeEvery(FETCH_STATES, fetchStates);
}
