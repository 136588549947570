import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';

function AppGate ({ classes, loading, errors }) {
    return (
        <Grid container alignItems="center" justifyContent="center" direction="column" className={classes.container}>
            {loading ? <Grid item component={CircularProgress} className={classes.loading} /> : null}
            {errors
                ? (errors.map((error) => (
                    <Grid item key={error}>
                        <Typography color="error" className={classes.error}>{error}</Typography>
                    </Grid>
                )))
                : null}
        </Grid>
    );
}

AppGate.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
};

AppGate.defaultProps = {
    loading: true,
    errors: [],
};

export default withStyles(styles)(AppGate);
