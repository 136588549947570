import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'material-ui-snackbar-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { theme } from './theme';
import initializeStore from './stores';
import AppGate from './components/AppGate';
import Root from './Root';

const { store, persistor, history } = initializeStore();

const App = () => {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider SnackbarProps={{
                        autoHideDuration: 3500,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    }}
                    >
                        <PersistGate persistor={persistor} loading={<AppGate />}>
                            <Root history={history} />
                        </PersistGate>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
};

export default App;
