import { createSelector } from 'reselect';
import { getEntities } from './entities';
import { denormalize } from 'normalizr';
import { Packages } from '../schemas';

export const getOnboardingSuggestedWatchlist = createSelector(
    (state) => state.onBoardingWatchlist.suggestedWatchlist,
    getEntities,
    (suggestedWatchlist, entities) => denormalize(suggestedWatchlist, Packages, entities),
);
