import { put, select } from 'redux-saga/effects';

/**
 * Creates a saga that can be called variadicly with object IDs and will trigger a refetch of a page of API data
 * if the current page will become empty after deleting all of the declared IDs.
 *
 * @param {function({}): {pagination: {page: number}}} getContext A selector that will return the API page context.
 * @param {function({}): *[]} getCurrentPageIds A selector that will return the IDs of the objects on the current page
 * @param {function({}): {type: string}} onFetchRequested An action creator that will generate a fetch action from an
 *      api page context object.
 * @return {Generator} The callable saga which takes
 */
export const createReloadAfterDeleteSaga = (getContext, getCurrentPageIds, onFetchRequested) => {
    return function * (...ids) {
        const currentContext = yield select(getContext);
        const { pagination: { page } } = currentContext;
        if (page > 1) {
            /*
             * If we're not on the first page, we want to see if the current page will become empty
             * after deleting all of the objects and if so reload with pagination back one page. Otherwise
             * we can just reload the current page.
             */
            const idsOnPage = yield select(getCurrentPageIds);
            const idsRemaining = idsOnPage.filter((idOnPage) => !ids.includes(idOnPage));
            if (idsRemaining.length === 0) {
                yield put(onFetchRequested({
                    ...currentContext,
                    pagination: {
                        ...currentContext.pagination,
                        page: page - 1,
                    },
                }));
            } else {
                yield put(onFetchRequested(currentContext));
            }
        } else {
            // Since we're on the first page, it's safe to just reload with the current context.
            yield put(onFetchRequested(currentContext));
        }
    };
};
