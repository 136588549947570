import queryString from 'query-string';
import { DEFAULT_PER_PAGE } from '../constants/api';
import ROLES from '../constants/roles';
import EDITIONS from '../constants/editions';

export async function request (uri, method = 'GET', body = null) {
    const fullURI = `${process.env.REACT_APP_DOCUMENTS_API_URI}/api${uri}`;
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const options = { method, headers };
    if (body) {
        options.body = JSON.stringify(body);
    }
    return fetch(fullURI, options);
}

export async function requestWithAuth (uri, method = 'GET', token = null, query = null, body = null) {
    let fullURI = `${process.env.REACT_APP_DOCUMENTS_API_URI}/api${uri}`;
    if (query !== null) {
        fullURI += '?' + queryString.stringify(query, { arrayFormat: 'bracket' });
    }
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    if (token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }
    const options = { method, headers };
    if (body) {
        options.body = JSON.stringify(body);
    }
    return fetch(fullURI, options);
}

export async function uploadWithAuth (
    uri, method = 'GET', token = null, body = null) {
    const fullURI = `${process.env.REACT_APP_DOCUMENTS_API_URI}/api${uri}`;
    const headers = {
        Accept: 'application/json',
    };
    if (token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }
    const options = { method, headers };
    if (body) {
        options.body = body;
    }
    return fetch(fullURI, options);
}

/**
 * Transform the user to be stored in the store.
 *
 * We essentially extract out the users roles and editions from the object assuming they're nested in the rawUser
 * object as an array of objects with a name property OR a simple array of strings. If the rawUser object doesn't
 * contain editions or roles, they will respectively fall back to the existing values passed in as the remaining
 * arguments to this function.
 *
 * The remaining data in rawUser that is not editions or states will be returned in the data property.
 *
 * @param {{roles?: ({name: string}|string)[], editions?: ({name: string}|string)[]}} rawUser The raw user data
 *      to parse, typically from an API response.
 * @param {string[]?} oldRoles The existing array of roles in the state.
 * @param {string[]?} oldEditions The existing array of editions in the state.
 * @param {string[]?} oldStates The existing array of states in the state.
 * @param oldUnderwriters
 * @return {{editions: string[], data: {}, roles: string[]}}
 */
export function transformUser (rawUser, oldRoles = [], oldEditions = [], oldStates = [], oldUnderwriters = []) {
    const { editions = null, roles = null, states = null, underwriters = null, ...userData } = rawUser;
    return {
        data: userData,
        roles: (roles || oldRoles).reduce((filteredRoles, rawRole) => {
            const { name = null } = (typeof rawRole === 'object' ? rawRole : { name: rawRole });
            return (name && ROLES.includes(name))
                ? [...filteredRoles, name]
                : filteredRoles;
        }, []),
        editions: (editions || oldEditions).reduce((filteredEditions, rawEdition) => {
            const { name = null } = (typeof rawEdition === 'object' ? rawEdition : { name: rawEdition });
            return (name && EDITIONS.includes(name))
                ? [...filteredEditions, name]
                : filteredEditions;
        }, []),
        states: (states || oldStates).reduce((filteredStates, rawState) => {
            const { code = null } = (typeof rawState === 'object' ? rawState : { code: rawState });
            return (code)
                ? [...filteredStates, code]
                : filteredStates;
        }, []),
        underwriters: (underwriters || oldUnderwriters).reduce((filteredUnderwriters, rawUnderwriter) => {
            const { id = null } = (typeof rawUnderwriter === 'object' ? rawUnderwriter : { id: rawUnderwriter });
            return (id)
                ? [...filteredUnderwriters, id]
                : filteredUnderwriters;
        }, []),
    };
}

/**
 * Check an auth token with the server.
 *
 * @param token
 * @return {Promise<boolean|{token: string, user: {}}>}
 */
export async function check (token) {
    const response = await requestWithAuth('/auth/check', 'POST', token);
    if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
            return false;
        } else if (response.status >= 500) {
            throw new Error(
                'Unable to communicate with the servers due to heavy load. We apologize for the inconvenience.',
            );
        } else {
            throw new Error(
                `An unknown error occurred (${response.status}). Try again later.`,
            );
        }
    } else {
        const respData = await response.json();
        return { ...respData, token };
    }
}

/**
 * Generate an object that represents a page of data coming from the server.
 *
 * @param data
 * @param currentPage
 * @param perPage
 * @param total
 * @param extra
 * @return {{total: number, perPage: number, data: Array, currentPage: number}}
 */
export const createApiPageData = (
    {
        data = [],
        currentPage = 1,
        perPage = DEFAULT_PER_PAGE,
        total = 0,
        ...extra
    } = {},
) => ({
    data,
    currentPage: parseInt(currentPage),
    perPage: parseInt(perPage),
    total: parseInt(total),
    ...extra,
});

/**
 * Take an object representing a query string and add a state to it.
 *
 * @param {{}} query
 * @param {{}=null} state
 * @return {{state?: string}}
 */
export const addStateToQuery = (query, { state = null }) => {
    return state ? { ...query, state } : query;
};

/**
 * Safely add a token to a backend URL respecting any existing query parameters.
 * @param {string} rawUrl
 * @param {string} token
 * @param {{}?} params
 * @return {string}
 */
export const addTokenToUrl = (rawUrl, token, params = {}) => {
    const { url, initialParams = {} } = queryString.parseUrl(rawUrl, { arrayFormat: 'bracket' });
    const query = queryString.stringify(
        { ...initialParams, access_token: token, ...params },
        { arrayFormat: 'bracket' },
    );
    return `${url}?${query}`;
};

/**
 * Generate a URL to the backend server with the token in the query string.
 *
 * @param {string} path
 * @param {token} token
 * @param {{}?} params
 * @return {string}
 */
export const generateUrlWithToken = (path, token, params = {}) => {
    return addTokenToUrl(`${process.env.REACT_APP_DOCUMENTS_API_URI}${path}`, token, params);
};
