import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { ViewButton } from '../../ViewButton';
import SoftProDataTable from '../../SoftProDataTable';
import DataTableColumn from '../../DataTable/DataTableColumn';
import { ADMIN_UNDERWRITERS_ORDER_BY_FIELD_NAME } from '../../../constants/api';
import { SORT_ASC } from '../../DataTable/constants';
import TablesPropTypes from '../../../propTypes/tables';
import ApiPropTypes from '../../../propTypes/api';
import { StyledTrash } from '../../TableComponents';
import EmptyTable from '../../EmptyTable';
import styles from './styles';

export const ACTION_DELETE = 'delete';
export const ACTION_LABELS = {
    [ACTION_DELETE]: 'Delete selected',
};
export const ACTIONS = [ACTION_DELETE];

const TABLE_ACTIONS = ACTIONS.map((action) => ({ value: action, label: ACTION_LABELS[action] }));

class SoftProUnderwritersDataTable extends Component {
    static propTypes = {
        // Required
        classes: PropTypes.object.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,

        // Optional
        underwritersLoading: PropTypes.arrayOf(PropTypes.number),
        getEditLocation: PropTypes.func,
        onDeleteRequested: PropTypes.func,
        onDeleteMultipleRequested: PropTypes.func,
    };

    static defaultProps = {
        underwritersLoading: [],
        getEditLocation: () => null,
        onDeleteRequested: null,
        onDeleteMultipleRequested: null,
    };

    getEditLocation = (id) => this.props.getEditLocation(id);

    createOnDeleteRequested = (id) => (event) => {
        event.preventDefault();
        const { onDeleteRequested } = this.props;
        if (onDeleteRequested) {
            onDeleteRequested(id);
        }
    };

    onActionSelect = (selectedIds, action) => {
        if (action === ACTION_DELETE) {
            const { onDeleteMultipleRequested } = this.props;
            if (onDeleteMultipleRequested) {
                onDeleteMultipleRequested(selectedIds);
            }
        }
    };

    isLoading = (id) => this.props.underwritersLoading.includes(id);

    renderName = ({ name }) => name;
    renderEditButton = ({ id }) => {
        const location = this.getEditLocation(id);
        return location ? <ViewButton disabled={this.isLoading(id)} key={id} to={location} /> : null;
    };

    renderDeleteButton = ({ id }) => (
        <StyledTrash
            disabled={this.isLoading(id)}
            key={id}
            onClick={this.createOnDeleteRequested(id)}
        />
    );

    renderEmpty = () => <EmptyTable title="Underwriters" />;

    getKey = ({ id }) => id;

    render () {
        const {
            classes,
            tableContext,
            tableData,
            onFetchDataRequested,
        } = this.props;
        return (
            <SoftProDataTable
                getKey={this.getKey}
                onFetchDataRequested={onFetchDataRequested}
                tableContext={tableContext}
                tableData={tableData}
                renderEmpty={this.renderEmpty}
                actions={TABLE_ACTIONS}
                onActionSelect={this.onActionSelect}
            >
                {({ createOnSort, createSortGetter }) => (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="Underwriter Name"
                            classes={{ headCell: classes.stretchCol, bodyCell: classes.stretchCol }}
                            getSort={createSortGetter(ADMIN_UNDERWRITERS_ORDER_BY_FIELD_NAME)}
                            onSort={createOnSort(ADMIN_UNDERWRITERS_ORDER_BY_FIELD_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {this.renderName}
                        </DataTableColumn>
                        <DataTableColumn classes={{ headCell: classes.editCol, bodyCell: classes.editCol }}>
                            {this.renderEditButton}
                        </DataTableColumn>
                        <DataTableColumn classes={{ headCell: classes.removeCol, bodyCell: classes.removeCol }}>
                            {this.renderDeleteButton}
                        </DataTableColumn>
                    </Fragment>
                )}
            </SoftProDataTable>
        );
    }
}

export default withStyles(styles)(SoftProUnderwritersDataTable);
