const baseDropzoneStyle = {
    padding: '0 15px',
    width: 320,
    height: 150,
    borderWidth: 2,
    borderColor: '#6089BD',
    borderStyle: 'dashed',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 10,
    marginTop: 6,
};
export const styles = () => ({
    textField: {
        width: 350,
        marginTop: 20,
        marginBottom: 5,
        color: '#57444E',
        marginRight: 25,
        alignSelf: 'flex-start',
        fontSize: 14,
    },
    textInput: {
        textIndent: 20,
        height: 30,
    },
    textFieldLabel: {
        textTransform: 'uppercase',
    },
    dropdown: {
        marginTop: 9,
        marginBottom: 8,
        width: 350,
        fontSize: 14,
    },
    states: {
        display: 'flex',
        flexDirection: 'column',
    },
    stateChips: {
        marginLeft: 0,
        width: 500,
        marginBottom: 15,
    },
    underwriters: {
        display: 'flex',
        flexDirection: 'column',
    },
    underwriterChips: {
        marginTop: 15,
        marginLeft: 0,
        width: 500,
        marginBottom: 15,
    },
    editions: {
        display: 'flex',
        flexDirection: 'column',
    },
    editionChips: {
        marginLeft: 0,
        marginBottom: 15,
    },
    chipStyle: {
        marginRight: 8,
        marginBottom: 8,
    },
    dropdownInputLabel: {
        marginBottom: 0,
        marginTop: 7,
        width: 450,
        textTransform: 'uppercase',
    },
    underwriterLabel: {
        marginBottom: 20,
        marginTop: 7,
        width: 450,
        textTransform: 'uppercase',
    },
    formControl: {
        width: '50%',
        marginBottom: '0.8em',
        alignSelf: 'flex-start',
    },
    textFormControl: {
        width: 'auto',
        marginBottom: '0.8em',
    },
    checkboxDiv: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 10,
        color: '#57444E !important',
    },
    checkboxLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#57444E',
        marginBottom: 10,
        textTransform: 'uppercase',
    },
    saveButton: {
        width: 'auto',
        fontSize: 16,
        marginTop: 10,
        paddingLeft: '3em',
        paddingRight: '3em',
    },
    textAreaInput: {
        minHeight: 150,
        height: 150,
        maxHeight: 150,
        padding: 10,
        fontSize: 14,
        width: 330,
        color: '#57444E',
        marginTop: 16,
    },
    dropZoneContainer: {
        marginBottom: '0.8em',
    },
    baseDropzoneStyle,
    activeDropzoneStyle: {
        ...baseDropzoneStyle,
        borderStyle: 'solid',
        borderColor: '#6089BD',
        backgroundColor: '#eee',
    },
    rejectDropzoneStyle: {
        ...baseDropzoneStyle,
        borderStyle: 'solid',
        borderColor: '#F33E36',
        backgroundColor: '#eee',
    },
    dropzoneLabel: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#57444E',
        fontSize: 12,
    },
    downloadFileButton: {
        letterSpacing: 0,
        border: 'none',
        textTransform: 'none',
        width: '100%',
        marginTop: 0,
        marginBottom: 7,
        fontSize: 14,
        padding: 0,
        minHeight: 'unset',
        marginLeft: 10,
        height: 14,
    },
});
