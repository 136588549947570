import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { styles } from './styles';
import React from 'react';

function TabContainer (props) {
    return (
        <Typography component="div" className={props.classes.tabContents}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabContainer);
