export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';
export const FETCH_USER_SETTINGS_SUCCEEDED = 'FETCH_USER_SETTINGS_SUCCEEDED';
export const FETCH_USER_SETTINGS_FAILED = 'FETCH_USER_SETTINGS_FAILED';

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_SUCCEEDED = 'UPDATE_USER_SETTINGS_SUCCEEDED';
export const UPDATE_USER_SETTINGS_FAILED = 'UPDATE_USER_SETTINGS_FAILED';

export function fetchUserSettings () {
    return {
        type: FETCH_USER_SETTINGS,
    };
}

export function fetchUserSettingsSucceeded (settings) {
    return {
        type: FETCH_USER_SETTINGS_SUCCEEDED,
        settings,
    };
}

export function fetchUserSettingsFailed () {
    return {
        type: FETCH_USER_SETTINGS_FAILED,
    };
}

export function updateUserSettings (settings) {
    return {
        type: UPDATE_USER_SETTINGS,
        settings,
    };
}

export function updateUserSettingsSucceeded (settings) {
    return {
        type: UPDATE_USER_SETTINGS_SUCCEEDED,
        settings,
    };
}

export function updateUserSettingsFailed (validationErrors) {
    return {
        type: UPDATE_USER_SETTINGS_FAILED,
        validationErrors,
    };
}
