import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { noop } from '../../utils';
import FormPropTypes from '../../propTypes/forms';
import { StyledSaveButton } from '../FormComponents';
import FormMessages from '../FormMessages';
import BackButton from '../BackButton';

class SimpleForm extends Component {
    static propTypes = {
        // A render prop
        children: PropTypes.func,
        // The route to go back to, defaults to render no back button
        backTo: PropTypes.string,
        // Whether or not the form is in a loading state.
        loading: PropTypes.bool,
        // An object map of field names to an array of their errors.
        fieldErrors: PropTypes.object,
        // An array of general form errors.
        formErrors: FormPropTypes.errors,
        // An array of general form success messages.
        formSuccessMessages: FormPropTypes.messages,
        // The method to call when the form is submitted.
        onSubmit: PropTypes.func,
        // The method to call with the field name and it's current value when any fields are changed
        onChange: PropTypes.func,
        // An optional classname to apply to the form.
        className: PropTypes.string,
        // Text to use for the submit button. Default: "Save"
        submitText: PropTypes.string,
    };

    static defaultProps = {
        children: () => null,
        backTo: null,
        loading: false,
        fieldErrors: {},
        formErrors: [],
        formSuccessMessages: [],
        onSubmit: noop,
        onChange: noop,
        submitText: 'Save',
    };

    /**
     * DOM event handler for the form submission. Blocks actual submission and calls the
     * onSubmit prop appropriately.
     *
     * @param {React.FormEvent} event
     */
    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit();
    };

    /**
     * Creates an onChange DOM event handler for a form text field onChange submission.
     * Calls the onChange prop appropriately.
     *
     * @param {string} field
     * @return function(React.ChangeEvent): void
     */
    createTextOnChange = (field) => ({ target: { value } }) => {
        this.props.onChange(field, value);
    };

    /**
     * Get the array of field errors for a field if any.
     * @param {string} field
     * @return {string[]}
     */
    getFieldErrors = (field) => {
        const { fieldErrors } = this.props;
        return (fieldErrors && fieldErrors[field]) ? fieldErrors[field] : [];
    };

    renderBackButton = () => {
        const { backTo } = this.props;
        if (backTo) {
            return <Grid item component={BackButton} to={backTo} />;
        }
        return null;
    };

    render () {
        const { loading, children, formErrors, formSuccessMessages, className, submitText } = this.props;
        const renderProps = {
            createTextOnChange: this.createTextOnChange,
            getFieldErrors: this.getFieldErrors,
        };
        return (
            <Grid container direction="column" alignItems="stretch" justifyContent="flex-start" className={className}>
                <FormMessages asGridItems errors={formErrors} successMessages={formSuccessMessages} />
                <Grid item container direction="row" alignItems="flex-start" justifyContent="space-between" wrap="nowrap">
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="stretch"
                        justifyContent="flex-start"
                        component="form"
                        onSubmit={this.onSubmit}
                    >
                        {children(renderProps)}
                        <Grid item>
                            <StyledSaveButton updating={loading} text={submitText} />
                        </Grid>
                    </Grid>
                    {this.renderBackButton()}
                </Grid>
            </Grid>
        );
    }
}

export default SimpleForm;
