import { call, put, select, takeEvery } from 'redux-saga/effects';
import { requestWithAuth } from '../utils/api';
import { getAuthToken } from '../selectors/auth';
import {
    adminDashboardDataRequestFailed,
    adminDashboardDataRequestSucceeded,
    DASHBOARD_DATA_REQUESTED,
    UPDATE_RANGE,
    UPDATE_RANGE_ANALYTICS,
} from '../stores/admin/actions';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { getRange, getDate } from '../selectors/analytics';

function * fetchAdminDashboardData () {
    const token = yield select(getAuthToken);
    const range = yield select(getRange);
    const date = yield select(getDate);
    const response = yield call(requestWithAuth, '/admin/dashboard', 'GET', token, { startDate: date, numberOfDays: range });
    if (response.ok) {
        const {
            downloads, uploads, notifications, topThree,
            statsDownloads, statsDownloadsSelect, statsDownloadsStandard,
            statsPackages, statsPackagesSelect, statsPackagesStandard,
            statsUsers, statsOrganizations,
        } = yield call([response, response.json]);
        yield put(adminDashboardDataRequestSucceeded(
            downloads, uploads, notifications, topThree,
            statsDownloads, statsDownloadsSelect, statsDownloadsStandard,
            statsPackages, statsPackagesSelect, statsPackagesStandard,
            statsUsers, statsOrganizations));
    } else {
        yield put(snackbar.show({ message: 'Retrieving the Dashboard data from the server has failed.' }));
        yield put(adminDashboardDataRequestFailed());
    }
}

export function * listenAdminDashboardDataRequested () {
    yield takeEvery(DASHBOARD_DATA_REQUESTED, fetchAdminDashboardData);
    yield takeEvery(UPDATE_RANGE, fetchAdminDashboardData);
    yield takeEvery(UPDATE_RANGE_ANALYTICS, fetchAdminDashboardData);
}
