import { PREFIX } from '../admin/actions';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';

export const USERS_DATA_REQUESTED = `${PREFIX}USERS_DATA_REQUESTED`;
export const USERS_DATA_REQUEST_FAILED = `${PREFIX}USERS_DATA_REQUEST_FAILED`;
export const USERS_DATA_REQUEST_SUCCEEDED = `${PREFIX}USERS_DATA_REQUEST_SUCCEEDED`;

export const USER_DATA_REQUESTED = `${PREFIX}USER_DATA_REQUESTED`;
export const USER_DATA_REQUEST_FAILED = `${PREFIX}USER_DATA_REQUEST_FAILED`;
export const USER_DATA_REQUEST_SUCCEEDED = `${PREFIX}USER_DATA_REQUEST_SUCCEEDED`;

export const USER_DATA_UPDATE_REQUESTED = `${PREFIX}USER_DATA_UPDATE_REQUESTED`;
export const USER_DATA_UPDATE_REQUEST_FAILED = `${PREFIX}USER_DATA_UPDATE_REQUEST_FAILED`;
export const USER_DATA_UPDATE_REQUEST_SUCCEEDED = `${PREFIX}USER_DATA_UPDATE_REQUEST_SUCCEEDED`;

export const USER_DOWNLOAD_HISTORY_REQUESTED = `${PREFIX}USER_DOWNLOAD_HISTORY_REQUESTED`;
export const USER_DOWNLOAD_HISTORY_REQUEST_FAILED = `${PREFIX}USER_DOWNLOAD_HISTORY_REQUEST_FAILED`;
export const USER_DOWNLOAD_HISTORY_REQUEST_SUCCEEDED = `${PREFIX}USER_DOWNLOAD_HISTORY_REQUEST_SUCCEEDED`;

export const CLEAR_SUCCESS_MESSAGES = `${PREFIX}CLEAR_SUCCESS_MESSAGES`;

export function usersDataRequested (
    page = 1, search = '', selectedOrganizations = [], perPage = DEFAULT_ROWS_PER_PAGE) {
    return {
        type: USERS_DATA_REQUESTED,
        perPage,
        page,
        search,
        selectedOrganizations,
    };
}

export function usersDataRequestFailed () {
    return {
        type: USERS_DATA_REQUEST_FAILED,
    };
}

export function usersDataRequestSucceeded (data, page, perPage, total, organizations) {
    return {
        type: USERS_DATA_REQUEST_SUCCEEDED,
        data,
        page,
        perPage,
        total,
        organizations,
    };
}

export function userDataRequested (userId) {
    return {
        type: USER_DATA_REQUESTED,
        userId,
    };
}

export function userDataRequestFailed () {
    return {
        type: USER_DATA_REQUEST_FAILED,
    };
}

export function userDataRequestSucceeded (userData) {
    return {
        type: USER_DATA_REQUEST_SUCCEEDED,
        userData,
    };
}

export function userDataUpdateRequested (userId, userData) {
    return {
        type: USER_DATA_UPDATE_REQUESTED,
        userId,
        userData,
    };
}

export function userDataUpdateRequestFailed (validationErrors = {}) {
    return {
        type: USER_DATA_UPDATE_REQUEST_FAILED,
        validationErrors,
    };
}

export function userDataUpdateRequestSucceeded (userData) {
    return {
        type: USER_DATA_UPDATE_REQUEST_SUCCEEDED,
        userData,
    };
}

export function userDownloadHistoryRequested (userId, page = 1, perPage = DEFAULT_ROWS_PER_PAGE) {
    return {
        type: USER_DOWNLOAD_HISTORY_REQUESTED,
        userId,
        perPage,
        page,
    };
}

export function userDownloadHistoryRequestFailed () {
    return {
        type: USER_DOWNLOAD_HISTORY_REQUEST_FAILED,
    };
}

export function userDownloadHistoryRequestSucceeded (data, page, perPage, total) {
    return {
        type: USER_DOWNLOAD_HISTORY_REQUEST_SUCCEEDED,
        data,
        page,
        perPage,
        total,
    };
}

export function clearSuccessMessages () {
    return { type: CLEAR_SUCCESS_MESSAGES };
}
