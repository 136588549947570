import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PlayArrow as ArrowIcon } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { bindActionCreators, compose } from 'redux';
import { getUrl, withRouter } from '../../utils';
import { styles } from './styles';
import { connect } from 'react-redux';
import { downloadsDataForUserRequested, downloadsExportForUserRequested } from '../../stores/analytics/actions';
import { getDownloadsContextForUser, getDownloadsDataForUser } from '../../selectors/analytics';
import { ExportButton } from '../../components/ExportButton';
import TablesPropTypes from '../../propTypes/tables';
import SoftProAdminDataTable from '../../components/SoftProDataTable/SoftProAdminDataTable';
import { getAvailableStatesForDataTableFilter } from '../../selectors/states';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';

class PackageDownloadTable extends React.Component {
    render () {
        const {
            tableData,
            tableContext,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            downloadsDataForUserRequested,
            userId,
        } = this.props;

        return (
            // Using the userId as the dataType is somewhat hack-ish but I won't tell if you won't.
            <SoftProAdminDataTable
                tableData={tableData}
                tableContext={tableContext}
                availableStatesForDataTableFilter={availableStatesForDataTableFilter}
                onFetchDataRequested={downloadsDataForUserRequested}
                dataType={userId}
                hasEditionColumn
                hasUnderwriterColumn
                availableUnderwritersForDataTableFilter={availableUnderwritersForDataTableFilter}
                hasViewColumn={false}
                getKey={({ packageId }) => packageId}
            />
        );
    }
}

PackageDownloadTable.propTypes = {
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: PropTypes.object.isRequired,
    downloadsDataForUserRequested: PropTypes.func.isRequired,
    availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
    userId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

class UserDownloads extends React.Component {
    componentDidMount () {
        const { userId, downloadsDataForUserRequested } = this.props;
        downloadsDataForUserRequested(userId);
    }

    render () {
        const {
            classes,
            navigate,
            tableData,
            tableContext,
            downloadsDataForUserRequested,
            downloadsExportForUserRequested,
            loading,
            router,
            email,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            userId,
        } = this.props;
        return (
            <Grid container direction="column" className={ classes.root }>
                <Grid container justifyContent="space-between" wrap="nowrap">
                    <Grid container alignItems="center">
                        <Grid item>
                            <Link
                                to="/admin/analytics"
                                className={ classes.breadcrumb }
                            >
                                Analytics
                            </Link>
                        </Grid>
                        <Grid item>
                            <ArrowIcon className={ classes.arrow } />
                        </Grid>
                        <Grid item>
                            <Link
                                to="/admin/analytics/users"
                                className={ classes.breadcrumb }
                            >
                                Users
                            </Link>
                        </Grid>
                        <Grid item>
                            <ArrowIcon className={ classes.arrow } />
                        </Grid>
                        <Grid item>
                            {loading
                                ? <CircularProgress size={ 14 } />
                                : <Link to={getUrl(window, router)} className={ classes.breadcrumb }>{ email }</Link>}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BackButton onClick={ () => navigate('/admin/analytics/users') } />
                    </Grid>
                </Grid>
                <Grid item>
                    <PackageDownloadTable
                        availableStatesForDataTableFilter={ availableStatesForDataTableFilter }
                        availableUnderwritersForDataTableFilter={ availableUnderwritersForDataTableFilter }
                        downloadsDataForUserRequested={ downloadsDataForUserRequested }
                        tableContext={ tableContext }
                        tableData={ tableData }
                        userId={ userId }
                    />
                </Grid>
                <Grid item>
                    <ExportButton
                        loading={ loading }
                        onClick={ () => downloadsExportForUserRequested(userId, tableContext) }
                    />
                </Grid>
            </Grid>
        );
    }
}

UserDownloads.propTypes = {
    classes: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    tableData: TablesPropTypes.tablePageData,
    tableContext: PropTypes.object.isRequired,
    downloadsDataForUserRequested: PropTypes.func.isRequired,
    downloadsExportForUserRequested: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
    email: PropTypes.string.isRequired,
    userId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => {
    return {
        loading: state.analytics.downloadsForUser.loading,
        tableData: getDownloadsDataForUser(state),
        tableContext: getDownloadsContextForUser(state),
        email: state.analytics.downloadsForUserEmail,
        availableStatesForDataTableFilter: getAvailableStatesForDataTableFilter(state),
        availableUnderwritersForDataTableFilter: getAvailableUnderwritersForDataTableFilter(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(
            bindActionCreators({
                downloadsDataForUserRequested,
                downloadsExportForUserRequested,
            }, dispatch)
        ),
    };
};

const UserDownloadsFunction = (props) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    return <UserDownloads { ...props } userId={ userId } navigate={navigate} />;
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(UserDownloadsFunction);
