import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import styles from './styles';

export const ViewButton = withStyles(styles)(({ classes, to, ...buttonProps }) => {
    if (to) {
        buttonProps.component = Link;
        buttonProps.to = to;
    }
    return (
        <div className={classes.viewButtonDiv}>
            <Button
                className={classes.viewButton}
                {...buttonProps}
            />
        </div>
    );
});

ViewButton.propTypes = {
    to: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node,
};

ViewButton.defaultProps = {
    onClick: null,
    to: null,
    size: 'small',
    color: 'primary',
    variant: 'contained',
    children: 'View',
};
