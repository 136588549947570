import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Done as CheckIcon } from '@mui/icons-material';
import classNames from 'classnames';
import standardIcon from '../../icons/logo-edition-standard.png';
import selectIcon from '../../icons/logo-edition-select.png';
import { styles } from './styles';
import { EDITION_NAMES, EDITION_SELECT, EDITION_STANDARD } from '../../constants/editions';
import EmptyIcon from './EmptyIcon';

export class SoftwareVersionSelector extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            select: this.props.editions.includes(EDITION_SELECT),
            standard: this.props.editions.includes(EDITION_STANDARD),
        };
    }

    toggleStandard = () => {
        this.props.handleStandard();
        this.setState(
            {
                standard: !this.state.standard,
            },
        );
    };

    toggleSelect = () => {
        this.props.handleSelect();
        this.setState(
            {
                select: !this.state.select,
            },
        );
    };

    render () {
        const { classes } = this.props;
        return (
            <Grid
                container
                justifyContent="center"
                direction="column"
                wrap="nowrap"
                className={classes.softwareVersionSelector}
            >
                <Grid item>
                    <Typography variant="h4" align="center" className={classes.chooseTitle}>
                        Choose your SoftPro Software Edition(s)
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid
                        item
                        className={classNames([classes.imageButton, classes.imageButtonStandard])}
                        component={(props) => (
                            <ButtonBase
                                component="a"
                                variant="contained"
                                onClick={this.toggleStandard}
                                key={EDITION_STANDARD}
                                {...props}
                            />
                        )}
                    >
                        <Grid item className={classes.imageButtonIconContainer}>
                            <img
                                src={standardIcon}
                                alt={EDITION_NAMES[EDITION_STANDARD]}
                                className={classes.imageButtonIcon}
                            />
                        </Grid>
                        <Grid item className={classes.imageButtonTextContainer}>
                            <Typography
                                gutterBottom
                                align="center"
                                variant="h3"
                                className={classes.imageButtonText}
                            >
                                {EDITION_NAMES[EDITION_STANDARD]}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            className={classNames([
                                classes.imageButtonCheckContainer,
                                classes.imageButtonCheckContainerStandard,
                            ])}
                        >
                            {this.state.standard ? <CheckIcon color="primary" /> : <EmptyIcon />}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classNames([classes.imageButton, classes.imageButtonSelect])}
                        component={(props) => (
                            <ButtonBase
                                component="a"
                                variant="contained"
                                onClick={this.toggleSelect}
                                key={EDITION_SELECT}
                                {...props}
                            />
                        )}
                    >
                        <Grid item className={classes.imageButtonIconContainer}>
                            <img
                                src={selectIcon}
                                alt={EDITION_NAMES[EDITION_SELECT]}
                                className={classes.imageButtonIcon}
                            />
                        </Grid>
                        <Grid item className={classes.imageButtonTextContainer}>
                            <Typography
                                gutterBottom
                                align="center"
                                variant="h3"
                                className={classes.imageButtonText}
                            >
                                {EDITION_NAMES[EDITION_SELECT]}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            className={classNames([
                                classes.imageButtonCheckContainer,
                                classes.imageButtonCheckContainerSelect,
                            ])}
                        >
                            {this.state.select ? <CheckIcon color="secondary" /> : <EmptyIcon />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

SoftwareVersionSelector.propTypes = {
    editions: PropTypes.array.isRequired,
    handleStandard: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SoftwareVersionSelector);
