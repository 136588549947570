import {
    FETCH_USER_SETTINGS,
    fetchUserSettingsFailed,
    fetchUserSettingsSucceeded,
    UPDATE_USER_SETTINGS,
    updateUserSettingsFailed,
    updateUserSettingsSucceeded,
} from '../stores/settings/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getAuthToken } from '../selectors/auth';
import { requestWithAuth } from '../utils/api';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';

function * fetchSettings () {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/auth/me', 'GET', token);
    if (response.ok) {
        const settings = yield call([response, response.json]);
        yield put(fetchUserSettingsSucceeded(settings));
    } else {
        yield put(snackbar.show({ message: 'Retrieving your current Settings from the server has failed.' }));
        yield put(fetchUserSettingsFailed());
    }
}

export function * listenFetchSettings () {
    yield takeEvery(FETCH_USER_SETTINGS, fetchSettings);
}

function * updateSettings ({ settings }) {
    const token = yield select(getAuthToken);
    const { name, organization, states, editions, notifications, notificationEmails, underwriters = null } = settings;
    const updates = {
        name,
        organization,
        states,
        editions,
        notifications,
        notificationEmails,
    };
    if (underwriters) {
        updates.underwriters = underwriters.map((underwriter) => {
            return typeof underwriter === 'object' ? underwriter.id : underwriter;
        });
    }
    const response = yield call(requestWithAuth, '/auth/me', 'POST', token, null, updates);
    if (response.ok) {
        const settings = yield call([response, response.json]);
        yield put(updateUserSettingsSucceeded(settings));
    } else {
        const { validationErrors } = yield call([response, response.json]);
        yield put(snackbar.show({ message: 'Updating your Settings has failed.' }));
        yield put(updateUserSettingsFailed(validationErrors));
    }
}

export function * listenUpdateSettings () {
    yield takeEvery(UPDATE_USER_SETTINGS, updateSettings);
}
