import { INITIALIZATION_FAILED, INITIALIZATION_SUCCEEDED } from './actions';
import { LOGOUT } from '../auth/actions';

export const INITIALIZATION_STATE_LOADING = 0;
export const INITIALIZATION_STATE_FAILED = -1;
export const INITIALIZATION_STATE_SUCCEEDED = 1;

const appInitialState = {
    initializationState: INITIALIZATION_STATE_LOADING,
    initializationErrors: [],
};

export default function (state = appInitialState, action) {
    switch (action.type) {
        case INITIALIZATION_SUCCEEDED:
            return { ...state, initializationState: INITIALIZATION_STATE_SUCCEEDED };
        case INITIALIZATION_FAILED:
            return {
                ...state,
                initializationState: INITIALIZATION_STATE_FAILED,
                initializationErrors: state.initializationErrors.concat(action.error || []),
            };
        case LOGOUT:
            return {
                ...state,
                initializationState: INITIALIZATION_STATE_LOADING,
            };
        default:
            return state;
    }
}
