export default {
    suggestedDownloadsTable: {
        marginTop: 50,
    },
    suggestedDownloadsTitle: {
        marginRight: 20,
        flex: 1,
    },
    suggestedDownloadsTitleTypography: {
        marginLeft: 0,
        marginBottom: '0.5em',
        marginTop: 0,
    },
};
