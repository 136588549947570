import { LOGOUT } from '../auth/actions';
import {
    CREATE_ADMIN_USER_FAILED,
    CREATE_ADMIN_USER_FIELD_CHANGED,
    CREATE_ADMIN_USER_FORM_CLEAR_REQUESTED,
    CREATE_ADMIN_USER_REQUESTED,
    CREATE_ADMIN_USER_SUCCEEDED,
} from './actions';
import { update } from '../../utils/immutable';
import { omit } from '../../utils';
import { NEW_ADMIN_DEFAULT_ORG_NAME } from '../../constants/admin';

const initialDataState = {
    name: '',
    email: '',
    organization: NEW_ADMIN_DEFAULT_ORG_NAME,
    password: '',
    passwordConfirmation: '',
};

const initialState = {
    creating: false,
    data: initialDataState,
    formErrors: [],
    formSuccessMessages: [],
    fieldErrors: {},
};

export default (state = initialState, { type, ...payload }) => {
    switch (type) {
        case CREATE_ADMIN_USER_REQUESTED:
            return update(state, {
                creating: true,
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: {},
            });
        case CREATE_ADMIN_USER_SUCCEEDED:
            return update(state, {
                creating: false,
                data: initialDataState,
                formErrors: [],
                formSuccessMessages: [`New admin user created with email ${payload.email}`],
                fieldErrors: {},
            });
        case CREATE_ADMIN_USER_FAILED:
            return update(state, {
                creating: false,
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: payload.fieldErrors,
            });
        case CREATE_ADMIN_USER_FIELD_CHANGED:
            return update(state, {
                data: update(state.data, {
                    [payload.field]: payload.value,
                }),
                fieldErrors: omit(state.fieldErrors, [payload.field]),
            });
        case CREATE_ADMIN_USER_FORM_CLEAR_REQUESTED: // Intentional fallthrough
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
