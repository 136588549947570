/**
 * Return a copy of an object with updates merged in shallowly.
 *
 * @param {{}} initial
 * @param {...{}} updates
 * @return {{}}
 */
export const update = (initial, ...updates) => {
    return updates.reduce((newState, update) => ({ ...newState, ...update }), { ...initial });
};
