import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { styles } from './styles';
import { noop } from '../../utils';

const ViewInfoDialog = ({ open, onClose, title, text, classes }) => {
    return (
        <Dialog open={open} TransitionProps={{ onClose }}>
            <DialogContent>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography
                        variant="h4"
                        className={classes.dialogTitleTypography}
                    >
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContentText> {text} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={onClose}
                    className={classes.dialogButton}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
ViewInfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};
ViewInfoDialog.defaultProps = {
    open: false,
    onClose: noop(),
    title: '',
    text: '',
};
export default withStyles(styles)(ViewInfoDialog);
