import { LOGOUT } from '../auth/actions';
import {
    ADMIN_UNDERWRITER_DETAIL_FIELD_CHANGED,
    ADMIN_UNDERWRITER_DETAIL_FORM_CLEAR_REQUESTED,
    CREATE_ADMIN_UNDERWRITER_FAILED,
    CREATE_ADMIN_UNDERWRITER_REQUESTED,
    CREATE_ADMIN_UNDERWRITER_SUCCEEDED,
    FETCH_ADMIN_UNDERWRITER_FAILED,
    FETCH_ADMIN_UNDERWRITER_REQUESTED,
    FETCH_ADMIN_UNDERWRITER_SUCCEEDED,
    UPDATE_ADMIN_UNDERWRITER_FAILED,
    UPDATE_ADMIN_UNDERWRITER_REQUESTED,
    UPDATE_ADMIN_UNDERWRITER_SUCCEEDED,
} from './actions';
import { update } from '../../utils/immutable';
import { omit } from '../../utils';

const initialDataState = {
    id: null,
    name: '',
};

const initialState = {
    fetching: false,
    saving: false,
    data: initialDataState,
    formErrors: [],
    formSuccessMessages: [],
    fieldErrors: {},
};

export default (state = initialState, { type, ...payload }) => {
    switch (type) {
        case FETCH_ADMIN_UNDERWRITER_REQUESTED:
            return update(state, {
                fetching: true,
                data: initialState,
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: {},
            });
        case FETCH_ADMIN_UNDERWRITER_SUCCEEDED:
            return update(state, {
                fetching: false,
                data: { ...initialDataState, ...payload.underwriterData },
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: {},
            });
        case FETCH_ADMIN_UNDERWRITER_FAILED:
            // TODO add form and field errors
            return update(state, { fetching: false });
        case UPDATE_ADMIN_UNDERWRITER_REQUESTED: // Intentional fallthrough
        case CREATE_ADMIN_UNDERWRITER_REQUESTED:
            return update(state, {
                saving: true,
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: {},
            });
        case UPDATE_ADMIN_UNDERWRITER_SUCCEEDED:
            return update(state, {
                saving: false,
                data: { ...initialDataState, ...payload.underwriterData },
                formErrors: [],
                formSuccessMessages: [`Successfully updated "${payload.underwriterData.name}".`],
                fieldErrors: {},
            });
        case CREATE_ADMIN_UNDERWRITER_SUCCEEDED:
            return update(state, {
                saving: false,
                data: { ...initialDataState, ...payload.underwriterData },
                formErrors: [],
                formSuccessMessages: [`Successfully created "${payload.underwriterData.name}".`],
                fieldErrors: {},
            });
        case UPDATE_ADMIN_UNDERWRITER_FAILED:
            return update(state, {
                saving: false,
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: payload.fieldErrors,
            });
        case CREATE_ADMIN_UNDERWRITER_FAILED:
            return update(state, {
                saving: false,
                formErrors: [],
                formSuccessMessages: [],
                fieldErrors: payload.fieldErrors,
            });
        case ADMIN_UNDERWRITER_DETAIL_FIELD_CHANGED:
            return update(state, {
                data: update(state.data, {
                    [payload.field]: payload.value,
                }),
                fieldErrors: omit(state.fieldErrors, [payload.field]),
            });
        case ADMIN_UNDERWRITER_DETAIL_FORM_CLEAR_REQUESTED: // Intentional fallthrough
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
