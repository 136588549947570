export const styles = (theme) => ({
    topGrid: theme.softpro.safeBox,
    tableTitle: {
        flexGrow: 1,
        marginLeft: 0,
    },
    buttonGridContainer: {
        marginBottom: 30,
    },
});
