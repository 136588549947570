import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    RECENT_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED,
    RECENT_DOWNLOADS_FOR_USERS_EDITIONS_REQUESTED,
    RECENT_DOWNLOADS_REQUESTED,
    recentDownloadsRequested,
    recentDownloadsRequestFailed,
    recentDownloadsRequestSucceeded,
} from '../stores/recentDownloads/actions';
import { getAuthToken } from '../selectors/auth';
import { requestWithAuth } from '../utils/api';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { RECENT_DOWNLOADS_PER_PAGE_DEFAULT } from '../constants/api';
import { getEdition } from '../selectors/edition';
import { getCurrentUserEditions } from '../selectors/currentUser';

function * fetchRecentDownloads ({ edition, page = 1, perPage = RECENT_DOWNLOADS_PER_PAGE_DEFAULT }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/recentDownloads',
        'GET',
        token,
        {
            edition,
            perPage,
            page,
        },
    );
    if (response.status === 200) {
        const { currentPage, data, total, perPage } = yield call([response, response.json]);
        yield put(recentDownloadsRequestSucceeded(edition, data, currentPage, total, parseInt(perPage, 10)));
    } else {
        yield put(snackbar.show({ message: 'Loading data for Recent Downloads has failed' }));
        yield put(recentDownloadsRequestFailed());
    }
}

function * fetchRecentDownloadsForCurrentEdition ({ args = [] }) {
    const edition = yield select(getEdition);
    yield put(recentDownloadsRequested(edition, ...args));
}

function * fetchRecentDownloadsForUsersEditions ({ args = [] }) {
    const editions = yield select(getCurrentUserEditions);
    for (const edition of editions) {
        yield put(recentDownloadsRequested(edition, ...args));
    }
}

export function * listenFetchRecentDownloads () {
    yield takeEvery(RECENT_DOWNLOADS_REQUESTED, fetchRecentDownloads);
    yield takeEvery(RECENT_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED, fetchRecentDownloadsForCurrentEdition);
    yield takeEvery(RECENT_DOWNLOADS_FOR_USERS_EDITIONS_REQUESTED, fetchRecentDownloadsForUsersEditions);
}
