import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { matchPath, Navigate, Route } from 'react-router';
import { Link, Routes } from 'react-router-dom';
import {
    Button,
    Drawer,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
    HelpOutline as HelpIcon,
    NotificationsOutlined as NotificationsIcon,
    SubjectOutlined as BrowseAllIcon,
    VerticalAlignBottom as RecentDownloadsIcon,
    VisibilityOutlined as WatchlistIcon,
} from '@mui/icons-material';
import SoftProHeader from '../Header';
import Watchlist from '../../components/Watchlist';
import RecentDownloads from '../../components/RecentDownloads';
import ManageNotifications from '../ManageNotifications';
import HelpPage from '../HelpPage/index';
import BrowseAll from '../../components/BrowseAll';
import BrowseUnderwriterPackages from '../../components/BrowseUnderwriterPackages';
import BrowseByState from '../../components/BrowseByState';
import BrowseLandTitlePackages from '../../components/BrowseLandTitlePackages';
import BrowseNationalPackages from '../../components/BrowseNationalPackages';
import BrowseStatePackages from '../../components/BrowseStatePackages';
import Settings from '../Settings';
import { styles } from './styles';
import Breadcrumbs from '../../components/Breadcrumbs';
import { EDITION_STANDARD } from '../../constants/editions';
import { getPromptsFileUrl } from '../../selectors/download';
import { getPathname } from '../../selectors/navigation';
import { PROMPTS_FILE_INSTALL_INSTRUCTIONS_URL } from './constants';
import { withRouter } from '../../utils';
import { getEdition } from '../../selectors/edition';

class ListItemLink extends React.Component {
    static propTypes = {
        classes: PropTypes.shape({
            listItemText: PropTypes.string.isRequired,
        }).isRequired,
        icon: PropTypes.node.isRequired,
        primary: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    };

    renderLink = (itemProps) => <Link to={this.props.to} {...itemProps} />;

    render () {
        const { icon, primary, classes, to, path } = this.props;
        const selected = matchPath(new URL(to).pathname, path) !== null;
        return (
            <li>
                <ListItemButton selected={selected} component={this.renderLink}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText disableTypography primary={primary} className={classes.listItemText} />
                </ListItemButton>
            </li>
        );
    }
}

const mapStateToPropsForLinks = (state) => {
    return {
        path: getPathname(state),
    };
};
const StyledListItemLink = compose(
    withStyles(styles),
    connect(mapStateToPropsForLinks),
)(ListItemLink);

function DocumentPortalTabs () {
    const documentsPortalTabData = [
        { text: 'My Watchlist', icon: <WatchlistIcon />, linkTo: `${window.location.origin}/portal/watchlist` },
        { text: 'Browse All', icon: <BrowseAllIcon />, linkTo: `${window.location.origin}/portal/browseAll` },
        { text: 'Recent Downloads', icon: <RecentDownloadsIcon />, linkTo: `${window.location.origin}/portal/recentDownloads` },
        { text: 'Manage Notifications', icon: <NotificationsIcon />, linkTo: `${window.location.origin}/portal/manageNotifications` },
        { text: 'Info Videos and FAQs', icon: <HelpIcon />, linkTo: `${window.location.origin}/portal/faq` },
    ];

    return (
        documentsPortalTabData.map((tabData) => (
            <div className="tab" key={tabData.text}>
                <StyledListItemLink to={tabData.linkTo} primary={tabData.text} icon={tabData.icon} />
            </div>),
        )
    );
}

class DocumentsPortal extends React.Component {
    static propTypes = {
        edition: PropTypes.string.isRequired,
        promptsFileUrl: PropTypes.string,
    };

    static defaultProps = {
        promptsFileUrl: null,
    };

    render () {
        const { classes, edition, promptsFileUrl } = this.props;
        return (
            <div className="documentsPortalApp">
                <div>
                    <SoftProHeader isUserLoggedIn isOnBoardingComplete />
                    <Drawer variant="permanent" anchor="left" PaperProps={{ elevation: 7 }}>
                        <Grid
                            container
                            columns={1}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {(edition === EDITION_STANDARD &&
                                promptsFileUrl)
                                ? (
                                    <Grid
                                        sx={{
                                            paddingBottom: 5,
                                            paddingTop: 5,
                                        }}
                                        item
                                        justifyContent="center"
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{
                                                    letterSpacing: 0,
                                                    minHeight: '35px',
                                                    fontSize: '10px',
                                                    margin: '10px',
                                                    textTransform: 'uppercase',
                                                }}
                                                href={promptsFileUrl}
                                                target="_blank"
                                            >
                                                Download Prompts File
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={classes.promptsInstructions}
                                            >
                                                {`The latest Prompts file is required for all packages.
                                                Click above to download and click `}
                                                {
                                                    <Button
                                                        sx={{
                                                            color: 'white',
                                                            fontSize: 14,
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                            textDecoration: 'underline',
                                                            letterSpacing: 0,
                                                            border: 'none',
                                                            textTransform: 'none',
                                                            padding: 0,
                                                            margin: 0,
                                                            marginBottom: '2px',
                                                            minHeight: 'unset',
                                                            minWidth: 'unset',
                                                            height: 'unset',
                                                            width: 'unset',
                                                            lineHeight: 1.5,
                                                        }}
                                                        variant="text"
                                                        href={PROMPTS_FILE_INSTALL_INSTRUCTIONS_URL}
                                                        target="_blank"
                                                        component="a"
                                                        disableElevation={true}
                                                    >
                                                    here
                                                    </Button>
                                                }{' for installation instructions.'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                                : null
                            }
                            <Grid item width="100%">
                                <List>
                                    <DocumentPortalTabs />
                                </List>
                            </Grid>
                        </Grid>
                    </Drawer>
                    <div className={classes.breadcrumbsDiv}>
                        <Routes>
                            <Route path="/portal/browseAll" element={<Breadcrumbs />} />
                        </Routes>
                    </div>
                    <Routes>
                        <Route path="*" element={<Navigate to="/portal/watchlist" />} />
                        <Route path="watchlist" element={<Watchlist />} />
                        <Route path="browseAll" element={<BrowseAll />} />
                        <Route path="recentDownloads" element={<RecentDownloads />} />
                        <Route path="manageNotifications" element={<ManageNotifications />} />
                        <Route path="faq" element={<HelpPage />} />
                        <Route path="settings" element={<Settings />} />
                        <Route
                            path="browseAll/underwriterPackages"
                            element={<BrowseUnderwriterPackages />}
                        />
                        <Route path="browseAll/statePackages" element={<BrowseStatePackages />} />
                        <Route path="browseAll/nationalPackages" element={<BrowseNationalPackages />} />
                        <Route path="browseAll/landTitlePackages" element={<BrowseLandTitlePackages />} />
                        <Route
                            path="browseAll/byState"
                            element={<Navigate to="/browseAll" />}
                        />
                        <Route path="browseAll/byState/:stateCode" element={<BrowseByState />} />
                    </Routes>
                </div>
            </div>
        );
    }
}

DocumentsPortal.propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    edition: PropTypes.string.isRequired,
    promptsFileUrl: PropTypes.string,
};

const mapStateToProps = function (state) {
    return {
        edition: getEdition(state),
        promptsFileUrl: getPromptsFileUrl(state),
    };
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withRouter,
)(DocumentsPortal);
