import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { push } from 'connected-react-router';
import { requestWithAuth } from '../utils/api';
import { PageOfUnderwriters, parseAndNormalizeJsonResponse } from '../schemas';
import { getAuthToken } from '../selectors/auth';
import {
    getAdminUnderwriterDetailData,
    getAdminUnderwritersContext,
    getAdminUnderwritersPageIds,
} from '../selectors/adminUnderwriters';
import {
    CREATE_ADMIN_UNDERWRITER_REQUESTED,
    createAdminUnderwriterFailed,
    createAdminUnderwriterSucceeded,
    DELETE_ADMIN_UNDERWRITER_REQUESTED,
    DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED,
    deleteAdminUnderwriterFailed,
    deleteAdminUnderwriterSucceeded,
    deleteMultipleAdminUnderwritersFailed,
    deleteMultipleAdminUnderwritersSucceeded,
    FETCH_ADMIN_UNDERWRITER_REQUESTED,
    FETCH_ADMIN_UNDERWRITERS_REQUESTED,
    fetchAdminUnderwriterFailed,
    fetchAdminUnderwritersFailed,
    fetchAdminUnderwritersRequested,
    fetchAdminUnderwritersSucceeded,
    fetchAdminUnderwriterSucceeded,
    UPDATE_ADMIN_UNDERWRITER_REQUESTED,
    updateAdminUnderwriterFailed,
    updateAdminUnderwriterSucceeded,
} from '../stores/adminUnderwriters/actions';
import { createReloadAfterDeleteSaga } from './api';
import { objectValuesAreEmpty } from '../utils';
import { validateNonEmptyString } from '../utils/validation';

function * fetchAdminUnderwriters (
    {
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { search = null },
    },
) {
    const token = yield select(getAuthToken);
    const query = {
        page,
        perPage,
        orderBy,
        orderByDir,
        search: search || '',
    };
    const response = yield call(
        requestWithAuth,
        '/underwriters',
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const data = yield call(
            parseAndNormalizeJsonResponse,
            response,
            PageOfUnderwriters,
        );
        yield put(fetchAdminUnderwritersSucceeded(
            data,
            { orderBy, orderByDir },
            { search: query.search },
        ));
    } else {
        yield put(snackbar.show({ message: 'Loading Underwriters has failed.' }));
        yield put(fetchAdminUnderwritersFailed());
    }
}

function * fetchAdminUnderwriter ({ underwriterId }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, `/underwriters/${underwriterId}`, 'GET', token);
    if (response.ok) {
        const underwriterData = yield call([response, response.json]);
        yield put(fetchAdminUnderwriterSucceeded(underwriterData));
    } else {
        yield put(snackbar.show({ message: 'Retrieving the Underwriter has failed.' }));
        yield put(fetchAdminUnderwriterFailed());
    }
}

const reloadAdminUnderwritersAfterDelete = createReloadAfterDeleteSaga(
    getAdminUnderwritersContext,
    getAdminUnderwritersPageIds,
    fetchAdminUnderwritersRequested,
);

function * deleteAdminUnderwriter ({ underwriterId }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        `/underwriters/${underwriterId}`,
        'DELETE',
        token,
    );
    if (response.ok) {
        yield put(deleteAdminUnderwriterSucceeded(underwriterId));
        yield call(reloadAdminUnderwritersAfterDelete, underwriterId);
    } else {
        yield put(snackbar.show({ message: 'Deleting the Underwriter has failed.' }));
        yield put(deleteAdminUnderwriterFailed(underwriterId));
    }
}

function * deleteMultipleAdminUnderwriters ({ underwriterIds }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/underwriters',
        'DELETE',
        token,
        null,
        { underwriterIds },
    );
    if (response.ok) {
        yield put(deleteMultipleAdminUnderwritersSucceeded(underwriterIds));
        yield call(reloadAdminUnderwritersAfterDelete, ...underwriterIds);
    } else {
        const { error = null } = yield call([response, response.json]);
        const message = error === 'underwriter.has_packages'
            ? ('One or more of the underwriters you\'ve selected has packages associated with them. ' +
                'Delete those packages first.')
            : 'Deleting underwriters has failed. Try again later';
        yield put(snackbar.show({ message }));
        yield put(deleteMultipleAdminUnderwritersFailed(underwriterIds));
    }
}

function validateUnderwriterData ({ name = '' }) {
    const errors = {
        name: [],
    };

    const data = {
        name: validateNonEmptyString(name),
    };

    if (!data.name) {
        errors.name.push('Name is required.');
    }

    if (objectValuesAreEmpty(errors)) {
        return data;
    } else {
        throw errors;
    }
}

function * updateAdminUnderwriter ({ underwriterId }) {
    const rawUnderwriterData = yield select(getAdminUnderwriterDetailData);
    let underwriterData = null;

    try {
        underwriterData = yield call(validateUnderwriterData, rawUnderwriterData);
    } catch (errors) {
        yield put(updateAdminUnderwriterFailed(errors));
        return;
    }

    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        `/underwriters/${underwriterId}`,
        'PUT',
        token,
        null,
        underwriterData,
    );
    if (response.ok) {
        const newUnderwriterData = yield call([response, response.json]);
        yield put(updateAdminUnderwriterSucceeded(newUnderwriterData));
    } else {
        if (response.status === 400) {
            const { validationErrors: fieldErrors = {} } = yield call([response, response.json]);
            yield put(updateAdminUnderwriterFailed(fieldErrors));
        } else {
            yield put(snackbar.show({ message: 'Updating the Underwriter has failed.' }));
            yield put(updateAdminUnderwriterFailed());
        }
    }
}

function * createAdminUnderwriter () {
    const rawUnderwriterData = yield select(getAdminUnderwriterDetailData);
    let underwriterData = null;

    try {
        underwriterData = yield call(validateUnderwriterData, rawUnderwriterData);
    } catch (errors) {
        yield put(createAdminUnderwriterFailed(errors));
        return;
    }

    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/underwriters',
        'POST',
        token,
        null,
        underwriterData,
    );
    if (response.ok) {
        const newUnderwriterData = yield call([response, response.json]);
        const { id } = newUnderwriterData;
        yield put(createAdminUnderwriterSucceeded(newUnderwriterData));
        yield put(push(`/admin/underwriters/underwriter/${id}`));
    } else {
        if (response.status === 400) {
            const { validationErrors: fieldErrors = {} } = yield call([response, response.json]);
            yield put(createAdminUnderwriterFailed(fieldErrors));
        } else {
            yield put(snackbar.show({ message: 'Creating the Underwriter has failed.' }));
            yield put(createAdminUnderwriterFailed());
        }
    }
}

export default function * () {
    yield all([
        takeEvery(FETCH_ADMIN_UNDERWRITERS_REQUESTED, fetchAdminUnderwriters),
        takeEvery(FETCH_ADMIN_UNDERWRITER_REQUESTED, fetchAdminUnderwriter),
        takeEvery(DELETE_ADMIN_UNDERWRITER_REQUESTED, deleteAdminUnderwriter),
        takeEvery(DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED, deleteMultipleAdminUnderwriters),
        takeEvery(UPDATE_ADMIN_UNDERWRITER_REQUESTED, updateAdminUnderwriter),
        takeEvery(CREATE_ADMIN_UNDERWRITER_REQUESTED, createAdminUnderwriter),
    ]);
}
