import { createSelector } from 'reselect';
import { getAuthToken } from './auth';
import { addTokenToUrl } from '../utils/api';

export const getDownloadState = (state) => state.download || {};

export const getPromptsFileUrl = createSelector(
    getDownloadState,
    getAuthToken,
    ({ promptsFileUrl = null }, token) => {
        return promptsFileUrl ? addTokenToUrl(promptsFileUrl, token) : null;
    },
);
