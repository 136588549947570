import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { styles } from './styles';

const UnderwriterSelector = (props) => {
    const { classes, availableUnderwriters, underwriters, error = false } = props;
    return (
        <div className={classes.underwriters}>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.dropdownInputLabel}
                >
                    Underwriters of Interest
                </InputLabel>
                <Select
                    disableUnderline
                    value=""
                    variant="filled"
                    onChange={props.handleSelectedUnderwriter}
                    className={classes.dropdown}
                >
                    {availableUnderwriters.map(
                        (underwriter) => {
                            const { value, label } = underwriter;
                            return (
                                <MenuItem value={underwriter} key={value}>
                                    {label}
                                </MenuItem>
                            );
                        },
                    )}
                </Select>
                {error &&
                <FormHelperText error>
                    {error}
                </FormHelperText>}
            </FormControl>
            <div className={classes.stateChips}>
                {underwriters.map((underwriter) => {
                    const { name, id } = underwriter;
                    return (
                        <Chip
                            key={id}
                            label={name}
                            onDelete={() => props.handleDelete(underwriter)}
                            deleteIcon={<ClearIcon />}
                            variant="outlined"
                            className={classes.chipStyle}
                        />
                    );
                })}
            </div>
        </div>
    );
};

UnderwriterSelector.propTypes = {
    handleSelectedUnderwriter: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    underwriters: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
    availableUnderwriters: PropTypes.array,
};
export default withStyles(styles)(UnderwriterSelector);
