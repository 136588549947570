import {
    DASHBOARD_DATA_REQUEST_FAILED,
    DASHBOARD_DATA_REQUEST_SUCCEEDED,
    DASHBOARD_DATA_REQUESTED,
    UPDATE_RANGE,
    UPDATE_RANGE_ANALYTICS,
} from './actions';
import { LOGOUT } from '../auth/actions';
import { DEFAULT_DAY_RANGE, DEFAULT_START_DATE } from '../../constants/analytics';

const rangeInitialState = {
    range: DEFAULT_DAY_RANGE,
    date: DEFAULT_START_DATE.toISOString().slice(0, 10),
};

export function adminRanges (state = rangeInitialState, action) {
    switch (action.type) {
        case UPDATE_RANGE_ANALYTICS: // Intentional fall through
        case UPDATE_RANGE:
            return {
                ...state,
                range: action.range,
                date: action.date,
            };
        case LOGOUT:
            return rangeInitialState;
        default:
            return state;
    }
}

const adminDashboardDataInitialState = {
    loading: false,
    downloads: [],
    uploads: [],
    notifications: [],
    topThree: [],
    statsDownloads: 0,
    statsDownloadsSelect: 0,
    statsDownloadsStandard: 0,
    statsPackages: 0,
    statsPackagesSelect: 0,
    statsPackagesStandard: 0,
    statsUsers: 0,
    statsOrganizations: 0,
};

export function adminDashboardData (state = adminDashboardDataInitialState, action) {
    switch (action.type) {
        case UPDATE_RANGE_ANALYTICS: // Intentional fall through
        case DASHBOARD_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case DASHBOARD_DATA_REQUEST_SUCCEEDED: {
            const {
                downloads, uploads, notifications, topThree,
                statsDownloads, statsDownloadsSelect, statsDownloadsStandard,
                statsPackages, statsPackagesSelect, statsPackagesStandard,
                statsUsers, statsOrganizations,
            } = action;
            return {
                ...state,
                loading: false,
                downloads,
                uploads,
                notifications,
                topThree,
                statsDownloads,
                statsDownloadsSelect,
                statsDownloadsStandard,
                statsPackages,
                statsPackagesSelect,
                statsPackagesStandard,
                statsUsers,
                statsOrganizations,
            };
        }
        case DASHBOARD_DATA_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_RANGE:
            return {
                ...state,
                loading: true,
            };
        case LOGOUT:
            return adminDashboardDataInitialState;
        default:
            return state;
    }
}
