import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isUserLoggedIn } from '../../selectors/auth';
import {
    isCurrentUserAdmin,
    isOnboardingCompleted,
} from '../../selectors/currentUser';
import DocumentsPortal from '../DocumentsPortal';
import LoginPage from '../../components/LoginPage';
import OnBoardingStepper from '../OnBoardingStepper';
import AdminPortal from '../AdminPortal';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { connect } from 'react-redux';

const Home = () => <Navigate to="/login" />;

class AppRouter extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        onboardingCompleted: PropTypes.bool,
        loggedIn: PropTypes.bool,
        isAdmin: PropTypes.bool,
    };

    static defaultProps = {
        onboardingCompleted: false,
        loggedIn: false,
        isAdmin: false,
    };

    render () {
        const {
            history,
            onboardingCompleted,
            loggedIn,
            isAdmin,
        } = this.props;
        const routeSwitch = loggedIn
            ? (
                <Routes>
                    <Route path="/" element={<Home />} />
                    {isAdmin && (
                        <Route path="/admin/*" element={<AdminPortal />} />
                    )}
                    {isAdmin && <Route path="*" element={<Navigate to="/admin" />} />}
                    {!onboardingCompleted && !isAdmin &&
                            <Route path="/onboarding/*" element={<OnBoardingStepper history={history} />} />
                    }
                    {onboardingCompleted && !isAdmin &&
                            <Route path="/portal/*" element={<DocumentsPortal />} />
                    }
                    {onboardingCompleted && !isAdmin
                        ? (
                            <Route path="*" element={<Navigate to="/portal" />} />
                        )
                        : (
                            <Route path="*" element={<Navigate to="/onboarding" />} />
                        )}
                </Routes>
            )
            : (
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/login"
                        element={<LoginPage />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            );
        return (
            <Router history={history}>{routeSwitch}</Router>
        );
    }
}

const mapStateToProps = function (state) {
    const {
        auth: { error: loginError },
    } = state;
    return {
        loggedIn: isUserLoggedIn(state),
        isAdmin: isCurrentUserAdmin(state),
        onboardingCompleted: isOnboardingCompleted(state),
        loginError,
    };
};

export default connect(mapStateToProps, {})(AppRouter);
