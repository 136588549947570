import React from 'react';
import ReleaseNotesIcon from '../../icons/icon-notes-green.png';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
    CloudDownload as DownloadIcon,
    DeleteOutlined as TrashIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styles } from './styles';
import stopMan from '../../icons/stop-man.png';
import { noop } from '../../utils';

export class ReleaseNotes extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        value: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
    };

    render () {
        const { classes, value, disabled } = this.props;
        return value
            ? (
                <div>
                    <div
                        className={classNames(
                            classes.releaseNotes,
                            disabled ? classes.disabled : null,
                        )}
                    >
                        <IconButton href={value} target="_blank" size="large">
                            <img
                                src={ReleaseNotesIcon}
                                alt="Release Notes"
                                width="19"
                                height="19"
                            />
                        </IconButton>
                    </div>
                </div>
            )
            : (
                ''
            );
    }
}

const StyledReleaseNotes = withStyles(styles)(ReleaseNotes);

class DownloadButton extends React.Component {
    state = { dialogOpen: false };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        packageName: PropTypes.string.isRequired,
        downloadNotes: PropTypes.string,
        downloadUrl: PropTypes.string.isRequired,
    };

    static defaultProps = {
        downloadNotes: null,
        disabled: false,
    };

    onClick = (event) => {
        const { downloadNotes, disabled } = this.props;
        if (downloadNotes || disabled) {
            event.preventDefault();
        }
        if (downloadNotes && !disabled) {
            this.setState({ dialogOpen: true });
        }
    };

    onDialogDownloadButtonClick = () => {
        this.setState({ dialogOpen: false });
    };

    render () {
        const { classes, disabled, packageName, downloadNotes, downloadUrl } =
            this.props;
        const { dialogOpen } = this.state;
        return (
            <div
                className={classNames(
                    classes.downloadButtonWrapper,
                    disabled ? classes.disabled : null,
                )}
            >
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classNames({
                        [classes.downloadButton]: true,
                        'desktop-only': true,
                    })}
                    disabled={disabled}
                    href={downloadUrl}
                    target="_blank"
                    onClick={this.onClick}
                >
                    Download
                </Button>

                <IconButton
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classNames({
                        [classes.downloadIconButton]: true,
                        'mobile-only': true,
                    })}
                    disabled={disabled}
                    href={downloadUrl}
                    target="_blank"
                    onClick={this.onClick}
                >
                    <DownloadIcon />
                </IconButton>

                <Dialog open={dialogOpen}>
                    <DialogTitle className={classes.releaseNotesDialogTitle}>
                        {packageName}
                    </DialogTitle>
                    <DialogContent
                        className={classes.releaseNotesDialogContent}
                    >
                        <img
                            src={stopMan}
                            alt="SoftPro Stop"
                            width={205}
                            height={210}
                        />
                        <DialogContentText
                            className={classes.releaseNotesDialogText}
                        >
                            {downloadNotes}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        className={classes.releaseNotesDialogButtons}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.releaseNotesDialogButton}
                            href={downloadUrl}
                            target="_blank"
                            onClick={this.onDialogDownloadButtonClick}
                        >
                            Download
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => this.setState({ dialogOpen: false })}
                            className={classes.releaseNotesDialogButton}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const StyledDownloadButton = withStyles(styles)(DownloadButton);

export class AddButton extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        onClick: null,
        disabled: false,
    };

    render () {
        const { classes, onClick, disabled } = this.props;
        return (
            <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classNames(
                    classes.addButton,
                    disabled ? classes.disabled : null,
                )}
                disabled={disabled}
                onClick={!disabled && onClick ? onClick : noop}
            >
                Add to Watchlist
            </Button>
        );
    }
}

const StyledAddButton = withStyles(styles)(AddButton);

export class CustomTableToolbar extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        actions: PropTypes.array,
        renderActionLabel: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]),
        title: PropTypes.node,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        actions: [],
        renderActionLabel: (action) => action,
        title: null,
        onChange: noop,
    };

    renderActionLabel = (action) => {
        const { renderActionLabel } = this.props;
        return typeof renderActionLabel === 'object'
            ? renderActionLabel[action]
            : renderActionLabel(action);
    };

    render () {
        const { classes, actions, title, onChange } = this.props;
        return (
            <React.Fragment>
                {title}
                <Tooltip title="Actions">
                    <div className={classes.actionsContainer}>
                        <FormControl
                            variant="filled"
                            className={classes.actionsFormControl}
                        >
                            <Select
                                displayEmpty
                                value=""
                                variant="filled"
                                className={classes.actions}
                                onChange={onChange}
                            >
                                <MenuItem value="">Actions</MenuItem>
                                {actions.map((action) => (
                                    <MenuItem value={action} key={action}>
                                        {this.renderActionLabel(action)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Tooltip>
            </React.Fragment>
        );
    }
}

const StyledCustomTableToolbar = withStyles(styles)(CustomTableToolbar);

class NotifyCheckbox extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        checked: PropTypes.bool,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        text: PropTypes.string,
    };

    static defaultProps = {
        onClick: null,
        checked: false,
        disabled: false,
        text: 'Notify Me',
    };

    render () {
        const { classes, checked, onClick, disabled, text } = this.props;
        return (
            <div
                className={classNames(
                    classes.notifyCheckbox,
                    disabled ? classes.disabled : null,
                )}
            >
                <Checkbox
                    checked={checked}
                    onClick={!disabled && onClick ? onClick : noop}
                    disabled={disabled}
                />
                <div className={classes.notifyMeText}>{text}</div>
            </div>
        );
    }
}

const StyledNotifyCheckbox = withStyles(styles)(NotifyCheckbox);

class Trash extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        title: 'Remove from my watchlist',
        onClick: null,
        disabled: false,
    };

    render () {
        const { classes, title, onClick, disabled } = this.props;
        return (
            <Tooltip title={title}>
                <TrashIcon
                    className={classNames(
                        classes.trash,
                        disabled ? classes.disabled : null,
                    )}
                    onClick={!disabled && onClick ? onClick : noop}
                />
            </Tooltip>
        );
    }
}

const StyledTrash = withStyles(styles)(Trash);

export {
    StyledReleaseNotes,
    StyledDownloadButton,
    StyledAddButton,
    StyledCustomTableToolbar,
    StyledNotifyCheckbox,
    StyledTrash,
};
