import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import FormPropTypes from '../../propTypes/forms';
import MessagePaper from '../MessagePaper';

const FormMessages = ({ errors, successMessages, asGridItems }) => (
    <Fragment>
        {errors.map((error) => {
            return (
                asGridItems
                    ? <Grid item key={error}><MessagePaper type="error">{error}</MessagePaper></Grid>
                    : <MessagePaper key={error} type="error">{error}</MessagePaper>
            );
        })}
        {successMessages.map((message) => {
            return (
                asGridItems
                    ? <Grid item key={message}><MessagePaper type="success">{message}</MessagePaper></Grid>
                    : <MessagePaper key={message} type="success">{message}</MessagePaper>
            );
        })}
    </Fragment>
);

FormMessages.propTypes = {
    errors: FormPropTypes.errors,
    successMessages: FormPropTypes.messages,
    asGridItems: PropTypes.bool,
};

FormMessages.defaultProps = {
    errors: [],
    successMessages: [],
    asGridItems: false,
};

export default FormMessages;
