import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debugWarn } from '../../utils/log';

export const COMPONENT_NAME = 'KeyedDataTableSelector';

/**
 * @class KeyedDataTableSelector
 *
 * TODO static getDerivedStateFromProps in order to clean up this.state.selectedKeys when data changes if
 *      actionableMustBeInData is true.
 */
class KeyedDataTableSelector extends Component {
    static propTypes = {
        /*
         * The children should be a render prop. The function will be given an object of properties that it should
         * spread onto a DataTable.
         */
        children: PropTypes.func.isRequired,

        // The data that will be forwarded into the DataTable.
        data: PropTypes.array.isRequired,

        // A function that will return the unique ID for the row given the row data itself.
        getKey: PropTypes.func.isRequired,

        /*
         * If set to false, onActionSelect will be called with all selected IDs even if they aren't present
         * in the current table data (i.e. were selected on previous pages).
         * Default: false
         */
        actionableMustBeInData: PropTypes.bool,

        /*
         * A function that's called when an action is selected in the DataTable but the first argument
         * will be the array of selected keys. The rest of the arguments will whatever DataTable calls
         * onActionSelect with.
         * Default: do not provide / override prop in returned DataTable props.
         */
        onActionSelect: PropTypes.func,

        /*
         * A function that's called for each row to determine if it's selectable. Called with the row key as the first
         * argument (as determined by getKey) and then the rest of the arguments will be whatever DataTable calls
         * isRowSelectable with by default.
         * Default: do not provide / override prop in returned DataTable props.
         */
        isRowSelectable: PropTypes.func,
    };

    static defaultProps = {
        actionableMustBeInData: true,
        onActionSelect: null,
        isRowSelectable: null,
    };

    state = {
        selectedKeys: [],
    };

    getKeys = () => {
        const { data, getKey } = this.props;
        return data.map(getKey);
    };

    getSelectedKeys = () => {
        const { actionableMustBeInData } = this.props;
        const { selectedKeys: rawSelectedKeys } = this.state;
        if (actionableMustBeInData) {
            const keysOnPage = this.getKeys();
            return rawSelectedKeys.filter((rawSelectedId) => keysOnPage.includes(rawSelectedId));
        }
        return rawSelectedKeys;
    };

    isRowSelected = (data) => {
        /*
         * Note here that we don't care about actionableMustBeInData because this function in particular is only called
         * for rows being rendered on the page anyway.
         */
        const { selectedKeys } = this.state;
        const { getKey } = this.props;
        return selectedKeys.includes(getKey(data));
    };

    onRowSelectionChange = (checked, row) => {
        const { getKey } = this.props;
        const key = getKey(row);
        const selectedKeysWithoutMe = this.getSelectedKeys().filter((selectedKey) => selectedKey !== key);
        this.setState({
            selectedKeys: checked ? [...selectedKeysWithoutMe, key] : selectedKeysWithoutMe,
        });
    };

    onAllRowsSelectionChange = (checked) => {
        this.setState({
            selectedKeys: checked ? this.getKeys() : [],
        });
    };

    onActionSelect = (...rest) => {
        this.props.onActionSelect(this.getSelectedKeys(), ...rest);
    };

    isRowSelectable = (row, ...rest) => {
        const { getKey, isRowSelectable } = this.props;
        isRowSelectable(getKey(row), row, ...rest);
    };

    render () {
        const { children: renderTable, data, onActionSelect, isRowSelectable } = this.props;

        if (typeof renderTable !== 'function' || React.isValidElement(renderTable)) {
            debugWarn(`Did not expect non function child to ${COMPONENT_NAME}:`, renderTable);
        }

        const dataTableProps = {
            data,
            isRowSelected: this.isRowSelected,
            onRowSelectionChange: this.onRowSelectionChange,
            onAllRowsSelectionChange: this.onAllRowsSelectionChange,
        };

        if (onActionSelect) {
            dataTableProps.onActionSelect = this.onActionSelect;
        }

        if (isRowSelectable) {
            dataTableProps.isRowSelectable = this.isRowSelectable;
        }

        return renderTable(dataTableProps);
    }
}

export default KeyedDataTableSelector;
