import { ADD_SOFTWARE_EDITION } from './actions';
import { LOGIN_SUCCEEDED, LOGOUT, ON_BOARDING_COMPLETED } from '../auth/actions';

export const editionsInitialState = {
    editions: [],
};

export function addEdition (state = {}, edition) {
    if (!state.editions) {
        return Object.assign({}, state, {
            editions: [edition],
        });
    } else if (!state.editions.includes(edition)) {
        return { ...state, editions: [...state.editions, edition] };
    } else {
        const currentEditions = [...state.editions];
        currentEditions.splice(currentEditions.indexOf(edition), 1);
        return Object.assign({}, state, {
            editions: currentEditions,
        });
    }
}

export function editions (state = editionsInitialState, action) {
    switch (action.type) {
        case ADD_SOFTWARE_EDITION:
            return addEdition(state, action.edition);
        case LOGOUT: // Intentional fall-through
        case ON_BOARDING_COMPLETED:
            return editionsInitialState;
        case LOGIN_SUCCEEDED: {
            const { user } = action;
            if (user && !user.onboardingCompleted) {
                return {
                    ...state,
                    // We want an array of editions by name, not the full object, so do a map here to add the names to the array
                    editions: user.editions?.map((edition) => edition.name) || [],
                };
            }
            return state;
        }
        default:
            return state;
    }
}
