export const PREFIX = 'adminCreateAdmin/';
export const CREATE_ADMIN_USER_REQUESTED = `${PREFIX}CREATE_ADMIN_USER_REQUESTED`;
export const CREATE_ADMIN_USER_SUCCEEDED = `${PREFIX}CREATE_ADMIN_USER_SUCCEEDED`;
export const CREATE_ADMIN_USER_FAILED = `${PREFIX}CREATE_ADMIN_USER_FAILED`;
export const CREATE_ADMIN_USER_FIELD_CHANGED = `${PREFIX}CREATE_ADMIN_USER_FIELD_CHANGED`;
export const CREATE_ADMIN_USER_FORM_CLEAR_REQUESTED = `${PREFIX}CREATE_ADMIN_USER_FORM_CLEAR_REQUESTED`;

export const createAdminUserRequested = () => ({ type: CREATE_ADMIN_USER_REQUESTED });

export const createAdminUserSucceeded = (email) => ({ type: CREATE_ADMIN_USER_SUCCEEDED, email });

export const createAdminUserFailed = (fieldErrors = {}) => ({ type: CREATE_ADMIN_USER_FAILED, fieldErrors });

export const createAdminUserFieldChanged = (field, value) => ({ type: CREATE_ADMIN_USER_FIELD_CHANGED, field, value });

export const createAdminUserFormClearRequested = () => ({ type: CREATE_ADMIN_USER_FORM_CLEAR_REQUESTED });
