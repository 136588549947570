import emailValidator from 'email-validator';

/**
 * Coerce a value into a string and return it trimmed. If it becomes empty after trimming, will explicitly return false.
 *
 * @param {string} val
 * @return {string|boolean}
 */
export const validateNonEmptyString = (val) => {
    const str = `${val}`.trim();
    return str || false;
};

/**
 * Coerce a value into a nonempty string and ensure it's an email. If it's not, will explicitly return false, returning
 * the email otherwise.
 *
 * @param val
 * @return {string|boolean}
 */
export const validateEmail = (val) => {
    const email = validateNonEmptyString(val);
    return (email && emailValidator.validate(email)) ? email : false;
};
