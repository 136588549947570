import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { bindActionCreators, compose } from 'redux';
import { Navigate, Routes } from 'react-router-dom';
import { getUrl, withRouter } from '../../utils';
import { Route } from 'react-router';
import { styles } from './styles';
import UploadPromptsFile from '../../components/UploadPromptsFile';
import SoftProAdminDataTable from '../../components/SoftProDataTable/SoftProAdminDataTable';
import { connect } from 'react-redux';
import {
    deleteMultiplePackagesRequested,
    deletePackageRequested,
    fetchAdminPackagesRequested,
} from '../../stores/adminPackages/actions';
import { getAvailableStatesForDataTableFilter } from '../../selectors/states';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';
import {
    getAdminPackagesContext,
    getAdminPackagesForTable,
} from '../../selectors/adminPackages';
import Typography from '@mui/material/Typography';
import { PACKAGES } from '../../constants/admin';
import TablesPropTypes from '../../propTypes/tables';
import {
    ADMIN_PACKAGE_TABLE_ACTIONS,
    TABLE_ACTION_DELETE,
    TABLE_ACTIONS_LABELS,
} from '../../constants/tables';
import Package from '../Package';
import AdminButton from '../../components/AdminButton';
import ConfirmDialog from '../../components/ConfirmDialog';

const Title = ({ classes }) => (
    <Typography variant="h4" className={classes.tableTitle}>
        {' '}
        All Packages{' '}
    </Typography>
);
Title.propTypes = { classes: PropTypes.object.isRequired };
const StyledAllPackagesTitle = withStyles(styles)(Title);

const PACKAGE_TABLE_ACTION_ITEMS = ADMIN_PACKAGE_TABLE_ACTIONS.map(
    (action) => ({
        value: action,
        label: TABLE_ACTIONS_LABELS[action],
    }),
);

class PackagesTable extends React.Component {
    onActionSelect = (selectedIds, action) => {
        if (TABLE_ACTION_DELETE === action) {
            this.props.deleteMultiplePackagesRequested(selectedIds);
        }
    };

    /*
     * Create a function that ignores the dataType param that SoftProAdminDataTable expects.
     * TODO update SoftProAdminDataTable not to expect this and only expect to pass context and create
     *      a HOC for the Analytics tables which need this.
     */
    onFetchAdminDataRequested = (dataType, context) =>
        this.props.fetchAdminPackagesRequested(context);

    render () {
        const {
            tableData,
            tableContext,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            onTrashClick,
            deletingIds,
            onViewButtonClick,
            viewPath,
        } = this.props;

        return (
            <SoftProAdminDataTable
                tableData={tableData}
                tableContext={tableContext}
                dataType={PACKAGES}
                availableStatesForDataTableFilter={
                    availableStatesForDataTableFilter
                }
                availableUnderwritersForDataTableFilter={
                    availableUnderwritersForDataTableFilter
                }
                onFetchDataRequested={this.onFetchAdminDataRequested}
                title={<StyledAllPackagesTitle />}
                onViewRequested={(packageId) => onViewButtonClick(packageId)}
                hasEditionColumn
                hasUnderwriterColumn
                hasTrashColumn
                onTrashClick={onTrashClick}
                deletingIds={deletingIds}
                actions={PACKAGE_TABLE_ACTION_ITEMS}
                onActionSelect={this.onActionSelect}
                getKey={({ packageId }) => packageId}
                viewPath={viewPath}
                hasReleaseNotesColumn={true}
                hasDownloadNotesColumn={true}
                hasZipContentColumn={true}
                hasEndorsementLookupsIncludedColumn={true}
            />
        );
    }
}

PackagesTable.propTypes = {
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: PropTypes.object.isRequired,
    availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object)
        .isRequired,
    availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object)
        .isRequired,
    fetchAdminPackagesRequested: PropTypes.func.isRequired,
    onTrashClick: PropTypes.func.isRequired,
    deletingIds: PropTypes.array.isRequired,
    deleteMultiplePackagesRequested: PropTypes.func.isRequired,
    viewPath: PropTypes.string.isRequired,
    onViewButtonClick: PropTypes.func,
};

class Packages extends React.Component {
    componentDidMount () {
        this.props.fetchAdminPackagesRequested();
    }

    render () {
        const {
            classes,
            tableData,
            tableContext,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            fetchAdminPackagesRequested,
            onTrashClick,
            deletingIds,
            deleteMultiplePackagesRequested,
            router,
        } = this.props;
        const url = getUrl(window, router);

        return (
            <Grid container direction="column">
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={classes.buttonGridContainer}
                >
                    <Grid item>
                        <AdminButton to={`${url}/createPackage`}>
                            Create a New Package
                        </AdminButton>
                    </Grid>
                    <Grid item>
                        <AdminButton
                            to={`${url}/uploadPromptsFile`}
                            variant="outlined"
                        >
                            Add a New Prompts File
                        </AdminButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <PackagesTable
                        tableData={tableData}
                        tableContext={tableContext}
                        availableStatesForDataTableFilter={
                            availableStatesForDataTableFilter
                        }
                        availableUnderwritersForDataTableFilter={
                            availableUnderwritersForDataTableFilter
                        }
                        fetchAdminPackagesRequested={
                            fetchAdminPackagesRequested
                        }
                        onTrashClick={onTrashClick}
                        deletingIds={deletingIds}
                        deleteMultiplePackagesRequested={
                            deleteMultiplePackagesRequested
                        }
                        viewPath={`${router.location.pathname}/package/`}
                    />
                </Grid>
            </Grid>
        );
    }
}

Packages.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: PropTypes.object.isRequired,
    availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object)
        .isRequired,
    availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object)
        .isRequired,
    fetchAdminPackagesRequested: PropTypes.func.isRequired,
    onTrashClick: PropTypes.func.isRequired,
    deletingIds: PropTypes.array.isRequired,
    deleteMultiplePackagesRequested: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
};

class AdminPackages extends React.Component {
    state = {
        showConfirmDialog: false,
        deletingPackageIds: [],
        deletingPackageNames: [],
    };

    toggleDeletePackagesConfirmation = (deletingPackageIds = []) => {
        const { showConfirmDialog } = this.state;
        const { tableData: { data = [] } = {} } = this.props;
        const deletingPackageNames = data
            .filter(({ packageId }) => deletingPackageIds.includes(packageId))
            .map(({ name }) => name);
        this.setState({
            showConfirmDialog: !showConfirmDialog,
            deletingPackageIds,
            deletingPackageNames,
        });
    };

    onDeletePackagesConfirmed = () => {
        const { deleteMultiplePackagesRequested, onDeletePackageRequested } =
            this.props;
        const { deletingPackageIds } = this.state;
        if (deletingPackageIds && deletingPackageIds.length > 0) {
            if (deletingPackageIds.length === 1) {
                onDeletePackageRequested(deletingPackageIds[0]);
            } else {
                deleteMultiplePackagesRequested(deletingPackageIds);
            }
        }
        this.toggleDeletePackagesConfirmation([]);
    };

    render () {
        const {
            router,
            classes,
            tableData,
            tableContext,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            fetchAdminPackagesRequested,
            deletingIds,
        } = this.props;
        const { showConfirmDialog, deletingPackageIds, deletingPackageNames } =
            this.state;
        const deletingMoreThanOnePackage =
            deletingPackageIds && deletingPackageIds.length > 1;
        return (
            <Grid container className={classes.topGrid} direction="column">
                <Grid item>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Fragment>
                                    <Packages
                                        classes={classes}
                                        tableData={tableData}
                                        tableContext={tableContext}
                                        availableStatesForDataTableFilter={
                                            availableStatesForDataTableFilter
                                        }
                                        availableUnderwritersForDataTableFilter={
                                            availableUnderwritersForDataTableFilter
                                        }
                                        fetchAdminPackagesRequested={
                                            fetchAdminPackagesRequested
                                        }
                                        onTrashClick={(packageId) =>
                                            this.toggleDeletePackagesConfirmation(
                                                [packageId],
                                            )
                                        }
                                        deletingIds={deletingIds}
                                        deleteMultiplePackagesRequested={
                                            this
                                                .toggleDeletePackagesConfirmation
                                        }
                                        router={router}
                                    />
                                    <ConfirmDialog
                                        open={showConfirmDialog}
                                        onConfirm={
                                            this.onDeletePackagesConfirmed
                                        }
                                        onCancel={() =>
                                            this.toggleDeletePackagesConfirmation(
                                                [],
                                            )
                                        }
                                        title={`Confirm Delete Package${
                                            deletingMoreThanOnePackage
                                                ? 's'
                                                : ''
                                        }`}
                                        text={
                                            'You are about to delete ' +
                                            `${
                                                deletingMoreThanOnePackage
                                                    ? 'these packages'
                                                    : 'this package'
                                            }: ` +
                                            `${deletingPackageNames.map(
                                                (name) => ` ${name}`,
                                            )}`
                                        }
                                        subText="Are you sure you want to do this?"
                                    />
                                </Fragment>
                            }
                        />
                        <Route
                            path="/uploadPromptsFile"
                            element={<UploadPromptsFile />}
                        />
                        <Route
                            path="/createPackage"
                            element={<Package key="create" router={router} />}
                        />
                        <Route
                            path="/package/:packageId"
                            element={<Package existingPackage />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/admin/packages" />}
                        />
                    </Routes>
                </Grid>
            </Grid>
        );
    }
}

AdminPackages.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: PropTypes.object.isRequired,
    availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object)
        .isRequired,
    availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object)
        .isRequired,
    fetchAdminPackagesRequested: PropTypes.func.isRequired,
    onDeletePackageRequested: PropTypes.func.isRequired,
    deletingIds: PropTypes.array.isRequired,
    deleteMultiplePackagesRequested: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    tableData: getAdminPackagesForTable(state),
    tableContext: getAdminPackagesContext(state),
    availableStatesForDataTableFilter:
        getAvailableStatesForDataTableFilter(state),
    deletingIds: state.adminPackages.deletingIds,
    availableUnderwritersForDataTableFilter:
        getAvailableUnderwritersForDataTableFilter(state),
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchAdminPackagesRequested,
                deleteMultiplePackagesRequested,
            },
            dispatch,
        ),
        onDeletePackageRequested: (packageId) =>
            dispatch(deletePackageRequested(packageId)),
    };
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(AdminPackages);
