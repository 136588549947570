import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LandTitleTitle, ViewInfoButton } from '../TableTitles/index';
import { DIALOG_TEXT, DIALOG_TITLES, PACKAGE_TYPE_LAND_TITLE } from '../../constants/packageTypes';
import TablesPropTypes from '../../propTypes/tables';
import {
    doLandTitlePackagesHaveFilters,
    getLandTitlePackagesContext,
    getLandTitlePackagesForTable,
} from '../../selectors/packages';
import BrowseByType from '../BrowseByType';
import ApiPropTypes from '../../propTypes/api';

class BrowseLandTitlePackages extends React.Component {
    state = {
        infoDialogOpen: false,
    };

    onClickViewInfoButton = () => {
        this.setState({ infoDialogOpen: true });
    };

    onCloseInfoDialog = () => {
        this.setState({ infoDialogOpen: false });
    };

    render () {
        const { infoDialogOpen } = this.state;
        const ViewInfoButtonLandTitle = (
            <ViewInfoButton
                onClick={this.onClickViewInfoButton}
                infoDialogOpen={infoDialogOpen}
                onCloseInfoDialog={this.onCloseInfoDialog}
                dialogTitle={DIALOG_TITLES[PACKAGE_TYPE_LAND_TITLE]}
                dialogText={DIALOG_TEXT[PACKAGE_TYPE_LAND_TITLE]}
            />);
        return (
            <BrowseByType
                type={PACKAGE_TYPE_LAND_TITLE}
                title={<LandTitleTitle button={ViewInfoButtonLandTitle} />}
                {...this.props}
            />
        );
    }
}

BrowseLandTitlePackages.propTypes = {
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: ApiPropTypes.apiPageContext.isRequired,
    hasFilters: PropTypes.bool.isRequired,
};

const mapStateToProps = function (state) {
    return {
        tableData: getLandTitlePackagesForTable(state),
        tableContext: getLandTitlePackagesContext(state),
        hasFilters: doLandTitlePackagesHaveFilters(state),
    };
};

export default connect(mapStateToProps)(BrowseLandTitlePackages);
