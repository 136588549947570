import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styles } from '../Analytics/styles';
import AnalyticsChart from '../AnalyticsChart';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { updateRange } from '../../stores/admin/actions';
import { analyticsDataRequested, analyticsExportRequested } from '../../stores/analytics/actions';
import { USERS } from '../../constants/analytics';
import {
    getRange,
    getTotalActiveUserCount,
    getUsersChartData,
    getUsersContext,
    getUsersError,
    getUsersLoading,
    getUsersPage,
    getUserTabData,
} from '../../selectors/analytics';
import SoftProUsersDataTable from '../SoftProDataTable/SoftProUsersDataTable';
import { ExportButton } from '../ExportButton';
import TablesPropTypes from '../../propTypes/tables';

const UsersChart = (props) => {
    const { data, range, total } = props;
    return (
        <div>
            <AnalyticsChart
                headerText="Users"
                headerCount={total}
                range={range}
                data={data}
            />
        </div>
    );
};
UsersChart.propTypes = {
    data: PropTypes.array.isRequired,
    range: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

class UsersTabContent extends React.Component {
    componentDidMount () {
        const { analyticsDataRequested } = this.props;
        analyticsDataRequested(USERS);
    }

    render () {
        const {
            range,
            data,
            context,
            counts,
            loading,
            classes,
            analyticsDataRequested,
            analyticsExportRequested,
            total,
            userError,
        } = this.props;
        return (
            <div className={classes.topGrid}>
                <Grid container>
                    <Grid container spacing={8} direction="column">
                        <Grid item>
                            {loading
                                ? <CircularProgress />
                                : userError
                                    ? <Typography>Could not fetch users. Try a smaller range of dates or try again later.</Typography>
                                    : <UsersChart range={range} data={counts} total={total} />}
                        </Grid>
                        <Grid item>
                            <SoftProUsersDataTable
                                tableData={data}
                                tableContext={context}
                                onFetchDataRequested={analyticsDataRequested}
                            />
                        </Grid>
                        <Grid item>
                            <ExportButton loading={loading} onClick={() => analyticsExportRequested(USERS, context)} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

UsersTabContent.propTypes = {
    range: PropTypes.number.isRequired,
    data: TablesPropTypes.tablePageData.isRequired,
    context: PropTypes.object.isRequired,
    counts: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    analyticsDataRequested: PropTypes.func.isRequired,
    analyticsExportRequested: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    userError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        range: getRange(state),
        counts: getUsersChartData(state),
        data: getUserTabData(state),
        context: getUsersContext(state),
        page: getUsersPage(state),
        loading: getUsersLoading(state),
        total: getTotalActiveUserCount(state),
        userError: getUsersError(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(
            bindActionCreators({
                updateRange,
                analyticsDataRequested,
                analyticsExportRequested,
            }, dispatch)
        ),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(UsersTabContent);
