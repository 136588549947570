import {
    FETCH_USER_SETTINGS,
    FETCH_USER_SETTINGS_FAILED,
    FETCH_USER_SETTINGS_SUCCEEDED,
    UPDATE_USER_SETTINGS,
    UPDATE_USER_SETTINGS_FAILED,
    UPDATE_USER_SETTINGS_SUCCEEDED,
} from './actions';
import { LOGOUT } from '../auth/actions';

export const settingsInitialState = {
    loading: false,
    name: '',
    states: [],
    editions: [],
    serialNumber: '',
    validationErrors: {},
    notifications: '',
    notificationEmails: [],
};

export function settings (state = settingsInitialState, action) {
    const { settings } = action;
    switch (action.type) {
        case FETCH_USER_SETTINGS:
            return {
                ...state,
                loading: true,
            };
        case FETCH_USER_SETTINGS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                validationErrors: {},
                ...settings,
            };
        case FETCH_USER_SETTINGS_FAILED:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_USER_SETTINGS:
            return {
                ...state,
                loading: true,
                ...settings,
            };
        case UPDATE_USER_SETTINGS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                validationErrors: {},
                ...settings,
            };
        case UPDATE_USER_SETTINGS_FAILED: {
            const { validationErrors = {} } = action;
            return {
                ...state,
                loading: false,
                validationErrors,
            };
        }
        case LOGOUT:
            return settingsInitialState;
        default:
            return state;
    }
}
