import {
    DESELECT_PACKAGES_FOR_WATCHLIST,
    FETCH_SUGGESTED_WATCHLIST,
    FETCH_SUGGESTED_WATCHLIST_FAILED,
    FETCH_SUGGESTED_WATCHLIST_SUCCEEDED,
    SELECT_PACKAGES_FOR_WATCHLIST,
} from './actions';
import { LOGOUT, ON_BOARDING_COMPLETED } from '../auth/actions';

const onBoardingWatchlistInitialState = {
    loading: false,
    suggestedWatchlist: [],
    selectedPackagesForWatchlist: [],
};

export function onBoardingWatchlist (state = onBoardingWatchlistInitialState, action) {
    switch (action.type) {
        case FETCH_SUGGESTED_WATCHLIST: {
            const { editions, underwriterIds, states } = action;
            return {
                ...state,
                loading: true,
                editions,
                underwriterIds,
                states,
            };
        }
        case FETCH_SUGGESTED_WATCHLIST_SUCCEEDED: {
            const { data: { result: suggestedWatchlist } } = action;
            return {
                ...state,
                loading: false,
                suggestedWatchlist,
                selectedPackagesForWatchlist: suggestedWatchlist,
            };
        }
        case FETCH_SUGGESTED_WATCHLIST_FAILED:
            return {
                ...state,
                loading: false,
            };
        case SELECT_PACKAGES_FOR_WATCHLIST:
            return {
                ...state,
                selectedPackagesForWatchlist: [
                    ...(new Set([
                        ...state.selectedPackagesForWatchlist,
                        ...action.packageIds,
                    ]))],
            };
        case DESELECT_PACKAGES_FOR_WATCHLIST:
            return {
                ...state,
                selectedPackagesForWatchlist: state.selectedPackagesForWatchlist.filter((packageId) => {
                    return !action.packageIds.includes(packageId);
                }),
            };
        case LOGOUT: // Intentional fall-through
        case ON_BOARDING_COMPLETED:
            return onBoardingWatchlistInitialState;
        default:
            return state;
    }
}
