import { FETCH_UNDERWRITERS, FETCH_UNDERWRITERS_SUCCEEDED, UPDATE_UNDERWRITERS } from './actions';
import { LOGIN_SUCCEEDED, LOGOUT, ON_BOARDING_COMPLETED } from '../auth/actions';

export const underwritersInitialState = {
    underwriters: [],
    availableUnderwriters: [],
    loading: false,
};

export function underwriters (state = underwritersInitialState, action) {
    let newState;
    switch (action.type) {
        case UPDATE_UNDERWRITERS: {
            const currentUnderwriters = [...state.underwriters];
            if (currentUnderwriters.includes(action.underwriter)) {
                // This chip was already selected, so let's un-select it
                currentUnderwriters.splice(currentUnderwriters.indexOf(action.underwriter), 1);
                newState = ({
                    ...state,
                    underwriters: [...currentUnderwriters],
                });
            } else {
                // New chip selection, so lets add it to the list of selected chips
                newState = ({ ...state, underwriters: [...currentUnderwriters, action.underwriter] });
            }
            return newState;
        }
        case FETCH_UNDERWRITERS: {
            const { stateCodes } = action;
            return {
                ...state,
                loading: true,
                stateCodes,
            };
        }
        case FETCH_UNDERWRITERS_SUCCEEDED: {
            const { data: { result: availableUnderwriters } } = action;
            return {
                ...state,
                loading: false,
                availableUnderwriters,
            };
        }
        case LOGIN_SUCCEEDED: {
            const { user, underwriters: initUnderwriters } = action;
            const hasAvailableUnderwriters = initUnderwriters && initUnderwriters.result;
            return {
                ...state,
                // We want an array of underwriter ids, not the full object, so do a map here to add the ids to the array
                underwriters: user.underwriters?.map((underwriter) => underwriter.id) || [],
                availableUnderwriters: hasAvailableUnderwriters ? initUnderwriters.result : state.underwriters,
            };
        }
        case ON_BOARDING_COMPLETED: // Intentional fall-through
        case LOGOUT:
            return underwritersInitialState;
        default:
            return state;
    }
}
