import PropTypes from 'prop-types';

export const messages = PropTypes.arrayOf(PropTypes.string);

export const FormPropTypes = {
    errors: messages,
    messages,
};

export default FormPropTypes;
