export const styles = (theme) => ({
    browseAll: {
        ...theme.softpro.safeBox,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25,
    },
    packageViewingOptions: {
        marginLeft: 0,
        marginBottom: 16,
    },
    browseButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'auto',
        marginRight: 20,
        maxWidth: 1100,
        [theme.breakpoints.down('lg')]: {
            minWidth: 425,
            minHeight: 150,
            flexWrap: 'wrap',
            justifyContent: 'space-around',
        },
    },
    browseButton: {
        width: 175,
        height: 175,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
            width: 120,
            height: 120,
        },
    },
    browseButtonText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '75%',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 25,
        [theme.breakpoints.down('lg')]: {
            width: 'unset',
            height: 'unset',
            left: 0,
            bottom: 10,
        },
    },
    browseButtonTypography: {
        fontSize: 20,
        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
        },
    },
    browseButtonIcon: {
        position: 'absolute',
        top: '17%',
        height: 45,
        width: 60,
        [theme.breakpoints.down('lg')]: {
            height: 25,
            width: 35,
        },
    },
    browseButtonDiv: {
        width: 175,
        height: 175,
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            width: 120,
            height: 120,
            marginTop: 20,
        },
    },
    imageButton: {
        display: 'flex',
        flexDirection: 'column',
        width: 339,
    },
});
