import { combineReducers } from 'redux';
import suggested, { suggestedPackagesInitialState } from './suggested';
import browseAll, { browseAllInitialState } from './browseAll';
import byState, { byStateInitialState } from './byState';
import { LOGOUT } from '../auth/actions';

const packagesDelegateReducer = combineReducers({ suggested, browseAll, byState });

const packagesInitialState = {
    suggested: suggestedPackagesInitialState,
    browseAll: browseAllInitialState,
    byState: byStateInitialState,
};

export default function packagesReducer (state = packagesInitialState, action) {
    switch (action.type) {
        case LOGOUT:
            return packagesInitialState;
        default:
            return packagesDelegateReducer(state, action);
    }
}
