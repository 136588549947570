import { createSelector } from 'reselect';
import { getEntities } from './entities';
import { denormalize } from 'normalizr';
import { States } from '../schemas';

export const getAvailableStates = createSelector(
    (state) => state.states.availableStates,
    getEntities,
    (states, entities) => denormalize(states || [], States, entities),
);

export const getAvailableStatesForDataTableFilter = createSelector(
    getAvailableStates,
    (availableStates) => ([
        { label: 'All States', value: '' },
        ...availableStates.map(({ name, code }) => ({ label: name, value: code })),
    ]),
);
