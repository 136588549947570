import { getAuthToken } from '../selectors/auth';
import { addStateToQuery, generateUrlWithToken, requestWithAuth } from '../utils/api';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { capitalizeFirstLetter } from '../utils';
import {
    ANALYTICS_DATA_FOR_PACKAGE_REQUESTED,
    ANALYTICS_DATA_REQUESTED,
    ANALYTICS_EXPORT_FOR_PACKAGE_REQUESTED,
    ANALYTICS_EXPORT_REQUESTED,
    analyticsDataForPackageRequestFailed,
    analyticsDataForPackageRequestSucceeded,
    analyticsDataRequestFailed,
    analyticsDataRequestSucceeded,
    analyticsUserDataRequestSucceeded,
    DOWNLOADS_EXPORT_FOR_USER_REQUESTED,
    DOWNLOADS_FOR_USER_REQUESTED,
    downloadsForUserRequestFailed,
    downloadsForUserRequestSucceeded,
} from '../stores/analytics/actions';
import { UPDATE_RANGE_ANALYTICS } from '../stores/admin/actions';
import { getRange, getDate } from '../selectors/analytics';

function * fetchAnalytics (
    {
        dataType,
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { state = null, type = null, search = null, underwriter = null },
    }) {
    const token = yield select(getAuthToken);
    const range = yield select(getRange);
    const date = yield select(getDate);
    const query = yield call(
        addStateToQuery,
        {
            numberOfDays: range,
            startDate: date,
            page,
            perPage,
            orderBy,
            orderByDir,
            search: search || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    if (type) {
        query.type = type;
    }
    const response = yield call(
        requestWithAuth,
        '/admin/' + dataType,
        'GET',
        token,
        query,
    );
    if (response.ok) {
        if (dataType === 'users') {
            const { users, data } = yield call([response, response.json]);
            yield put(analyticsUserDataRequestSucceeded(
                users,
                data,
                { orderBy, orderByDir },
                { state: query.state || state, search, underwriter },
            ));
        } else {
            const data = yield call([response, response.json]);
            yield put(analyticsDataRequestSucceeded(
                dataType,
                data,
                { orderBy, orderByDir },
                { state: query.state || state, search, type, underwriter },
            ));
        }
    } else {
        yield put(snackbar.show({
            message: 'Retrieving ' + capitalizeFirstLetter(dataType) + ' from the server has failed.',
        }));
        yield put(analyticsDataRequestFailed(dataType));
    }
}

export function * listenAnalyticsDataRequested () {
    yield takeEvery(ANALYTICS_DATA_REQUESTED, fetchAnalytics);
    yield takeEvery(UPDATE_RANGE_ANALYTICS, fetchAnalytics);
}

function * exportAnalytics (
    {
        dataType,
        order: { orderBy, orderByDir },
        filters: { state = null, type = null, search = null, underwriter = null },
    }) {
    const token = yield select(getAuthToken);
    const range = yield select(getRange);
    const date = yield select(getDate);
    const query = yield call(
        addStateToQuery,
        {
            numberOfDays: range,
            startDate: date,
            orderBy,
            orderByDir,
            search: search || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    if (type) {
        query.type = type;
    }
    const url = yield call(
        generateUrlWithToken,
        '/download/export/analytics/' + dataType,
        token,
        query,
    );
    yield call([window, window.open], url);
}

function * exportAnalyticsForPackage (
    {
        dataType,
        packageId,
        order: { orderBy, orderByDir },
        filters: { search = null },
    }) {
    const token = yield select(getAuthToken);
    const query = {
        orderBy,
        orderByDir,
        search: search || '',
    };

    const url = yield call(
        generateUrlWithToken,
        '/download/export/' + dataType + '/' + packageId,
        token,
        query,
    );
    yield call([window, window.open], url);
}

function * exportDownloadsForUser (
    {
        userId,
        order: { orderBy, orderByDir },
        filters: { state = null, type = null, search = null, underwriter = null },
    }) {
    const token = yield select(getAuthToken);
    const range = yield select(getRange);
    const date = yield select(getDate);
    const query = yield call(
        addStateToQuery,
        {
            numberOfDays: range,
            startDate: date,
            orderBy,
            orderByDir,
            search: search || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    if (type) {
        query.type = type;
    }
    const url = yield call(
        generateUrlWithToken,
        '/download/export/downloads/user/' + userId,
        token,
        query,
    );
    yield call([window, window.open], url);
}

export function * listenExportAnalytics () {
    yield takeEvery(ANALYTICS_EXPORT_REQUESTED, exportAnalytics);
    yield takeEvery(ANALYTICS_EXPORT_FOR_PACKAGE_REQUESTED, exportAnalyticsForPackage);
    yield takeEvery(DOWNLOADS_EXPORT_FOR_USER_REQUESTED, exportDownloadsForUser);
}

function * fetchAnalyticsForPackage (
    {
        dataType,
        packageId,
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { search = null },
    }) {
    const token = yield select(getAuthToken);
    const query = {
        page,
        perPage,
        orderBy,
        orderByDir,
        search: search || '',
    };
    const response = yield call(
        requestWithAuth,
        '/admin/' + dataType + '/' + packageId,
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const { name, data } = yield call([response, response.json]);
        yield put(analyticsDataForPackageRequestSucceeded(
            dataType,
            name,
            data,
            { orderBy, orderByDir },
            { search },
        ));
    } else {
        yield put(snackbar.show({
            message: 'Retrieving ' + capitalizeFirstLetter(dataType) + ' for package from the server has failed.',
        }));
        yield put(analyticsDataForPackageRequestFailed(dataType));
    }
}

export function * listenFetchAnalyticsForPackage () {
    yield takeEvery(ANALYTICS_DATA_FOR_PACKAGE_REQUESTED, fetchAnalyticsForPackage);
}

function * fetchDownloadsForUser (
    {
        userId,
        pagination: { perPage, page },
        order: { orderBy, orderByDir },
        filters: { state = null, type = null, search = null, underwriter = null },
    }) {
    const token = yield select(getAuthToken);
    const query = yield call(
        addStateToQuery,
        {
            userId,
            page,
            perPage,
            orderBy,
            orderByDir,
            search: search || '',
            underwriter: underwriter || '',
        },
        { state },
    );
    if (type) {
        query.type = type;
    }
    const response = yield call(
        requestWithAuth,
        '/admin/downloads/user/' + userId,
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const { email, data } = yield call([response, response.json]);
        yield put(downloadsForUserRequestSucceeded(
            email,
            data,
            { orderBy, orderByDir },
            { state: query.state || state, search, type, underwriter },
        ));
    } else {
        yield put(snackbar.show({
            message: 'Retrieving Downloads data for user from the server has failed.',
        }));
        yield put(downloadsForUserRequestFailed());
    }
}

export function * listenFetchDownloadsForUser () {
    yield takeEvery(DOWNLOADS_FOR_USER_REQUESTED, fetchDownloadsForUser);
}
