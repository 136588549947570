import { combineReducers } from 'redux';
import app from './app';
import currentUser from './currentUser';
import { editions } from './editions';
import { states } from './states';
import { underwriters } from './underwriters';
import { edition } from './edition';
import { snackbarReducer } from 'material-ui-snackbar-redux';
import { auth } from './auth';
import loginPage from './loginPage';
import { watchlist } from './watchlist';
import { onBoardingWatchlist } from './onBoardingWatchlist';
import packages from './packages';
import { help } from './help';
import entities from './entities';
import { settings } from './settings';
import { recentDownloads } from './recentDownloads';
import { adminDashboardData, adminRanges } from './admin';
import { analytics } from './analytics';
import { users } from './users';
import { promptsFile } from './promptsFile';
import download from './download';
import adminPackages from './adminPackages';
import adminUnderwriters from './adminUnderwriters';
import adminCreateAdmin from './adminCreateAdmin';
import changePassword from './changePassword';

export default (routeReducer) => combineReducers({
    app,
    currentUser,
    editions,
    states,
    underwriters,
    edition,
    snackbar: snackbarReducer,
    auth,
    loginPage,
    watchlist,
    onBoardingWatchlist,
    router: routeReducer,
    packages,
    help,
    entities,
    settings,
    recentDownloads,
    adminRanges,
    adminDashboardData,
    analytics,
    users,
    promptsFile,
    download,
    adminPackages,
    adminUnderwriters,
    adminCreateAdmin,
    changePassword,
});
