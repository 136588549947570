import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppBar, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link, Routes } from 'react-router-dom';
import { getUrl, withRouter } from '../../utils';
import { Navigate, Route, useParams } from 'react-router';
import TabContainer from '../../components/TabContainer/index';
import { bindActionCreators, compose } from 'redux';
import { styles } from './styles';
import { connect } from 'react-redux';
import ArrowIcon from '@mui/icons-material/PlayArrow';
import InfoTab from '../UserInfoTab';
import DownloadHistoryTab from '../DownloadHistoryTab';
import BackButton from '../../components/BackButton';
import { userDataRequested } from '../../stores/users/actions';
import { getSpecificUserDataLoading } from '../../selectors/users';

class UserDetails extends Component {
    componentDidMount () {
        const { userId, userDataRequested } = this.props;
        userDataRequested(userId);
    }

    render () {
        const {
            router,
            location,
            classes,
            email,
            loading,
            userId,
        } = this.props;
        const { pathname } = location;
        const url = getUrl(window, router);
        return (
            <Fragment>
                <Grid item container alignItems="center" justifyContent="space-between" wrap="nowrap">
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Link to="/admin/users/" className={classes.breadcrumb}> Users </Link>
                        </Grid>
                        <Grid item>
                            <ArrowIcon className={classes.arrow} />
                        </Grid>
                        <Grid item>
                            {loading
                                ? <CircularProgress size={14} />
                                : <Link to={url} className={classes.breadcrumbActive}>{email}</Link>}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BackButton to="/admin/users" />
                    </Grid>
                </Grid>
                <Grid item container direction="row" alignItems="flex-end">
                    <Grid item>
                        <AppBar position="static">
                            <Tabs value={pathname} indicatorColor="primary">
                                <Tab
                                    label="User Information"
                                    component={Link}
                                    to={`/admin/users/userDetails/${userId}/userEmail`}
                                    value={`/admin/users/userDetails/${userId}/userEmail`}
                                />
                                <Tab
                                    label="Download History"
                                    component={Link}
                                    to={`/admin/users/userDetails/${userId}/downloadHistory`}
                                    value={`/admin/users/userDetails/${userId}/downloadHistory`}
                                />
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Grid item className={classes.appBarSpacer} />
                </Grid>
                <Grid item container component={TabContainer}>
                    <Routes>
                        <Route path="userEmail" element={<InfoTab />} />
                        <Route path="downloadHistory" element={<DownloadHistoryTab />} />
                        <Route path="*" element={<Navigate to="userEmail" />} />
                    </Routes>
                </Grid>
            </Fragment>
        );
    }
}

UserDetails.propTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    userDataRequested: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        email: state.users.userData.email,
        loading: getSpecificUserDataLoading(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(bindActionCreators({ userDataRequested }, dispatch)),
    };
};

const UserDetailsFunction = (props) => {
    const { userId } = useParams();
    return <UserDetails {...props} userId={userId} />;
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(UserDetailsFunction);
