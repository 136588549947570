export const styles = (theme) => ({
    ...theme.softpro.tableColumns,
    appBarSpacer: {
        flexGrow: 1,
        borderBottom: '1px solid #6089BD',
        opacity: 0.7,
    },
    breadcrumb: {
        marginBottom: 0,
    },
    breadcrumbActive: {
        color: '#6089BD',
    },
    arrow: {
        width: 10,
        color: '#002244',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 9,
    },
});
