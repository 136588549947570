import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Clear, Search } from '@mui/icons-material';
import { extractArrayProperty, extractStringProperty, noop } from '../../utils';
import Pagination from '../Pagination';
import styles from './styles';
import DataTableColumn, { RENDER_TARGET_BODY, RENDER_TARGET_HEAD } from './DataTableColumn';

export const COMPONENT_NAME = 'DataTable';
const { v4: uuidv4 } = require('uuid');

const intelligentlyCreateMenuItem = (item) => {
    const value = extractStringProperty(item, 'value', `${item}`);
    const label = extractStringProperty(item, 'label', (value || `${item}`));
    const key = extractStringProperty(item, 'key', (value || `${item}`));
    return <MenuItem key={key} value={value}>{label}</MenuItem>;
};

/**
 * @typedef {{
 *  id: string,
 *  searchVisible: boolean,
 *  selectedRows: *[],
 *  selectableRows: *[],
 *  someRowsSelected: boolean,
 *  allRowsSelected: boolean,
 *  hasData: boolean,
 * }} DataTableState
 */

/**
 * @class DataTable
 */
class DataTable extends Component {
    static propTypes = {
        // The data to render on the current page. Can be an array of anything.
        data: PropTypes.array.isRequired,

        /*
         * The title to render.
         * Default: render nothing.
         */
        title: PropTypes.node,

        /*
         * Show a loading indicator in the table body.
         * Default: false
         */
        loading: PropTypes.bool,

        /*
         * Available actions.
         * Default: none
         */
        actions: PropTypes.array,

        /*
         * How to render an action in the `<Select>` dropdown. Typically a `<MenuItem />`
         * Receives the following arguments:
         *   - The original action from the actions array.
         *   - The table state.
         * Default: a function that will return a `<MenuItem />` containing the original action coerced to a
         *      string (for its contents as well as its value) OR – if the original action is an object and has
         *      `label` and `value` keys – the appropriate keys will be used for the contents and value/key prop
         *      respectively.
         */
        renderAction: PropTypes.func,

        /*
         * The function to be called when an action is selected.
         * Receives the following arguments:
         *   - The value of the rendered action that was selected
         *   - The table state
         * Default: no op
         */
        onActionSelect: PropTypes.func,

        /*
         * Should rows be selectable in this table?
         * Default: True if at least one action is provided, false otherwise.
         */
        selectable: PropTypes.bool,

        /*
         * A function that determines if a specific row is selectable.
         * Received the following arguments:
         *   - The data for this row
         *   - The index for this row
         * Default: Always true.
         */
        isRowSelectable: PropTypes.func,

        /*
         * A function that determines if a specific row is currently selected.
         * Received the following arguments:
         *   - The data for this row
         *   - The index for this row
         * Default: no op
         */
        isRowSelected: PropTypes.func,

        /*
         * Called when a user clicks a checkbox in a specific row
         * Received the following arguments:
         *   - The new checkbox value
         *   - The data for this row
         *   - The index for this row
         *   - The table state
         * Default: no op
         */
        onRowSelectionChange: PropTypes.func,

        /*
         * Called when a user clicks the checkbox to select/deselect all in the header.
         * Received the following arguments:
         *   - The new checkbox value
         *   - The table state
         * Default: no op
         */
        onAllRowsSelectionChange: PropTypes.func,

        /*
         * Called to render the checkbox column when the table is selectable. Must return a DataTableColumn.
         * Received the following arguments:
         *   - An object containing props:
         *      - `classes`: the JSS classes object containing the classNames, of interest: `classes.checkboxBodyCell`
         *        and `classes.checkboxHeadCell`.
         *      - `onCheckboxHeadCellChange`: a function that can be passed to the `onChange` prop of a Checkbox in the
         *        head cell for example to automatically handle calling `onAllRowsSelectionChange` appropriately.
         *      - `createOnCheckboxBodyCellChange`: a function that can be called with `row` and `rowIndex` which will
         *        return a function that can be passed to the `onChange` prop of a Checkbox in a body cell for example
         *        to automatically handle calling `onRowSelectionChange` appropriately.
         *      - `onRowSelectionChange`: the `onRowSelectionChange` prop passed through for further control. Note:
         *        it's not necessary to use this at all if you use `createOnCheckboxBodyCellChange`.
         *      - `onAllRowsSelectionChange`: the `onAllRowsSelectionChange` prop passed through for further control.
         *        Note: it's not necessary to use this at all if you use `onCheckboxHeadCellChange`.
         *   - The table state
         * Default: render a suitable checkbox column that
         */
        renderCheckboxColumn: PropTypes.func,

        /*
         * Available filters.
         * Default: none
         */
        filters: PropTypes.array,

        /*
         * A function that returns an array of available options for a filter.
         * Receives the following arguments:
         *   - The original filter from the filters array
         *   - The table state.
         * Default: A function that will return an empty array unless the original filter is an object and contains
         *      an `options` key that is an array, then it will return that.
         */
        getFilterOptions: PropTypes.func,

        /*
         * A function that returns a filter's current value.
         * Receives the following arguments:
         *   - The original option from the array that getFilterOptions returns
         *   - The original filter from the filters array
         *   - The table state.
         * Default: A function that returns null.
         */
        getFilterValue: PropTypes.func,

        /*
         * A function that returns a filter dropdown's label's text content.
         * Receives the following arguments:
         *   - The original filter from the filters array
         *   - The table state.
         * Default: a function that will return the original filter coerced to a string OR – if the original
         *      filter is an object and has a `label` key – the `label` key of the filter.
         */
        getFilterLabelText: PropTypes.func,

        /*
         * A function that renders one of a filter dropdown's options. Typically a `<MenuItem />`.
         * Receives the following arguments:
         *   - The original option from the array that getFilterOptions returns
         *   - The original filter from the filters array
         *   - The table state.
         * Default: a function that will return a `<MenuItem />` containing the original option coerced to a
         *      string (for its contents as well as its value) OR – if the original option is an object and has
         *      `label` and `value` keys – the appropriate keys will be used for the contents and value/key prop
         *      respectively.
         */
        renderFilterOption: PropTypes.func,

        /*
         * The function to be called when a filter is changed.
         * Receives the following arguments:
         *   - The value of the rendered filter option that was selected
         *   - The original filter from the filters array
         *   - The table state.
         * Default: no op
         */
        onFilterChange: PropTypes.func,

        /*
         * Whether or not the to enable the search functionality.
         * Default: true
         */
        searchable: PropTypes.bool,

        /*
         * The current search value.
         * Default: ''
         */
        search: PropTypes.string,

        /*
         * Called when the user changes the value of the search input. Called with the new value.
         * Default: no op
         */
        onSearchChange: PropTypes.func,

        /*
         * Called when the search is submitted by the user (e.g. they press enter). Called with no arguments.
         * Default: no op
         */
        onSearchSubmit: PropTypes.func,

        /*
         * Called when the search is cleared by the user (e.g. they press the X button). Called with no arguments.
         * Default: no op
         */
        onSearchClear: PropTypes.func,

        /*
         * The props to pass to the Pagination object. If null, don't render pagination.
         * Default: null
         */
        pagination: PropTypes.object,

        // The classes to style the component
        classes: PropTypes.object.isRequired,

        /*
         * The ID of the component will be passed through to the root div.
         * Default: the value of the randomly generated ID in the state.
         */
        id: PropTypes.string,

        /*
         * What to render when the table's data is empty
         * Default: don't render anything.
         */
        renderEmpty: PropTypes.func,

        /*
         * What to render when the table is in a loading state.
         * Default: `<CircularProgress />`
         */
        renderLoading: PropTypes.func,

        /*
         * Render the top area above the table containing (by default) the title, filters, and actions.
         * Receives the following arguments:
         *   - An object containing props:
         *      - `classes`: the JSS classes object containing the classNames (of interest: `classes.top` to apply to the
         *        container element to maintain consistent styling and inherit theme styling etc.).
         *      - `title`: the passed in `title` prop
         *      - `renderActions`: a function that will render the actions
         *      - `renderFilters`: a function that will render the filters
         *      - `renderSearchToggle`: a function that will render the search button that toggles the search visibility
         *        by default
         *      - `renderSearch`: a function that will render the search text box
         *      - `onSearchOpen`: a function that will make search visible
         *      - `onSearchClose`: a function that will make search not visible
         *      - `onSearchToggle`: a function that will toggle search visibility
         *      - `onSearchChange`: a function that can be used as an onChange handler for the search text input
         *        to appropriately call the onSearchChange prop.
         *      - `onSearchSubmit`: a function that can be used as a onSubmit handler for a form that will block the
         *        form from submitting and call the onSearchSubmit prop appropriately.
         *      - `onSearchClear`: a function that will call the onSearchClear prop appropriately.
         *   - The table state.
         * Default: render title always, render actions only when selections are made and otherwise render filters in
         *      its place.
         */
        renderTop: PropTypes.func,
        children: PropTypes.shape({
            type: PropTypes.oneOf([Fragment, TableHead, TableBody, TableRow, TableCell]),
            props: PropTypes.shape({
                children: PropTypes.array,
            }),
        }).isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        // Actions
        actions: [],
        renderAction: intelligentlyCreateMenuItem,
        onActionSelect: noop,

        // Selectable
        selectable: null,
        isRowSelectable: () => true,
        isRowSelected: () => false,
        onRowSelectionChange: noop,
        onAllRowsSelectionChange: noop,
        renderCheckboxColumn: null,

        // Filters
        filters: [],
        getFilterOptions: (filter) => extractArrayProperty(filter, 'options', []),
        getFilterValue: () => '',
        getFilterLabelText: (filter) => extractStringProperty(filter, 'label'),
        renderFilterOption: intelligentlyCreateMenuItem,
        onFilterChange: noop,

        // Search
        searchable: true,
        search: '',
        onSearchChange: noop,
        onSearchSubmit: noop,
        onSearchClear: noop,

        // Pagination
        pagination: null,

        // Render props for certain table states / sections
        renderEmpty: null,
        renderLoading: () => <CircularProgress />,
        renderTop: null,

        // Misc
        title: null,
        loading: false,
        id: null,
    };

    state = {
        randomId: uuidv4(),
        searchVisible: false,
    };

    /**
     * Returns the current state of the table which is a derived form of the actual internal state and the props.
     * @return {DataTableState}
     */
    getTableState = () => {
        const { searchVisible } = this.state;
        const { data, isRowSelectable, isRowSelected } = this.props;
        const selectedRows = data ? data.filter((row, rowIndex) => isRowSelected(row, rowIndex)) : [];
        const selectableRows = data ? data.filter((row, rowIndex) => isRowSelectable(row, rowIndex)) : [];
        return ({
            id: this.generateId(),
            searchVisible,
            selectedRows,
            selectableRows,
            someRowsSelected: selectedRows.length !== 0,
            allRowsSelected: selectedRows.length !== 0 && selectedRows.length === data.length,
            hasData: data ? data.length !== 0 : false,
        });
    };

    /**
     * Generate the ID of this table or parts of the table.
     *
     * @return {string}
     */
    generateId = (...parts) => ([COMPONENT_NAME, (this.props.id || this.state.randomId), ...parts]).join('--');

    /**
     * Gets the number of literal rendered columns.
     *
     * @param {number} numColumns
     * @return {number}
     */
    getColSpan = (numColumns) => {
        return this.isSelectable() ? (numColumns + 1) : numColumns;
    };

    /**
     * Determine if we need to render the checkbox column and allow items to be selectable.
     * @return {boolean}
     */
    isSelectable = () => {
        const { selectable, actions } = this.props;
        return (selectable === true || (selectable === null && actions.length > 0));
    };

    /**
     * Get all columns which is just the children unless the table is selectable then it also includes
     * a prepended column which contains the checkboxes.
     *
     * If children is a fragment, it will be unravelled.
     *
     * @param {DataTableState} tableState
     * @return {*}
     */
    getColumns = (tableState) => {
        const { children: possibleFragment } = this.props;

        /** @see https://github.com/facebook/react/issues/11859#issuecomment-351970589 */
        const children = possibleFragment.type === Fragment
            ? possibleFragment.props.children
            : possibleFragment;

        const columns = this.isSelectable()
            ? [this.renderCheckboxColumn(tableState), ...React.Children.toArray(children)]
            : React.Children.toArray(children);

        return columns.filter((column) => !!column);
    };

    /**
     * A function that can be passed to the onChange prop of a Checkbox in order to appropriately call
     * the `onAllRowsSelectionChange` prop.
     *
     * @param {{target: {checked: boolean}}} event
     */
    onCheckboxHeadCellChange = ({ target: { checked } }) => {
        this.props.onAllRowsSelectionChange(checked, this.getTableState());
    };

    /**
     * Renders a head cell for the default checkbox column.
     *
     * @param {boolean} allRowsSelected
     * @param {boolean} someRowsSelected
     * @param {boolean} hasData
     * @return {React.ReactNode}
     */
    renderCheckboxHeadCellContent = ({ allRowsSelected, someRowsSelected, hasData }) => {
        return (
            hasData
                ? (
                    <Checkbox
                        checked={allRowsSelected}
                        indeterminate={someRowsSelected && !allRowsSelected}
                        onChange={this.onCheckboxHeadCellChange}
                    />
                )
                : null
        );
    };

    /**
     * Create a function that can be passed to the onChange prop of a Checkbox in order to appropriately
     * call the `onRowSelectionChange` prop.
     *
     * @param {*} row
     * @param {number} rowIndex
     * @return {function({target: {checked: boolean}}): void}
     */
    createOnCheckboxBodyCellChange = (row, rowIndex) => ({ target: { checked } }) => {
        this.props.onRowSelectionChange(checked, row, rowIndex, this.getTableState());
    };

    /**
     * Render a body cell for the default checkbox column.
     *
     * @param {*} row
     * @param {number} rowIndex
     * @param {*[]} selectedRows
     * @return {React.ReactNode}
     */
    renderCheckboxBodyCellContent = (row, rowIndex, { selectedRows }) => {
        return (
            <Checkbox
                checked={selectedRows.includes(row)}
                onChange={this.createOnCheckboxBodyCellChange(row, rowIndex)}
            />
        );
    };

    /**
     * Render the checkbox column when the table is selectable.
     *
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderCheckboxColumn = (tableState) => {
        const { classes, renderCheckboxColumn, onAllRowsSelectionChange, onRowSelectionChange } = this.props;
        if (renderCheckboxColumn) {
            return renderCheckboxColumn(
                {
                    classes,
                    onCheckboxHeadCellChange: this.onCheckboxHeadCellChange,
                    createOnCheckboxBodyCellChange: this.createOnCheckboxBodyCellChange,
                    onAllRowsSelectionChange,
                    onRowSelectionChange,
                },
                tableState,
            );
        } else {
            return (
                <DataTableColumn
                    className={classes.checkboxBodyCell}
                    headCellClassName={classes.checkboxHeadCell}
                    headCellContent={this.renderCheckboxHeadCellContent}
                >
                    {this.renderCheckboxBodyCellContent}
                </DataTableColumn>
            );
        }
    };

    /**
     * Renders the filters out.
     * @param {DataTableState} renderTimeTableState
     * @return {React.ReactNode}
     */
    renderFilters = (renderTimeTableState = this.getTableState()) => {
        const {
            classes,
            filters,
            getFilterValue,
            getFilterOptions,
            getFilterLabelText,
            renderFilterOption,
            onFilterChange,
        } = this.props;
        if (filters.length > 0) {
            return (
                <div className={classes.filters}>
                    {filters.map((filter, filterIndex) => {
                        const selectId = this.generateId(`filter-${filterIndex}`);
                        const labelText = getFilterLabelText(filter, renderTimeTableState);
                        return (
                            <FormControl className={classes.filter} key={selectId}>
                                <InputLabel
                                    htmlFor={selectId}
                                    className={classNames(classes.label, classes.filterLabel)}
                                >
                                    {labelText}
                                </InputLabel>
                                <Select
                                    variant="filled"
                                    displayEmpty
                                    id={selectId}
                                    className={classNames(classes.input, classes.filterInput)}
                                    value={getFilterValue(filter, renderTimeTableState)}
                                    onChange={(event) => {
                                        return onFilterChange(event.target.value, filter, this.getTableState());
                                    }}
                                >
                                    {getFilterOptions(filter, renderTimeTableState).map((option) => (
                                        renderFilterOption(option, filter, renderTimeTableState)
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    /**
     * Render the action dropdown out.
     * @param {DataTableState} renderTimeTableState
     * @return {React.ReactNode}
     */
    renderActions = (renderTimeTableState = this.getTableState()) => {
        const {
            classes,
            actions,
            renderAction,
            onActionSelect,
        } = this.props;
        if (actions.length > 0) {
            const actionsSelectId = this.generateId('actions');
            return (
                <FormControl className={classes.actions}>
                    <InputLabel
                        htmlFor={actionsSelectId}
                        className={classNames(classes.label, classes.actionsLabel)}
                    >
                        Actions
                    </InputLabel>
                    <Select
                        variant="filled"
                        displayEmpty
                        id={actionsSelectId}
                        className={classNames(classes.input, classes.actionsInput)}
                        value=""
                        onChange={(event) => onActionSelect(event.target.value, this.getTableState())}
                    >
                        <MenuItem value="" key={`${actionsSelectId}-placeholder`}>Actions</MenuItem>
                        {actions.map((action) => renderAction(action, renderTimeTableState))}
                    </Select>
                </FormControl>
            );
        }
        return null;
    };

    /**
     * OnClick handler to make search visible
     *
     * @param {{preventDefault: function}?} event
     */
    onSearchOpen = (event) => {
        if (event) {
            event.preventDefault();
        }
        const { searchVisible } = this.getTableState();
        if (!searchVisible) {
            this.setState({ searchVisible: true });
        }
    };

    /**
     * OnClick handler to make search not visible
     *
     * @param {{preventDefault: function}?} event
     */
    onSearchClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        const { searchVisible } = this.getTableState();
        if (searchVisible) {
            this.setState({ searchVisible: false });
        }
    };

    /**
     * OnClick handler to toggle search visibility
     *
     * @param {{preventDefault: function}?} event
     */
    onSearchToggle = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({ searchVisible: !this.getTableState().searchVisible });
    };

    /**
     * OnClick handler to indicate search should be cleared and also hides the search bar.
     * @param event
     */
    onSearchClear = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.onSearchClear();
        this.setState({ searchVisible: false });
    };

    /**
     * Form input onChange handler to call onSearchChange
     *
     * @param {{target: {value: *}}} event
     */
    onSearchChange = (event) => {
        this.props.onSearchChange(event.target.value);
    };

    /**
     * Form onSubmit handler to handle enter press in search field.
     *
     * @param {{preventDefault: function}?} event
     */
    onSearchSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.onSearchSubmit();
    };

    /**
     * Render the search toggle button that will toggle the visibility of the search field.
     *
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderSearchToggle = (tableState = this.getTableState()) => {
        const { classes } = this.props;
        const { searchVisible } = tableState;

        const className = classNames(
            classes.searchToggleButton,
            searchVisible ? classes.searchToggleButtonHide : classes.searchToggleButtonShow,
        );

        const iconClassName = classNames(
            classes.searchToggleIcon,
            searchVisible ? classes.searchToggleIconHide : classes.searchToggleIconShow,
        );

        return (
            <IconButton
                className={className}
                aria-label={searchVisible ? 'Hide search' : 'Show search'}
                onClick={searchVisible ? this.onSearchClear : this.onSearchOpen}
                size="large"
            >
                {searchVisible ? <Clear className={iconClassName} /> : <Search className={iconClassName} />}
            </IconButton>
        );
    };

    /**
     * Render the actual search input.
     *
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderSearch = (tableState = this.getTableState()) => {
        const { classes, search } = this.props;

        const searchInputId = this.generateId('search');
        return (
            <form onSubmit={this.onSearchSubmit} className={classes.search}>
                <FormControl className={classes.searchControl}>
                    <InputLabel
                        htmlFor={searchInputId}
                        className={classNames(classes.label, classes.searchLabel)}
                    >
                        Search
                    </InputLabel>
                    <Input
                        type="text"
                        id={searchInputId}
                        value={search}
                        onChange={this.onSearchChange}
                        className={classNames(classes.input, classes.searchInput)}
                        autoFocus
                        placeholder="Hit Enter to Search"
                    />
                </FormControl>
            </form>
        );
    };

    /**
     * Render the table body containing the data by cloning all of the column definition elements for each row and
     * rendering them out in "body cell" mode with the table state and row data passed in as props.
     *
     * @see DataTableColumn
     *
     * @param {React.ReactNodeArray} columns
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderBody = (columns, tableState) => {
        const { classes, data } = this.props;

        return (
            <TableBody className={classNames(classes.tableBody, classes.tableBodyWithData)}>
                {data.map((row, index) => {
                    const bodyCells = React.Children.map(columns, (column) => {
                        return React.cloneElement(column, {
                            _render: RENDER_TARGET_BODY,
                            _tableState: tableState,
                            _row: row,
                            _rowIndex: index,
                        });
                    });
                    const rowId = this.generateId('row', index);
                    return (
                        <TableRow
                            key={rowId}
                            className={classNames(classes.tableBodyRow, classes.tableBodyWithDataRow)}
                        >
                            {bodyCells}
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    /**
     * Render the loading table body.
     *
     * @param {number} numColumns
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderLoading = (numColumns, tableState) => {
        const { classes, renderLoading } = this.props;
        if (renderLoading) {
            return (
                <TableBody className={classNames(classes.tableBody, classes.tableBodyLoading)}>
                    <TableRow className={classNames(classes.tableBodyRow, classes.tableBodyLoadingRow)}>
                        <TableCell colSpan={this.getColSpan(numColumns)} className={classes.tableLoadingCell}>
                            {renderLoading(tableState)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }
        return null;
    };

    /**
     * Render the empty table body.
     * @param {number} numColumns
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderEmpty = (numColumns, tableState) => {
        const { classes, renderEmpty } = this.props;
        if (renderEmpty) {
            return (
                <TableBody className={classNames(classes.tableBody, classes.tableBodyEmpty)}>
                    <TableRow className={classNames(classes.tableBodyRow, classes.tableBodyEmptyRow)}>
                        <TableCell colSpan={this.getColSpan(numColumns)} className={classes.tableEmptyCell}>
                            {renderEmpty(tableState)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }
        return null;
    };

    /**
     * Render the table content based on the data available, loading state, etc.
     *
     * @see renderBody
     * @see renderLoading
     * @see renderEmpty
     *
     * @param {React.ReactNodeArray} columns
     * @param {number} numColumns
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderTableContent = (columns, numColumns, tableState) => {
        const { loading, data } = this.props;

        if (loading) {
            return this.renderLoading(numColumns, tableState);
        } else if (data && data.length === 0) {
            return this.renderEmpty(numColumns, tableState);
        } else {
            return (
                <Fragment>
                    {this.renderBody(columns, tableState)}
                </Fragment>
            );
        }
    };

    /**
     * Render the header and table content by cloning the column definition elements with new props that indicate
     * to them they should render in "head cell" mode and then rendering the rest of the table based on the
     * current state.
     *
     * @see DataTableColumn
     * @see renderTableContent
     *
     * @return {React.ReactNode}
     */
    renderTable (tableState) {
        const { classes } = this.props;

        const columns = this.getColumns(tableState);

        /*
         * Generate the head cells by cloning the child `DataTableColumns` with props to indicate
         * they should be rendered in "head cell" mode. Also used to generate a column count.
         */
        const headCells = React.Children.map(columns, (column) => {
            return React.cloneElement(column, {
                _render: RENDER_TARGET_HEAD,
                _tableState: tableState,
            });
        });

        const numColumns = React.Children.count(headCells);

        const head = (
            <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableHeadRow}>
                    {headCells}
                </TableRow>
            </TableHead>
        );

        return (
            <Table className={classes.table}>
                {head}
                {this.renderTableContent(columns, numColumns, tableState)}
            </Table>
        );
    }

    /**
     * Render the content that goes alongside the title in the top portion of the table by default.
     *
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderTopContent = (tableState = this.getTableState()) => {
        const { searchable } = this.props;
        const { someRowsSelected, searchVisible } = tableState;
        if (someRowsSelected) {
            return this.renderActions(tableState);
        }
        return (
            <Fragment>
                {(searchVisible && searchable) ? this.renderSearch(tableState) : null}
                {searchable ? this.renderSearchToggle(tableState) : null}
                {this.renderFilters(tableState)}
            </Fragment>
        );
    };

    /**
     * Render the top area above the table.
     *
     * @param {DataTableState} tableState
     * @return {React.ReactNode}
     */
    renderTop = (tableState) => {
        const { classes, renderTop, title, pagination } = this.props;
        if (renderTop) {
            return renderTop(
                {
                    classes,
                    title,
                    renderFilters: this.renderFilters,
                    renderActions: this.renderActions,
                    renderSearchToggle: this.renderSearchToggle,
                    renderSearch: this.renderSearch,
                    onSearchOpen: this.onSearchOpen,
                    onSearchClose: this.onSearchClose,
                    onSearchToggle: this.onSearchToggle,
                    onSearchChange: this.onSearchChange,
                    onSearchSubmit: this.onSearchSubmit,
                    onSearchClear: this.onSearchClear,
                },
                tableState,
            );
        } else {
            return (
                <div className={classes.top}>
                    <div className={classes.topTitleAndContent}>
                        {title}
                        {this.renderTopContent(tableState)}
                    </div>
                    <Pagination {...pagination} />
                </div>
            );
        }
    };

    render () {
        const {
            classes,
            className: classNameProp,
        } = this.props;

        const tableState = this.getTableState();

        return (
            <div className={classNames(classes.root, classNameProp)} id={this.generateId()}>
                {this.renderTop(tableState)}
                {this.renderTable(tableState)}
            </div>
        );
    }
}

export default withStyles(styles, { name: COMPONENT_NAME })(DataTable);
