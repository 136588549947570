export default () => ({
    viewButton: {
        width: 110,
        height: 22,
        minHeight: 22,
        margin: 0,
        padding: 0,
    },
    viewButtonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});
