import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import {
    Button,
    CircularProgress,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    Grid,
    InputAdornment,
    InputBase,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Add as PlusIcon, Search as SearchIcon } from '@mui/icons-material';
import Linkify from 'linkify-react';
import { styles } from './styles';
import { fetchFaq, fetchVideos } from '../../stores/help/actions';
import { getCurrentUserEditions } from '../../selectors/currentUser';
import { getEditionName } from '../../selectors/edition';
import { areVideosLoading, getFaq, getVideos, isFaqLoading } from '../../selectors/help';

function InstructionalVideo ({ classes, video }) {
    return (
        <div className={classes.videoContainer} key={`${video.id}`}>
            <video
                width={280}
                controls
                key={`${video.id}`}
                className={classes.video}
            >
                <source src={video.src} />
            </video>
            {video.title ? <Typography className={classes.videoTitle}>{video.title}</Typography> : null}
        </div>
    );
}

InstructionalVideo.propTypes = {
    classes: PropTypes.object.isRequired,
    video: PropTypes.shape({
        id: PropTypes.any.isRequired,
        src: PropTypes.string.isRequired,
        type: PropTypes.any.isRequired,
        title: PropTypes.string,
    }).isRequired,
};

class InstructionalVideos extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any.isRequired,
            src: PropTypes.string.isRequired,
            type: PropTypes.any.isRequired,
            title: PropTypes.string,
        })),
        loading: PropTypes.bool,
    };

    static defaultProps = {
        data: [],
        loading: false,
    };

    containerRef = null;

    /**
     * Safely pause all videos using the DOM directly by maintaining a ref on the container element and using that
     * ref + querySelectorAll to find all videos and pause them.
     *
     * This is necessary since react-slick's "infinite" behaviour clones slides in such a way that makes it impossible
     * to identify a ref uniquely using callback refs.
     */
    pauseAllVideos = () => {
        if (this.containerRef && typeof this.containerRef.querySelectorAll === 'function') {
            for (const video of this.containerRef.querySelectorAll('video')) {
                if (typeof video === 'object' && typeof video.pause === 'function') {
                    video.pause();
                }
            }
        }
    };

    setContainerRef = (ref) => {
        this.containerRef = ref;
    };

    render () {
        const { classes, data, loading } = this.props;
        const settings = {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
            beforeChange: this.pauseAllVideos,
        };
        return (
            <Fragment>
                <Typography className={classes.subheading}>
                    Instructional Videos
                </Typography>
                {loading
                    ? <CircularProgress />
                    : (
                        <div className={classes.videosContainer} ref={this.setContainerRef}>
                            <Slider {...settings}>
                                {data.filter(({ type }) => type === 'native').map((video) => (
                                    <InstructionalVideo classes={classes} video={video} key={`${video.id}`} />
                                ))}
                            </Slider>
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

const HelpInfo = ({ classes }) => {
    return (
        <div className={classes.helpInfo}>
            <div className={classes.helpInfoSection}>
                <Typography className={classes.subheading}>
                    Regulatory Compliance Updates
                </Typography>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.helpInfoButton}
                    href="http://blog.softprocorp.com/topic/regulatory-and-compliance"
                >
                    View SoftPro Blog
                </Button>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.helpInfoSection}>
                <Typography className={classes.subheading}>
                    Subscribe to SoftPro Blog
                </Typography>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.helpInfoButton}
                    href="http://blog.softprocorp.com/"
                >
                    Subscribe
                </Button>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.helpInfoSection}>
                <Typography className={classes.subheading}>
                    Return to SoftProCorp.com
                </Typography>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.helpInfoButton}
                    href="http://www.softprocorp.com"
                >
                    softprocorp.com
                </Button>
            </div>
        </div>
    );
};

HelpInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

const FAQSearchBar = (props) => {
    const { classes, onChange } = props;
    const [showSearch, setShowSearch] = useState(true);
    const onSearchChange = (e) => {
        if (e.target?.value !== '') {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
        onChange(e);
    };
    return (
        <div className={classes.faqSearchBar}>
            <FormControl>
                <InputBase
                    placeholder="Search"
                    type="search"
                    variant="outline"
                    onChange={onSearchChange}
                />
                <InputAdornment variant="filled" position="end">
                    <SearchIcon className={showSearch ? classes.searchIcon : classes.hidden} />
                </InputAdornment>
            </FormControl>
        </div>
    );
};

FAQSearchBar.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

class FAQ extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        loading: PropTypes.bool,
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any,
            question: PropTypes.string,
            answer: PropTypes.string,
        })),
    };

    static defaultProps = {
        loading: false,
        data: [],
    };

    state = {
        searchString: '',
    };

    onSearchInputChange = (event) => {
        this.setState({ searchString: event.target.value });
    };

    render () {
        const { classes, loading, data } = this.props;
        const { searchString } = this.state;
        return (
            <div className={classes.faqContainer}>
                <div className={classes.faqHeading}>
                    <Typography className={classes.subheading}>
                        FAQs
                    </Typography>
                    <FAQSearchBar onChange={this.onSearchInputChange} classes={classes} />
                </div>
                {loading && <CircularProgress />}
                {!loading &&
                <div className={classes.faqPanelDiv}>
                    {data.map(({ question, answer, id }) => {
                        const includedInSearch = searchString === '' ||
                            question.toUpperCase().indexOf(searchString.toUpperCase()) > -1 ||
                            answer.toUpperCase().indexOf(searchString.toUpperCase()) > -1;
                        return (
                            includedInSearch
                                ? <FAQPanel question={question} answer={answer} classes={classes} key={id} />
                                : ''
                        );
                    })}
                </div>
                }
            </div>
        );
    }
}

const FAQPanel = ({ classes, question, answer }) => {
    return (
        <Accordion className={classes.faqPanel} key={question}>
            <AccordionSummary expandIcon={<PlusIcon color="primary" />}>
                <Typography
                    color="primary"
                    className={classes.faqQuestion}
                >{question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <Linkify options={{ target: '_blank' }} >
                        {answer}
                    </Linkify>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

FAQPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
};

class HelpPage extends Component {
    componentDidMount () {
        const { fetchFaq, fetchVideos, userEditions } = this.props;
        for (const userEdition of userEditions) {
            fetchFaq(userEdition);
            fetchVideos(userEdition);
        }
    }

    render () {
        const { classes, editionName, faqLoading, videosLoading, faq, videos } = this.props;
        return (
            <div className={classes.helpPage}>
                <Typography variant="h1" className={classes.helpTitle}>
                    {editionName} - Info Videos and FAQs
                </Typography>
                <Grid container spacing={16} direction="row" alignItems="flex-start" wrap="nowrap">
                    <Grid item xs={6}>
                        <InstructionalVideos classes={classes} loading={videosLoading} data={videos} />
                    </Grid>
                    <Grid item xs={6}>
                        <HelpInfo classes={classes} />
                    </Grid>
                </Grid>
                <FAQ classes={classes} loading={faqLoading} data={faq} />
            </div>
        );
    }
}

HelpPage.propTypes = {
    classes: PropTypes.object.isRequired,
    fetchFaq: PropTypes.func.isRequired,
    fetchVideos: PropTypes.func.isRequired,
    editionName: PropTypes.string.isRequired,
    faq: PropTypes.arrayOf(PropTypes.object).isRequired,
    faqLoading: PropTypes.bool.isRequired,
    videos: PropTypes.arrayOf(PropTypes.object).isRequired,
    videosLoading: PropTypes.bool.isRequired,
    userEditions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = function (state) {
    return {
        editionName: getEditionName(state),
        faq: getFaq(state),
        faqLoading: isFaqLoading(state),
        videos: getVideos(state),
        videosLoading: areVideosLoading(state),
        userEditions: getCurrentUserEditions(state),
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators({ fetchFaq, fetchVideos }, dispatch)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(HelpPage);
