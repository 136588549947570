export const PREFIX = 'help/';

export const FETCH_FAQ = `${PREFIX}FETCH_FAQ`;
export const FETCH_FAQ_SUCCEEDED = `${PREFIX}FETCH_FAQ_SUCCEEDED`;
export const FETCH_FAQ_FAILED = `${PREFIX}FETCH_FAQ_FAILED`;

export const FETCH_VIDEOS = `${PREFIX}FETCH_VIDEOS`;
export const FETCH_VIDEOS_SUCCEEDED = `${PREFIX}FETCH_VIDEOS_SUCCEEDED`;
export const FETCH_VIDEOS_FAILED = `${PREFIX}FETCH_VIDEOS_FAILED`;

export function fetchFaq (edition) {
    return {
        type: FETCH_FAQ,
        edition,
    };
}

export function fetchFaqSucceeded (edition, data) {
    return {
        type: FETCH_FAQ_SUCCEEDED,
        edition,
        data,
    };
}

export function fetchFaqFailed () {
    return {
        type: FETCH_FAQ_FAILED,
    };
}

export function fetchVideos (edition) {
    return {
        type: FETCH_VIDEOS,
        edition,
    };
}

export function fetchVideosSucceeded (edition, data) {
    return {
        type: FETCH_VIDEOS_SUCCEEDED,
        edition,
        data,
    };
}

export function fetchVideosFailed () {
    return {
        type: FETCH_VIDEOS_FAILED,
    };
}
