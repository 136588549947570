import { combineReducers } from 'redux';
import listReducer from './list';
import detailReducer from './detail';
import deleteReducer from './delete';

export default combineReducers({
    list: listReducer,
    detail: detailReducer,
    delete: deleteReducer,
});
