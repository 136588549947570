import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Add as PlusIcon, Check as CheckIcon } from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';
import { StyledCustomTableToolbar } from '../../components/TableComponents';
import { formatDate, noop } from '../../utils';
import { getSelectedIds } from '../../utils/tableUtils';
import { EDITION_NAMES } from '../../constants/editions';
import styles from './styles';

const AddButton = ({ value, selected, onClick, classes }) => {
    const text = selected ? 'Added to Watchlist' : 'Add';
    const icon = selected
        ? <CheckIcon className={classes.icon} />
        : <PlusIcon className={classes.icon} />;
    const color = selected ? 'primary' : 'secondary';
    return (
        <div className={classes.addButtonDiv}>
            <Button
                variant="contained"
                size="small"
                color={color}
                className={classes.addButton}
                onClick={() => onClick(value)}
            >
                {text} {icon}
            </Button>
        </div>
    );
};

AddButton.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.any,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

AddButton.defaultProps = {
    value: null,
    selected: false,
    onClick: noop,
};

const ACTION_ADD = 'add';
const ACTION_REMOVE = 'remove';
const ACTIONS = [ACTION_ADD, ACTION_REMOVE];
const ACTIONS_LABELS = {
    [ACTION_ADD]: 'Add',
    [ACTION_REMOVE]: 'Remove',
};

class OnBoardingWatchlistTable extends React.Component {
    static propTypes = {
        selectedPackagesForWatchlist: PropTypes.array,
        onAddPackages: PropTypes.func,
        onRemovePackages: PropTypes.func,
        data: PropTypes.arrayOf(PropTypes.shape({
            length: PropTypes.number.isRequired,
            map: PropTypes.func.isRequired,
        })).isRequired,
        loading: PropTypes.bool.isRequired,
        classes: PropTypes.shape({
            lastUpdatedCol: PropTypes.string.isRequired,
            typeCol: PropTypes.string.isRequired,
            editionCol: PropTypes.string.isRequired,
            stateCol: PropTypes.string.isRequired,
            underwriterNameCol: PropTypes.string.isRequired,
            emptyTitleDiv: PropTypes.string.isRequired,
        }).isRequired,
    };

    onAddPackage = (...packageIds) => this.props.onAddPackages(packageIds);
    onRemovePackage = (...packageIds) => this.props.onRemovePackages(
        packageIds);

    getColumns = () => {
        const { classes } = this.props;
        return ([
            {
                name: 'Last Updated',
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: () => ({ className: classes.lastUpdatedCol }),
                },
            },
            {
                name: 'Type',
                setCellProps: () => ({ className: classes.typeCol }),
            },
            {
                name: 'Software Ed.',
                setCellProps: () => ({ className: classes.editionCol }),
            },
            {
                name: 'State/Region',
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: () => ({ className: classes.stateCol }),
                },
            },
            {
                name: 'Underwriter Name',
                options: {
                    filter: true,
                    sort: true,
                    setCellProps: () => ({ className: classes.underwriterNameCol }),
                },
            },
            {
                name: 'Package Name',
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: () => ({ style: { width: 'auto' } }),
                },
            },
            {
                name: '',
                options: {
                    customBodyRender: (value) => {
                        const { selectedPackagesForWatchlist } = this.props;
                        const selected = selectedPackagesForWatchlist &&
                            selectedPackagesForWatchlist.includes(value);
                        return (
                            <AddButton
                                classes={classes}
                                key={value}
                                value={value}
                                selected={selected}
                                onClick={selected
                                    ? this.onRemovePackage
                                    : this.onAddPackage}
                            />
                        );
                    },
                    filter: false,
                    sort: false,
                },
            },
        ]);
    };

    onToolbarActionSelected = (action, selectedIds) => {
        const {
            onAddPackages,
            onRemovePackages,
        } = this.props;
        if (action === ACTION_ADD) {
            onAddPackages(selectedIds);
        } else if (action === ACTION_REMOVE) {
            onRemovePackages(selectedIds);
        }
    };

    getOptions = () => {
        const { classes } = this.props;
        const toolbarProps = {
            actions: ACTIONS,
            renderActionLabel: ACTIONS_LABELS,
            title: <div className={classes.emptyTitleDiv} />,
        };
        return {
            download: false,
            print: false,
            viewColumns: false,
            search: false,
            responsive: 'scroll',
            filterType: 'dropdown',
            pagination: false,
            filter: false,
            customToolbarSelect: (selectedRows, displayData) => {
                const selectedIds = getSelectedIds(selectedRows, displayData,
                    5);
                return (
                    <StyledCustomTableToolbar
                        {...toolbarProps}
                        onChange={({ target: { value: action } }) => this.onToolbarActionSelected(
                            action, selectedIds)}
                    />
                );
            },
        };
    };

    render () {
        const { data, loading } = this.props;
        const dataExists = !loading && data && data.length > 0;
        if (!loading && dataExists) {
            const tableData = data.map((packageData) => {
                const { name, type, updatedAt, states, editions, id, underwriterName = '' } = packageData;
                const stateNames = states.map((state) => {
                    return state.name;
                });
                const editionNames = editions.map(
                    (edition) => EDITION_NAMES[edition.name]);
                return (
                    [
                        formatDate(updatedAt),
                        type,
                        editionNames.join(', '),
                        stateNames.join(', '),
                        underwriterName,
                        name,
                        id,
                    ]
                );
            });
            return (
                <MUIDataTable
                    columns={this.getColumns()}
                    options={this.getOptions()}
                    data={tableData}
                />
            );
        } else if (loading) {
            return (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item component={CircularProgress} />
                </Grid>
            );
        } else {
            return (
                <div>
                    <Typography align="center">
                        There are no packages for your preferences at this time,
                        but
                        you can still browse and add packages
                        to your watchlist manually.
                    </Typography>
                </div>
            );
        }
    }
}

class OnBoardingWatchlist extends React.Component {
    componentDidMount () {
        this.props.fetchSuggestedWatchlist();
    }

    render () {
        const {
            selectedPackagesForWatchlist,
            onAddPackages,
            onRemovePackages,
            suggestedWatchlist,
            loading,
            classes,
        } = this.props;
        return (
            <Grid
                container
                direction="column"
                alignItems="stretch"
                wrap="nowrap"
                className={classes.watchlistStep}
            >
                <Grid item>
                    <Typography variant="h5" align="center" gutterBottom>
                        These are the available downloads that will be added to
                        your Watchlist.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        align="center"
                        className={classes.extraText}
                    >
                        Don&apos;t worry, you can always adjust this from your
                        Watchlist tab later.
                    </Typography>
                </Grid>
                <Grid item className={classes.watchlistTable}>
                    <OnBoardingWatchlistTable
                        classes={classes}
                        data={suggestedWatchlist}
                        loading={loading}
                        selectedPackagesForWatchlist={selectedPackagesForWatchlist}
                        onAddPackages={onAddPackages}
                        onRemovePackages={onRemovePackages}
                    />
                </Grid>
            </Grid>
        );
    }
}

OnBoardingWatchlist.propTypes = {
    classes: PropTypes.object.isRequired,
    fetchSuggestedWatchlist: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    suggestedWatchlist: PropTypes.array,
    selectedPackagesForWatchlist: PropTypes.array,
    onAddPackages: PropTypes.func,
    onRemovePackages: PropTypes.func,
};

export default withStyles(styles)(OnBoardingWatchlist);
