import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, useMediaQuery } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
    Email as NotificationsIcon,
    NotInterested as NoDownloadAllowedIcon,
} from '@mui/icons-material';
import styles from './styles';
import TablesPropTypes from '../../../propTypes/tables';
import {
    StyledAddButton,
    StyledDownloadButton,
    StyledNotifyCheckbox,
    StyledReleaseNotes,
    StyledTrash,
} from '../../TableComponents';
import {
    PACKAGES_FILTER_FIELD_STATE,
    PACKAGES_FILTER_FIELD_TYPE,
    PACKAGES_ORDER_BY_FIELD_NAME,
    PACKAGES_ORDER_BY_FIELD_TYPE,
    PACKAGES_ORDER_BY_FIELD_UPDATED_AT,
    PACKAGES_FILTER_FIELD_UNDERWRITER,
    PACKAGES_ORDER_BY_FIELD_UNDERWRITER_NAME,
    PACKAGES_ORDER_BY_FIELD_STATE_NAME,
    PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS,
} from '../../../constants/api';
import ApiPropTypes from '../../../propTypes/api';
import DataTableColumn from '../../DataTable/DataTableColumn';
import { SORT_ASC, SORT_DESC } from '../../DataTable/constants';
import SoftProDataTable from '../../SoftProDataTable';
import { PACKAGE_TYPE_NAMES } from '../../../constants/packageTypes';
import { noop } from '../../../utils';
import {
    TABLE_ACTION_ADD,
    TABLE_ACTION_DOWNLOAD,
    TABLE_ACTION_REMOVE,
    TABLE_ACTION_UPDATE_DO_NOT_NOTIFY,
    TABLE_ACTION_UPDATE_DO_NOTIFY,
} from '../../../constants/tables';
import { DEFAULT_DOWNLOAD_NOT_ALLOWED_TOOLTIP } from './constants';

const STATES_FILTER = { key: PACKAGES_FILTER_FIELD_STATE, label: 'State' };
const TYPE_FILTER = { key: PACKAGES_FILTER_FIELD_TYPE, label: 'Type' };
const UNDERWRITER_FILTER = {
    key: PACKAGES_FILTER_FIELD_UNDERWRITER,
    label: 'Underwriter',
};

const PACKAGE_TYPES_FILTER_OPTIONS = [
    { value: '', label: 'All Package Types' },
    ...Object.entries(PACKAGE_TYPE_NAMES).map(
        ([packageType, packageTypeName]) => ({
            label: packageTypeName,
            value: packageType,
        }),
    ),
];

class SoftProPackagesDataTable extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,

        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,
        onAddWatchlistRequested: PropTypes.func,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,

        loadingUpdate: PropTypes.arrayOf(PropTypes.number),
        title: PropTypes.node,
        availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object),
        availableUnderwritersForDataTableFilter: PropTypes.arrayOf(
            PropTypes.object,
        ),
        renderEmpty: PropTypes.func,
        hasTypeColumn: PropTypes.bool,
        hasStatesColumn: PropTypes.bool,
        hasAddButtonColumn: PropTypes.bool,
        hasUnderwriterColumn: PropTypes.bool,
        hasRemoveColumn: PropTypes.bool,
        hasEndorsementLookupsIncludedColumn: PropTypes.bool,
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.node,
            }),
        ),
        isLargeScreen: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        onAddWatchlistRequested: noop(),
        loadingUpdate: [],
        title: null,
        availableStatesForDataTableFilter: [],
        availableUnderwritersForDataTableFilter: [],
        renderEmpty: () => null,
        hasTypeColumn: false,
        hasStatesColumn: true,
        hasAddButtonColumn: false,
        hasUnderwriterColumn: true,
        hasEndorsementLookupsIncludedColumn: false,
        hasRemoveColumn: true,
        actions: [],
    };

    isLoading = (packageId) => this.props.loadingUpdate.includes(packageId);

    getKey = ({ id }) => id;

    isRowSelectable = (id) => !this.isLoading(id);

    getFilters = () => {
        const { hasTypeColumn, hasStatesColumn, hasUnderwriterColumn } =
            this.props;
        const filters = [];
        if (hasStatesColumn) {
            filters.push(STATES_FILTER);
        }
        if (hasTypeColumn) {
            filters.push(TYPE_FILTER);
        }
        if (hasUnderwriterColumn) {
            filters.push(UNDERWRITER_FILTER);
        }
        return filters;
    };

    getFilterOptions = ({ key }) => {
        if (key === PACKAGES_FILTER_FIELD_STATE) {
            return this.props.availableStatesForDataTableFilter;
        } else if (key === PACKAGES_FILTER_FIELD_TYPE) {
            return PACKAGE_TYPES_FILTER_OPTIONS;
        } else if (key === PACKAGES_FILTER_FIELD_UNDERWRITER) {
            return this.props.availableUnderwritersForDataTableFilter;
        }
        return [];
    };

    renderUpdatedAt = ({ updatedAt }) => updatedAt;
    renderType = ({ type }) => type;
    renderStates = ({ states }) => states;
    renderPackageName = ({ name }) => name;
    renderNotifications = ({ id, notify, watching }) => {
        const { onUpdateWatchlistRequested } = this.props;
        const loading = this.isLoading(id);
        return watching
            ? (
                <StyledNotifyCheckbox
                    checked={notify}
                    onClick={() =>
                        onUpdateWatchlistRequested(id, { notify: !notify })
                    }
                    disabled={loading}
                    text={loading ? 'Updating...' : 'Notify Me'}
                />
            )
            : null;
    };

    renderAddButton = ({ id, watching }) => {
        const { onAddWatchlistRequested, classes } = this.props;
        const loading = this.isLoading(id);
        const columnContent = !watching
            ? (
                <StyledAddButton
                    disabled={loading}
                    onClick={() => onAddWatchlistRequested(id)}
                />
            )
            : (
                this.renderRemoveButton({ id, watching }, true)
            );
        return <div className={classes.addColDiv}> {columnContent} </div>;
    };

    renderReleaseNotes = ({ id, releaseNotesUrl }) => {
        const loading = this.isLoading(id);
        return releaseNotesUrl
            ? (
                <StyledReleaseNotes disabled={loading} value={releaseNotesUrl} />
            )
            : null;
    };

    renderDownloadButton = ({
        id,
        name,
        latestRevision,
        downloadUrl,
        hasDownload,
        downloadNotAllowedMessage,
    }) => {
        const loading = this.isLoading(id);

        if (hasDownload && !downloadUrl) {
            /*
             * There is a download but the URL was not supplied,
             * so no button should appear but we should also indicate
             * they can't see it so we can taunt them.
             */
            return (
                <Tooltip
                    title={
                        downloadNotAllowedMessage ||
                        DEFAULT_DOWNLOAD_NOT_ALLOWED_TOOLTIP
                    }
                >
                    <NoDownloadAllowedIcon
                        classes={{
                            root: this.props.classes.noDownloadAllowedIcon,
                        }}
                    />
                </Tooltip>
            );
        }

        return downloadUrl
            ? (
                <StyledDownloadButton
                    disabled={loading}
                    key={id}
                    packageName={name}
                    downloadNotes={
                        latestRevision ? latestRevision.downloadNotes : null
                    }
                    downloadUrl={downloadUrl}
                />
            )
            : null;
    };

    renderEndorsementLookupsIncluded = ({ hasEndorsementLookupsIncluded }) => {
        return hasEndorsementLookupsIncluded ? 'x' : '';
    };

    renderRemoveButton = ({ id, watching }, inAddCol = false) => {
        const loading = this.isLoading(id);
        const { onRemoveWatchlistRequested } = this.props;
        return watching
            ? (
                <StyledTrash
                    disabled={loading}
                    key={id}
                    onClick={() => onRemoveWatchlistRequested(id)}
                    classes={
                        inAddCol
                            ? { trash: this.props.classes.trashIconInAddCol }
                            : {}
                    }
                />
            )
            : null;
    };

    renderUnderwriterName = ({ underwriterName }) => underwriterName;

    onActionSelect = (selectedIds, action) => {
        const {
            onUpdateWatchlistRequested,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
        } = this.props;
        if (action === TABLE_ACTION_REMOVE) {
            onRemoveWatchlistRequested(selectedIds);
        } else if (action === TABLE_ACTION_ADD) {
            onAddWatchlistRequested(selectedIds);
        } else if (action === TABLE_ACTION_UPDATE_DO_NOTIFY) {
            onUpdateWatchlistRequested(selectedIds, { notify: true });
        } else if (action === TABLE_ACTION_UPDATE_DO_NOT_NOTIFY) {
            onUpdateWatchlistRequested(selectedIds, { notify: false });
        } else if (action === TABLE_ACTION_DOWNLOAD) {
            onDownloadRequested(selectedIds);
        }
    };

    render () {
        const {
            tableData,
            tableContext,
            onFetchDataRequested,
            onUpdateWatchlistRequested,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
            title,
            renderEmpty,
            actions,

            classes,
            hasTypeColumn,
            hasStatesColumn,
            hasAddButtonColumn,
            hasUnderwriterColumn,
            hasEndorsementLookupsIncludedColumn,
            hasRemoveColumn,
            isLargeScreen,

            ...restProps
        } = this.props;

        const softProDataTableProps = {
            tableData,
            tableContext,
            onFetchDataRequested,
            onUpdateWatchlistRequested,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
            title,
            renderEmpty,
            actions,
            classes: { input: classes.input },

            getKey: this.getKey,
            isRowSelectable: this.isRowSelectable,
            filters: this.getFilters(),
            getFilterOptions: this.getFilterOptions,
            onActionSelect: this.onActionSelect,

            ...restProps,
        };

        return (
            <SoftProDataTable {...softProDataTableProps}>
                {({ createOnSort, createSortGetter }) => (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="Last Updated"
                            classes={{
                                headCell: classes.lastUpdatedCol,
                                bodyCell: classes.lastUpdatedCol,
                            }}
                            getSort={createSortGetter(
                                PACKAGES_ORDER_BY_FIELD_UPDATED_AT,
                            )}
                            onSort={createOnSort(
                                PACKAGES_ORDER_BY_FIELD_UPDATED_AT,
                            )}
                            initialSort={SORT_DESC}
                        >
                            {this.renderUpdatedAt}
                        </DataTableColumn>
                        {hasTypeColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Type"
                                    classes={{
                                        headCell: classes.typeCol,
                                        bodyCell: classes.typeCol,
                                    }}
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_TYPE,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_TYPE,
                                    )}
                                    initialSort={SORT_DESC}
                                >
                                    {this.renderType}
                                </DataTableColumn>
                            )
                            : null}
                        {hasStatesColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="State/Region"
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_STATE_NAME,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_STATE_NAME,
                                    )}
                                    initialSort={SORT_ASC}
                                    classes={{
                                        headCell: classes.stateCol,
                                        bodyCell: classes.stateCol,
                                    }}
                                >
                                    {this.renderStates}
                                </DataTableColumn>
                            )
                            : null}
                        {hasUnderwriterColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Underwriter Name"
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_UNDERWRITER_NAME,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_UNDERWRITER_NAME,
                                    )}
                                    initialSort={SORT_ASC}
                                    classes={{
                                        headCell: classes.underwriterNameCol,
                                        bodyCell: classes.underwriterNameCol,
                                    }}
                                >
                                    {this.renderUnderwriterName}
                                </DataTableColumn>
                            )
                            : null}
                        <DataTableColumn
                            headCellContent="Package Name"
                            classes={{
                                headCell: classes.packageNameCol,
                                bodyCell: classes.packageNameCol,
                            }}
                            getSort={createSortGetter(
                                PACKAGES_ORDER_BY_FIELD_NAME,
                            )}
                            onSort={createOnSort(PACKAGES_ORDER_BY_FIELD_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {this.renderPackageName}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent={
                                isLargeScreen
                                    ? (
                                        'Notifications'
                                    )
                                    : (
                                        <NotificationsIcon
                                            className={classes.notificationsIcon}
                                        />
                                    )
                            }
                            classes={{
                                headCell: classes.notificationsCol,
                                bodyCell: classes.notificationsCol,
                            }}
                        >
                            {this.renderNotifications}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent={isLargeScreen ? 'Rel. Notes' : ''}
                            classes={{
                                headCell: classes.relNotesCol,
                                bodyCell: classes.relNotesCol,
                            }}
                        >
                            {this.renderReleaseNotes}
                        </DataTableColumn>
                        {hasEndorsementLookupsIncludedColumn
                            ? (
                                <DataTableColumn
                                    headCellContent="Endorsement Lookups Included"
                                    classes={{
                                        headCell: classes.downloadNotesCol,
                                        bodyCell: classes.downloadNotesCol,
                                    }}
                                    className="text-center"
                                    getSort={createSortGetter(
                                        PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS,
                                    )}
                                    onSort={createOnSort(
                                        PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS,
                                    )}
                                    initialSort={SORT_DESC}
                                >
                                    {this.renderEndorsementLookupsIncluded}
                                </DataTableColumn>
                            )
                            : null}
                        {hasAddButtonColumn
                            ? (
                                <DataTableColumn
                                    classes={{
                                        headCell: classes.addCol,
                                        bodyCell: classes.addCol,
                                    }}
                                    headCellContent="Watchlist"
                                >
                                    {this.renderAddButton}
                                </DataTableColumn>
                            )
                            : null}
                        <DataTableColumn
                            classes={{
                                headCell: classes.downloadCol,
                                bodyCell: classes.downloadCol,
                            }}
                            headCellContent="Download"
                        >
                            {this.renderDownloadButton}
                        </DataTableColumn>
                        {hasRemoveColumn
                            ? (
                                <DataTableColumn
                                    classes={{
                                        headCell: classes.removeCol,
                                        bodyCell: classes.removeCol,
                                    }}
                                    headCellContent="Watchlist"
                                >
                                    {this.renderRemoveButton}
                                </DataTableColumn>
                            )
                            : null}
                    </Fragment>
                )}
            </SoftProDataTable>
        );
    }
}

const SoftProPackagesDataTableFunction = (props) => {
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    return (
        <SoftProPackagesDataTable {...props} isLargeScreen={isLargeScreen} />
    );
};

export default withStyles(styles)(SoftProPackagesDataTableFunction);
