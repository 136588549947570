import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import AdminButton from '../AdminButton';
import styles from './styles';

class ConfirmDialog extends Component {
    render () {
        const {
            classes,
            title,
            text,
            subText,
            onConfirm,
            onCancel,
            additionalContent,
            confirmButtonText,
            cancelButtonText,
            ...props
        } = this.props;
        return (
            <Dialog PaperProps={{ className: classes.contentPaper }} {...props}>
                <DialogTitle className={classes.title}>
                    {title}
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText
                        variant="body1"
                        gutterBottom
                        className={classNames([
                            classes.contentText,
                            classes.text,
                        ])}
                    >
                        {text}
                    </DialogContentText>
                    <DialogContentText
                        variant="body2"
                        gutterBottom
                        className={classNames([
                            classes.contentText,
                            classes.subText,
                        ])}
                    >
                        {subText}
                    </DialogContentText>
                    {additionalContent && (
                        <DialogContentText gutterBottom>
                            {additionalContent}
                        </DialogContentText>
                    )}
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            spacing={24}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <AdminButton
                                    size="large"
                                    onClick={onConfirm}
                                    className={classes.button}
                                >
                                    {confirmButtonText}
                                </AdminButton>
                            </Grid>
                            <Grid item>
                                <AdminButton
                                    color="secondary"
                                    size="large"
                                    onClick={onCancel}
                                    className={classes.button}
                                >
                                    {cancelButtonText}
                                </AdminButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

ConfirmDialog.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The title for the dialog.
    title: PropTypes.string.isRequired,

    // The text that will be displayed inside the dialog in bold.
    text: PropTypes.string,

    // The text that will be displayed in the dialog, below the text.
    subText: PropTypes.string,

    // Function that will be called when the Confirm button is clicked.
    onConfirm: PropTypes.func.isRequired,

    // Function that will be called when the Cancel button is clicked.
    onCancel: PropTypes.func.isRequired,

    // Any additional content to be rendered below the subText.
    additionalContent: PropTypes.node,

    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
};

ConfirmDialog.defaultProps = {
    // By default, the dialog is not open.
    open: false,

    text: '',
    subText: '',
    additionalContent: null,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
};

export default withStyles(styles)(ConfirmDialog);
