export const styles = (theme) => ({
    title: {
        marginBottom: 16,
    },
    recentDownloads: {
        display: 'flex',
        flexDirection: 'column',
        ...theme.softpro.safeBox,
        marginTop: 0,
    },
    downloadButtonColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dateTitle: {
        marginLeft: 'unset',
    },
    typeCol: theme.softpro.tableColumns.typeCol,
    stateCol: theme.softpro.tableColumns.stateCol,
    packageNameCol: theme.softpro.tableColumns.packageNameCol,
    downloadedAtCol: theme.softpro.tableColumns.downloadedAtCol,
    downloadCol: {
        ...theme.softpro.tableColumns.downloadCol,
        textAlign: 'right',
    },
    underwriterNameCol: {
        ...theme.softpro.tableColumns.underwriterNameCol,
    },
});
