export const PACKAGE_TYPE_STATE = 'State';
export const PACKAGE_TYPE_NATIONAL = 'National';
export const PACKAGE_TYPE_LAND_TITLE = 'Land Title';
export const PACKAGE_TYPE_UNDERWRITER = 'Underwriter';
export const PACKAGE_TYPES = [
    PACKAGE_TYPE_STATE,
    PACKAGE_TYPE_NATIONAL,
    PACKAGE_TYPE_LAND_TITLE,
    PACKAGE_TYPE_UNDERWRITER,
];

export default PACKAGE_TYPES;

export const PACKAGE_TYPE_NAMES = {
    [PACKAGE_TYPE_UNDERWRITER]: 'Underwriter',
    [PACKAGE_TYPE_STATE]: 'State',
    [PACKAGE_TYPE_NATIONAL]: 'National',
    [PACKAGE_TYPE_LAND_TITLE]: 'Land Title',
};

export const PACKAGE_TYPE_EMPTY_TEXT = {
    [PACKAGE_TYPE_UNDERWRITER]: 'You don\'t have any Underwriter packages in your watchlist.',
    [PACKAGE_TYPE_STATE]: 'You don\'t have any State packages in your watchlist.',
    [PACKAGE_TYPE_NATIONAL]: 'You don\'t have any National packages in your watchlist.',
    [PACKAGE_TYPE_LAND_TITLE]: 'You don\'t have any Land Title packages in your watchlist.',
};

const PACKAGES = ' Packages';
export const DIALOG_TITLE_LAND_TITLE = PACKAGE_TYPE_LAND_TITLE + PACKAGES;
export const DIALOG_TITLE_STATE = PACKAGE_TYPE_STATE + PACKAGES;
export const DIALOG_TITLE_UNDERWRITER = PACKAGE_TYPE_UNDERWRITER + PACKAGES;
export const DIALOG_TITLE_NATIONAL = PACKAGE_TYPE_NATIONAL + PACKAGES;

export const DIALOG_TITLES = {
    [PACKAGE_TYPE_LAND_TITLE]: DIALOG_TITLE_LAND_TITLE,
    [PACKAGE_TYPE_STATE]: DIALOG_TITLE_STATE,
    [PACKAGE_TYPE_UNDERWRITER]: DIALOG_TITLE_UNDERWRITER,
    [PACKAGE_TYPE_NATIONAL]: DIALOG_TITLE_NATIONAL,
};

export const DIALOG_TEXT_LAND_TITLE = (
    'A Land Title package contains specific Land Title documents, such as ALTA or TLTA or CLTA documents.'
);
export const DIALOG_TEXT_STATE = (
    'A State packages contains State specific documents, typically recordable documents, and sometimes State reports as well.'
);
export const DIALOG_TEXT_UNDERWRITER = (
    'An Underwriter package contains specific Underwriter branded title documents.'
);
export const DIALOG_TEXT_NATIONAL = (
    'A National package contains specific documents another package may reference and is therefore noted in the other package ' +
    'as a prerequisite for installation.'
);

export const DIALOG_TEXT = {
    [PACKAGE_TYPE_LAND_TITLE]: DIALOG_TEXT_LAND_TITLE,
    [PACKAGE_TYPE_STATE]: DIALOG_TEXT_STATE,
    [PACKAGE_TYPE_UNDERWRITER]: DIALOG_TEXT_UNDERWRITER,
    [PACKAGE_TYPE_NATIONAL]: DIALOG_TEXT_NATIONAL,
};
