export const styles = (theme) => ({
    ...theme.softpro.tableColumns,
    adminDashboard: theme.softpro.safeBox,
    chartContainer: {
        height: 250,
        width: '100%',
        flex: 1,
        padding: '0 20px 0 0',
        [theme.breakpoints.up('xl')]: {
            height: 320,
        },
    },
    chartHeader: {
        padding: '0 20px 20px 20px',
    },
    chartHeaderCountText: {
        fontWeight: 'bold',
        fontSize: 64,
    },
    chartHeaderText: {
        marginLeft: '0.5em',
        marginBottom: '0.6em',
    },
    downloadsColumn: {
        fontWeight: 'bold',
    },
    orgColumnHeader: {
        width: 550,
    },
    viewButton: {
        width: 150,
        height: 22,
        minHeight: 22,
        margin: 0,
        padding: 0,
    },
    viewButtonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topUsersTitle: {
        marginLeft: 'unset',
    },
    topGrid: {
        width: '95%',
        marginBottom: 35,
        marginLeft: 30,
    },
    topUsersTable: {
        width: '95%',
        marginLeft: 40,
    },
    emptyChartContainer: {
        paddingLeft: 30,
        paddingBottom: 20,
    },
    emptyChart: {
        backgroundColor: '#DFDFDF',
        padding: 20,
        textAlign: 'center',
        flexGrow: 1,
    },
    emptyUsersTable: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#DFDFDF',
        alignItems: 'center',
        padding: '40px 0px',
    },
    emptyUsersDiv: {
        marginLeft: '-30px',
    },
    table: {
        flexGrow: 1,
    },
});
