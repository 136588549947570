import { theme } from '../../theme';

export const styles = {
    root: {},
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'scroll',
    },
    connector: {
        display: 'none',
        width: 0,
    },
    activeStepIcon: {
        backgroundColor: '#002244',
        color: 'white',
        padding: 4,
        height: 18,
        width: 18,
        fontSize: 16,
        borderRadius: 15,
        border: '1px solid white',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    inactiveStepIcon: {
        backgroundColor: '#DFDFDF',
        padding: 4,
        height: 18,
        width: 18,
        fontSize: 16,
        borderRadius: 15,
        border: '1px solid #303233',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    navButtons: {
        padding: '20px 0',
        flex: 0,
        borderTop: '1px solid #DFDFDF',
    },
    topText: {
        fontWeight: 'bold',
    },
    letsGetStarted: {
        marginBottom: 40,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 10,
        },
    },
    whiteText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '3em',
    },
    button: {
        marginTop: 0,
        marginLeft: 15,
        marginRight: 15,
    },
    dialogActions: {
        padding: '20px 0',
    },
    stepperContainer: {
        backgroundColor: '#FFF',
        width: 'auto',
        marginLeft: 10,
        marginRight: 10,
        height: 'auto',
        marginBottom: 10,
        [theme.breakpoints.up('lg')]: {
            width: 1200,
        },
    },
    finalStepLoading: {
        padding: 40,
        textAlign: 'center',
    },
    stepContent: {
        flex: 1,
        overflow: 'auto',
        maxHeight: 300,
        paddingTop: 10,
        paddingBottom: 10,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 500,
            paddingTop: 20,
            paddingBottom: 20,
        },
    },
    stepContainer: {
        [theme.breakpoints.up('lg')]: {
            minHeight: 500,
        },
    },
    disclaimerText: {
        color: '#ffffff',
        textAlign: 'center',
    },
};
