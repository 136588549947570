import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NationalTitle, ViewInfoButton } from '../TableTitles/index';
import { DIALOG_TEXT, DIALOG_TITLES, PACKAGE_TYPE_NATIONAL } from '../../constants/packageTypes';
import TablesPropTypes from '../../propTypes/tables';
import {
    doNationalPackagesHaveFilters,
    getNationalPackagesContext,
    getNationalPackagesForTable,
} from '../../selectors/packages';
import BrowseByType from '../BrowseByType';
import ApiPropTypes from '../../propTypes/api';

class BrowseNationalPackages extends React.Component {
    state = {
        infoDialogOpen: false,
    };

    onClickViewInfoButton = () => {
        this.setState({ infoDialogOpen: true });
    };

    onCloseInfoDialog = () => {
        this.setState({ infoDialogOpen: false });
    };

    render () {
        const { infoDialogOpen } = this.state;
        const ViewInfoButtonNational = (
            <ViewInfoButton
                onClick={this.onClickViewInfoButton}
                infoDialogOpen={infoDialogOpen}
                onCloseInfoDialog={this.onCloseInfoDialog}
                dialogTitle={DIALOG_TITLES[PACKAGE_TYPE_NATIONAL]}
                dialogText={DIALOG_TEXT[PACKAGE_TYPE_NATIONAL]}
            />);
        return (
            <BrowseByType
                type={PACKAGE_TYPE_NATIONAL}
                title={<NationalTitle button={ViewInfoButtonNational} />}
                {...this.props}
            />
        );
    }
}

BrowseNationalPackages.propTypes = {
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: ApiPropTypes.apiPageContext.isRequired,
    hasFilters: PropTypes.bool.isRequired,
};

const mapStateToProps = function (state) {
    return {
        tableData: getNationalPackagesForTable(state),
        tableContext: getNationalPackagesContext(state),
        hasFilters: doNationalPackagesHaveFilters(state),
    };
};

export default connect(mapStateToProps)(BrowseNationalPackages);
