import { LOGIN_SUCCEEDED } from '../auth/actions';

const downloadInitialState = {
    promptsFileUrl: null,
};

export default function download (state = downloadInitialState, action) {
    if (action.type === LOGIN_SUCCEEDED) {
        return {
            ...state,
            promptsFileUrl: action.promptsFileUrl,
        };
    }
    return state;
}
