import { RECENT_DOWNLOADS_PER_PAGE_DEFAULT } from '../../constants/api';

export const PREFIX = 'recentDownloads/';
export const RECENT_DOWNLOADS_REQUESTED = `${PREFIX}RECENT_DOWNLOADS_REQUESTED`;
export const RECENT_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED = `${PREFIX}RECENT_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED`;
export const RECENT_DOWNLOADS_FOR_USERS_EDITIONS_REQUESTED = `${PREFIX}RECENT_DOWNLOADS_FOR_USERS_EDITIONS_REQUESTED`;
export const RECENT_DOWNLOADS_REQUEST_SUCCEEDED = `${PREFIX}RECENT_DOWNLOADS_REQUEST_SUCCEEDED`;
export const RECENT_DOWNLOADS_REQUEST_FAILED = `${PREFIX}RECENT_DOWNLOADS_REQUEST_FAILED`;

export function recentDownloadsRequested (edition, page = 1, perPage = RECENT_DOWNLOADS_PER_PAGE_DEFAULT) {
    return {
        type: RECENT_DOWNLOADS_REQUESTED,
        edition,
        page,
        perPage,
    };
}

export function recentDownloadsForCurrentEditionRequested (...args) {
    return {
        type: RECENT_DOWNLOADS_FOR_CURRENT_EDITION_REQUESTED,
        args,
    };
}

export function recentDownloadsForUsersEditionsRequested (...args) {
    return {
        type: RECENT_DOWNLOADS_FOR_USERS_EDITIONS_REQUESTED,
        args,
    };
}

export function recentDownloadsRequestSucceeded (edition, data, page, total, perPage) {
    return {
        type: RECENT_DOWNLOADS_REQUEST_SUCCEEDED,
        edition,
        data,
        page,
        total,
        perPage,
    };
}

export function recentDownloadsRequestFailed () {
    return {
        type: RECENT_DOWNLOADS_REQUEST_FAILED,
    };
}
