import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from '../../utils';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BrowseAllButton, LandTitleTitle, NationalTitle, StateTitle, UnderwriterTitle } from '../TableTitles';
import { styles } from './styles';
import {
    fetchWatchlist,
    fetchWatchlistForCurrentEdition,
    removeWatchlist,
    updateWatchlist,
} from '../../stores/watchlist/actions';
import {
    doesLandTitleCurrentlyHaveFilters,
    doesNationalCurrentlyHaveFilters,
    doesStateCurrentlyHaveFilters,
    doesUnderwriterCurrentlyHaveFilters,
    getCurrentLandTitleContext,
    getCurrentNationalContext,
    getCurrentStateContext,
    getCurrentUnderwriterContext,
    getLandTitlePackagesForTables,
    getNationalPackagesForTables,
    getStatePackagesForTables,
    getUnderwriterPackagesForTables,
} from '../../selectors/watchlist';
import PACKAGE_TYPES, {
    PACKAGE_TYPE_EMPTY_TEXT,
    PACKAGE_TYPE_LAND_TITLE,
    PACKAGE_TYPE_NATIONAL,
    PACKAGE_TYPE_STATE,
    PACKAGE_TYPE_UNDERWRITER,
} from '../../constants/packageTypes';
import EDITIONS, { EDITION_SELECT } from '../../constants/editions';
import TablesPropTypes from '../../propTypes/tables';
import WatchlistTable from '../WatchlistTable';
import { getAvailableStatesForDataTableFilter } from '../../selectors/states';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';
import ApiPropTypes from '../../propTypes/api';
import { downloadMultiplePackages, downloadPackage } from '../../stores/download/actions';
import { DISCLAIMER_TEXT } from '../../constants/disclaimer';
import { useNavigate } from 'react-router';
import { getEdition } from '../../selectors/edition';

class Watchlist extends Component {
    static propTypes = {
        underwriterTableData: TablesPropTypes.tablePageData.isRequired,
        stateTableData: TablesPropTypes.tablePageData.isRequired,
        nationalTableData: TablesPropTypes.tablePageData.isRequired,
        landTitleTableData: TablesPropTypes.tablePageData.isRequired,

        underwriterTableContext: ApiPropTypes.apiPageContext.isRequired,
        stateTableContext: ApiPropTypes.apiPageContext.isRequired,
        nationalTableContext: ApiPropTypes.apiPageContext.isRequired,
        landTitleTableContext: ApiPropTypes.apiPageContext.isRequired,

        loadingUpdate: PropTypes.arrayOf(PropTypes.number).isRequired,
        navigate: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
        availableUnderwritersForDataTableFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
        onFetchAllWatchlistsRequested: PropTypes.func.isRequired,
        onFetchWatchlistRequested: PropTypes.func.isRequired,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,

        underwriterTableHasFilters: PropTypes.bool.isRequired,
        stateTableHasFilters: PropTypes.bool.isRequired,
        nationalTableHasFilters: PropTypes.bool.isRequired,
        landTitleTableHasFilters: PropTypes.bool.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,
        edition: PropTypes.string.isRequired,
    };

    onBrowseAllClick = (which) => {
        this.props.navigate(`/portal/browseAll/${which}`);
    };

    onUnderwritersBrowseAllClick = () => this.onBrowseAllClick('underwriterPackages');
    onStateBrowseAllClick = () => this.onBrowseAllClick('statePackages');
    onNationalBrowseAllClick = () => this.onBrowseAllClick('nationalPackages');
    onLandTitleBrowseAllClick = () => this.onBrowseAllClick('landTitlePackages');

    onUnderwritersFetchDataRequested = (context = {}) => {
        return this.props.onFetchWatchlistRequested(PACKAGE_TYPE_UNDERWRITER, context);
    };

    onStateFetchDataRequested = (context = {}) => {
        return this.props.onFetchWatchlistRequested(PACKAGE_TYPE_STATE, context);
    };

    onNationalFetchDataRequested = (context = {}) => {
        return this.props.onFetchWatchlistRequested(PACKAGE_TYPE_NATIONAL, context);
    };

    onLandTitleFetchDataRequested = (context = {}) => {
        return this.props.onFetchWatchlistRequested(PACKAGE_TYPE_LAND_TITLE, context);
    };

    renderUnderwritersTitle = () => (
        <UnderwriterTitle button={<BrowseAllButton handleBrowseAllButtonClick={this.onUnderwritersBrowseAllClick} />} />
    );

    renderStateTitle = () => (
        <StateTitle button={<BrowseAllButton handleBrowseAllButtonClick={this.onStateBrowseAllClick} />} />
    );

    renderNationalTitle = () => (
        <NationalTitle button={<BrowseAllButton handleBrowseAllButtonClick={this.onNationalBrowseAllClick} />} />
    );

    renderLandTitleTitle = () => (
        <LandTitleTitle button={<BrowseAllButton handleBrowseAllButtonClick={this.onLandTitleBrowseAllClick} />} />
    );

    componentDidMount () {
        this.props.onFetchAllWatchlistsRequested();
    }

    render () {
        const {
            classes,
            underwriterTableData,
            stateTableData,
            nationalTableData,
            landTitleTableData,
            underwriterTableContext,
            stateTableContext,
            nationalTableContext,
            landTitleTableContext,
            underwriterTableHasFilters,
            stateTableHasFilters,
            nationalTableHasFilters,
            landTitleTableHasFilters,
            loadingUpdate,
            onUpdateWatchlistRequested,
            onRemoveWatchlistRequested,
            availableStatesForDataTableFilter,
            onDownloadRequested,
            availableUnderwritersForDataTableFilter,
            edition,
        } = this.props;
        const commonTableProps = {
            loadingUpdate,
            onUpdateWatchlistRequested,
            onRemoveWatchlistRequested,
            availableStatesForDataTableFilter,
            onDownloadRequested,
            availableUnderwritersForDataTableFilter,
        };
        const hasEndorsementLookupsIncludedColumn = edition === EDITION_SELECT;
        return (
            <div className={classes.watchlist}>
                <Typography variant="h1" className={classes.watchlistTitle}>My Watchlist</Typography>
                <WatchlistTable
                    {...commonTableProps}
                    tableData={underwriterTableData}
                    tableContext={underwriterTableContext}
                    hasFilters={underwriterTableHasFilters}
                    onFetchDataRequested={this.onUnderwritersFetchDataRequested}
                    renderTitle={this.renderUnderwritersTitle}
                    onBrowseAllClick={this.onUnderwritersBrowseAllClick}
                    emptyText={PACKAGE_TYPE_EMPTY_TEXT[PACKAGE_TYPE_UNDERWRITER]}
                    hasEndorsementLookupsIncludedColumn={hasEndorsementLookupsIncludedColumn}
                />
                <WatchlistTable
                    {...commonTableProps}
                    tableData={stateTableData}
                    tableContext={stateTableContext}
                    hasFilters={stateTableHasFilters}
                    onFetchDataRequested={this.onStateFetchDataRequested}
                    renderTitle={this.renderStateTitle}
                    onBrowseAllClick={this.onStateBrowseAllClick}
                    emptyText={PACKAGE_TYPE_EMPTY_TEXT[PACKAGE_TYPE_STATE]}
                    hasEndorsementLookupsIncludedColumn={hasEndorsementLookupsIncludedColumn}
                />
                <WatchlistTable
                    {...commonTableProps}
                    tableData={nationalTableData}
                    tableContext={nationalTableContext}
                    hasFilters={nationalTableHasFilters}
                    onFetchDataRequested={this.onNationalFetchDataRequested}
                    renderTitle={this.renderNationalTitle}
                    onBrowseAllClick={this.onNationalBrowseAllClick}
                    emptyText={PACKAGE_TYPE_EMPTY_TEXT[PACKAGE_TYPE_NATIONAL]}
                    hasEndorsementLookupsIncludedColumn={hasEndorsementLookupsIncludedColumn}
                />
                <WatchlistTable
                    {...commonTableProps}
                    tableData={landTitleTableData}
                    tableContext={landTitleTableContext}
                    hasFilters={landTitleTableHasFilters}
                    onFetchDataRequested={this.onLandTitleFetchDataRequested}
                    renderTitle={this.renderLandTitleTitle}
                    onBrowseAllClick={this.onLandTitleBrowseAllClick}
                    emptyText={PACKAGE_TYPE_EMPTY_TEXT[PACKAGE_TYPE_LAND_TITLE]}
                    hasEndorsementLookupsIncludedColumn={hasEndorsementLookupsIncludedColumn}
                />
                <Typography>
                    {DISCLAIMER_TEXT}
                </Typography>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    underwriterTableData: getUnderwriterPackagesForTables(state),
    stateTableData: getStatePackagesForTables(state),
    nationalTableData: getNationalPackagesForTables(state),
    landTitleTableData: getLandTitlePackagesForTables(state),
    underwriterTableContext: getCurrentUnderwriterContext(state),
    stateTableContext: getCurrentStateContext(state),
    nationalTableContext: getCurrentNationalContext(state),
    landTitleTableContext: getCurrentLandTitleContext(state),
    underwriterTableHasFilters: doesUnderwriterCurrentlyHaveFilters(state),
    stateTableHasFilters: doesStateCurrentlyHaveFilters(state),
    nationalTableHasFilters: doesNationalCurrentlyHaveFilters(state),
    landTitleTableHasFilters: doesLandTitleCurrentlyHaveFilters(state),
    loadingUpdate: state.watchlist.loadingUpdate,
    availableStatesForDataTableFilter: getAvailableStatesForDataTableFilter(state),
    availableUnderwritersForDataTableFilter: getAvailableUnderwritersForDataTableFilter(state),
    edition: getEdition(state),
});

const mapDispatchToProps = (dispatch) => ({
    onFetchAllWatchlistsRequested: () => {
        for (const edition of EDITIONS) {
            for (const packageType of PACKAGE_TYPES) {
                dispatch(fetchWatchlist(edition, packageType));
            }
        }
    },
    onFetchWatchlistRequested: (packageType, context) => {
        dispatch(fetchWatchlistForCurrentEdition(packageType, context));
    },
    onUpdateWatchlistRequested: (...args) => dispatch(updateWatchlist(...args)),
    onRemoveWatchlistRequested: (packageIds) => dispatch(removeWatchlist(packageIds)),
    onDownloadRequested: (packageIds) => {
        return packageIds && packageIds.length > 1
            ? dispatch(downloadMultiplePackages(packageIds))
            : dispatch(downloadPackage(packageIds[0]));
    },
});

const WatchlistFunction = (props) => {
    const navigate = useNavigate();
    return <Watchlist {...props} navigate={navigate} />;
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withStyles(styles),
)(WatchlistFunction);
