export default (theme) => ({
    ...theme.softpro.tableColumns,
    watchlistStep: {
        width: 'inherit',
        height: 'auto',
        flexGrow: 1,
    },
    extraText: {
        fontSize: 14,
    },
    watchlistTable: {
        width: 'auto',
        marginLeft: 10,
        marginRight: 10,
        maxHeight: 250,
    },
    addButton: {
        height: 22,
        width: 175,
        letterSpacing: 1,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        fontSize: 10,
    },
    addColumn: {
        // Must do this so the table header style matches the MUIDataTableHeaderCell style
        top: 0,
        left: 0,
        zIndex: 100,
        position: 'sticky',
        backgroundColor: 'White',
    },
    addButtonDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: 185,
    },
    icon: {
        marginLeft: 5,
        marginBottom: 2,
        width: 14,
        height: 14,
        fontWeight: 'bold',
    },
    actions: {
        width: 112,
        height: 30,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 12,
    },
    emptyTitleDiv: {
        width: '95%',
    },
});
