import { DEFAULT_ROWS_PER_PAGE } from './admin';

const msPerDay = 1000 * 60 * 60 * 24;
const end = new Date();
const start = new Date();
// Month is 0-11 and day is 1-31
start.setTime(end.getTime() - (90 * msPerDay)); // Set default time to 90 days before now
export const DEFAULT_START_DATE = start;
const days = Math.round((end.getTime() - start.getTime()) / (msPerDay));
export const DEFAULT_DAY_RANGE = days;
export const dayRangeLabel = (dayRange) => 'Selected ' + dayRange + ' Days';
export const ROWS_PER_PAGE_OPTIONS = [DEFAULT_ROWS_PER_PAGE, 15, 20];

export const USERS = 'users';
export const DOWNLOADS = 'downloads';
export const NOTIFICATIONS = 'notifications';
export const UPLOADS = 'uploads';
