import { DateTime } from 'luxon';
import { matchPath } from 'react-router';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import React from 'react';

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export function camelCaseToTitleCase (camelCase) {
    if (camelCase == null || camelCase === '') {
        return camelCase;
    }

    camelCase = camelCase.trim();
    let newText = '';
    for (let i = 0; i < camelCase.length; i++) {
        if (/[A-Z]/.test(camelCase[i]) &&
            i !== 0 &&
            /[a-z]/.test(camelCase[i - 1])) {
            newText += ' ';
        }
        if (i === 0 && /[a-z]/.test(camelCase[i])) {
            newText += camelCase[i].toUpperCase();
        } else {
            newText += camelCase[i];
        }
    }

    return newText;
}

export function noop () {
    // This function intentionally does nothing and intentionally opts in to implicit undefined `return`.
}

export function formatDate (dateString, format = 'MM.dd.yyyy') {
    return DateTime.fromISO(dateString.split(' ').join('T')).toLocal().toFormat(format);
}

export function formatDateToTimestamp (dateString) {
    return DateTime.fromISO(dateString.split(' ')[0].concat('T00:00:00')).toLocal().toMillis();
}

/**
 * Converts dateString given into a Simple Time in the user's local TZ.
 * Assumes dateString given as input is a time in UTC because it came from the server,
 * unless otherwise specified.
 *
 * @param dateString
 * @param zone of the input dateString, Defaults to UTC
 * @returns {string} the time in the users locale timezone
 */
export function formatDateAsTimeOnly (dateString, zone = 'utc') {
    return DateTime.fromISO(dateString.split(' ').join('T'), { zone }).toLocal().toLocaleString(DateTime.TIME_SIMPLE);
}

/**
 * For use with the Recent Downloads API data.
 * Converts the key specified to two new keys,
 * one that is the local date and another that is the local time.
 * The original input key will be converted to the local date,
 * and (if indicated via includeTimeKey) a new key will be added that is the given key + "Time" with a value
 * that is the local time in the simple time format.
 *
 * @param data
 * @param key
 * @param includeTimeKey if true (default) will add another key to each value that is the local time
 * @returns {Array}
 */
export function convertDatesToLocal (data = [], key = 'occurredAt', includeTimeKey = true) {
    return data.map((object) => {
        if (has(object, key)) {
            const withFormattedDate = { ...object, [key]: formatDate(object[key]) };
            return includeTimeKey
                ? { ...withFormattedDate, [`${key}Time`]: formatDateAsTimeOnly(object[key]) }
                : withFormattedDate;
        }
        return object;
    });
}

/**
 * Combines two arrays and filters out any duplicates.
 *
 * @param array1
 * @param array2
 * @returns {T[]}
 */
export function combine (array1, array2) {
    const combined = array1.concat(array2);
    return combined.filter(function (item, position) {
        return combined.indexOf(item) === position;
    });
}

/**
 * Remove all entries in toRemove array from array.
 * @param array
 * @param toRemove
 */
export function remove (array, toRemove) {
    return array.filter(function (entry) {
        return !toRemove.includes(entry);
    });
}

/**
 * Safely determine if an object has a key.
 *
 * @see https://eslint.org/docs/rules/no-prototype-builtins
 * @see Object.prototype.hasOwnProperty
 *
 * @param {{}} obj
 * @param {string} key
 */
export function has (obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key);
}

/**
 * Returns the sum of all of the totals in the given data.
 *
 * @param data
 * @returns {*}
 */
export const sumTotals = (data) => {
    return data ? data.reduce((total, obj) => obj.total + total, 0) : 0;
};

/**
 *  Condense object that contains arrays as each key's value down to unique values of the given key.
 *
 * @param object
 * @param key
 */
export const unique = (object, key) => {
    return Object.entries(object).reduce((uniqueObject, [objKey, objVal]) => {
        return { ...uniqueObject, [objKey]: uniqueArray(objVal, key) };
    }, {});
};

/**
 *  Condense array of objects down to only unique value of the given key.
 * @param array
 * @param key
 * @returns {*}
 */
export const uniqueArray = (array, key) => {
    return array.map((e) => e[key])
    // Store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // Eliminate the dead keys & store unique objects
        .filter((e) => array[e]).map((e) => array[e]);
};

/**
 * If value is an object and contains the specified property, that will be returned.
 * Otherwise the default value will be returned. By default the default value is the value
 * itself.
 *
 * @param {*} value
 * @param {string} prop
 * @param {*} def
 * @return {string}
 */
export const extractStringProperty = (value, prop, def = value) => {
    return (value && typeof value === 'object' && has(value, prop))
        ? `${value[prop]}`
        : `${def}`;
};

/**
 * If value is an object and contains the specified property, and that property is an array,
 * it will be returned. Otherwise the default value will be returned which is an empty array
 * by default.
 *
 * @param {*} value
 * @param {string} prop
 * @param {*} def
 * @return {*[]}
 */
export const extractArrayProperty = (value, prop, def = []) => {
    return (value && typeof value === 'object' && has(value, prop) && Array.isArray(value[prop]))
        ? value[prop]
        : def;
};

/**
 * Reduces object, removes keys specified in the omitKeys array
 *
 * @param obj
 * @param omitKeys
 * @returns {{}}
 */
export const omit = (obj, omitKeys = []) => {
    return Object.keys(obj).reduce((result, key) => {
        if (!omitKeys.includes(key)) {
            result[key] = obj[key];
        }
        return result;
    }, {});
};

/**
 * Returns true if the path given matches any of the
 * paths in the paths array. (Not exact and not strict.)
 * @param path
 * @param paths
 * @returns {boolean}
 */
export const matchesPaths = (path, paths = []) => {
    return paths.reduce(
        (accumulator = false, testPath) => {
            return accumulator || matchPath({ path: testPath }, path) !== null;
        }, false,
    );
};

/**
 * Determine if all of an objects values are empty.
 *
 * If a value is an array, it's considered empty if it has no items.
 *
 * If a value is not an array, it's considered empty if it's falsy.
 *
 * @param {{}} obj
 * @return {boolean}
 */
export const objectValuesAreEmpty = (obj) => {
    const valueCount = Object.values(obj)
        .reduce((count, item) => count + (Array.isArray(item) ? item.length : (item ? 1 : 0)), 0);
    return valueCount === 0;
};

export const isClosingPassportPreferred = () => {
    const preferClosingPassport = process.env.REACT_APP_PREFER_CLOSING_PASSPORT;
    const closingPassportUrl = process.env.REACT_APP_CLOSING_PASSPORT_URL;
    return preferClosingPassport !== '0' && preferClosingPassport !==
    'false' && closingPassportUrl;
};

export const getDateString = (date, range) => {
    const startDate = DateTime.fromISO(date);
    const endDate = DateTime.fromISO(date).plus({ days: range });
    const startString = formatDate(`${startDate}`);
    const endString = formatDate(`${endDate}`);
    return `${startString} - ${endString}`;
};

export function withRouter (Component) {
    function ComponentWithRouterProp (props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export function useBeforeUnload ({ when, message }) {
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = message;
        return message;
    };
    if (when) {
        window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
}

export function getUrl (window, router) {
    const path = router.location.pathname;
    return window.location.origin + '/' + path.substring(path.indexOf('/') + 1);
}
