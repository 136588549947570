import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    ButtonBase,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { getAvailableStates } from '../../selectors/states';
import { styles } from './styles';

const BrowseByStateButton = (props) => {
    const { classes, availableStates } = props;
    return (
        <div className={classes.browseByStateButton}>
            <ButtonBase className={classes.viewByStateButton}>
                <div>
                    <Typography
                        variant="h3"
                        className={classes.stateButtonText}
                    >
                        View by <br /> State
                    </Typography>
                </div>
                <div className={classes.stateDropDown}>
                    <FormControl variant="filled">
                        <Select
                            displayEmpty
                            value=""
                            variant="filled"
                            className={classes.stateSelect}
                            onChange={props.onStateSelected}
                        >
                            <MenuItem value="">Select a state.</MenuItem>
                            { availableStates.map(({ code, name }) => (
                                <MenuItem
                                    value={code}
                                    key={code}
                                >
                                    { name }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>
                </div>
            </ButtonBase>
        </div>
    );
};

BrowseByStateButton.propTypes = {
    classes: PropTypes.object.isRequired,
    onStateSelected: PropTypes.func.isRequired,
    availableStates: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
};

const mapStateToProps = (state) => {
    return {
        availableStates: getAvailableStates(state),
    };
};
export default compose(
    connect(mapStateToProps),
    withStyles(styles),
)(BrowseByStateButton);
