import { all, apply, call, put, select, takeEvery } from 'redux-saga/effects';
import { normalize, Packages, PageOfWatchlists, parseAndNormalizeJsonResponse, Watchlists } from '../schemas';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import {
    ADD_WATCHLIST,
    addWatchlistFailed,
    addWatchlistSucceeded,
    FETCH_WATCHLIST,
    FETCH_WATCHLIST_FOR_CURRENT_EDITION,
    fetchWatchlist,
    fetchWatchlistFailed,
    fetchWatchlistSucceeded,
    REMOVE_WATCHLIST,
    removeWatchlistFailed,
    removeWatchlistSucceeded,
    UPDATE_WATCHLIST,
    updateWatchlistFailed,
    updateWatchlistSucceeded,
} from '../stores/watchlist/actions';
import { addStateToQuery, requestWithAuth } from '../utils/api';
import { getAuthToken } from '../selectors/auth';
import { EDITION_NAMES } from '../constants/editions';
import { PACKAGE_TYPE_NAMES } from '../constants/packageTypes';
import { createCurrentContextSelector } from '../selectors/watchlist';
import { getEdition } from '../selectors/edition';

function * doFetchWatchlist (
    {
        edition,
        packageType,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state = null, search = null, underwriter = null },
    },
) {
    const token = yield select(getAuthToken);
    const query = yield call(addStateToQuery, {
        edition,
        type: packageType,
        page,
        perPage,
        orderBy,
        orderByDir,
        search: search || '',
        underwriter: underwriter || '',
    }, { state });
    const response = yield call(
        requestWithAuth,
        '/watchlist',
        'GET',
        token,
        query,
    );
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, PageOfWatchlists);
        yield put(fetchWatchlistSucceeded(
            edition,
            packageType,
            data,
            { orderBy, orderByDir },
            { state: query.state || state, search, underwriter },
        ));
    } else {
        yield put(snackbar.show({
            message:
                `Loading data for ${PACKAGE_TYPE_NAMES[packageType]} Packages,` +
                ` ${EDITION_NAMES[edition]} Edition has failed`,
        }));
        yield put(fetchWatchlistFailed(edition, packageType));
    }
}

function * doFetchWatchlistForCurrentEdition ({ args = [] }) {
    const edition = yield select(getEdition);
    yield put(fetchWatchlist(edition, ...args));
}

function * doUpdateWatchlist ({ packages }) {
    const token = yield select(getAuthToken);
    const packageIds = Object.keys(packages).map((id) => parseInt(id, 10));
    const response = yield call(requestWithAuth, '/watchlist', 'PUT', token, null, { packages });
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, Watchlists);
        yield put(updateWatchlistSucceeded(data, packageIds));
    } else {
        yield put(snackbar.show({ message: 'Updating you watchlist has failed.' }));
        yield put(updateWatchlistFailed(packageIds));
    }
}

function calculateAffectedWatchlists (watchlistsOrPackages) {
    const affected = watchlistsOrPackages.reduce((affected, { editions, type }) => ([
        ...affected,
        ...editions.map((edition) => `${typeof edition === 'object' ? edition.name : edition}.${type}`),
    ]), []);
    return [...(new Set(affected))].map((stringPair) => stringPair.split('.'));
}

function * reloadAffectedWatchlists (affected) {
    for (const [edition, packageType] of affected) {
        const currentContext = yield select(createCurrentContextSelector(edition, packageType));
        yield put(fetchWatchlist(edition, packageType, currentContext));
    }
}

function * doAddWatchlist ({ packageIds, reloadAffected = true }) {
    const token = yield select(getAuthToken);
    const packages = packageIds.reduce((packagesObject, packageId) => ({ ...packagesObject, [packageId]: {} }), {});
    const response = yield call(requestWithAuth, '/watchlist', 'POST', token, null, { packages });
    if (response.ok) {
        const body = yield apply(response, response.json);
        const data = yield call(normalize, body, Watchlists);
        if (reloadAffected) {
            const affected = yield call(calculateAffectedWatchlists, body);
            yield call(reloadAffectedWatchlists, affected);
        }
        yield put(addWatchlistSucceeded(data, packageIds));
    } else {
        yield put(snackbar.show({
            message: `Adding ${packageIds.length > 1 ? 'packages' : 'a package'} to your watchlist has failed.`,
        }));
        yield put(addWatchlistFailed(packageIds));
    }
}

function * doRemoveWatchlist ({ packageIds, reloadAffected = true }) {
    const token = yield select(getAuthToken);
    const response = yield call(requestWithAuth, '/watchlist', 'DELETE', token, null, { packages: packageIds });
    if (response.ok) {
        const body = yield apply(response, response.json);
        const data = yield call(normalize, body, { packages: Packages });
        if (reloadAffected) {
            const affected = yield call(calculateAffectedWatchlists, body.packages);
            yield call(reloadAffectedWatchlists, affected);
        }
        yield put(removeWatchlistSucceeded(data, packageIds));
    } else {
        yield put(snackbar.show({
            message: `Removing ${packageIds.length > 1 ? 'packages' : 'a package'} from your watchlist has failed.`,
        }));
        yield put(removeWatchlistFailed(packageIds));
    }
}

export default function * watchlist () {
    yield all([
        takeEvery(FETCH_WATCHLIST, doFetchWatchlist),
        takeEvery(FETCH_WATCHLIST_FOR_CURRENT_EDITION, doFetchWatchlistForCurrentEdition),
        takeEvery(ADD_WATCHLIST, doAddWatchlist),
        takeEvery(REMOVE_WATCHLIST, doRemoveWatchlist),
        takeEvery(UPDATE_WATCHLIST, doUpdateWatchlist),
    ]);
}
