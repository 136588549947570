export const styles = (theme) => ({
    browseAll: {
        ...theme.softpro.safeBox,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25,
    },
    viewByStateButton: {
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('lg')]: {
            width: 120,
            height: 120,
        },
    },
    browseByStateButton: {
        border: '1px solid #6089BD',
        width: 175,
        height: 175,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            width: 120,
            height: 120,
            marginTop: 20,
        },
    },
    stateButtonText: {
        color: '#6089BD',
        marginBottom: 30,
        marginTop: 30,
        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
            marginBottom: 5,
            marginTop: 5,
        },
    },
    stateSelect: {
        width: 150,
        fontSize: 12,
        [theme.breakpoints.down('lg')]: {
            width: 110,
            fontSize: 8,
        },
    },
    stateDropDown: {
        display: 'flex',
    },
});
