import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import { styles } from './styles';

export const LoginHelp = () => {
    return (
        <div style={styles.loginHelp}>
            <Typography variant="subtitle1">
                Need an account?
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                size="small"
                style={styles.signupButtonLink}
                href="https://my.softprocorp.com/Register?"
            >
                Signup
            </Button>
        </div>
    );
};
