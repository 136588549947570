import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import FormPropTypes from '../../propTypes/forms';
import { StyledChangePasswordField } from '../FormComponents';
import SimpleForm from '../SimpleForm';

class ChangePasswordForm extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        data: PropTypes.shape({
            oldPassword: PropTypes.string,
            newPassword: PropTypes.string,
            newPasswordConfirmation: PropTypes.string,
        }),
        fieldErrors: PropTypes.shape({
            oldPassword: FormPropTypes.errors,
            newPassword: FormPropTypes.errors,
            newPasswordConfirmation: FormPropTypes.errors,
        }),
        formErrors: FormPropTypes.errors,
        formSuccessMessages: FormPropTypes.messages,
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        loading: false,
        data: {},
        fieldErrors: {},
        formErrors: [],
        formSuccessMessages: [],
    };

    render () {
        const {
            data: {
                oldPassword,
                newPassword,
                newPasswordConfirmation,
            },
            loading,
            fieldErrors,
            formErrors,
            formSuccessMessages,
            onSubmit,
            onChange,
        } = this.props;

        const formProps = {
            loading,
            fieldErrors,
            formErrors,
            formSuccessMessages,
            onSubmit,
            onChange,
            submitText: 'Change Password',
        };

        return (
            <SimpleForm {...formProps}>
                {({ createTextOnChange, getFieldErrors }) => (
                    <Fragment>
                        <Grid item>
                            <StyledChangePasswordField
                                id="changePasswordOldPassword"
                                label="Current Password"
                                value={oldPassword}
                                onChange={createTextOnChange('oldPassword')}
                                errors={getFieldErrors('oldPassword')}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <StyledChangePasswordField
                                id="changePasswordNewPassword"
                                label="New Password"
                                value={newPassword}
                                onChange={createTextOnChange('newPassword')}
                                errors={getFieldErrors('newPassword')}
                                disabled={loading}
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item>
                            <StyledChangePasswordField
                                id="changePasswordNewPasswordConfirmation"
                                label="Confirm New Password"
                                value={newPasswordConfirmation}
                                onChange={createTextOnChange('newPasswordConfirmation')}
                                errors={getFieldErrors('newPasswordConfirmation')}
                                disabled={loading}
                                autoComplete="new-password"
                            />
                        </Grid>
                    </Fragment>
                )}
            </SimpleForm>
        );
    }
}

export default ChangePasswordForm;
