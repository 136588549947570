export const styles = (theme) => ({
    helpPage: {
        display: 'flex',
        flexDirection: 'column',
        ...theme.softpro.safeBox,
        marginTop: 0,
    },
    helpTitle: {
        marginBottom: 32,
    },
    subheading: {
        marginTop: 0,
        color: '#002244',
        fontSize: 24,
        fontWeight: 'bold',
        alignSelf: 'flex-end',
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
        },

    },
    divider: {
        height: 4,
        marginTop: 15,
        marginBottom: 15,
    },
    helpInfoSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    helpInfoButton: {
        minWidth: 165,
        width: 165,
        letterSpacing: 0,
        fontSize: 10,
        textTransform: 'uppercase',
        [theme.breakpoints.down('lg')]: {
            width: 150,
            minWidth: 150,
            fontSize: 8,
        },
    },
    hidden: {
        display: 'none',
    },
    faqSection: {
        marginTop: 64,
    },
    faqPanel: {
        border: '1px solid #6089BD',
        height: 'unset',
        width: '100%',
        justifyContent: 'center',
        marginBottom: '10px !important',
        boxShadow: 'none',
        paddingTop: 10,
        paddingBottom: 10,
    },
    faqQuestion: {
        fontWeight: 'bold',
    },
    faqPanelDiv: {
        display: 'flex',
        flexDirection: 'column',
    },
    helpInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    faqContainer: {
        marginTop: 40,
    },
    faqSearchBar: {
        width: 350,
        marginLeft: 40,
    },
    faqHeading: {
        display: 'flex',
        marginBottom: 10,
    },
    searchIcon: {
        color: '#57444E',
        position: 'absolute',
        top: 8,
        right: 10,
    },
    searchLabel: {
        marginLeft: 15,
    },
    videosContainer: {
        padding: '20px 40px',
    },
    videoContainer: {
        display: 'flex !important',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    video: {
        marginBottom: 10,
    },
    videoTitle: {
        textAlign: 'center',
    },
});
