import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { Grid } from '@mui/material';
import AdminButton from '../../components/AdminButton';
import SoftProUnderwritersDataTable from '../../components/SoftProDataTable/SoftProUnderwritersDataTable';
import ApiPropTypes from '../../propTypes/api';
import TablesPropTypes from '../../propTypes/tables';
import {
    getAdminUnderwritersContext,
    getAdminUnderwritersPage,
    getLoadingAdminUnderwriters,
} from '../../selectors/adminUnderwriters';
import {
    deleteAdminUnderwriterRequested,
    deleteMultipleAdminUnderwritersRequested,
    fetchAdminUnderwritersRequested,
} from '../../stores/adminUnderwriters/actions';

class AdminUnderwritersTable extends Component {
    componentDidMount () {
        this.props.onFetchDataRequested();
    }

    getEditLocation = (id) => `${this.props.router.location.pathname}/underwriter/${id}`;

    render () {
        const {
            router,
            tableData,
            tableContext,
            underwritersLoading,
            onFetchDataRequested,
            onDeleteRequested,
            onDeleteMultipleRequested,
        } = this.props;

        return (
            <Fragment>
                <Grid item>
                    <AdminButton to={`${router.location.pathname}/underwriter/create`}>
                        Add a New Underwriter
                    </AdminButton>
                </Grid>
                <Grid item>
                    <SoftProUnderwritersDataTable
                        tableData={tableData}
                        tableContext={tableContext}
                        underwritersLoading={underwritersLoading}
                        onFetchDataRequested={onFetchDataRequested}
                        onDeleteRequested={onDeleteRequested}
                        onDeleteMultipleRequested={onDeleteMultipleRequested}
                        getEditLocation={this.getEditLocation}
                    />
                </Grid>
            </Fragment>
        );
    }
}

AdminUnderwritersTable.propTypes = {
    // WithRouter
    router: PropTypes.object.isRequired,

    // Connect -> mapStateToProps
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: ApiPropTypes.apiPageContext.isRequired,
    underwritersLoading: PropTypes.arrayOf(PropTypes.number).isRequired,

    // Connect -> mapDispatchToProps
    onFetchDataRequested: PropTypes.func.isRequired,
    onDeleteRequested: PropTypes.func.isRequired,
    onDeleteMultipleRequested: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    tableData: getAdminUnderwritersPage(state),
    tableContext: getAdminUnderwritersContext(state),
    underwritersLoading: getLoadingAdminUnderwriters(state),
});
const mapDispatchToProps = (dispatch) => ({
    onFetchDataRequested: (context) => dispatch(fetchAdminUnderwritersRequested(context)),
    onDeleteRequested: (id) => dispatch(deleteAdminUnderwriterRequested(id)),
    onDeleteMultipleRequested: (ids) => dispatch(deleteMultipleAdminUnderwritersRequested(ids)),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(AdminUnderwritersTable);
