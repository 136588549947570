import PropTypes from 'prop-types';
import { ORDER_BY_DIRS } from '../constants/api';

export const apiPageDataShape = {
    // The index (from 1) of this page in the result set.
    currentPage: PropTypes.number.isRequired,
    // The array of items from the result set on this page.
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    // The index (from 1) of the first item on the page in the entire result set. Null if page is empty.
    from: PropTypes.number,
    // The index (from 1) of the last page in the result set.
    lastPage: PropTypes.number,
    // The max number of items in each page.
    perPage: PropTypes.number.isRequired,
    // The index (from 1) of the last item on the page in the entire result set. Null if page is empty.
    to: PropTypes.number,
    // The total number of results in the result set.
    total: PropTypes.number.isRequired,
};

export const apiPageData = PropTypes.shape(apiPageDataShape);

export const apiPageContextShape = {
    // Describes the pagination parameters of the request
    pagination: PropTypes.shape({
        // The page # of this request
        page: PropTypes.number,
        // The number of results per page for this request
        perPage: PropTypes.number,
    }),
    // Describes the ordering used in the requested
    order: PropTypes.shape({
        // The field as understood by the API endpoint to order by
        orderBy: PropTypes.string,
        // The direction to order the specified field by
        orderByDir: PropTypes.oneOf(ORDER_BY_DIRS),
    }),
    // Describes the filters as key-value pairs as the API endpoint understands/accepts them
    filters: PropTypes.object,
};

export const apiPageContext = PropTypes.shape(apiPageContextShape);

export const ApiPropTypes = {
    apiPageData,
    apiPageContext,
};

export default ApiPropTypes;
