import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';
import { BROWSE_ORDER_BY_DEFAULT_DIR, BROWSE_ORDER_BY_DEFAULT_FIELD } from '../../constants/api';

export const PREFIX = 'admin/';
export const UPDATE_RANGE = `${PREFIX}UPDATE_RANGE`;
export const UPDATE_RANGE_ANALYTICS = `${PREFIX}UPDATE_RANGE_ANALYTICS`;
export const DASHBOARD_DATA_REQUESTED = `${PREFIX}DASHBOARD_DATA_REQUESTED`;
export const DASHBOARD_DATA_REQUEST_FAILED = `${PREFIX}DASHBOARD_DATA_REQUEST_FAILED`;
export const DASHBOARD_DATA_REQUEST_SUCCEEDED = `${PREFIX}DASHBOARD_DATA_REQUEST_SUCCEEDED`;

// Action creators
export function updateRange (range, date) {
    return {
        type: UPDATE_RANGE,
        range,
        date,
    };
}

export function updateRangeAnalytics (
    range,
    date,
    dataType,
    {
        pagination: {
            perPage = DEFAULT_ROWS_PER_PAGE,
            page = 1,
        } = {},
        order: {
            orderBy = BROWSE_ORDER_BY_DEFAULT_FIELD,
            orderByDir = BROWSE_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            state = null,
            search = null,
            type = null,
        } = {},
    } = {}) {
    return {
        type: UPDATE_RANGE_ANALYTICS,
        range,
        date,
        dataType,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, type },
    };
}

export function adminDashboardDataRequested () {
    return {
        type: DASHBOARD_DATA_REQUESTED,
    };
}

export function adminDashboardDataRequestFailed () {
    return {
        type: DASHBOARD_DATA_REQUEST_FAILED,
    };
}

export function adminDashboardDataRequestSucceeded (
    downloads, uploads, notifications, topThree,
    statsDownloads, statsDownloadsSelect, statsDownloadsStandard,
    statsPackages, statsPackagesSelect, statsPackagesStandard,
    statsUsers, statsOrganizations) {
    return {
        type: DASHBOARD_DATA_REQUEST_SUCCEEDED,
        downloads,
        uploads,
        notifications,
        topThree,
        statsDownloads,
        statsDownloadsSelect,
        statsDownloadsStandard,
        statsPackages,
        statsPackagesSelect,
        statsPackagesStandard,
        statsUsers,
        statsOrganizations,
    };
}
