import {
    RECENT_DOWNLOADS_REQUEST_FAILED,
    RECENT_DOWNLOADS_REQUEST_SUCCEEDED,
    RECENT_DOWNLOADS_REQUESTED,
} from './actions';
import { RECENT_DOWNLOADS_PER_PAGE_DEFAULT } from '../../constants/api';

const recentDownloadsInitialState = {
    select: {
        data: [],
        loading: false,
        page: 1,
        total: 0,
        perPage: RECENT_DOWNLOADS_PER_PAGE_DEFAULT,
    },
    standard: {
        data: [],
        loading: false,
        page: 1,
        total: 0,
        perPage: RECENT_DOWNLOADS_PER_PAGE_DEFAULT,
    },
};

export function recentDownloads (state = recentDownloadsInitialState, action) {
    const { edition } = action;
    switch (action.type) {
        case RECENT_DOWNLOADS_REQUESTED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    loading: true,
                },
            };
        case RECENT_DOWNLOADS_REQUEST_SUCCEEDED: {
            const { data, page, perPage, total } = action;
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    loading: false,
                    data,
                    page,
                    perPage,
                    total,
                },
            };
        }
        case RECENT_DOWNLOADS_REQUEST_FAILED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    loading: false,
                },
            };
        default:
            return state;
    }
}
