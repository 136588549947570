export const styles = () => ({
    breadcrumb: {
        marginBottom: 0,
    },
    backButton: {
        marginTop: 0,
    },
    top: {
        marginBottom: '1.2em',
    },
    breadcrumbActive: {
        color: '#6089BD',
    },
    arrow: {
        width: 10,
        color: '#002244',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 9,
    },
    spacingTop: {
        marginTop: 10,
    },
    spacingBottom: {
        marginBottom: 10,
    },
    fileDescriptorText: {
        fontWeight: 'bold',
    },
    removeFilesButton: {
        letterSpacing: 0,
        marginBottom: 10,
    },
    divider: {
        margin: '20px 0',
    },
    fileNamesHeader: {
        textDecorationLine: 'underline',
        fontWeight: 'bold',
    },
    fileName: {
        marginLeft: 15,
    },
});
