export default (theme) => ({
    tableTitleIcon: {
        height: 34,
        width: 34,
        marginTop: 10,
    },
    tableTitleButton: {
        padding: 0,
        height: 22,
        minHeight: 22,
        width: 106,
        fontSize: 10,
        letterSpacing: 0,
    },
    packagesHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        flexGrow: 1,

        [theme.breakpoints.down('xl')]: {
            marginBottom: '1em',
            justifyContent: 'center',
        },
    },
    packagesTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginRight: 12,
    },
    packagesTitleTypography: {
        whiteSpace: 'nowrap',
    },
    emptyTableButton: {
        marginTop: 0,
        alignSelf: 'center',
        height: 35,
        width: 'auto',
        fontSize: 12,
        marginLeft: 25,
        letterSpacing: 0,
        whiteSpace: 'nowrap',
    },
    emptyTableBrowseButton: {
        paddingLeft: 30,
        paddingRight: 30,
    },
});
