import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import {
    BROWSE_TABLE_ACTIONS,
    TABLE_ACTIONS_LABELS,
} from '../../constants/tables';
import { EmptyTableResetButton } from '../TableTitles';
import TablesPropTypes from '../../propTypes/tables';
import ApiPropTypes from '../../propTypes/api';
import styles from './styles';
import SoftProPackagesDataTable from '../SoftProDataTable/SoftProPackagesDataTable';
import { EDITION_SELECT } from '../../constants/editions';

const BROWSE_TABLE_ACTION_ITEMS = BROWSE_TABLE_ACTIONS.map((action) => ({
    value: action,
    label: TABLE_ACTIONS_LABELS[action],
}));

class BrowseTable extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        onAddWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        loadingUpdate: PropTypes.arrayOf(PropTypes.number).isRequired,
        title: PropTypes.node.isRequired,

        className: PropTypes.string,
        emptyText: PropTypes.string,
        noResultsText: PropTypes.string,
        availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object),
        availableUnderwritersForDataTableFilter: PropTypes.arrayOf(
            PropTypes.object,
        ),
        hasFilters: PropTypes.bool,
        displayTypeColumn: PropTypes.bool,
        displayStatesColumn: PropTypes.bool,
        edition: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: null,
        emptyText: 'There are no packages to show.',
        noResultsText: 'There are no packages that match the selected filters.',
        availableStatesForDataTableFilter: [],
        availableUnderwritersForDataTableFilter: [],
        hasFilters: false,
        displayTypeColumn: false,
        displayStatesColumn: true,
    };

    onRequestTableDataReset = () => this.props.onFetchDataRequested();

    renderEmpty = () => {
        const { classes, emptyText, noResultsText, hasFilters } = this.props;
        return (
            <div className={classes.emptyTable}>
                <Typography className={classes.emptyTableText}>
                    {hasFilters ? noResultsText : emptyText}
                </Typography>
                {hasFilters
                    ? (
                        <EmptyTableResetButton
                            onClick={this.onRequestTableDataReset}
                        />
                    )
                    : null}
            </div>
        );
    };

    render () {
        const {
            className,
            classes,
            displayTypeColumn,
            displayStatesColumn,
            title,
            tableData,
            tableContext,
            onFetchDataRequested,
            onAddWatchlistRequested,
            onUpdateWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
            loadingUpdate,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            edition,
            ...restProps
        } = this.props;

        const softProPackagesDataTableProps = {
            tableData,
            tableContext,
            onFetchDataRequested,
            onAddWatchlistRequested,
            onUpdateWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
            loadingUpdate,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            title,
            hasEndorsementLookupsIncludedColumn: edition === EDITION_SELECT,
            hasTypeColumn: displayTypeColumn,
            hasStatesColumn: displayStatesColumn,
            hasRemoveColumn: false,
            hasAddButtonColumn: true,
            actions: BROWSE_TABLE_ACTION_ITEMS,
            renderEmpty: this.renderEmpty,

            ...restProps,
        };

        return (
            <div className={classNames(classes.packageTable, className)}>
                <SoftProPackagesDataTable {...softProPackagesDataTableProps} />
            </div>
        );
    }
}

export default withStyles(styles)(BrowseTable);
