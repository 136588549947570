export const styles = {
    signupButtonLink: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    loginHelp: {
        backgroundColor: '#002244',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '15px 40px',
    },
};
