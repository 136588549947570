import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';

const MessagePaper = ({ type, classes, ...rest }) => {
    return <Paper className={classNames([classes.root, type !== null ? classes[type] : null])} {...rest} />;
};

MessagePaper.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['error', 'success']),
};

MessagePaper.defaultProps = {
    type: null,
};

export default withStyles(styles)(MessagePaper);
