import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import FormPropTypes from '../../propTypes/forms';
import { StyledAdminUnderwriterName } from '../FormComponents';
import SimpleForm from '../SimpleForm';

class AdminUnderwriterForm extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        data: PropTypes.shape({
            name: PropTypes.string,
        }),
        fieldErrors: PropTypes.shape({
            name: FormPropTypes.errors,
        }),
        formErrors: FormPropTypes.errors,
        formSuccessMessages: FormPropTypes.messages,
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        loading: false,
        data: {},
        fieldErrors: {},
        formErrors: [],
        formSuccessMessages: [],
    };

    render () {
        const {
            data: { name = '' },
            loading,
            fieldErrors,
            formErrors,
            formSuccessMessages,
            onSubmit,
            onChange,
        } = this.props;

        const formProps = {
            loading,
            fieldErrors,
            formErrors,
            formSuccessMessages,
            onSubmit,
            onChange,
            backTo: '/admin/underwriters',
        };

        return (
            <SimpleForm {...formProps}>
                {({ createTextOnChange, getFieldErrors }) => (
                    <Fragment>
                        <Grid item>
                            <StyledAdminUnderwriterName
                                value={name}
                                onChange={createTextOnChange('name')}
                                errors={getFieldErrors('name')}
                                disabled={loading}
                            />
                        </Grid>
                    </Fragment>
                )}
            </SimpleForm>
        );
    }
}

export default AdminUnderwriterForm;
