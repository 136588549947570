import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { compose } from 'redux';
import convertRegion from '../../utils/usaStatesAPI';
import {
    EDITION_NAMES,
    EDITION_SELECT,
    EDITION_STANDARD,
} from '../../constants/editions';
import { USER_TYPES } from '../../constants/users';
import { noop } from '../../utils';
import PACKAGE_TYPES from '../../constants/packageTypes';
import { styles } from './styles';
import { withSnackbar } from 'material-ui-snackbar-redux';
import FormPropTypes from '../../propTypes/forms';
import UnderwriterSelector from './UnderwriterSelector';
export const StyledUnderwriterSelector = UnderwriterSelector;

const Email = (props) => {
    const {
        classes,
        email,
        onChange = noop,
        error = false,
        disabled = true,
    } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                Email Address
            </InputLabel>
            <InputBase
                disabled={disabled}
                id="email"
                type="email"
                className={classes.textField}
                inputProps={{ style: styles.textInput }}
                value={email}
                error={error}
                readOnly={disabled}
                onChange={onChange}
            />
            {error && (
                <FormHelperText error>
                    Email is required and must be unique.
                </FormHelperText>
            )}
        </FormControl>
    );
};
Email.propTypes = {
    classes: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};
export const StyledEmail = withStyles(styles)(Email);

const Name = (props) => {
    const { classes, name, onChange, error } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                {' '}
                Name
            </InputLabel>
            <InputBase
                id="fullName"
                className={classes.textField}
                inputProps={{ style: styles.textInput }}
                defaultValue={name}
                onChange={onChange}
                error={error}
            />
            {error && <FormHelperText error>Name is required.</FormHelperText>}
        </FormControl>
    );
};
Name.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
};
Name.defaultProps = {
    error: false,
};
export const StyledName = withStyles(styles)(Name);

const Company = (props) => {
    const {
        classes,
        company,
        error,
        validationError,
        hidden,
        onChange = noop,
    } = props;
    return hidden
        ? null
        : (
            <FormControl className={classes.formControl}>
                <InputLabel shrink className={classes.textFieldLabel}>
                    {' '}
                Company
                </InputLabel>
                <InputBase
                    id="company"
                    className={classes.textField}
                    inputProps={{ style: styles.textInput }}
                    defaultValue={company}
                    onChange={onChange}
                    error={error || validationError}
                />
                {error && (
                    <FormHelperText error>Company Name is required.</FormHelperText>
                )}
                {validationError && (
                    <FormHelperText error>
                    Company Name must be an existing Company Name in the system.
                    </FormHelperText>
                )}
            </FormControl>
        );
};
Company.propTypes = {
    classes: PropTypes.object.isRequired,
    company: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool.isRequired,
    validationError: PropTypes.bool,
    hidden: PropTypes.bool,
};
export const StyledCompany = withStyles(styles)(Company);

const States = (props) => {
    const { classes, availableStates, states, error = false } = props;
    return (
        <div className={classes.states}>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.dropdownInputLabel}
                >
                    States of Interest
                </InputLabel>
                <Select
                    disableUnderline
                    value=""
                    variant="filled"
                    onChange={props.handleSelectedState}
                    className={classes.dropdown}
                >
                    {availableStates.map((state) => {
                        return (
                            <MenuItem value={state.code} key={state.id}>
                                {state.name}
                            </MenuItem>
                        );
                    })}
                </Select>
                {error && (
                    <FormHelperText error>
                        You must select at least one State of interest.
                    </FormHelperText>
                )}
            </FormControl>
            <div className={classes.stateChips}>
                {states.map((data) => {
                    const label = convertRegion(data, 1);
                    return (
                        <Chip
                            key={data}
                            label={label}
                            onDelete={() => props.handleDelete(data)}
                            deleteIcon={<ClearIcon />}
                            variant="outlined"
                            className={classes.chipStyle}
                        />
                    );
                })}
            </div>
        </div>
    );
};

States.propTypes = {
    handleSelectedState: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    states: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    availableStates: PropTypes.array,
};
export const StyledStates = withStyles(styles)(States);

const Underwriters = (props) => {
    const { classes, underwriters } = props;
    return (
        <div className={classes.underwriters}>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.underwriterLabel}
                >
                    Underwriters
                </InputLabel>
            </FormControl>
            <div className={classes.underwriterChips}>
                {underwriters && underwriters.length
                    ? (
                        underwriters.map((data) => {
                            return (
                                <Chip
                                    key={data}
                                    label={data}
                                    variant="outlined"
                                    className={classes.chipStyle}
                                />
                            );
                        })
                    )
                    : (
                        <Chip
                            key={'None'}
                            label={'None'}
                            variant="outlined"
                            className={classes.chipStyle}
                        />
                    )}
            </div>
        </div>
    );
};

Underwriters.propTypes = {
    underwriters: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
};
export const StyledUnderwriters = withStyles(styles)(Underwriters);

const Editions = (props) => {
    const { classes, error } = props;
    return (
        <div className={classes.editions}>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.dropdownInputLabel}
                >
                    Software Edition
                </InputLabel>
                <Select
                    disableUnderline
                    value=""
                    variant="filled"
                    onChange={props.handleSelectedEdition}
                    className={classes.dropdown}
                    error={error}
                >
                    <MenuItem value={EDITION_STANDARD}>
                        {' '}
                        {EDITION_NAMES[EDITION_STANDARD]}{' '}
                    </MenuItem>
                    <MenuItem value={EDITION_SELECT}>
                        {' '}
                        {EDITION_NAMES[EDITION_SELECT]}{' '}
                    </MenuItem>
                </Select>
                {error && (
                    <FormHelperText error>
                        At least one Edition is required.
                    </FormHelperText>
                )}
            </FormControl>
            <div className={classes.editionChips}>
                {props.editions.map((data) => {
                    return (
                        <Chip
                            key={data}
                            label={EDITION_NAMES[data]}
                            onDelete={() => props.handleDelete(data)}
                            deleteIcon={<ClearIcon />}
                            variant="outlined"
                            className={classes.chipStyle}
                        />
                    );
                })}
            </div>
        </div>
    );
};

Editions.propTypes = {
    handleSelectedEdition: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool.isRequired,
    editions: PropTypes.array,
};
export const StyledEditions = withStyles(styles)(Editions);

const SerialNumber = (props) => {
    const { classes, serialNumber, onChange } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                Serial Number
            </InputLabel>
            <InputBase
                id="serialNumber"
                className={classes.textField}
                inputProps={{ style: styles.textInput }}
                value={serialNumber || ''}
                readOnly
                disabled
                onChange={onChange}
            />
        </FormControl>
    );
};
SerialNumber.propTypes = {
    classes: PropTypes.object.isRequired,
    serialNumber: PropTypes.string,
    onChange: PropTypes.func,
};
SerialNumber.defaultProps = {
    onChange: noop,
    serialNumber: '',
};
export const StyledSerialNumber = withStyles(styles)(SerialNumber);

const UserType = ({ classes, value, onChange, error = false }) => {
    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
                variant="outlined"
                shrink
                className={classes.dropdownInputLabel}
            >
                User Type
            </InputLabel>
            <Select
                disableUnderline
                variant="filled"
                value={value}
                onChange={onChange}
                displayEmpty={false}
                className={classes.dropdown}
            >
                {USER_TYPES.map((type) => {
                    return (
                        <MenuItem value={type} key={type}>
                            {type}
                        </MenuItem>
                    );
                })}
            </Select>
            {error && (
                <FormHelperText error>
                    You must select a User Type.
                </FormHelperText>
            )}
        </FormControl>
    );
};
UserType.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.node,
};
export const StyledUserType = withStyles(styles)(UserType);

const AmsMember = ({ classes, isAmsMember, onClick }) => {
    return (
        <div className={classes.checkboxDiv}>
            <Typography className={classes.checkboxLabel}>
                AMS Member
            </Typography>
            <Checkbox color="default" checked={isAmsMember} onClick={onClick} />
        </div>
    );
};
AmsMember.propTypes = {
    classes: PropTypes.object.isRequired,
    isAmsMember: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
AmsMember.defaultProps = {
    isAmsMember: false,
};
export const StyledAmsMember = withStyles(styles)(AmsMember);

const SaveButton = ({ classes, text = 'Save', updating = false }) => {
    return (
        <Button
            variant="contained"
            size="small"
            color="primary"
            type="submit"
            disabled={updating}
            className={classes.saveButton}
        >
            {updating ? <CircularProgress size={28} /> : text}
        </Button>
    );
};
SaveButton.propTypes = {
    classes: PropTypes.object.isRequired,
    updating: PropTypes.bool,
    text: PropTypes.string,
};
export const StyledSaveButton = withStyles(styles)(SaveButton);

const PackageName = (props) => {
    const { classes, name, onChange, error } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                Package Name
            </InputLabel>
            <InputBase
                id="packageName"
                className={classes.textField}
                inputProps={{ style: styles.textInput }}
                defaultValue={name}
                onChange={onChange}
                error={error}
            />
            {error && (
                <FormHelperText error>Package Name is required.</FormHelperText>
            )}
        </FormControl>
    );
};
PackageName.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
};
export const StyledPackageName = withStyles(styles)(PackageName);

const PackageType = ({ classes, value, onChange, error = false }) => {
    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
                variant="outlined"
                shrink
                className={classes.dropdownInputLabel}
            >
                Package Type
            </InputLabel>
            <Select
                disableUnderline
                variant="filled"
                value={value}
                onChange={onChange}
                displayEmpty={false}
                className={classes.dropdown}
            >
                {PACKAGE_TYPES.map((type) => {
                    return (
                        <MenuItem value={type} key={type}>
                            {type}
                        </MenuItem>
                    );
                })}
            </Select>
            {error && (
                <FormHelperText error>
                    You must select a Package Type.
                </FormHelperText>
            )}
        </FormControl>
    );
};
PackageType.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};
export const StyledPackageType = withStyles(styles)(PackageType);

const UnderwriterName = ({
    classes,
    value,
    onChange,
    availableUnderwriters,
    error = false,
}) => {
    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
                variant="outlined"
                shrink
                className={classes.dropdownInputLabel}
            >
                Underwriter
            </InputLabel>
            <Select
                disableUnderline
                variant="filled"
                value={value}
                onChange={onChange}
                displayEmpty
                className={classes.dropdown}
            >
                <MenuItem value={0} key={0} />
                {availableUnderwriters.map(({ name, id }) => {
                    return (
                        <MenuItem value={id} key={id}>
                            {name}
                        </MenuItem>
                    );
                })}
            </Select>
            {error && (
                <FormHelperText error>
                    You must select an Underwriter for an Underwriter Package.
                </FormHelperText>
            )}
        </FormControl>
    );
};
UnderwriterName.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    availableUnderwriters: PropTypes.array.isRequired,
};
export const StyledUnderwriterName = withStyles(styles)(UnderwriterName);

const DownloadNotes = (props) => {
    const { classes, value, onChange } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                Download Notes
            </InputLabel>
            <InputBase
                id="downloadNotes"
                multiline
                inputProps={{ className: classes.textAreaInput }}
                defaultValue={value}
                onChange={onChange}
            />
        </FormControl>
    );
};
DownloadNotes.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
export const StyledDownloadNotes = withStyles(styles)(DownloadNotes);

const InternalNotes = (props) => {
    const { classes, value, onChange } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                Internal Notes
            </InputLabel>
            <InputBase
                id="internalNotes"
                multiline
                inputProps={{ className: classes.textAreaInput }}
                defaultValue={value}
                onChange={onChange}
            />
        </FormControl>
    );
};
InternalNotes.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
export const StyledInternalNotes = withStyles(styles)(InternalNotes);

const DynamicsCode = (props) => {
    const { classes, value, onChange } = props;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink className={classes.textFieldLabel}>
                Dynamics Code
            </InputLabel>
            <InputBase
                id="dynamicsCode"
                multiline
                inputProps={{ className: classes.textAreaInput }}
                defaultValue={value}
                onChange={onChange}
            />
        </FormControl>
    );
};
DynamicsCode.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
export const StyledDynamicsCode = withStyles(styles)(DynamicsCode);

class SingleFileDropZone extends React.Component {
    render () {
        const {
            classes,
            snackbar,
            accepted,
            onDrop,
            label,
            showLabel = false,
            accept = '',
        } = this.props;
        const errorMessage =
            'The ' +
            label +
            ' file can be only one file'
                .concat(
                    accept
                        ? ' of type ' + accept.toUpperCase().replace('.', '')
                        : '',
                )
                .concat('.');
        return (
            <Grid
                container
                direction="column"
                className={classes.dropZoneContainer}
            >
                {showLabel && (
                    <Grid item>
                        <Typography className={classes.dropzoneLabel}>
                            Add {label}
                        </Typography>
                    </Grid>
                )}
                <Grid item>
                    <Dropzone
                        multiple={false}
                        onDropRejected={() => snackbar.show(errorMessage)}
                        onDrop={onDrop}
                        accept={accept}
                    >
                        {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        }) => {
                            let styles = classes.baseDropzoneStyle;
                            styles = isDragActive
                                ? classes.activeDropzoneStyle
                                : styles;
                            styles = isDragReject
                                ? classes.rejectDropzoneStyle
                                : styles;
                            return (
                                <div {...getRootProps()} className={styles}>
                                    <input {...getInputProps()} />
                                    <Typography gutterBottom>
                                        {isDragAccept ? 'Drop' : 'Drag'} file
                                        here or click to select
                                        {accepted && accepted.length > 0
                                            ? ' a different '
                                            : ' '}
                                        file...
                                    </Typography>
                                    {accepted.map(({ name }) => (
                                        <Typography
                                            key={name}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {name}
                                        </Typography>
                                    ))}
                                </div>
                            );
                        }}
                    </Dropzone>
                </Grid>
            </Grid>
        );
    }
}

SingleFileDropZone.propTypes = {
    classes: PropTypes.object.isRequired,
    snackbar: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired,
    accepted: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    accept: PropTypes.string,
};

export const StyledSingleFileDropZone = compose(
    withStyles(styles),
    withSnackbar(),
)(SingleFileDropZone);

class MultipleFileDropZone extends React.Component {
    render () {
        const {
            classes,
            accepted,
            onDrop,
            label,
            showLabel = false,
            accept = '',
        } = this.props;
        return (
            <Grid
                container
                direction="column"
                className={classes.dropZoneContainer}
            >
                {showLabel && (
                    <Grid item>
                        <Typography className={classes.dropzoneLabel}>
                            {label}
                        </Typography>
                    </Grid>
                )}
                <Grid item>
                    <Dropzone multiple onDrop={onDrop} accept={accept}>
                        {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        }) => {
                            let styles = classes.baseDropzoneStyle;
                            styles = isDragActive
                                ? classes.activeDropzoneStyle
                                : styles;
                            styles = isDragReject
                                ? classes.rejectDropzoneStyle
                                : styles;
                            return (
                                <div {...getRootProps()} className={styles}>
                                    <input {...getInputProps()} />
                                    <Typography gutterBottom>
                                        {isDragAccept ? 'Drop' : 'Drag'} files
                                        here or click to select
                                        {accepted && accepted.length > 0
                                            ? ' different '
                                            : ' '}
                                        files...
                                    </Typography>
                                    {accepted.map(({ name }) => (
                                        <Typography
                                            key={name}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {name}
                                        </Typography>
                                    ))}
                                </div>
                            );
                        }}
                    </Dropzone>
                </Grid>
            </Grid>
        );
    }
}

MultipleFileDropZone.propTypes = {
    classes: PropTypes.object.isRequired,
    snackbar: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired,
    accepted: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    accept: PropTypes.string,
};

export const StyledMultipleFileDropZone = compose(
    withStyles(styles),
    withSnackbar(),
)(MultipleFileDropZone);

const FileDownloadButton = ({ classes, url, filename }) => {
    return (
        <Button
            className={classes.downloadFileButton}
            variant="text"
            color="primary"
            href={url}
            target="_blank"
        >
            {filename}
        </Button>
    );
};
FileDownloadButton.propTypes = {
    classes: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
};
export const StyledFileDownloadButton = withStyles(styles)(FileDownloadButton);

const RevisionCheckbox = ({ classes, value, onClick }) => {
    return (
        <div className={classes.checkboxDiv}>
            <Typography className={classes.checkboxLabel}>
                Update Existing Revision
            </Typography>
            <Tooltip
                title={
                    'Check this if you want to update the existing revision and not cause any notifications to be sent for these changes.'
                }
            >
                <Checkbox color="default" checked={value} onClick={onClick} />
            </Tooltip>
        </div>
    );
};
RevisionCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    isAmsMember: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.bool,
};
RevisionCheckbox.defaultProps = {
    isAmsMember: false,
};
export const StyledRevisionCheckbox = withStyles(styles)(RevisionCheckbox);

const GenericCheckbox = ({ classes, value, onClick, label, title }) => {
    return (
        <div className={classes.checkboxDiv}>
            <Typography className={classes.checkboxLabel}>{label}</Typography>
            <Tooltip title={title}>
                <Checkbox color="primary" checked={value} onClick={onClick} />
            </Tooltip>
        </div>
    );
};
GenericCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
};
GenericCheckbox.defaultProps = {
    value: false,
};
export const StyledGenericCheckbox = withStyles(styles)(GenericCheckbox);

const AMSRequiredCheckbox = ({ classes, value, onClick }) => {
    return (
        <div className={classes.checkboxDiv}>
            <Typography className={classes.checkboxLabel}>
                AMS Required
            </Typography>
            <Tooltip title={'Check this if you want AMS Required to be true.'}>
                <Checkbox color="default" checked={value} onClick={onClick} />
            </Tooltip>
        </div>
    );
};
AMSRequiredCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    isAmsMember: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.bool,
};
AMSRequiredCheckbox.defaultProps = {
    isAmsMember: false,
};
export const StyledAMSRequiredCheckbox =
    withStyles(styles)(AMSRequiredCheckbox);

const MustBeInProductLineCheckbox = ({ classes, value, onClick }) => {
    return (
        <div className={classes.checkboxDiv}>
            <Typography className={classes.checkboxLabel}>
                Must Be In Product Line
            </Typography>
            <Tooltip
                title={
                    'Check this if you want Must Be In Product Line to be true.'
                }
            >
                <Checkbox color="default" checked={value} onClick={onClick} />
            </Tooltip>
        </div>
    );
};
MustBeInProductLineCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    isAmsMember: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.bool,
};
MustBeInProductLineCheckbox.defaultProps = {
    isAmsMember: false,
};
export const StyledMustBeInProductLineCheckbox = withStyles(styles)(
    MustBeInProductLineCheckbox,
);

const SimpleTextField = ({
    classes,
    value,
    errors,
    id,
    label,
    className,
    inputProps,
    ...restProps
}) => {
    return (
        <FormControl className={classes.textFormControl}>
            <InputLabel shrink className={classes.textFieldLabel} htmlFor={id}>
                {label}
            </InputLabel>
            <InputBase
                id={id}
                className={classNames(classes.textField, className)}
                inputProps={{ className: classes.textInput, ...inputProps }}
                value={value}
                error={errors.length > 0}
                {...restProps}
            />
            {errors.map((error) => (
                <FormHelperText error key={error}>
                    {error}
                </FormHelperText>
            ))}
        </FormControl>
    );
};
SimpleTextField.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    errors: FormPropTypes.errors,
    label: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
};
SimpleTextField.defaultProps = {
    className: null,
    value: '',
    errors: [],
    inputProps: {},
};
export const StyledSimpleTextField = withStyles(styles)(SimpleTextField);

export const StyledAdminUnderwriterName = (props) => (
    <StyledSimpleTextField label="Name" id="underwriterName" {...props} />
);
export const StyledAdminCreateAdminName = (props) => (
    <StyledSimpleTextField label="Name" id="adminCreateAdminName" {...props} />
);
export const StyledAdminCreateAdminEmail = (props) => (
    <StyledSimpleTextField
        label="Email"
        id="adminCreateAdminEmail"
        type="email"
        autoComplete="off"
        {...props}
    />
);
export const StyledAdminCreateAdminOrganization = (props) => (
    <StyledSimpleTextField
        label="Organization"
        id="adminCreateAdminOrganization"
        {...props}
    />
);
export const StyledAdminCreateAdminPassword = (props) => (
    <StyledSimpleTextField
        label="Password"
        id="adminCreateAdminPassword"
        type="password"
        autoComplete="new-password"
        {...props}
    />
);
export const StyledAdminCreateAdminPasswordConfirmation = (props) => (
    <StyledSimpleTextField
        label="Confirm Password"
        id="adminCreateAdminPasswordConfirmation"
        type="password"
        autoComplete="new-password"
        {...props}
    />
);

export const StyledChangePasswordField = (props) => (
    <StyledSimpleTextField type="password" {...props} />
);
