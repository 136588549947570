import { createSelector } from 'reselect';
import { getEntities } from './entities';
import { denormalize } from 'normalizr';
import { Underwriters } from '../schemas';

export const getAvailableUnderwriters = createSelector(
    (state) => state.underwriters.availableUnderwriters,
    getEntities,
    (underwriters, entities) => denormalize(underwriters, Underwriters, entities),
);

export const getAvailableUnderwritersForDataTableFilter = createSelector(
    getAvailableUnderwriters,
    (availableUnderwriters) => {
        return ([
            { label: 'All Underwriters', value: '' },
            ...availableUnderwriters.sort((a, b) => {
                return (a.name > b.name) ? 1 : -1;
            }).map(({ name, id }) => ({ label: name, value: id })),
        ]);
    },
);
