import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { ButtonBase, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from '../../utils';
import UnderwriterIcon from '../../icons/icon-quill-large-white.png';
import StateIcon from '../../icons/icon-building-large-white.png';
import NationalIcon from '../../icons/icon-flag-large-white.png';
import LandIcon from '../../icons/icon-mountains-large-white.png';
import { styles } from './styles';
import SuggestedDownloadsTable from '../SuggestedDownloadsTable';
import BrowseByStateButton from '../../views/BrowseByStateButton';
import { useNavigate } from 'react-router';

const BrowseIconButton = (props) => {
    const { classes } = props;
    const style = { backgroundColor: props.bgColor };
    return (
        <div style={style} className={classes.browseButtonDiv}>
            <ButtonBase
                variant="contained"
                onClick={props.onClick}
                className={classes.imageButton}
                key={props.text}
            >
                <img src={props.icon} alt={props.text} className={classes.browseButtonIcon} />
                <div className={classes.browseButtonText}>
                    <Typography gutterBottom align="center" variant="h3" className={classes.browseButtonTypography}>
                        {props.text}
                    </Typography>
                </div>
            </ButtonBase>
        </div>
    );
};

BrowseIconButton.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

const PackageViewingOptions = (props) => {
    const { classes } = props;
    return (
        <div className="packageViewingOptions">
            <Typography variant="h4" className={classes.packageViewingOptions} gutterBottom>
                Package Viewing Options
            </Typography>
            <div className={classes.browseButtons}>
                <BrowseIconButton
                    bgColor="#002244"
                    icon={UnderwriterIcon}
                    text="Underwriter Packages"
                    onClick={() => props.onPackagesClick('underwriterPackages')}
                    classes={classes}
                />
                <BrowseIconButton
                    bgColor="#B1B902"
                    icon={StateIcon}
                    text="State Packages"
                    onClick={() => props.onPackagesClick('statePackages')}
                    classes={classes}
                />
                <BrowseIconButton
                    bgColor="#F33E36"
                    icon={NationalIcon}
                    text="National Packages"
                    onClick={() => props.onPackagesClick('nationalPackages')}
                    classes={classes}
                />
                <BrowseIconButton
                    bgColor="#6089BD"
                    icon={LandIcon}
                    text="Land Title Packages"
                    onClick={() => props.onPackagesClick('landTitlePackages')}
                    classes={classes}
                />
                <BrowseByStateButton onStateSelected={props.onStateSelected} />
            </div>
        </div>
    );
};

PackageViewingOptions.propTypes = {
    classes: PropTypes.object.isRequired,
    onPackagesClick: PropTypes.func,
    onStateSelected: PropTypes.func,
};

export class BrowseAll extends React.Component {
    onStateSelected = (event) => {
        this.props.navigate(`/portal/browseAll/byState/${event.target.value}`);
    };

    onPackagesClick = (which) => {
        this.props.navigate(`/portal/browseAll/${which}`);
    };

    render () {
        const { classes } = this.props;
        return (
            <div className={classes.browseAll}>
                <Typography variant="h1" gutterBottom>Browse All Packages</Typography>
                <PackageViewingOptions
                    onPackagesClick={this.onPackagesClick}
                    onStateSelected={this.onStateSelected}
                    classes={classes}
                />
                <SuggestedDownloadsTable />
            </div>
        );
    }
}

BrowseAll.propTypes = {
    classes: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
};

const BrowseAllFunction = (props) => {
    const navigate = useNavigate();
    return <BrowseAll navigate={navigate} {...props} />;
};

export default compose(
    withRouter,
    withStyles(styles),
)(BrowseAllFunction);
