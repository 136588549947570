const toggleSwitch = (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: 26,
    width: 'auto',
    margin: 20,
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down('lg')]: {
        margin: 5,
        paddingRight: 10,
    },
});

export const styles = (theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 105,
        zIndex: 101,
        alignItems: 'center',
    },
    topLogo: {
        display: 'flex',
        flexDirection: 'row',
        height: 105,
        width: 245,
        justifyContent: 'space-evenly',
        marginLeft: 25,
        alignItems: 'center',
    },
    documentsPortal: {
        fontWeight: 'bold',
        fontSize: 15,
        color: '#003263',
        marginTop: 20,
        marginLeft: 15,
    },
    rightHeaderText: {
        marginRight: 10,
        [theme.breakpoints.down('lg')]: {
            fontSize: 12,
            marginTop: 12,
            textAlign: 'right',
        },
    },
    switchIconLeft: {
        paddingRight: 10,
        [theme.breakpoints.down('lg')]: {
            paddingRight: 5,
        },
    },
    switchIconRight: {
        paddingLeft: 10,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 5,
        },
    },
    softProButton: {
        marginRight: 25,
        [theme.breakpoints.down('lg')]: {
            marginRight: 5,
        },
    },
    documentsPortalText: {
        fontWeight: 'bold',
        color: '#003263',
    },
    toggleSwitchStandard: {
        ...toggleSwitch(theme),
        backgroundColor: '#002244',
    },
    toggleSwitchSelect: {
        ...toggleSwitch(theme),
        backgroundColor: '#F33E36',
    },
    rightHeader: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 25,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('lg')]: {
            paddingRight: 5,
        },
    },
    rightHeader2: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    rightHeader3: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            height: 105,
            alignItems: 'flex-end',
            marginRight: 10,
        },
    },
    editionToggle: {
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        alignItems: 'center',
        paddingRight: 20,
        [theme.breakpoints.down('lg')]: {
            fontSize: 10,
            flexDirection: 'column',
        },
    },
    logoutButton: {
        width: 'unset',
        margin: 0,
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
            flex: 1,
        },
    },
    settingsIcon: {
        [theme.breakpoints.down('lg')]: {
            flex: 1,
        },
    },
    switchText: {
        color: '#fff',
    },
});

export default styles;
