export default (theme) => ({
    packagesTable: {
        width: 'auto',
    },
    packages: {
        marginBottom: 60,
    },
    emptyTable: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#DFDFDF',
        padding: 20,
    },
    emptyTableText: {
        maxWidth: 500,
    },
});
