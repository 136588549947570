import {
    CLEAR_SUCCESS_MESSAGES,
    USER_DATA_REQUEST_FAILED,
    USER_DATA_REQUEST_SUCCEEDED,
    USER_DATA_REQUESTED,
    USER_DATA_UPDATE_REQUEST_FAILED,
    USER_DATA_UPDATE_REQUEST_SUCCEEDED,
    USER_DATA_UPDATE_REQUESTED,
    USER_DOWNLOAD_HISTORY_REQUEST_FAILED,
    USER_DOWNLOAD_HISTORY_REQUEST_SUCCEEDED,
    USER_DOWNLOAD_HISTORY_REQUESTED,
    USERS_DATA_REQUEST_FAILED,
    USERS_DATA_REQUEST_SUCCEEDED,
    USERS_DATA_REQUESTED,
} from './actions';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';
import { LOGOUT } from '../auth/actions';

export const usersInitialState = {
    loading: false,
    specificUserLoading: false,
    userUpdating: false,
    data: [],
    perPage: DEFAULT_ROWS_PER_PAGE,
    page: 1,
    total: 0,
    organizations: [],
    search: '',
    selectedOrganizations: [],
    formSuccessMessages: [],
    userData: {
        email: '',
        name: '',
        roleNames: [],
        editionNames: '',
        stateCodes: '',
        serialNumber: '',
        isAmsMember: false,
        organizationName: '',
    },
    validationErrors: {
        email: [],
    },
    downloadHistory: {
        data: [],
        loading: false,
        page: 1,
        perPage: DEFAULT_ROWS_PER_PAGE,
        total: 0,
    },
};

export function users (state = usersInitialState, action) {
    const { type, page, userData, perPage, total, data } = action;
    switch (type) {
        case USERS_DATA_REQUESTED: {
            const { search, selectedOrganizations } = action;
            return {
                ...state,
                loading: true,
                page,
                search,
                selectedOrganizations,
            };
        }
        case USERS_DATA_REQUEST_SUCCEEDED: {
            const { organizations } = action;
            return {
                ...state,
                loading: false,
                data,
                page,
                perPage: parseInt(perPage, 10),
                total,
                organizations,
            };
        }
        case USERS_DATA_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                formSuccessMessages: [],
            };
        case USER_DATA_REQUESTED:
            return {
                ...state,
                specificUserLoading: true,
                formSuccessMessages: [],
            };
        case USER_DATA_REQUEST_SUCCEEDED:
            return {
                ...state,
                specificUserLoading: false,
                userData,
                formSuccessMessages: [],
            };
        case USER_DATA_REQUEST_FAILED:
            return {
                ...state,
                specificUserLoading: false,
                formSuccessMessages: [],
            };
        case USER_DATA_UPDATE_REQUESTED:
            return {
                ...state,
                userUpdating: true,
                formSuccessMessages: [],
            };
        case USER_DATA_UPDATE_REQUEST_SUCCEEDED:
            return {
                ...state,
                userUpdating: false,
                userData,
                formSuccessMessages: ['Successfully updated user.'],
            };
        case USER_DATA_UPDATE_REQUEST_FAILED: {
            const { validationErrors } = action;
            return {
                ...state,
                userUpdating: false,
                validationErrors,
                formSuccessMessages: [],
            };
        }
        case USER_DOWNLOAD_HISTORY_REQUESTED:
            return {
                ...state,
                formSuccessMessages: [],
                downloadHistory: {
                    ...state.downloadHistory,
                    loading: true,
                    page,
                    perPage,
                },
            };
        case USER_DOWNLOAD_HISTORY_REQUEST_SUCCEEDED:
            return {
                ...state,
                downloadHistory: {
                    ...state.downloadHistory,
                    loading: false,
                    data,
                    page,
                    total,
                },
            };
        case USER_DOWNLOAD_HISTORY_REQUEST_FAILED:
            return {
                ...state,
                downloadHistory: {
                    ...state.downloadHistory,
                    loading: false,
                },
            };
        case CLEAR_SUCCESS_MESSAGES:
            return {
                ...state,
                formSuccessMessages: [],
            };
        case LOGOUT:
            return usersInitialState;
        default:
            return state;
    }
}
