import { styles } from './styles';
import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const EmptyTable = ({ title, includeRangeInMessage, includeSearchFilterInMessage }) => {
    const rangeMessage = includeRangeInMessage ? ' the given range of days and' : '';
    const titleString = title + ' ';
    const searchFilterMessage = includeSearchFilterInMessage ? ' the current Search/Filters applied (if any)' : ' ';
    const message = 'No ' + titleString + 'data available' + rangeMessage + searchFilterMessage + '.';
    return (
        <div>
            <div style={styles.emptyTable}>
                <Typography>
                    {message}
                </Typography>
            </div>
        </div>
    );
};

EmptyTable.propTypes = {
    includeRangeInMessage: PropTypes.bool,
    includeSearchFilterInMessage: PropTypes.bool,
    title: PropTypes.string,
};
EmptyTable.defaultProps = {
    includeRangeInMessage: false,
    includeSearchFilterInMessage: true,
    title: '',
};

export default EmptyTable;
