import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from '../../utils';
import { Route, Routes, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import AdminUnderwritersTable from '../AdminUnderwritersTable';
import AdminUnderwriterDetail from '../AdminUnderwriterDetail';
import styles from './styles';

const AdminUnderwriterDetailUpdate = () => {
    const { id } = useParams();
    return <AdminUnderwriterDetail key={id || 'create'} id={id} />;
};

const AdminUnderwriterDetailCreate = () => <AdminUnderwriterDetail key={'create'} id={null} />;

const AdminUnderwriters = ({ classes }) => (
    <Grid container direction="column" alignItems="stretch" className={classes.container}>
        <Routes>
            <Route path="/" element={<AdminUnderwritersTable />} />
            <Route path="underwriter/create" element={<AdminUnderwriterDetailCreate />} />
            <Route path="underwriter/:id" element={<AdminUnderwriterDetailUpdate />} />
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
    </Grid>
);

AdminUnderwriters.propTypes = {
    // WithStyles
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    withRouter,
)(AdminUnderwriters);
