import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    Chip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PlayArrow as ArrowIcon } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    withRouter,
    combine,
    objectValuesAreEmpty,
    omit,
    getUrl,
} from '../../utils';
import { bindActionCreators, compose } from 'redux';
import { updatedDiff } from 'deep-object-diff';
import deepEqual from 'deep-equal';
import { styles } from './styles';
import {
    StyledAMSRequiredCheckbox,
    StyledDownloadNotes,
    StyledDynamicsCode,
    StyledEditions,
    StyledFileDownloadButton,
    StyledGenericCheckbox,
    StyledInternalNotes,
    StyledMultipleFileDropZone,
    StyledMustBeInProductLineCheckbox,
    StyledPackageName,
    StyledPackageType,
    StyledRevisionCheckbox,
    StyledSaveButton,
    StyledSingleFileDropZone,
    StyledStates,
    StyledUnderwriterName,
} from '../../components/FormComponents';
import { getAvailableStates } from '../../selectors/states';
import { connect } from 'react-redux';
import { getAvailableUnderwriters } from '../../selectors/underwriters';
import { fetchUnderwriters } from '../../stores/underwriters/actions';
import { fetchStates } from '../../stores/states/actions';
import {
    clearSuccessMessages,
    createPackageRequested,
    fetchPackageRequested,
    updatePackageRequested,
} from '../../stores/adminPackages/actions';
import {
    getAdminPackageSuccessMessages,
    getDownloadUrl,
    getPackageEditions,
    getReleaseNotesUrl,
} from '../../selectors/adminPackages';
import { withSnackbar } from 'material-ui-snackbar-redux';
import BackButton from '../../components/BackButton';
import MessagePaper from '../../components/MessagePaper';
import { PACKAGE_TYPE_UNDERWRITER } from '../../constants/packageTypes';
import { EDITION_SELECT } from '../../constants/editions';
import ClearIcon from '@mui/icons-material/Clear';

class PackageForm extends React.Component {
    componentDidUpdate (prevProps, prevState, snapshot) {
        const { saving: prevSaving } = prevProps;
        const { saving, formSuccessMessages } = this.props;
        if (
            prevSaving &&
            !saving &&
            formSuccessMessages &&
            formSuccessMessages.length > 0
        ) {
            /*
             * Successful save occurred, so clearing out the accepted files in state
             * So that they are not seen as "changes" which would trigger the prompt
             */
            const newExistingFiles = this.state.existingFiles;
            this.state.packageFilesAccepted.forEach((item) => {
                if (
                    item.name &&
                    newExistingFiles &&
                    newExistingFiles.indexOf(item.name) === -1
                ) {
                    newExistingFiles.push(item.name);
                }
            });
            const newExistingNotes = [];
            if (
                this.state.releaseNotesAccepted.length > 0 &&
                this.state.releaseNotesAccepted[0].name
            ) {
                newExistingNotes[0] = this.state.releaseNotesAccepted[0].name;
            } else if (this.state.existingNotes.length > 0) {
                newExistingNotes[0] = this.state.existingNotes[0];
            }
            this.setState({ existingFiles: newExistingFiles });
            this.setState({ existingNotes: newExistingNotes });
            this.setState({
                packageFilesAccepted: [],
                releaseNotesAccepted: [],
            });
            this.setState({
                packageFilesNamesDeleted: [],
                releaseNotesNamesDeleted: [],
            });
        }
    }

    state = {
        name: this.props.existingPackage ? this.props.packageData.name : '',

        type: this.props.existingPackage ? this.props.packageData.type : '',
        editions: this.props.existingPackage ? this.props.packageEditions : [],
        states: this.props.existingPackage
            ? this.props.packageData.stateCodes
            : [],
        underwriterId:
            this.props.existingPackage && this.props.packageData.underwriter
                ? this.props.packageData.underwriter.id
                : 0,
        downloadNotes: this.props.existingPackage
            ? this.props.packageData.downloadNotes
            : '',
        internalNotes: this.props.existingPackage
            ? this.props.packageData.internalNotes
            : '',
        dynamicsCode: this.props.existingPackage
            ? this.props.packageData.dynamicsCode
            : '',
        amsRequired: this.props.existingPackage
            ? this.props.packageData.amsRequired
            : false,
        endorsementLookupsIncluded: this.props.existingPackage
            ? this.props.packageData.endorsementLookupsIncluded
            : false,
        mustBeInProductLine: this.props.existingPackage
            ? this.props.packageData.mustBeInProductLine
            : false,
        nameError: false,
        typeError: false,
        editionError: false,
        underwriterError: false,
        releaseNotesAccepted: [],
        packageFilesAccepted: [],
        existingFiles: this.props.existingPackage
            ? this.props.packageData.packageFileNames
            : [],
        existingNotes: this.props.existingPackage
            ? Array(1).fill(this.props.packageData.releaseNotesFileName)
            : [],
        releaseNotesNamesDeleted: [],
        packageFilesNamesDeleted: [],
        updateRevision: false,
    };

    onNameChange = (event) => {
        this.setState({
            name: event.target.value,
            nameError: false,
        });
    };

    onPackageTypeChange = (event) => {
        this.setState({
            type: event.target.value,
            typeError: false,
            underwriterError: false,
        });
    };

    onStateSelected = (value) => {
        if (!this.state.states.includes(value)) {
            this.setState({
                states: [...this.state.states, value],
            });
        } else {
            const states = this.state.states.filter((state) => state !== value);
            this.setState({
                states,
            });
        }
    };

    onEditionSelected = (value) => {
        if (!this.state.editions.includes(value)) {
            this.setState({
                ...this.state,
                editions: [...this.state.editions, value],
                editionError: false,
            });
        } else {
            const editions = this.state.editions.filter(
                (edition) => edition !== value,
            );
            this.setState({
                editions,
            });
        }
    };

    onUnderwriterSelected = (value) => {
        this.setState({
            underwriterId: value,
            underwriterError: false,
        });
    };

    onDownloadNotesChange = (event) => {
        this.setState({
            downloadNotes: event.target.value,
        });
    };

    onInternalNotesChange = (event) => {
        this.setState({
            internalNotes: event.target.value,
        });
    };

    onDynamicsCodeChange = (event) => {
        this.setState({
            dynamicsCode: event.target.value,
        });
    };

    onReleaseNotesDrop = (accepted) => {
        this.setState({
            releaseNotesAccepted: accepted,
        });
    };

    onPackageFilesDrop = (accepted) => {
        this.setState({
            packageFilesAccepted: combine(
                this.state.packageFilesAccepted,
                accepted,
            ),
        });
    };

    getDifferences = () => {
        const { packageData, packageEditions } = this.props;
        const {
            states = [],
            editions = [],
            downloadNotes,
            underwriterId,
            releaseNotesAccepted,
            releaseNotesNamesDeleted,
            packageFilesAccepted,
            packageFilesNamesDeleted,
            name,
            type,
            internalNotes,
            dynamicsCode,
            amsRequired,
            mustBeInProductLine,
            endorsementLookupsIncluded,
            updateRevision,
        } = this.state;

        const existingPackageData = {
            name: packageData.name,
            type: packageData.type,
            internalNotes: packageData.internalNotes,
            dynamicsCode: packageData.dynamicsCode,
            amsRequired: packageData.amsRequired,
            endorsementLookupsIncluded: packageData.endorsementLookupsIncluded,
            mustBeInProductLine: packageData.mustBeInProductLine,
            underwriterId: packageData.underwriter
                ? packageData.underwriter.id
                : null,
            downloadNotes: packageData.downloadNotes,
        };
        const newPackageData = {
            name,
            type,
            internalNotes,
            dynamicsCode,
            amsRequired,
            endorsementLookupsIncluded,
            mustBeInProductLine,
            downloadNotes,
            packageFilesNamesDeleted,
            releaseNotesNamesDeleted,
        };

        if (underwriterId) {
            newPackageData.underwriterId = underwriterId;
        } else {
            newPackageData.underwriterId = null;
        }
        const differences = updatedDiff(existingPackageData, newPackageData);
        const statesEqual = deepEqual(states, packageData.stateCodes);
        if (!statesEqual) {
            differences.states = states;
        }
        const editionsEqual = deepEqual(editions, packageEditions);
        if (!editionsEqual) {
            differences.editions = editions;
        }
        // Fix amsRequired and mustBeInProductLine difference if applicable
        // eslint-disable-next-line eqeqeq
        if (
            typeof differences.amsRequired !== 'undefined' &&
            // eslint-disable-next-line eqeqeq
            amsRequired == packageData.amsRequired
        ) {
            delete differences.amsRequired;
        }
        // eslint-disable-next-line eqeqeq
        if (
            typeof differences.mustBeInProductLine !== 'undefined' &&
            // eslint-disable-next-line eqeqeq
            mustBeInProductLine == packageData.mustBeInProductLine
        ) {
            delete differences.mustBeInProductLine;
        }

        if (releaseNotesAccepted && releaseNotesAccepted.length > 0) {
            differences.releaseNotes = releaseNotesAccepted;
        }
        if (packageFilesAccepted && packageFilesAccepted.length > 0) {
            differences.packageFiles = packageFilesAccepted;
        }
        if (releaseNotesNamesDeleted && releaseNotesNamesDeleted.length > 0) {
            differences.releaseNotesNamesDeleted = releaseNotesNamesDeleted;
        }
        if (packageFilesNamesDeleted && packageFilesNamesDeleted.length > 0) {
            differences.packageFilesNamesDeleted = packageFilesNamesDeleted;
        }
        if (updateRevision) {
            differences.updateRevision = 1;
        }
        return differences;
    };

    getNewPackageData = () => {
        const {
            states = [],
            editions = [],
            downloadNotes,
            underwriterId,
            releaseNotesAccepted,
            packageFilesAccepted,
            name,
            type,
            internalNotes,
            dynamicsCode,
            amsRequired,
            mustBeInProductLine,
            endorsementLookupsIncluded,
        } = this.state;
        const newData = {
            name,
            type,
            internalNotes,
            dynamicsCode,
            amsRequired,
            mustBeInProductLine,
            editions,
            states,
            downloadNotes,
            endorsementLookupsIncluded,
        };
        if (underwriterId) {
            newData.underwriterId = underwriterId;
        }
        if (releaseNotesAccepted) {
            newData.releaseNotes = releaseNotesAccepted;
        }
        if (packageFilesAccepted) {
            newData.packageFiles = packageFilesAccepted;
        }
        return newData;
    };

    differencesExist = () => {
        // Skip check if saving or redirecting after save
        if (this.props.saving || this.props.redirecting) {
            return false;
        }
        const differences = this.getDifferences();
        return (
            Object.keys(omit(differences, ['updateRevision'])).length > 0 &&
            !objectValuesAreEmpty(differences)
        );
    };

    onSubmit = (event) => {
        event.preventDefault();
        const {
            name = '',
            editions = [],
            type = '',
            underwriterId,
        } = this.state;
        const { existingPackage } = this.props;
        if (
            !name ||
            !type ||
            !editions ||
            editions.length === 0 ||
            (underwriterId === 0 && type === PACKAGE_TYPE_UNDERWRITER)
        ) {
            this.setState({
                nameError: !name,
                editionError: !editions || editions.length === 0,
                typeError: !type,
                underwriterError:
                    underwriterId === 0 && type === PACKAGE_TYPE_UNDERWRITER,
            });
            this.props.clearSuccessMessages();
        } else if (existingPackage) {
            /*
             * Its an existing package that we're updating here
             * First check for actual differences
             */
            const differences = this.getDifferences();
            if (Object.keys(differences).length > 0) {
                // There are differences to be saved
                this.props.updatePackageRequested(
                    this.props.packageId,
                    differences,
                );
            } else {
                const { snackbar } = this.props;
                snackbar.show('There are no changes to be saved.');
            }
        } else {
            // If we got here, its a new package with no missing value for required fields.
            const newData = this.getNewPackageData();
            this.props.createPackageRequested(newData);
        }
    };

    removeValueFromArray = (array, value) => {
        return array.filter((element) => element !== value);
    };

    render () {
        const {
            classes,
            saving,
            availableStates,
            availableUnderwriters,
            existingPackage,
            packageData: { releaseNotesFileName, packageFileNames },
            releaseNotesUrl,
            downloadUrl,
            formSuccessMessages,
        } = this.props;
        const {
            name,
            type,
            editions,
            states,
            underwriterId,
            downloadNotes,
            nameError,
            typeError,
            editionError,
            underwriterError,
            releaseNotesAccepted,
            packageFilesAccepted,
            updateRevision,
            releaseNotesNamesDeleted,
            packageFilesNamesDeleted,
            existingFiles,
            existingNotes,
            internalNotes,
            dynamicsCode,
            amsRequired,
            mustBeInProductLine,
            endorsementLookupsIncluded,
        } = this.state;
        const isSelectOnly =
            editions.length &&
            editions.length === 1 &&
            editions.indexOf(EDITION_SELECT) > -1;
        return (
            <Fragment>
                <Grid
                    container
                    direction="column"
                    component="form"
                    onSubmit={this.onSubmit}
                >
                    {formSuccessMessages.map((message) => (
                        <MessagePaper key={message} type="success">
                            {message}
                        </MessagePaper>
                    ))}
                    <Grid item>
                        <StyledPackageName
                            error={nameError}
                            name={name}
                            onChange={this.onNameChange}
                        />
                    </Grid>
                    <Grid item>
                        <StyledPackageType
                            value={type}
                            error={typeError}
                            onChange={this.onPackageTypeChange}
                        />
                    </Grid>
                    <Grid item>
                        <StyledEditions
                            editions={editions}
                            handleSelectedEdition={(event) =>
                                this.onEditionSelected(event.target.value)
                            }
                            handleDelete={(value) =>
                                this.onEditionSelected(value)
                            }
                            error={editionError}
                        />
                    </Grid>
                    <Grid item>
                        <StyledStates
                            states={states}
                            availableStates={availableStates}
                            handleSelectedState={(event) =>
                                this.onStateSelected(event.target.value)
                            }
                            handleDelete={(value) =>
                                this.onStateSelected(value)
                            }
                        />
                    </Grid>
                    <Grid item>
                        <StyledUnderwriterName
                            availableUnderwriters={availableUnderwriters}
                            value={underwriterId}
                            error={underwriterError}
                            onChange={(event) =>
                                this.onUnderwriterSelected(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item>
                        <StyledInternalNotes
                            onChange={this.onInternalNotesChange}
                            value={internalNotes}
                        />
                    </Grid>
                    <Grid item>
                        <StyledDynamicsCode
                            onChange={this.onDynamicsCodeChange}
                            value={dynamicsCode}
                        />
                    </Grid>
                    <Grid item>
                        <StyledAMSRequiredCheckbox
                            value={!!amsRequired}
                            onClick={() =>
                                this.setState({ amsRequired: !amsRequired })
                            }
                        />
                    </Grid>
                    <Grid item>
                        <StyledMustBeInProductLineCheckbox
                            value={!!mustBeInProductLine}
                            onClick={() =>
                                this.setState({
                                    mustBeInProductLine: !mustBeInProductLine,
                                })
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        className={classes.spacingTop}
                    >
                        {existingPackage && downloadUrl && (
                            <Grid
                                item
                                container
                                direction="column"
                                className={classes.spacingBottom}
                            >
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item>
                                        <Typography
                                            gutterBottom
                                            className={
                                                classes.fileDescriptorText
                                            }
                                        >
                                            Current ZIP File:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <StyledFileDownloadButton
                                            filename={name}
                                            url={downloadUrl}
                                        />
                                    </Grid>
                                </Grid>
                                {packageFileNames &&
                                    packageFileNames.length > 0 &&
                                    packageFilesNamesDeleted.length !==
                                        packageFileNames.length && (
                                    <Grid item container direction="row">
                                        <Grid item>
                                            <Typography
                                                gutterBottom
                                                className={
                                                    classes.fileNamesHeader
                                                }
                                            >
                                                    Saved ZIP Content - Files
                                                    with the same name will be
                                                    overwritten
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                        >
                                            {Array.isArray(existingFiles) &&
                                                    existingFiles.length > 0 &&
                                                    existingFiles.map(
                                                        (fileName, index) => (
                                                            <Grid
                                                                item
                                                                key={fileName}
                                                            >
                                                                <Chip
                                                                    key={index}
                                                                    label={
                                                                        fileName
                                                                    }
                                                                    onDelete={() => {
                                                                        const filter =
                                                                            this.removeValueFromArray(
                                                                                existingFiles,
                                                                                fileName,
                                                                            );
                                                                        this.setState(
                                                                            {
                                                                                existingFiles:
                                                                                    filter,
                                                                            },
                                                                        );
                                                                        this.setState(
                                                                            {
                                                                                packageFilesNamesDeleted:
                                                                                    packageFilesNamesDeleted.concat(
                                                                                        fileName,
                                                                                    ),
                                                                            },
                                                                        );
                                                                    }}
                                                                    deleteIcon={
                                                                        <ClearIcon />
                                                                    }
                                                                    variant="outlined"
                                                                    className={
                                                                        classes.chipStyle
                                                                    }
                                                                />
                                                            </Grid>
                                                        ),
                                                    )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <Grid item>
                            <StyledMultipleFileDropZone
                                label="Files to add in Package"
                                showLabel
                                onDrop={(accepted) =>
                                    this.onPackageFilesDrop(accepted)
                                }
                                accepted={packageFilesAccepted}
                            />
                        </Grid>
                        {((packageFilesAccepted &&
                            packageFilesAccepted.length > 0) ||
                            (packageFilesNamesDeleted &&
                                packageFilesNamesDeleted.length > 0)) && (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    className={classes.removeFilesButton}
                                    onClick={() => {
                                        this.setState({
                                            existingFiles: existingPackage
                                                ? packageFileNames
                                                : [],
                                        });
                                        this.setState({
                                            packageFilesNamesDeleted: [],
                                        });
                                        this.setState({
                                            packageFilesAccepted: [],
                                        });
                                    }}
                                >
                                    Reset File Selection
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <StyledDownloadNotes
                            onChange={this.onDownloadNotesChange}
                            value={downloadNotes}
                        />
                    </Grid>
                    <Grid container direction="column">
                        {existingPackage &&
                            releaseNotesFileName &&
                            releaseNotesUrl &&
                            releaseNotesNamesDeleted.length !== 1 && (
                            <Grid
                                container
                                alignItems="center"
                                className={classes.spacingBottom}
                            >
                                <Grid item>
                                    <Typography
                                        gutterBottom
                                        className={
                                            classes.fileDescriptorText
                                        }
                                    >
                                            Current Release Notes PDF File:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <StyledFileDownloadButton
                                        filename={releaseNotesFileName}
                                        url={releaseNotesUrl}
                                    />
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography
                                            gutterBottom
                                            className={
                                                classes.fileNamesHeader
                                            }
                                        >
                                                Saved Release Notes - File will
                                                be overwritten
                                        </Typography>
                                    </Grid>
                                    {existingNotes.map(
                                        (fileName, index) => (
                                            <Grid item key={fileName}>
                                                <Chip
                                                    key={index}
                                                    label={fileName}
                                                    onDelete={() => {
                                                        const filter =
                                                                this.removeValueFromArray(
                                                                    existingNotes,
                                                                    fileName,
                                                                );
                                                        this.setState({
                                                            existingNotes:
                                                                    filter,
                                                        });
                                                        this.setState({
                                                            releaseNotesNamesDeleted:
                                                                    releaseNotesNamesDeleted.concat(
                                                                        fileName,
                                                                    ),
                                                        });
                                                    }}
                                                    deleteIcon={
                                                        <ClearIcon />
                                                    }
                                                    variant="outlined"
                                                    className={
                                                        classes.chipStyle
                                                    }
                                                />
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <StyledSingleFileDropZone
                                label="Release Notes PDF"
                                showLabel
                                accept={'.pdf'}
                                onDrop={(accepted) =>
                                    this.onReleaseNotesDrop(accepted)
                                }
                                accepted={releaseNotesAccepted}
                            />
                        </Grid>
                        {((releaseNotesAccepted &&
                            releaseNotesAccepted.length > 0) ||
                            (releaseNotesNamesDeleted &&
                                releaseNotesNamesDeleted.length > 0)) && (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    className={classes.removeFilesButton}
                                    onClick={() => {
                                        this.setState({
                                            existingNotes: existingPackage
                                                ? Array(1).fill(
                                                    releaseNotesFileName,
                                                )
                                                : [],
                                        });
                                        this.setState({
                                            releaseNotesNamesDeleted: [],
                                        });
                                        this.setState({
                                            releaseNotesAccepted: [],
                                        });
                                    }}
                                >
                                    Reset File Selection
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container direction="column">
                        {existingPackage && (
                            <Grid item>
                                <StyledRevisionCheckbox
                                    value={!!updateRevision}
                                    onClick={() =>
                                        this.setState({
                                            updateRevision: !updateRevision,
                                        })
                                    }
                                />
                            </Grid>
                        )}
                        {isSelectOnly
                            ? (
                                <Grid item>
                                    <StyledGenericCheckbox
                                        label="Endorsement Lookups Included"
                                        value={!!endorsementLookupsIncluded}
                                        onClick={() =>
                                            this.setState({
                                                endorsementLookupsIncluded:
                                                !endorsementLookupsIncluded,
                                            })
                                        }
                                        title="Check this box if the package contains endorsement lookups."
                                    />
                                </Grid>
                            )
                            : null}
                        <Grid
                            item
                            component={Divider}
                            className={classes.divider}
                        />
                        <Grid item>
                            <StyledSaveButton updating={saving} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* TODO: add back Prompt for React Router v6
                <Prompt
                    message={
                        `You have ${existingPackage
                            ? 'modified'
                            : 'added'} this package without saving. ` +
                        'Click Cancel to stop and Save your changes, or click OK to discard your changes.'
                    }
                    when={this.differencesExist()}
                /> */}
            </Fragment>
        );
    }
}

PackageForm.propTypes = {
    saving: PropTypes.bool,
    redirecting: PropTypes.bool,
    packageData: PropTypes.object,
    packageEditions: PropTypes.array,
    snackbar: PropTypes.object.isRequired,
    packageId: PropTypes.string.isRequired,
    releaseNotesUrl: PropTypes.string,
    formSuccessMessages: PropTypes.array.isRequired,
    clearSuccessMessages: PropTypes.func.isRequired,
    existingPackage: PropTypes.bool,
    createPackageRequested: PropTypes.func,
    updatePackageRequested: PropTypes.func,
    classes: PropTypes.object,
    availableStates: PropTypes.array,
    availableUnderwriters: PropTypes.array,
    downloadUrl: PropTypes.string,
};
const SnackbarPackageForm = withSnackbar()(PackageForm);

class Package extends React.Component {
    componentDidMount () {
        this.props.fetchStates();
        this.props.fetchUnderwriters();
        if (this.props.existingPackage) {
            this.props.fetchPackageRequested(this.props.packageId);
        } else {
            this.props.clearSuccessMessages();
        }
    }

    render () {
        const {
            navigate,
            router,
            classes,
            availableStates,
            availableUnderwriters,
            existingPackage,
            loading,
            saving,
            redirecting,
            packageData,
            packageEditions,
            updatePackageRequested,
            createPackageRequested,
            releaseNotesUrl,
            downloadUrl,
            formSuccessMessages,
            clearSuccessMessages,
            packageId,
        } = this.props;
        return (
            <Grid container direction="column">
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.top}
                >
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <Link
                                to="/admin/packages"
                                className={classes.breadcrumb}
                            >
                                Packages
                            </Link>
                        </Grid>
                        <Grid item>
                            <ArrowIcon className={classes.arrow} />
                        </Grid>
                        <Grid item>
                            <Link
                                to={getUrl(window, router)}
                                className={classes.breadcrumbActive}
                            >
                                {existingPackage
                                    ? loading
                                        ? 'Loading...'
                                        : packageData.name
                                    : 'Create a Package'}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BackButton
                            className={classes.backButton}
                            onClick={() => navigate('/admin/packages')}
                        >
                            Back
                        </BackButton>
                    </Grid>
                </Grid>
                {existingPackage && loading
                    ? (
                        <Grid item>
                            {' '}
                            <CircularProgress />{' '}
                        </Grid>
                    )
                    : (
                        <Grid item>
                            <SnackbarPackageForm
                                classes={classes}
                                availableStates={availableStates}
                                availableUnderwriters={availableUnderwriters}
                                existingPackage={existingPackage}
                                loading={loading}
                                saving={saving}
                                redirecting={redirecting}
                                packageData={packageData}
                                packageEditions={packageEditions}
                                updatePackageRequested={updatePackageRequested}
                                createPackageRequested={createPackageRequested}
                                packageId={packageId}
                                releaseNotesUrl={releaseNotesUrl}
                                downloadUrl={downloadUrl}
                                formSuccessMessages={formSuccessMessages}
                                clearSuccessMessages={clearSuccessMessages}
                            />
                        </Grid>
                    )}
            </Grid>
        );
    }
}

Package.propTypes = {
    navigate: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    availableStates: PropTypes.array.isRequired,
    existingPackage: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    redirecting: PropTypes.bool.isRequired,
    fetchPackageRequested: PropTypes.func.isRequired,
    packageData: PropTypes.object,
    packageEditions: PropTypes.array,
    releaseNotesUrl: PropTypes.string,
    downloadUrl: PropTypes.string,
    formSuccessMessages: PropTypes.array.isRequired,
    clearSuccessMessages: PropTypes.func.isRequired,
    updatePackageRequested: PropTypes.func,
    createPackageRequested: PropTypes.func,
    fetchStates: PropTypes.func,
    fetchUnderwriters: PropTypes.func,
    availableUnderwriters: PropTypes.array,
    packageId: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        availableStates: getAvailableStates(state),
        availableUnderwriters: getAvailableUnderwriters(state),
        loading: state.adminPackages.package.loading,
        saving: state.adminPackages.package.saving,
        redirecting: state.adminPackages.package.redirecting,
        packageData: state.adminPackages.package.packageData,
        packageEditions: getPackageEditions(state),
        releaseNotesUrl: getReleaseNotesUrl(state),
        downloadUrl: getDownloadUrl(state),
        formSuccessMessages: getAdminPackageSuccessMessages(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchStates,
                fetchUnderwriters,
                fetchPackageRequested,
                updatePackageRequested,
                createPackageRequested,
                clearSuccessMessages,
            },
            dispatch,
        ),
    };
};

const PackageFunction = (props) => {
    const { packageId } = useParams();
    const navigate = useNavigate();
    return <Package {...props} packageId={packageId} navigate={navigate} />;
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(PackageFunction);
