import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { requestWithAuth } from '../utils/api';
import { getAuthToken } from '../selectors/auth';
import {
    CREATE_ADMIN_USER_REQUESTED,
    createAdminUserFailed,
    createAdminUserSucceeded,
} from '../stores/adminCreateAdmin/actions';
import { getAdminCreateAdminData } from '../selectors/adminCreateAdmin';
import { validateEmail, validateNonEmptyString } from '../utils/validation';
import { objectValuesAreEmpty } from '../utils';

function validateAdminUserData (
    {
        name = '',
        email = '',
        organization = '',
        password = '',
        passwordConfirmation = '',
    },
) {
    const errors = {
        name: [],
        email: [],
        organization: [],
        password: [],
        passwordConfirmation: [],
    };

    const data = {
        name: validateNonEmptyString(name),
        email: validateEmail(email),
        organization: validateNonEmptyString(organization),
        password,
        passwordConfirmation,
    };

    if (!data.name) {
        errors.name.push('Name is required.');
    }

    if (!data.email) {
        errors.email.push(!email ? 'Email is required.' : 'Email must be a valid email.');
    }

    if (!data.organization) {
        errors.organization.push('Organization is required.');
    }

    if (!validateNonEmptyString(data.password)) {
        errors.password.push('Password is required.');
    } else if (!validateNonEmptyString(data.passwordConfirmation)) {
        errors.passwordConfirmation.push('Please retype the password to confirm.');
    } else if (data.password !== data.passwordConfirmation) {
        errors.passwordConfirmation.push('Passwords do not match.');
    }

    if (objectValuesAreEmpty(errors)) {
        return data;
    } else {
        throw errors;
    }
}

function * createAdminUser () {
    const rawData = yield select(getAdminCreateAdminData);
    let data = null;

    try {
        data = yield call(validateAdminUserData, rawData);
    } catch (errors) {
        yield put(createAdminUserFailed(errors));
        return;
    }

    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/admin/adminUser',
        'POST',
        token,
        null,
        data,
    );
    if (response.ok) {
        const { email } = yield call([response, response.json]);
        yield put(createAdminUserSucceeded(email));
    } else {
        if (response.status === 400) {
            const { validationErrors: fieldErrors = {} } = yield call([response, response.json]);
            yield put(createAdminUserFailed(fieldErrors));
        } else {
            yield put(snackbar.show({
                message: 'Creating the new Admin has failed for an unknown reason. Try again later.',
            }));
            yield put(createAdminUserFailed());
        }
    }
}

export default function * () {
    yield all([
        takeEvery(CREATE_ADMIN_USER_REQUESTED, createAdminUser),
    ]);
}
