import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../DataTable';
import KeyedDataTableSelector from '../DataTable/KeyedDataTableSelector';
import SearchDraftManager from '../DataTable/SearchDraftManager';
import { has, noop } from '../../utils';
import ApiPropTypes from '../../propTypes/api';
import { SORT_ASC, SORT_DESC } from '../DataTable/constants';
import { ORDER_BY_DIR_ASC, ORDER_BY_DIR_DESC } from '../../constants/api';
import TablesPropTypes from '../../propTypes/tables';
import { debugWarn } from '../../utils/log';

export const COMPONENT_NAME = 'SoftProDataTable';

class SoftProDataTable extends Component {
    static propTypes = {
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        getKey: PropTypes.func.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,
        onActionSelect: PropTypes.func,
        isRowSelectable: PropTypes.func,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        onAddWatchlistRequested: noop,
        onActionSelect: noop,
        isRowSelectable: null,
    };

    getSearch = () => {
        return (this.props.tableContext.filters && this.props.tableContext.filters.search)
            ? this.props.tableContext.filters.search
            : '';
    };

    getFilterValue = ({ key }) => {
        if (this.props.tableContext.filters && has(this.props.tableContext.filters, key)) {
            return this.props.tableContext.filters[key] || '';
        }
        return '';
    };

    /**
     * Create a function that will call onFetchDataRequested with the current table context + the results of
     * the provided `update` function merged into a specific slice (`contextKey`) of it (i.e. `pagination` or
     * `filters` or `order`).
     *
     * By default, the pagination will be reset to the first page. Use `resetToFirstPage` to disable this behaviour.
     *
     * @param {string} contextKey
     * @param {function(...*): {}} update
     * @param {boolean=true} resetToFirstPage
     * @return {function(...*): void}
     */
    createRefetcher = (contextKey, update, resetToFirstPage = true) => (...updateArgs) => {
        const { onFetchDataRequested, tableContext } = this.props;
        onFetchDataRequested({
            ...tableContext,
            [contextKey]: {
                ...tableContext[contextKey],
                ...update(...updateArgs),
            },
            ...(resetToFirstPage
                ? {
                    pagination: {
                        ...tableContext.pagination,
                        page: 1,
                    },
                }
                : {}),
        });
    };

    createOnSort = (orderByFieldKey) => this.createRefetcher(
        'order',
        (nextSortDir) => ({
            orderBy: orderByFieldKey,
            orderByDir: nextSortDir === SORT_ASC ? ORDER_BY_DIR_ASC : ORDER_BY_DIR_DESC,
        }),
    );

    createSortGetter = (orderByFieldKey) => () => {
        const { tableContext: { order: { orderBy, orderByDir } } } = this.props;
        if (orderBy === orderByFieldKey) {
            return orderByDir === ORDER_BY_DIR_ASC ? SORT_ASC : SORT_DESC;
        }
        return false;
    };

    onFilterChange = this.createRefetcher(
        'filters',
        (value, { key }) => ({
            [key]: value,
        }),
    );

    onSearchSubmit = this.createRefetcher('filters', (search) => ({ search }));
    onPageChange = this.createRefetcher('pagination', (page) => ({ page }), false);

    render () {
        const {
            getKey,
            isRowSelectable,
            children: renderTable = null,
            tableData,
            onActionSelect,
            ...rest
        } = this.props;

        const { loading, data, currentPage, total, perPage } = tableData;

        const searchDraftManagerProps = {
            search: this.getSearch(),
            onSearchSubmit: this.onSearchSubmit,
        };
        const keyedDataTableSelectorProps = {
            data,
            getKey,
            onActionSelect,
            isRowSelectable,
        };
        const dataTableProps = {
            pagination: { page: currentPage, total, perPage, onChange: this.onPageChange },
            getFilterValue: this.getFilterValue,
            onFilterChange: this.onFilterChange,
            loading,
            ...rest,
        };

        if (typeof renderTable !== 'function' || React.isValidElement(renderTable)) {
            debugWarn(`Unexpected non-function child of ${COMPONENT_NAME}:`, renderTable);
        }

        const renderTableProps = {
            createRefetcher: this.createRefetcher,
            createOnSort: this.createOnSort,
            createSortGetter: this.createSortGetter,
        };

        return (
            <SearchDraftManager {...searchDraftManagerProps}>
                {(searchDraftManagerDataTableProps) => (
                    <KeyedDataTableSelector {...keyedDataTableSelectorProps}>
                        {(keyedDataTableProps) => (
                            <DataTable
                                {...keyedDataTableProps}
                                {...searchDraftManagerDataTableProps}
                                {...dataTableProps}
                            >
                                {renderTable(renderTableProps)}
                            </DataTable>
                        )}
                    </KeyedDataTableSelector>
                )}
            </SearchDraftManager>
        );
    }
}

export default SoftProDataTable;
