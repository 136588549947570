/**
 * Log something out to the console but only in development environment.
 * @param {string} level The name of a console method for logging.
 * @param {...*} args
 */
export const debugConsole = (level, ...args) => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console[level](...args);
    }
};

export const debugLog = (...args) => debugConsole('log', ...args);

export const debugError = (...args) => debugConsole('error', ...args);

export const debugWarn = (...args) => debugConsole('warn', ...args);
