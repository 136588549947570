import {
    FETCH_ACTIONS,
    FETCH_WATCHLIST,
    FETCH_WATCHLIST_FAILED,
    FETCH_WATCHLIST_SUCCEEDED,
    LOADING_UPDATE_DONE_ACTIONS,
    LOADING_UPDATE_START_ACTIONS,
    PREFIX,
    UPDATE_WATCHLIST,
} from './actions';
import EDITIONS from '../../constants/editions';
import PACKAGE_TYPES from '../../constants/packageTypes';
import { createApiPageData } from '../../utils/api';
import { LOGOUT } from '../auth/actions';

const watchlistInitialState = {
    loadingUpdate: [],
    ...(EDITIONS.reduce((editionsInitialState, edition) => {
        return {
            ...editionsInitialState,
            [edition]: PACKAGE_TYPES.reduce((packageTypesInitialState, packageType) => {
                return {
                    ...packageTypesInitialState,
                    [packageType]: createApiPageData({ loading: false, order: {}, filters: {} }),
                };
            }, {}),
        };
    }, {})),
};

/**
 * Create a function which updates a specific slice of the watchlist state identified by the
 * edition and package type.
 *
 * @param {{}} state
 * @param {string} edition
 * @param {string} packageType
 * @return {function(...{}): {}} A function which will return the new state.
 */
const createWatchlistSliceUpdater = (state, edition, packageType) => (...updates) => {
    return {
        ...state,
        [edition]: {
            ...state[edition],
            [packageType]: {
                ...updates.reduce((current, next) => ({ ...current, ...next }), state[edition][packageType]),
            },
        },
    };
};

/**
 * Returns the new state, adding or removing an array of package IDs from the the state's
 * loadingUpdate parameter which tracks packages in the watchlist that have pending API udpates.
 *
 * @param {{}} state
 * @param {int[]} packageIds
 * @param {bool=true} loading
 * @return {{}} The new state
 */
const setLoadingUpdate = (state, packageIds, loading = true) => ({
    ...state,
    loadingUpdate: loading
        ? [...(new Set(state.loadingUpdate.concat(packageIds)))]
        : state.loadingUpdate.filter((packageId) => !packageIds.includes(packageId)),
});

export function watchlist (state = watchlistInitialState, action) {
    const { type } = action;

    if (type.slice(0, PREFIX.length) === PREFIX) {
        if (FETCH_ACTIONS.includes(type)) {
            const { data = {}, edition, packageType, filters = {}, order = {} } = action;
            const update = createWatchlistSliceUpdater(state, edition, packageType);

            if (type === FETCH_WATCHLIST) {
                return update({
                    loading: true,
                    order,
                    filters,
                });
            } else if (type === FETCH_WATCHLIST_SUCCEEDED) {
                return update(
                    createApiPageData(data.result),
                    { loading: false, filters, order },
                );
            } else if (type === FETCH_WATCHLIST_FAILED) {
                return update({ loading: false });
            }
        } else if (type === UPDATE_WATCHLIST) {
            const packageIds = Object.keys(action.packages).map((id) => parseInt(id, 10));
            return setLoadingUpdate(state, packageIds, true);
        } else if (LOADING_UPDATE_START_ACTIONS.includes(type)) {
            return setLoadingUpdate(state, action.packageIds, true);
        } else if (LOADING_UPDATE_DONE_ACTIONS.includes(type)) {
            return setLoadingUpdate(state, action.packageIds, false);
        }
    }

    if (type === LOGOUT) {
        return watchlistInitialState;
    }

    return state;
}
