import {
    ANALYTICS_DATA_FOR_PACKAGE_REQUEST_FAILED,
    ANALYTICS_DATA_FOR_PACKAGE_REQUEST_SUCCEEDED,
    ANALYTICS_DATA_FOR_PACKAGE_REQUESTED,
    ANALYTICS_DATA_REQUEST_FAILED,
    ANALYTICS_DATA_REQUEST_SUCCEEDED,
    ANALYTICS_DATA_REQUESTED,
    ANALYTICS_USER_DATA_REQUEST_SUCCEEDED,
    DOWNLOADS_FOR_USER_REQUEST_FAILED,
    DOWNLOADS_FOR_USER_REQUEST_SUCCEEDED,
    DOWNLOADS_FOR_USER_REQUESTED,
} from './actions';
import { LOGOUT } from '../auth/actions';
import { UPDATE_RANGE_ANALYTICS } from '../admin/actions';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';
import { createApiPageData } from '../../utils/api';

export const analyticsInitialState = {
    downloads: {
        ...createApiPageData({
            loading: false,
            perPage: DEFAULT_ROWS_PER_PAGE,
        }),
    },
    users: {
        users: [],
        ...createApiPageData({
            loading: false,
            perPage: DEFAULT_ROWS_PER_PAGE,
            error: false,
        }),
    },
    notifications: {
        ...createApiPageData({
            loading: false,
            perPage: DEFAULT_ROWS_PER_PAGE,
        }),
    },
    downloadsForPackage: {
        name: '',
        ...createApiPageData({
            loading: false,
            perPage: DEFAULT_ROWS_PER_PAGE,
        }),
    },
    notificationsForPackage: {
        name: '',
        ...createApiPageData({
            loading: false,
            perPage: DEFAULT_ROWS_PER_PAGE,
        }),
    },
    downloadsForUserEmail: '',
    downloadsForUser: {
        ...createApiPageData({
            loading: false,
            perPage: DEFAULT_ROWS_PER_PAGE,
        }),
    },
};

export function analytics (state = analyticsInitialState, action) {
    const { dataType } = action;
    switch (action.type) {
        case UPDATE_RANGE_ANALYTICS: // Intentional fall through
        case ANALYTICS_DATA_REQUESTED:
            return {
                ...state,
                [dataType]: {
                    ...state[dataType],
                    loading: true,
                },
            };
        case ANALYTICS_DATA_REQUEST_SUCCEEDED:
            return {
                ...state,
                [dataType]: {
                    ...state[dataType],
                    ...createApiPageData(action.data),
                    filters: action.filters || {},
                    order: action.order || {},
                    loading: false,
                },
            };
        case ANALYTICS_USER_DATA_REQUEST_SUCCEEDED: {
            const { users } = action;
            return {
                ...state,
                users: {
                    ...state[dataType],
                    users,
                    ...createApiPageData(action.data),
                    filters: action.filters || {},
                    order: action.order || {},
                    loading: false,
                },
            };
        }
        case ANALYTICS_DATA_REQUEST_FAILED:
            return {
                ...state,
                [dataType]: {
                    ...state[dataType],
                    loading: false,
                    error: true,
                },
            };
        case ANALYTICS_DATA_FOR_PACKAGE_REQUESTED:
            return {
                ...state,
                [dataType + 'ForPackage']: {
                    name: '',
                    ...state[dataType + 'ForPackage'],
                    loading: true,
                },
            };
        case ANALYTICS_DATA_FOR_PACKAGE_REQUEST_SUCCEEDED: {
            const { name } = action;
            return {
                ...state,
                [dataType + 'ForPackage']: {
                    ...state[dataType + 'ForPackage'],
                    name,
                    ...createApiPageData(action.data),
                    filters: action.filters || {},
                    order: action.order || {},
                    loading: false,
                },
            };
        }
        case ANALYTICS_DATA_FOR_PACKAGE_REQUEST_FAILED:
            return {
                ...state,
                [dataType + 'ForPackage']: {
                    ...state[dataType + 'ForPackage'],
                    loading: false,
                },
            };
        case DOWNLOADS_FOR_USER_REQUESTED:
            return {
                ...state,
                downloadsForUserEmail: '',
                downloadsForUser: {
                    ...state.downloadsForUser,
                    loading: true,
                },
            };
        case DOWNLOADS_FOR_USER_REQUEST_SUCCEEDED: {
            const { email } = action;
            return {
                ...state,
                downloadsForUserEmail: email,
                downloadsForUser: {
                    ...state.downloadsForUser,
                    ...createApiPageData(action.data),
                    filters: action.filters || {},
                    order: action.order || {},
                    loading: false,
                },
            };
        }
        case DOWNLOADS_FOR_USER_REQUEST_FAILED:
            return {
                ...state,
                downloadsForUser: {
                    ...state.downloadsForUser,
                    loading: false,
                },
            };
        case LOGOUT:
            return analyticsInitialState;
        default:
            return state;
    }
}
