import { formatDateToTimestamp, has } from './index';
import { DateTime } from 'luxon';

export const convertToShortDate = (timeInMillis) => {
    if (timeInMillis && !isNaN(timeInMillis)) {
        return DateTime.fromMillis(parseInt(timeInMillis)).toFormat('MM/dd');
    }
    return timeInMillis;
};

export const dateTickFormatter = (value) => {
    return convertToShortDate(value);
};

export const labelFormatter = (value) => {
    return convertToShortDate(value);
};

export function convertDatesToTimestamps (data = [], key = 'occurredAt') {
    return data.map((object) => {
        if (has(object, key)) {
            return { ...object, [key]: formatDateToTimestamp(object[key]) };
        }
        return object;
    });
}

/**
 * Converts data for Admin charts on the Dashboard and Analytics tabs.
 *
 * @param data
 * @param dataKey
 * @returns {Array}
 */
export function convertChartData (data = [], dataKey = 'occurredAt') {
    const countByDay = data.reduce((accumulator = {}, stat) => {
        return {
            ...accumulator,
            [stat[dataKey]]: has(accumulator, stat[dataKey]) ? (accumulator[stat[dataKey]] + 1) : 1,
        };
    }, {});
    return Object.entries(countByDay).map(([key, total]) => ({ [dataKey]: key, total }));
}
