import { theme } from '../../theme';

export const styles = () => ({
    softwareVersionSelector: {
        height: 'auto',
        paddingBottom: 10,
        width: 'auto',
        color: '#fff',
    },
    imageButton: {
        width: 225,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        overflow: 'hidden',
        borderRadius: 5,
        margin: '0 20px',
        [theme.breakpoints.up('lg')]: {
            width: 275,
        },
    },
    imageButtonStandard: {
        backgroundColor: '#002244',
        border: '1px solid #002244',
    },
    imageButtonSelect: {
        backgroundColor: '#F33E36',
        border: '1px solid #F33E36',
    },
    chooseTitle: {
        marginBottom: 15,
        [theme.breakpoints.up('lg')]: {
            marginBottom: 30,
        },
    },
    imageButtonIcon: {
        height: 65,
        width: 'auto',
        [theme.breakpoints.up('lg')]: {
            height: 90,
        },
    },
    imageButtonIconContainer: {
        textAlign: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        [theme.breakpoints.up('lg')]: {
            paddingTop: 30,
            paddingBottom: 15,
        },
    },
    imageButtonTextContainer: {
        paddingBottom: 25,
    },
    imageButtonText: {
        marginTop: 10,
        fontSize: 18,
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
        },
    },
    imageButtonCheckContainer: {
        backgroundColor: '#fff',
        paddingTop: 2,
        paddingBottom: 2,
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
});
