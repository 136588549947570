import { all, call } from 'redux-saga/effects';
import { init } from './app';
import auth from './auth';
import watchlist from './watchlist';
import { listenFetchStates } from './states';
import { listenFetchUnderwriters } from './underwriters';
import {
    listenAddPackagesToWatchlist,
    listenFetchSuggestedWatchlist,
    listenRedirectToPortal,
} from './onBoardingWatchlist';
import { listenFetchPackages } from './packages';
import { listenFetchFaq } from './faq';
import { listenFetchVideos } from './videos';
import { listenFetchSettings, listenUpdateSettings } from './settings';
import { listenFetchRecentDownloads } from './recentDownloads';
import { listenAdminDashboardDataRequested } from './adminDashboard';
import { listenDownload } from './download';
import {
    listenAnalyticsDataRequested,
    listenExportAnalytics,
    listenFetchAnalyticsForPackage,
    listenFetchDownloadsForUser,
} from './analytics';
import { listenFetchUsersData, listenUpdateUserData } from './users';
import { listenPromptsFileActions } from './promptsFile';
import {
    listenCreateUpdatePackage,
    listenDeletePackages,
    listenFetchAdminPackages,
    listenRedirectToEditPackage,
} from './adminPackages';
import adminUnderwriters from './adminUnderwriters';
import adminCreateAdmin from './adminCreateAdmin';
import changePassword from './changePassword';

/**
 *
 * @param {Persistor} persistor
 * @return {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function * rootSaga (persistor) {
    yield all([
        call(init, persistor),
        call(auth),
        call(watchlist),
        call(listenFetchStates),
        call(listenFetchUnderwriters),
        call(listenFetchSuggestedWatchlist),
        call(listenAddPackagesToWatchlist),
        call(listenRedirectToPortal),
        call(listenFetchPackages),
        call(listenFetchFaq),
        call(listenFetchVideos),
        call(listenFetchSettings),
        call(listenUpdateSettings),
        call(listenFetchRecentDownloads),
        call(listenAdminDashboardDataRequested),
        call(listenDownload),
        call(listenAnalyticsDataRequested),
        call(listenFetchUsersData),
        call(listenPromptsFileActions),
        call(listenUpdateUserData),
        call(listenExportAnalytics),
        call(listenFetchAdminPackages),
        call(listenDeletePackages),
        call(listenCreateUpdatePackage),
        call(listenRedirectToEditPackage),
        call(listenFetchAnalyticsForPackage),
        call(listenFetchDownloadsForUser),
        call(adminUnderwriters),
        call(adminCreateAdmin),
        call(changePassword),
    ]);
}
