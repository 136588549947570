import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormPropTypes from '../../propTypes/forms';
import AdminCreateAdminForm from '../../components/AdminCreateAdminForm';
import {
    createAdminUserFieldChanged,
    createAdminUserFormClearRequested,
    createAdminUserRequested,
} from '../../stores/adminCreateAdmin/actions';
import {
    getAdminCreateAdminData,
    getAdminCreateAdminFieldErrors,
    getAdminCreateAdminFormErrors,
    getAdminCreateAdminFormSuccessMessages,
    isAdminCreateAdminLoading,
} from '../../selectors/adminCreateAdmin';

class CreateAdmin extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        formErrors: FormPropTypes.errors,
        formSuccessMessages: FormPropTypes.messages,
        fieldErrors: PropTypes.shape({
            name: FormPropTypes.errors,
            email: FormPropTypes.errors,
            organization: FormPropTypes.errors,
            password: FormPropTypes.errors,
            passwordConfirmation: FormPropTypes.errors,
        }),
        data: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            organization: PropTypes.string,
            password: PropTypes.string,
            passwordConfirmation: PropTypes.string,
        }).isRequired,
    };

    componentDidMount () {
        const { loading, onReset } = this.props;
        if (!loading) {
            onReset();
        }
    }

    getFormData = () => {
        const {
            name = '',
            email = '',
            organization = '',
            password = '',
            passwordConfirmation = '',
        } = this.props.data;
        return {
            name,
            email,
            organization,
            password,
            passwordConfirmation,
        };
    };

    render () {
        const {
            loading,
            formErrors,
            formSuccessMessages,
            fieldErrors,
            onFieldChange,
            onSubmit,
        } = this.props;

        const formProps = {
            data: this.getFormData(),
            loading,
            formErrors,
            formSuccessMessages,
            fieldErrors,
            onSubmit,
            onChange: onFieldChange,
        };

        return <AdminCreateAdminForm {...formProps} />;
    }
}

const mapStateToProps = (state) => ({
    data: getAdminCreateAdminData(state),
    loading: isAdminCreateAdminLoading(state),
    formErrors: getAdminCreateAdminFormErrors(state),
    formSuccessMessages: getAdminCreateAdminFormSuccessMessages(state),
    fieldErrors: getAdminCreateAdminFieldErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(createAdminUserRequested()),
    onFieldChange: (field, value) => dispatch(createAdminUserFieldChanged(field, value)),
    onReset: () => dispatch(createAdminUserFormClearRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin);
