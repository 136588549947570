import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ArrowLeft as LeftIcon, ArrowRight as RightIcon } from '@mui/icons-material';
import RCPagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import classNames from 'classnames';
import styles from './styles';

const withPaginationStyles = (name) => withStyles(styles, { name });

const PaginationItem = withPaginationStyles('PaginationItem')(
    ({ active, number, type, element, classes }) => {
        if (type === 'page') {
            return (
                <Button
                    color="primary"
                    variant="text"
                    className={classNames([classes.item, active ? classes.activeItem : null])}
                >
                    {number}
                </Button>
            );
        } else if (type === 'prev') {
            return <LeftIcon color="primary" className={classes.arrow} />;
        } else if (type === 'next') {
            return <RightIcon color="primary" className={classes.arrow} />;
        } else {
            return element;
        }
    },
);

const paginationItemRender = (current) => function DynamicPaginationItem (number, type, element) {
    return <PaginationItem active={current && current === number} number={number} type={type} element={element} />;
};

const Pagination = ({ page, perPage, total, ...restProps }) => (
    <RCPagination
        locale={localeInfo}
        itemRender={paginationItemRender(page || restProps.current || restProps.defaultCurrent || null)}
        hideOnSinglePage
        current={page}
        pageSize={perPage}
        total={total}
        showPrevNextJumpers={false}
        {...restProps}
    />
);

Pagination.propTypes = {
    // All RCPagination propTypes and also:
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default withPaginationStyles('Pagination')(Pagination);
