import { has } from './index';

/**
 * Merge two entities. If a schema is provided, we'll use it's merge function to do so which
 * accounts for a schema entity's `mergeStrategy`.
 *
 * @see https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#entitykey-definition---options--
 *
 * @param {{}} entityA
 * @param {{}} entityB
 * @param {schema.Entity=} schema
 * @return {{}}
 */
export function mergeEntity (entityA, entityB, schema = null) {
    return schema
        ? schema.merge(entityA, entityB)
        : {
            ...(entityA || {}),
            ...(entityB || {}),
        };
}

/**
 * Merge two entity "tables". A "table" is a plain object whose keys are entity IDs and whose values are the
 * corresponding entity with that ID. All entity values should be of the same schema Entity.
 *
 * If a schema entity is provided as the final argument, it's merge strategy will be used to merge
 * any entities that exist in the old table and the new table.
 *
 * @param {{}} oldTable
 * @param {{}} newTable
 * @param {schema.Entity=} schema
 * @return {{}}
 */
export function mergeEntityTable (oldTable, newTable, schema = null) {
    return Object.entries(newTable).reduce((mergedTable, [id, entity]) => {
        return {
            ...mergedTable,
            [id]: mergeEntity(mergedTable[id] || {}, entity, schema),
        };
    }, oldTable);
}

/**
 * Merge two entity "databases". A "database" is a plain object whose keys are entity keys (the first argument
 * to the `schema.Entity` constructor and whose values are entity "tables". This kind of object is what's returned
 * by a call `normalize` in the `entities` key.
 *
 * If the `schema` argumen is provided, it should be a map of schema keys to their actual `schema.Entity` instance and
 * it will be used to resolve an `Entity` per table to utilize to merge entity objects within entity tables
 * intelligently. If not provided, a basic spread merge of new on top of old will be used.
 *
 * @param {{}} oldDatabase
 * @param {{}} newDatabase
 * @param {{}=} schemas
 * @return {{}}
 */
export function mergeEntityDatabase (oldDatabase, newDatabase, schemas = {}) {
    return Object.entries(newDatabase).reduce((mergedDatabase, [schemaKey, table]) => {
        return {
            ...mergedDatabase,
            [schemaKey]: mergeEntityTable(
                mergedDatabase[schemaKey] || {},
                table,
                has(schemas, schemaKey) ? schemas[schemaKey] : null,
            ),
        };
    }, oldDatabase);
}
