import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = {
    root: {
        height: 46,
        width: 42,
    },
};

const EmptyIcon = ({ classes }) => <div className={classes.root} />;

EmptyIcon.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmptyIcon);
