export const styles = {
    emptyTable: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#DFDFDF',
        alignItems: 'center',
        padding: '100px 0px',
        flex: 1,
    },
};
