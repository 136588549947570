import { FETCH_PACKAGES_REQUEST_FAILED, FETCH_PACKAGES_REQUEST_SUCCEEDED, FETCH_PACKAGES_REQUESTED } from './actions';
import EDITIONS from '../../constants/editions';
import PACKAGE_TYPES from '../../constants/packageTypes';
import { createApiPageData } from '../../utils/api';
import { BROWSE_PER_PAGE_DEFAULT } from '../../constants/api';

export const browseAllInitialState = {
    ...(EDITIONS.reduce((editionsState, edition) => {
        return {
            ...editionsState,
            [edition]: PACKAGE_TYPES.reduce((packageTypesState, packageType) => {
                return {
                    ...packageTypesState,
                    [packageType]: createApiPageData({
                        perPage: BROWSE_PER_PAGE_DEFAULT,
                        loading: false,
                        order: {},
                        filters: {},
                    }),
                };
            }, {}),
        };
    }, {})),
};

export default function (state = browseAllInitialState, action) {
    const { edition, packageType } = action;
    switch (action.type) {
        case FETCH_PACKAGES_REQUESTED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    [packageType]: {
                        ...state[edition][packageType],
                        loading: true,
                        filters: action.filters || {},
                        order: action.order || {},
                    },
                },
            };
        case FETCH_PACKAGES_REQUEST_SUCCEEDED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    [packageType]: {
                        ...state[edition][packageType],
                        ...createApiPageData(action.data.result),
                        loading: false,
                        filters: action.filters || {},
                        order: action.order || {},
                    },
                },
            };
        case FETCH_PACKAGES_REQUEST_FAILED:
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    [packageType]: {
                        ...state[edition][packageType],
                        loading: false,
                    },
                },
            };
        default:
            return state;
    }
}
