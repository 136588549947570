import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import {
    Chip,
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';

const UnderwriterChip = (props) => {
    const { classes } = props;
    return (
        <Chip
            label={props.label}
            variant={props.variant}
            color={props.color}
            className={classnames({
                [classes.chipStyle]: true,
                'text-light': props.isSelected,
            })}
            clickable
            onClick={() => props.onClick(props.underwriterId)}
        />
    );
};

UnderwriterChip.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    underwriterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string.isRequired,
    isSelected: PropTypes,
};

const UnderwriterChips = (props) => {
    const { underwriterSearch, classes, onClick, availableUnderwriters, loading, underwriters } = props;
    return (
        <ImageList className={classes.underwriterChipsList} cols={4} rowHeight={40} id="underwriterList">
            {loading
                ? (
                    <ImageListItem className={classes.underwriterChipGridListTile}>
                        <CircularProgress size={24} />
                    </ImageListItem>
                )
                : (
                    availableUnderwriters.map((underwriter) => {
                        const underwriterId = underwriter.id;
                        const isSelected = underwriters.includes(underwriterId);
                        const variant = isSelected ? 'default' : 'outlined';
                        const color = isSelected ? 'primary' : 'default';
                        const includedInSearchResult = underwriterSearch === '' ||
                            underwriter.name.toUpperCase().indexOf(underwriterSearch.toUpperCase()) > -1;
                        return includedInSearchResult
                            ? (
                                <ImageListItem
                                    key={underwriter.id}
                                    className={classes.underwriterChipGridListTile}
                                >
                                    <UnderwriterChip
                                        label={underwriter.name}
                                        variant={variant}
                                        color={color}
                                        onClick={onClick}
                                        key={underwriterId}
                                        isSelected={isSelected}
                                        underwriterId={underwriterId}
                                        {...props}
                                    />
                                </ImageListItem>
                            )
                            : ''; // If its not included in the search result, don't show anything
                    })
                )}
        </ImageList>
    );
};

UnderwriterChips.propTypes = {
    availableUnderwriters: PropTypes.array,
    classes: PropTypes.object.isRequired,
    fetchUnderwriters: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    underwriters: PropTypes.array.isRequired,
    underwriterSearch: PropTypes.string,
};

class UnderwriterSelector extends React.Component {
    state = {
        searchString: '',
    };

    selectUnderwriter = (underwriter) => {
        this.props.handleUnderwriterUpdate(underwriter);
    };

    onSearchInputChange = (event) => {
        this.setState({ searchString: event.target.value });
    };

    componentDidMount () {
        this.props.fetchUnderwriters();
    }

    render () {
        const { underwriters, classes, availableUnderwriters, loading } = this.props;
        return (
            <Grid
                container
                className={classes.underwriterSelector}
                direction="column"
                alignItems="center"
                wrap="nowrap"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        align="center"
                        gutterBottom
                    >
                        Choose the underwriters you work with.
                    </Typography>
                </Grid>
                <Grid item>
                    <UnderwriterChips
                        onClick={(underwriter) => this.selectUnderwriter(underwriter)}
                        underwriters={underwriters}
                        underwriterSearch={this.state.searchString}
                        availableUnderwriters={availableUnderwriters}
                        loading={loading}
                        {...this.props}
                    />
                </Grid>
            </Grid>
        );
    }
}

UnderwriterSelector.propTypes = {
    availableUnderwriters: PropTypes.array,
    classes: PropTypes.object.isRequired,
    handleUnderwriterUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    underwriters: PropTypes.array.isRequired,
    fetchUnderwriters: PropTypes.func.isRequired,
    selectedStates: PropTypes.array.isRequired,
};

UnderwriterSelector.defaultProps = {
    loading: false,
};

export default withStyles(styles)(UnderwriterSelector);
