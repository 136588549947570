import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StateTitle, ViewInfoButton } from '../TableTitles/index';
import { DIALOG_TEXT, DIALOG_TITLES, PACKAGE_TYPE_STATE } from '../../constants/packageTypes';
import TablesPropTypes from '../../propTypes/tables';
import {
    doStatePackagesHaveFilters,
    getStatePackagesContext,
    getStatePackagesForTable,
} from '../../selectors/packages';
import BrowseByType from '../BrowseByType';
import ApiPropTypes from '../../propTypes/api';
import { getEdition } from '../../selectors/edition';

class BrowseStatePackages extends React.Component {
    state = {
        infoDialogOpen: false,
    };

    onClickViewInfoButton = () => {
        this.setState({ infoDialogOpen: true });
    };

    onCloseInfoDialog = () => {
        this.setState({ infoDialogOpen: false });
    };

    render () {
        const { infoDialogOpen } = this.state;
        const ViewInfoButtonState = (
            <ViewInfoButton
                onClick={this.onClickViewInfoButton}
                infoDialogOpen={infoDialogOpen}
                onCloseInfoDialog={this.onCloseInfoDialog}
                dialogTitle={DIALOG_TITLES[PACKAGE_TYPE_STATE]}
                dialogText={DIALOG_TEXT[PACKAGE_TYPE_STATE]}
            />);

        return (
            <BrowseByType
                type={PACKAGE_TYPE_STATE}
                title={<StateTitle button={ViewInfoButtonState} />}
                {...this.props}
            />
        );
    }
}

BrowseStatePackages.propTypes = {
    tableData: TablesPropTypes.tablePageData.isRequired,
    tableContext: ApiPropTypes.apiPageContext.isRequired,
    hasFilters: PropTypes.bool.isRequired,
};

const mapStateToProps = function (state) {
    return {
        tableData: getStatePackagesForTable(state),
        tableContext: getStatePackagesContext(state),
        hasFilters: doStatePackagesHaveFilters(state),
        edition: getEdition(state),
    };
};

export default connect(mapStateToProps)(BrowseStatePackages);
