import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import { requestWithAuth } from '../utils/api';
import { getAuthToken } from '../selectors/auth';
import {
    CHANGE_PASSWORD_REQUESTED,
    changePasswordFailed,
    changePasswordSucceeded,
} from '../stores/changePassword/actions';
import { getChangePasswordData } from '../selectors/changePassword';
import { validateNonEmptyString } from '../utils/validation';
import { objectValuesAreEmpty } from '../utils';

function validatePasswordChangeData (
    {
        oldPassword = '',
        newPassword = '',
        newPasswordConfirmation = '',
    },
) {
    const errors = {
        oldPassword: [],
        newPassword: [],
        newPasswordConfirmation: [],
    };

    const data = {
        oldPassword,
        newPassword,
        newPasswordConfirmation,
    };

    if (!validateNonEmptyString(data.oldPassword)) {
        errors.oldPassword.push('You must enter your current password to change it.');
    }

    if (!validateNonEmptyString(data.newPassword)) {
        errors.newPassword.push('You must enter a new password.');
    } else if (!validateNonEmptyString(data.newPasswordConfirmation)) {
        errors.newPasswordConfirmation.push('You must re-type your new password to confirm.');
    } else if (data.newPassword !== data.newPasswordConfirmation) {
        errors.newPasswordConfirmation.push('Passwords do not match.');
    }

    if (objectValuesAreEmpty(errors)) {
        return data;
    } else {
        throw errors;
    }
}

function * changePassword () {
    const rawData = yield select(getChangePasswordData);
    let data = null;

    try {
        data = yield call(validatePasswordChangeData, rawData);
    } catch (errors) {
        yield put(changePasswordFailed(errors));
        return;
    }

    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/auth/password',
        'POST',
        token,
        null,
        data,
    );
    if (response.ok) {
        yield put(changePasswordSucceeded());
    } else {
        if (response.status === 400) {
            const { validationErrors: fieldErrors = {} } = yield call([response, response.json]);
            yield put(changePasswordFailed(fieldErrors));
        } else {
            yield put(snackbar.show({
                message: 'Changing your password has failed for an unknown reason. Try again later.',
            }));
            yield put(changePasswordFailed());
        }
    }
}

export default function * () {
    yield all([
        takeEvery(CHANGE_PASSWORD_REQUESTED, changePassword),
    ]);
}
