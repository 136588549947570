export const styles = () => ({
    dialogButton: {
        marginBottom: 25,
        width: 'auto',
    },
    dialogActions: {
        padding: '20px 0',
    },
    dialogTitleTypography: {
        marginTop: 0,
    },
    dialogTitle: {
        paddingTop: 0,
    },
});
