import {
    ADMIN_PACKAGES_ORDER_BY_DEFAULT_DIR,
    ADMIN_PACKAGES_ORDER_BY_DEFAULT_FIELD,
} from '../../constants/api';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';

export const PREFIX = 'adminPackages/';

export const FETCH_ADMIN_PACKAGES_REQUESTED = `${PREFIX}FETCH_ADMIN_PACKAGES`;
export const FETCH_ADMIN_PACKAGES_SUCCEEDED = `${PREFIX}FETCH_ADMIN_PACKAGES_SUCCEEDED`;
export const FETCH_ADMIN_PACKAGES_FAILED = `${PREFIX}FETCH_ADMIN_PACKAGES_FAILED`;

export const DELETE_PACKAGE_REQUESTED = `${PREFIX}DELETE_PACKAGE_REQUESTED`;
export const DELETE_PACKAGE_SUCCEEDED = `${PREFIX}DELETE_PACKAGE_SUCCEEDED`;
export const DELETE_PACKAGE_FAILED = `${PREFIX}DELETE_PACKAGE_FAILED`;

export const DELETE_MULTIPLE_PACKAGES_REQUESTED = `${PREFIX}DELETE_MULTIPLE_PACKAGES_REQUESTED`;
export const DELETE_MULTIPLE_PACKAGES_SUCCEEDED = `${PREFIX}DELETE_MULTIPLE_PACKAGES_SUCCEEDED`;
export const DELETE_MULTIPLE_PACKAGES_FAILED = `${PREFIX}DELETE_MULTIPLE_PACKAGES_FAILED`;

export const FETCH_PACKAGE_REQUESTED = `${PREFIX}FETCH_PACKAGE_REQUESTED`;
export const FETCH_PACKAGE_SUCCEEDED = `${PREFIX}FETCH_PACKAGE_SUCCEEDED`;
export const FETCH_PACKAGE_FAILED = `${PREFIX}FETCH_PACKAGE_FAILED`;

export const UPDATE_PACKAGE_REQUESTED = `${PREFIX}UPDATE_PACKAGE_REQUESTED`;
export const UPDATE_PACKAGE_SUCCEEDED = `${PREFIX}UPDATE_PACKAGE_SUCCEEDED`;
export const UPDATE_PACKAGE_FAILED = `${PREFIX}UPDATE_PACKAGE_FAILED`;

export const CREATE_PACKAGE_REQUESTED = `${PREFIX}CREATE_PACKAGE_REQUESTED`;
export const CREATE_PACKAGE_SUCCEEDED = `${PREFIX}CREATE_PACKAGE_SUCCEEDED`;
export const CREATE_PACKAGE_FAILED = `${PREFIX}CREATE_PACKAGE_FAILED`;

export const REDIRECT_TO_VIEW_EDIT_PACKAGE = `${PREFIX}REDIRECT_TO_VIEW_EDIT_PACKAGE`;

export const CLEAR_SUCCESS_MESSAGES = `${PREFIX}CLEAR_SUCCESS_MESSAGES`;

export function fetchAdminPackagesRequested ({
    pagination: { perPage = DEFAULT_ROWS_PER_PAGE, page = 1 } = {},
    order: {
        orderBy = ADMIN_PACKAGES_ORDER_BY_DEFAULT_FIELD,
        orderByDir = ADMIN_PACKAGES_ORDER_BY_DEFAULT_DIR,
    } = {},
    filters: {
        state = null,
        search = null,
        type = null,
        underwriter = null,
    } = {},
} = {}) {
    return {
        type: FETCH_ADMIN_PACKAGES_REQUESTED,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { state, search, type, underwriter },
    };
}

export function fetchAdminPackagesSucceeded (data, order = {}, filters = {}) {
    return {
        type: FETCH_ADMIN_PACKAGES_SUCCEEDED,
        data,
        order,
        filters,
    };
}

export function fetchAdminPackagesFailed () {
    return {
        type: FETCH_ADMIN_PACKAGES_FAILED,
    };
}

export function deletePackageRequested (packageId) {
    return {
        type: DELETE_PACKAGE_REQUESTED,
        packageId,
    };
}

export function deletePackageSucceeded (packageId) {
    return {
        type: DELETE_PACKAGE_SUCCEEDED,
        packageId,
    };
}

export function deletePackageFailed (packageId) {
    return {
        type: DELETE_PACKAGE_FAILED,
        packageId,
    };
}

export function deleteMultiplePackagesRequested (packageIds) {
    return {
        type: DELETE_MULTIPLE_PACKAGES_REQUESTED,
        packageIds,
    };
}

export function deleteMultiplePackagesSucceeded (packageIds) {
    return {
        type: DELETE_MULTIPLE_PACKAGES_SUCCEEDED,
        packageIds,
    };
}

export function deleteMultiplePackagesFailed (packageIds) {
    return {
        type: DELETE_MULTIPLE_PACKAGES_FAILED,
        packageIds,
    };
}

export function fetchPackageRequested (packageId) {
    return {
        type: FETCH_PACKAGE_REQUESTED,
        packageId,
    };
}

export function fetchPackageSucceeded (packageData) {
    return {
        type: FETCH_PACKAGE_SUCCEEDED,
        packageData,
    };
}

export function fetchPackageFailed () {
    return {
        type: FETCH_PACKAGE_FAILED,
    };
}

export function updatePackageRequested (packageId, packageData) {
    return {
        type: UPDATE_PACKAGE_REQUESTED,
        packageId,
        packageData,
    };
}

export function updatePackageSucceeded (packageData) {
    return {
        type: UPDATE_PACKAGE_SUCCEEDED,
        packageData,
    };
}

export function updatePackageFailed () {
    return {
        type: UPDATE_PACKAGE_FAILED,
    };
}

export function createPackageRequested (packageData) {
    return {
        type: CREATE_PACKAGE_REQUESTED,
        packageData,
    };
}

export function createPackageSucceeded (packageData) {
    return {
        type: CREATE_PACKAGE_SUCCEEDED,
        packageData,
    };
}

export function createPackageFailed () {
    return {
        type: CREATE_PACKAGE_FAILED,
    };
}

export function redirectToViewEditPackageAction (id) {
    return {
        type: REDIRECT_TO_VIEW_EDIT_PACKAGE,
        id,
    };
}

export function clearSuccessMessages () {
    return { type: CLEAR_SUCCESS_MESSAGES };
}
