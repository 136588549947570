export default (theme) => ({
    root: {
        padding: '0.8em 1em',
        marginBottom: '1em',
    },
    error: {
        backgroundColor: '#F33E36',
        color: '#fff',
    },
    success: {
        backgroundColor: '#1DB94B',
        color: '#fff',
    },
});
