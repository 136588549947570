const promptsInstructions = {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
};
export const styles = (theme) => ({
    innerDrawer: {
        height: '100%',
    },
    promptsFileDownloadContainer: {
        paddingBottom: 35,
        paddingTop: 35,
    },
    promptsInstructions,
    promptsInstructionsLink: {
        ...promptsInstructions,
        fontWeight: 'bold',
        textDecoration: 'underline',
        letterSpacing: 0,
        border: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: 0,
        margin: 0,
        marginBottom: 2,
        minHeight: 'unset',
        minWidth: 'unset',
        height: 'unset',
        width: 'unset',
        lineHeight: 1.5,
    },
    drawerButtonsContainer: {
        padding: '30px 0',
    },
    documentsPortalApp: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
    breadcrumbs: {
        background: 'white',
    },
    breadcrumbsDiv: {
        display: 'flex',
        paddingTop: 150,
        paddingLeft: 350,
        [theme.breakpoints.up('xl')]: {
            paddingLeft: 385,
        },
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 250,
        },
    },
    arrow: {
        width: 10,
        height: 50,
        color: '#002244',
        marginLeft: 5,
        marginRight: 5,
    },
    listItemText: {
        fontSize: '1.4em',
        padding: '16px 4px',
    },
});
