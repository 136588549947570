import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';
import TablesPropTypes from '../../propTypes/tables';
import { TABLE_ACTIONS_LABELS, WATCHLIST_TABLE_ACTIONS } from '../../constants/tables';
import { EmptyTableBrowseAllButton, EmptyTableResetButton } from '../TableTitles';
import ApiPropTypes from '../../propTypes/api';
import SoftProPackagesDataTable from '../SoftProDataTable/SoftProPackagesDataTable';

const WATCHLIST_TABLE_ACTION_ITEMS = WATCHLIST_TABLE_ACTIONS.map((action) => (
    { value: action, label: TABLE_ACTIONS_LABELS[action] }
));

class WatchlistTable extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        loadingUpdate: PropTypes.arrayOf(PropTypes.number),
        emptyText: PropTypes.string,
        noResultsText: PropTypes.string,
        onFetchDataRequested: PropTypes.func.isRequired,
        onBrowseAllClick: PropTypes.func.isRequired,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,
        renderTitle: PropTypes.func.isRequired,
        availableStatesForDataTableFilter: PropTypes.array,
        hasFilters: PropTypes.bool,
        availableUnderwritersForDataTableFilter: PropTypes.array,
        hasEndorsementLookupsIncludedColumn: PropTypes.bool,
    };

    static defaultProps = {
        availableStatesForDataTableFilter: [],
        emptyText: 'You do not have any packages of this type in your watchlist.',
        noResultsText: 'There are no packages of this type in your watchlist that match the selected filters.',
        hasEndorsementLookupsIncludedColumn: false,
        hasFilters: false,
    };

    onRequestTableDataReset = () => this.props.onFetchDataRequested();

    renderEmpty = () => {
        const { classes, noResultsText, emptyText, onBrowseAllClick, hasFilters } = this.props;
        return (
            <div className={classes.emptyTable}>
                <Typography className={classes.emptyTableText}>{hasFilters ? noResultsText : emptyText}</Typography>
                <EmptyTableBrowseAllButton onClick={onBrowseAllClick} />
                {hasFilters ? <EmptyTableResetButton onClick={this.onRequestTableDataReset} /> : null}
            </div>
        );
    };

    render () {
        const {
            classes,
            renderTitle,

            tableData,
            tableContext,
            onFetchDataRequested,
            onUpdateWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
            loadingUpdate,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            hasEndorsementLookupsIncludedColumn,
        } = this.props;

        const softProPackagesDataTableProps = {
            tableData,
            tableContext,
            onFetchDataRequested,
            onUpdateWatchlistRequested,
            onRemoveWatchlistRequested,
            onDownloadRequested,
            loadingUpdate,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            hasEndorsementLookupsIncludedColumn,
            hasTypeColumn: false,
            hasStatesColumn: true,
            hasAddButtonColumn: false,
            actions: WATCHLIST_TABLE_ACTION_ITEMS,
            title: renderTitle(),
            renderEmpty: this.renderEmpty,
        };

        return (
            <div className={classes.packages}>
                <div className={classes.packagesTable}>
                    <SoftProPackagesDataTable {...softProPackagesDataTableProps} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(WatchlistTable);
