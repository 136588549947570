import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isInitialized, isInitializing } from './selectors/app';
import AppRouter from './views/AppRouter';
import AppGate from './components/AppGate';

export const Root = ({ initialized, initializing, errors, history }) => {
    if (initialized) {
        return <AppRouter history={history} />;
    } else {
        return <AppGate loading={initializing} errors={errors} />;
    }
};

Root.propTypes = {
    initializing: PropTypes.bool,
    initialized: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    history: PropTypes.object,
};

Root.defaultProps = {
    initializing: true,
    initialized: false,
    errors: [],
    history: null,
};

const mapStateToProps = (state) => ({
    initializing: isInitializing(state),
    initialized: isInitialized(state),
    errors: state.app.initializationErrors,
});

export default connect(mapStateToProps)(Root);
