import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, sumTotals } from '../../utils';
import classNames from 'classnames';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { styles } from './styles';
import TimeRangeSelect from '../TimeRangeSelect';
import { adminDashboardDataRequested, updateRange } from '../../stores/admin/actions';
import { ViewButton } from '../ViewButton';
import {
    getDashboardLoading,
    getDownloadsChartData,
    getNotificationsChartData,
    getTopUsersData,
    getUploadsChartData,
} from '../../selectors/adminDashboard';
import { getDate, getRange } from '../../selectors/analytics';
import { dateTickFormatter, labelFormatter } from '../../utils/charts';
import { useNavigate } from 'react-router';

const withAdminDashboardStyles = withStyles(styles, { name: 'AdminDashboard' });

const AdminDashboardChart = ({ className, classes, color, data, xDataKey }) => {
    return (
        <div className={classNames(classes.chartContainer, className)}>
            <ResponsiveContainer>
                <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <CartesianGrid vertical horizontal />
                    <XAxis
                        dataKey={xDataKey}
                        tickLine={false}
                        tickFormatter={dateTickFormatter}
                        type="number"
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                    />
                    <YAxis tickLine={false} allowDecimals={false} />
                    <Tooltip labelFormatter={labelFormatter} />
                    <Area
                        type="monotone"
                        stroke={color}
                        fill={color}
                        fillOpacity={1}
                        dataKey="total"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};
AdminDashboardChart.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    xDataKey: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};
AdminDashboardChart.defaultProps = {
    className: null,
};
const StyledAdminDashboardChart = withAdminDashboardStyles(AdminDashboardChart);

const EmptyChart = ({ className, title, classes }) => {
    return (
        <Grid
            container
            direction="column"
            alignItems="stretch"
            className={classNames(classes.chartContainer, classes.emptyChartContainer, className)}
        >
            <Grid
                item
                container
                direction="column"
                alignItems="stretch"
                justifyContent="center"
                className={classes.emptyChart}
            >
                <Typography>
                    No {title} data available for the given range of days.
                </Typography>
            </Grid>
        </Grid>
    );
};
EmptyChart.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};
EmptyChart.defaultProps = {
    className: null,
};
const StyledEmptyChart = withAdminDashboardStyles(EmptyChart);

const AdminDashboardChartGridItem = (
    {
        classes,
        data,
        headerText,
        xDataKey,
        color,
        chartTitle,
        ...restProps
    },
) => (
    <Grid container direction="column" alignItems="stretch" justifyContent="flex-start" {...restProps}>
        <Grid item container direction="row" alignItems="flex-end" wrap="nowrap" className={classes.chartHeader}>
            <Typography variant="h1" className={classes.chartHeaderCountText}>
                {sumTotals(data)}
            </Typography>
            <Typography className={classes.chartHeaderText}>{headerText}</Typography>
        </Grid>
        {data && data.length > 0
            ? <Grid item component={StyledAdminDashboardChart} data={data} xDataKey={xDataKey} color={color} />
            : <Grid item component={StyledEmptyChart} title={chartTitle} />}
    </Grid>
);
AdminDashboardChartGridItem.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    headerText: PropTypes.string.isRequired,
    xDataKey: PropTypes.string,
    color: PropTypes.string,
    chartTitle: PropTypes.string.isRequired,
};
AdminDashboardChartGridItem.defaultProps = {
    xDataKey: 'occurredAt',
    color: '#6089BD',
};
const StyledAdminDashboardChartGridItem = withAdminDashboardStyles(AdminDashboardChartGridItem);

const DownloadsChart = ({ data, ...restProps }) => (
    <StyledAdminDashboardChartGridItem
        data={data}
        headerText="Downloads"
        chartTitle="Downloads"
        color="#6089BD"
        {...restProps}
    />
);
DownloadsChart.propTypes = {
    data: PropTypes.array.isRequired,
};

const FileUpdateChart = ({ data, ...restProps }) => (
    <StyledAdminDashboardChartGridItem
        data={data}
        headerText="File Updates"
        chartTitle="File Updates"
        color="#B1B902"
        xDataKey="createdAt"
        {...restProps}
    />
);
FileUpdateChart.propTypes = {
    data: PropTypes.array.isRequired,
};

const NotificationEmailsChart = ({ data, ...restProps }) => (
    <StyledAdminDashboardChartGridItem
        data={data}
        headerText="Notification Emails Sent"
        chartTitle="Notifications"
        color="#D95E16"
        {...restProps}
    />
);
NotificationEmailsChart.propTypes = {
    data: PropTypes.array.isRequired,
};

const options = {
    download: false,
    filter: false,
    selectableRows: 'none',
    pagination: false,
    search: false,
    viewColumns: false,
    print: false,
};

const TopUsersTitle = ({ classes }) => {
    return (
        <div>
            <Typography
                variant="h4"
                className={classes.topUsersTitle}
            >
                Top Users
            </Typography>
        </div>
    );
};
TopUsersTitle.propTypes = {
    classes: PropTypes.object.isRequired,
};
const StyledTopUsersTitle = withAdminDashboardStyles(TopUsersTitle);

const TopUsers = ({ data, classes, navigate }) => {
    const columns = [
        {
            name: 'USER EMAIL',
            options: {
                filter: false,
                setCellProps: () => ({ className: classes.emailCol }),
            },
        },
        {
            name: 'ORGANIZATION',
            options: {
                display: false, // Hiding this due to SOF-256, leaving it here but hidden bc we assume it'll come back someday
            },
        },
        {
            name: 'LAST DOWNLOADED',
            options: {
                filter: false,
                setCellProps: () => ({ style: { width: 'auto' } }),
            },
        },
        {
            name: 'NUMBER OF DOWNLOADS',
            options: {
                filter: false,
                setCellProps: () => ({ className: classes.countCol }),
            },
        },
        {
            name: '',
            options: {
                sort: false,
                setCellProps: () => ({ className: classes.viewCol }),
                /*
                 * Eslint thinks this is a React component but it's not (it doesn't take object props).
                 * Will no longer apply once we swap out mui-datatables for our custom implementation.
                 */
                // eslint-disable-next-line react/display-name
                customBodyRender: (value) => {
                    return (
                        <ViewButton
                            onClick={() => navigate('/admin/users/userDetails/' + value + '/userEmail')}
                        />
                    );
                },
            },
        },
    ];

    const dataExists = data ? data.length > 0 : false;
    return (
        <div className={classes.topUsersTable}>
            {dataExists
                ? <MUIDataTable columns={columns} data={data} options={options} title={<StyledTopUsersTitle />} />
                : <StyledEmptyUsersTable />}
        </div>
    );
};

TopUsers.propTypes = {
    navigate: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

const StyledTopUsers = withAdminDashboardStyles(TopUsers);

const EmptyUsersTable = ({ classes }) => {
    return (
        <div className={classes.emptyUsersDiv}>
            <StyledTopUsersTitle />
            <div className={classes.emptyUsersTable}>
                <Typography>
                    There were no active users in the given range of days.
                </Typography>
            </div>
        </div>
    );
};
EmptyUsersTable.propTypes = {
    classes: PropTypes.object.isRequired,
};
const StyledEmptyUsersTable = withAdminDashboardStyles(EmptyUsersTable);

class Dashboard extends React.Component {
    state = {
        customDialogOpen: false,
        customDialogError: false,
        customRange: 0,
    };

    closeTimeRangeDialog = (value) => {
        if (value === 'cancel') {
            this.setState({
                customDialogOpen: false,
                customDialogError: false,
                customRange: 0,
                customStartDate: '',
                customEndDate: '',
            });
        } else {
            // Set the range to the custom since they submitted the dialog
            // eslint-disable-next-line no-useless-escape
            const validationRegex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
            const newCustomRangeStart = this.state.customStartDate;
            const newCustomRangeEnd = this.state.customEndDate;
            if (
                !newCustomRangeStart ||
                !newCustomRangeStart.match(validationRegex) ||
                !newCustomRangeEnd ||
                !newCustomRangeEnd.match(validationRegex)) {
                this.setState({
                    customDialogOpen: true,
                    customDialogError: true,
                });
            } else {
                const startDate = new Date(newCustomRangeStart);
                const endDate = new Date(newCustomRangeEnd);
                // Convert milliseconds to days
                const daysDifference = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
                if (daysDifference < 0) {
                    this.props.onRangeSelected(-1 * daysDifference, newCustomRangeEnd);
                    this.setState({
                        customDialogOpen: false,
                        customDialogError: false,
                    });
                } else {
                    this.props.onRangeSelected(daysDifference, newCustomRangeStart);
                    this.setState({
                        customDialogOpen: false,
                        customDialogError: false,
                    });
                }
            }
        }
    };

    storeCustomStartDateValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({
                customStartDate: value,
                customDialogError: false,
            });
        }
    };

    storeCustomEndDateValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({
                customEndDate: value,
                customDialogError: false,
            });
        }
    };

    storeCustomRangeValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({
                customRange: parseInt(value),
                customDialogError: false,
            });
        } else {
            this.setState({
                customRange: value,
                customDialogError: false,
            });
        }
    };

    selectTimeRange = () => {
        this.setState({
            customDialogOpen: true,
            customDialogError: false,
        });
    };

    render () {
        const { date, range, topUsersData, downloads, uploads, notifications, classes, navigate } = this.props;
        const { customDialogOpen, customDialogError, customRange } = this.state;
        return (
            <Grid container direction="column">
                <Grid container direction="row" justifyContent="space-between" className={classes.topGrid}>
                    <Grid item>
                        <Typography
                            variant="h1"
                            gutterBottom
                        >
                            Dashboard
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TimeRangeSelect
                            open={customDialogOpen}
                            closeTimeRangeDialog={this.closeTimeRangeDialog}
                            storeCustomRangeValue={this.storeCustomRangeValue}
                            storeCustomStartDateValue={this.storeCustomStartDateValue}
                            storeCustomEndDateValue={this.storeCustomEndDateValue}
                            customRange={customRange}
                            selectTimeRange={this.selectTimeRange}
                            date={date}
                            range={range}
                            error={customDialogError}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item md={4} xs={12}>
                        <DownloadsChart data={downloads} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FileUpdateChart data={uploads} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <NotificationEmailsChart data={notifications} />
                    </Grid>
                </Grid>
                <Grid item>
                    <StyledTopUsers data={topUsersData} navigate={navigate} />
                </Grid>
            </Grid>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    range: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    topUsersData: PropTypes.array.isRequired,
    downloads: PropTypes.array.isRequired,
    uploads: PropTypes.array.isRequired,
    notifications: PropTypes.array.isRequired,
    onRangeSelected: PropTypes.func.isRequired,
    adminDashboardDataRequested: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

const DashboardFunction = (props) => {
    const navigate = useNavigate();
    return <Dashboard {...props} navigate={navigate} />;
};

const StyledDashboard = compose(
    withAdminDashboardStyles,
    withRouter,
)(DashboardFunction);

class AdminDashboard extends React.Component {
    componentDidMount () {
        const { adminDashboardDataRequested, range } = this.props;
        adminDashboardDataRequested(range);
    }

    renderDashboard () {
        const { onRangeSelected, adminDashboardDataRequested } = this.props;
        const { range, date, topUsersData, downloads, uploads, notifications } = this.props;
        return (
            <StyledDashboard
                range={range}
                date={date}
                topUsersData={topUsersData}
                downloads={downloads}
                uploads={uploads}
                notifications={notifications}
                onRangeSelected={onRangeSelected}
                adminDashboardDataRequested={adminDashboardDataRequested}
            />
        );
    }

    render () {
        const { classes, loading } = this.props;
        return (
            <div className={classes.adminDashboard}>
                {loading
                    ? <CircularProgress />
                    : this.renderDashboard()}
            </div>
        );
    }
}

AdminDashboard.propTypes = {
    range: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    downloads: PropTypes.array,
    uploads: PropTypes.array,
    notifications: PropTypes.array,
    topUsersData: PropTypes.array,
    updateRange: PropTypes.func.isRequired,
    adminDashboardDataRequested: PropTypes.func.isRequired,
    onRangeSelected: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        range: getRange(state),
        date: getDate(state),
        loading: getDashboardLoading(state),
        downloads: getDownloadsChartData(state),
        uploads: getUploadsChartData(state),
        notifications: getNotificationsChartData(state),
        topUsersData: getTopUsersData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(bindActionCreators({ updateRange, adminDashboardDataRequested }, dispatch)),
        onRangeSelected: (range, date) => dispatch(updateRange(range, date)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withAdminDashboardStyles,
)(AdminDashboard);
