import {
    convertDataForDownloadsTab,
    convertDataForNotificationsForPackageTable,
    convertDataForNotificationsTab,
    convertDataForUserDownloadsTable,
    convertDataForUsersTab,
} from '../utils/tableUtils';
import { createSelector } from 'reselect';
import { uniqueArray } from '../utils';
import { DOWNLOADS, NOTIFICATIONS, USERS } from '../constants/analytics';
import { transformApiPageToContext } from './api';
import { convertChartData, convertDatesToTimestamps } from '../utils/charts';

export const getRange = (state) => state.adminRanges.range;
export const getDate = (state) => state.adminRanges.date;

const createAnalyticsTableSelector = (dataType, convertFunction) => {
    return createSelector(
        (state) => state.analytics[dataType],
        (page) => ({
            ...page,
            data: convertFunction(page.data),
        }),
    );
};

const createAnalyticsForPackageTableSelector = (dataType, convertFunction = null) => {
    return createSelector(
        (state) => state.analytics[dataType + 'ForPackage'],
        (page) => {
            return convertFunction
                ? ({
                    ...page,
                    data: convertFunction(page.data),
                })
                : page;
        },
    );
};

const createDownloadsForUserTableSelector = () => {
    return createSelector(
        (state) => state.analytics.downloadsForUser,
        (page) => ({
            ...page,
            data: convertDataForUserDownloadsTable(page.data),
        }),
    );
};

const createAnalyticsContextForTableSelector = (pageSelector) => {
    return createSelector(
        pageSelector,
        transformApiPageToContext,
    );
};

export const getDownloadsData = createAnalyticsTableSelector(DOWNLOADS, convertDataForDownloadsTab);
export const getDownloadsContext = createAnalyticsContextForTableSelector(getDownloadsData);

export const getUserTabData = createAnalyticsTableSelector(USERS, convertDataForUsersTab);
export const getUsersContext = createAnalyticsContextForTableSelector(getUserTabData);

export const getNotificationsData = createAnalyticsTableSelector(NOTIFICATIONS, convertDataForNotificationsTab);
export const getNotificationsContext = createAnalyticsContextForTableSelector(getNotificationsData);

export const getDownloadsPage = (state) => state.analytics.downloads.page;
export const getUsersPage = (state) => state.analytics.users.page;
export const getNotificationsPage = (state) => state.analytics.notifications.page;

export const getUsersLoading = (state) => state.analytics.users.loading;
export const getNotificationsLoading = (state) => state.adminDashboardData.loading ||
    state.analytics.notifications.loading;

export const getUsersError = (state) => state.analytics.users.error;

const createUsersChartSelector = () => {
    return createSelector(
        (state) => state.analytics.users.users,
        (data) => {
            data = convertDatesToTimestamps(data);
            return convertChartData(uniqueArray(data, 'userId'));
        },
    );
};

export const getUsersChartData = createUsersChartSelector();

const createTotalActiveUserCountSelector = () => {
    return createSelector(
        (state) => state.analytics.users.users,
        (data) => {
            data = uniqueArray(data, 'userId');
            return data.length;
        },
    );
};

const createAnalyticsForPackageSelector = (dataType, field) => createSelector(
    (state) => state.analytics[dataType + 'ForPackage'],
    (analyticsForPackage) => analyticsForPackage[field],
);

export const getTotalActiveUserCount = createTotalActiveUserCountSelector();

export const getNotificationsDataForPackage = createAnalyticsForPackageTableSelector(NOTIFICATIONS,
    convertDataForNotificationsForPackageTable);
export const getNotificationsContextForPackage = createAnalyticsContextForTableSelector(getNotificationsDataForPackage);
export const getNotificationsForPackageName = createAnalyticsForPackageSelector(NOTIFICATIONS, 'name');

export const getDownloadsDataForPackage = createAnalyticsForPackageTableSelector(DOWNLOADS);
export const getDownloadsContextForPackage = createAnalyticsContextForTableSelector(getDownloadsDataForPackage);
export const getDownloadsForPackageName = createAnalyticsForPackageSelector(DOWNLOADS, 'name');

export const getDownloadsDataForUser = createDownloadsForUserTableSelector();
export const getDownloadsContextForUser = createAnalyticsContextForTableSelector(getDownloadsDataForUser);

export const getAdminDashboardDataState = (state) => state.adminDashboardData || {};
export const getStatsDownloads = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsDownloads || 0
);
export const getStatsDownloadsSelect = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsDownloadsSelect || 0
);
export const getStatsDownloadsStandard = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsDownloadsStandard || 0
);
export const getStatsPackages = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsPackages || 0
);
export const getStatsPackagesSelect = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsPackagesSelect || 0
);
export const getStatsPackagesStandard = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsPackagesStandard || 0
);
export const getStatsUsers = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsUsers || 0
);
export const getStatsOrganizations = createSelector(
    getAdminDashboardDataState,
    (adminDashboardData = {}) => adminDashboardData.statsOrganizations || 0
);
