import { FETCH_STATES, FETCH_STATES_SUCCEEDED, UPDATE_STATES } from './actions';
import { LOGIN_SUCCEEDED, LOGOUT, ON_BOARDING_COMPLETED } from '../auth/actions';

export const statesInitialState = {
    states: [],
    availableStates: [],
    loading: false,
};

export function states (state = statesInitialState, action) {
    switch (action.type) {
        case UPDATE_STATES: {
            let currentStates = !state.states ? [] : [...state.states];
            const newState = action.stateCode;

            if (currentStates.includes(newState)) {
                // State is in the list so remove it
                currentStates.splice(currentStates.indexOf(newState), 1);
            } else {
                // Add the new state
                currentStates = [...currentStates, newState];
            }
            return { ...state, states: currentStates };
        }
        case FETCH_STATES:
            return {
                ...state,
                loading: true,
            };
        case FETCH_STATES_SUCCEEDED: {
            const { data: { result: availableStates } } = action;
            return {
                ...state,
                availableStates,
                loading: false,
            };
        }
        case LOGIN_SUCCEEDED: {
            const { user = null, states: initStates = {} } = action;
            const hasAvailableStates = initStates && initStates.result;
            if (!user.onboardingCompleted || hasAvailableStates) {
                const newState = { ...state };
                if (!user.onboardingCompleted) {
                    newState.states = user.states?.map((state) => state.code) || [];
                }
                if (hasAvailableStates) {
                    newState.availableStates = initStates.result;
                }
                return newState;
            }
            return state;
        }
        case ON_BOARDING_COMPLETED:
            return {
                ...state,
                loading: false,
                states: [],
            };
        case LOGOUT:
            return statesInitialState;
        default:
            return state;
    }
}
