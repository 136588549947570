const styles = () => ({
    underwriterSelector: {
        paddingLeft: 45,
        paddingRight: 45,
        width: 'auto',
        flex: 1,
    },
    underwriterChipsList: {
        display: 'block',
        textAlign: 'center',
        height: 325,
        maxHeight: 325,
    },
    underwriterChipGridListTile: {
        display: 'inline-block',
        width: 'unset !important',
    },
    underwriterSearchBar: {
        width: 280,
        marginBottom: 10,
    },
    searchIcon: {
        color: '#57444E',
        position: 'absolute',
        top: 8,
        right: 10,
    },
    chipStyle: {
        borderRadius: 50,
        height: 35,
        fontWeight: 'bold',
        // If the chip also has the text-light class, then it is selected
        '&.text-light': {
            color: '#FFFFFF',
        },
    },
});

export default styles;
