import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { getEntities } from './entities';
import { PageOfUnderwriters } from '../schemas';
import { transformApiPageToContext } from './api';

export const getRawAdminUnderwritersPage = (state) => state.adminUnderwriters.list;

export const getAdminUnderwritersPageIds = createSelector(getRawAdminUnderwritersPage, ({ data }) => data);

export const getAdminUnderwritersPage = createSelector(
    getRawAdminUnderwritersPage,
    getEntities,
    (page, entities) => denormalize(page, PageOfUnderwriters, entities),
);

export const getAdminUnderwritersContext = createSelector(getAdminUnderwritersPage, transformApiPageToContext);

export const getAdminUnderwriterDetail = (state) => state.adminUnderwriters.detail;

export const isAdminUnderwriterDetailLoading = createSelector(
    getAdminUnderwriterDetail,
    (detail) => (detail.fetching || detail.saving),
);

const createAdminUnderwriterDetailSelector = (field) => createSelector(
    getAdminUnderwriterDetail,
    (detail) => detail[field],
);

export const getAdminUnderwriterDetailData = createAdminUnderwriterDetailSelector('data');
export const getAdminUnderwriterDetailFormErrors = createAdminUnderwriterDetailSelector('formErrors');
export const getAdminUnderwriterDetailFormSuccessMessages = createAdminUnderwriterDetailSelector(
    'formSuccessMessages',
);
export const getAdminUnderwriterDetailFieldErrors = createAdminUnderwriterDetailSelector('fieldErrors');

export const getLoadingAdminUnderwriters = createSelector(
    (state) => state.adminUnderwriters.delete.deleting,
    getAdminUnderwriterDetail,
    (deleting, detail) => {
        if (detail.data && detail.data.id && detail.saving) {
            return deleting.includes(detail.data.id)
                ? deleting
                : [...deleting, detail.data.id];
        }
        return deleting;
    },
);
