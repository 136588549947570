import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';
import { Link } from 'react-router-dom';
import { camelCaseToTitleCase, getUrl, withRouter } from '../../utils';
import { Route, useParams } from 'react-router';
import ArrowIcon from '@mui/icons-material/PlayArrow';

const Breadcrumbs = ({ router }) => {
    const url = getUrl(window, router);
    const { path } = useParams();
    return (
        <div style={styles.breadcrumbsDiv}>
            {url.split('/').length > 3 && <ArrowIcon style={styles.arrow} />}
            <Link to={url} style={router.location.pathname === path ? styles.breadcrumbActive : styles.breadcrumb}>
                {camelCaseToTitleCase(url.substr(url.lastIndexOf('/') + 1, url.length))}
            </Link>
            <Route path={`${url}/:path`} element={withRouter(Breadcrumbs)} />
        </div>
    );
};

Breadcrumbs.propTypes = {
    router: PropTypes.object.isRequired,
};

export default withRouter(Breadcrumbs);
