import {
    FETCH_FAQ,
    FETCH_FAQ_FAILED,
    FETCH_FAQ_SUCCEEDED,
    FETCH_VIDEOS,
    FETCH_VIDEOS_FAILED,
    FETCH_VIDEOS_SUCCEEDED,
    PREFIX,
} from './actions';
import EDITIONS from '../../constants/editions';
import { LOGOUT } from '../auth/actions';

const helpInitialState = {
    ...(EDITIONS.reduce((editionsState, edition) => {
        return {
            ...editionsState,
            [edition]: {
                faq: { data: [], loading: false },
                videos: { data: [], loading: false },
            },
        };
    }, {})),
};

export function help (state = helpInitialState, action) {
    if (action.type.indexOf(PREFIX) === 0) {
        const { data, edition } = action;
        if (action.type === FETCH_FAQ) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    faq: {
                        ...state[edition].faq,
                        loading: true,
                    },
                },
            };
        } else if (action.type === FETCH_FAQ_SUCCEEDED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    faq: {
                        ...state[edition].faq,
                        loading: false,
                        data: data.result,
                    },
                },
            };
        } else if (action.type === FETCH_FAQ_FAILED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    faq: {
                        ...state[edition].faq,
                        loading: false,
                    },
                },
            };
        } else if (action.type === FETCH_VIDEOS) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    videos: {
                        ...state[edition].faq,
                        videos: true,
                    },
                },
            };
        } else if (action.type === FETCH_VIDEOS_SUCCEEDED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    videos: {
                        ...state[edition].videos,
                        loading: false,
                        data: data.result,
                    },
                },
            };
        } else if (action.type === FETCH_VIDEOS_FAILED) {
            return {
                ...state,
                [edition]: {
                    ...state[edition],
                    videos: {
                        ...state[edition].videos,
                        loading: false,
                    },
                },
            };
        }
    }

    if (action.type === LOGOUT) {
        return helpInitialState;
    }

    return state;
}
