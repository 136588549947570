import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../utils';

export const ExportButton = (props) => {
    const { onClick, loading } = props;
    return (
        <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onClick}
            disabled={loading}
        >
            {loading ? <CircularProgress size={32} /> : 'Export Report'}
        </Button>
    );
};

ExportButton.propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

ExportButton.defaultProps = {
    onClick: noop,
    loading: false,
};
