import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { usersDataRequested } from '../../stores/users/actions';
import { Button, CircularProgress, FormControl, Grid, InputBase } from '@mui/material';
import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import styles from './styles';
import Pagination from '../../components/Pagination';
import { ViewButton } from '../../components/ViewButton';
import { Search as SearchIcon } from '@mui/icons-material';
import { withRouter } from '../../utils';
import { getUsersData } from '../../selectors/users';
import AdminButton from '../../components/AdminButton';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

const Search = ({ classes, onChange, onSubmit }) => {
    return (
        <Grid
            container
            component="form"
            alignItems="center"
            onSubmit={
                (event) => {
                    event.preventDefault();
                    onSubmit();
                }
            }
        >
            <Grid item>
                <FormControl>
                    <InputBase
                        placeholder="Search"
                        type="search"
                        variant="outline"
                        onChange={onChange}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Button
                    type="submit"
                    size="small"
                    className={classes.searchButton}
                >
                    <SearchIcon />
                </Button>
            </Grid>
        </Grid>
    );
};

Search.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const tableOptions = {
    pagination: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    filter: false,
};

const UsersTable = ({ loading, data, classes, onViewButtonClick }) => {
    const tableColumns = [
        {
            name: 'User Email',
            options: {
                filter: false,
                setCellProps: () => ({ className: classNames([classes.emailCol, classes.overflow]) }),
            },
        },
        {
            name: 'Organization',
            options: {
                display: false, // Hiding this due to SOF-256, leaving it here but hidden bc we assume it'll come back someday
                setCellProps: () => ({ className: classes.orgCol }),
            },
        },
        {
            name: 'Serial #',
            options: {
                filter: false,
                setCellProps: () => ({ className: classes.serialCol }),
            },
        },
        {
            name: 'Last Downloaded',
            options: {
                filter: false,
                setCellProps: () => ({ className: classes.stretchCol }),
            },
        },
        {
            name: 'Number of Downloads',
            options: {
                filter: false,
                setCellProps: () => ({ className: classes.countCol }),
            },
        },
        {
            name: '',
            options: {
                filter: false,
                setCellProps: () => ({ className: classes.viewCol }),
                /*
                 * Eslint thinks this is a React component but it's not (it doesn't take object props).
                 * Will no longer apply once we swap out mui-datatables for our custom implementation.
                 */
                // eslint-disable-next-line react/display-name
                customBodyRender: (value) => {
                    return <ViewButton onClick={() => onViewButtonClick(value)} />;
                },
            },
        },
    ];
    return (
        loading
            ? <CircularProgress className={classes.tableLoadingIndicator} />
            : (
                <MUIDataTable
                    data={data}
                    options={tableOptions}
                    columns={tableColumns}
                />
            )
    );
};

UsersTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onViewButtonClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

const StyledUsersTable = withStyles(styles)(UsersTable);

class Users extends React.Component {
    state = {
        search: '',
    };

    componentDidMount () {
        const { usersDataRequested } = this.props;
        usersDataRequested();
    }

    onPageChange = (page) => {
        const { usersDataRequested } = this.props;
        const { search } = this.state;
        usersDataRequested(page, search);
    };

    onSearchChange = (event) => {
        const newSearch = event.target.value;
        this.setState({ search: newSearch });
        if (!newSearch) {
            const { usersDataRequested } = this.props;
            usersDataRequested(1, '');
        }
    };

    onSearchSubmit = () => {
        const { search } = this.state;
        const { usersDataRequested } = this.props;
        usersDataRequested(1, search);
    };

    onViewButtonClick = (userId) => {
        this.props.navigate('/admin/users/userDetails/' + userId + '/userEmail');
    };

    render () {
        const { classes, loading, data, page, perPage, total, router } = this.props;
        return (
            <Fragment>
                <Grid item container direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                        <AdminButton className={classes.addAdminButton} to={`${router.location.pathname}/createAdmin`}>
                            Add a New Admin
                        </AdminButton>
                    </Grid>
                    <Grid item>
                        <Search
                            classes={classes}
                            onChange={this.onSearchChange}
                            onSubmit={this.onSearchSubmit}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        onChange={this.onPageChange}
                    />
                </Grid>
                <Grid item>
                    <StyledUsersTable
                        loading={loading}
                        data={data}
                        onViewButtonClick={(userId) => this.onViewButtonClick(userId)}
                    />
                </Grid>
            </Fragment>
        );
    }
}

Users.propTypes = {
    usersDataRequested: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    navigate: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
};

const mapStateToProps = function (state) {
    return {
        loading: state.users.loading,
        data: getUsersData(state),
        page: state.users.page,
        perPage: state.users.perPage,
        total: state.users.total,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        ...(bindActionCreators({ usersDataRequested }, dispatch)),
    };
};

const UsersFunction = (props) => {
    const navigate = useNavigate();
    return <Users {...props} navigate={navigate} />;
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
)(UsersFunction);
