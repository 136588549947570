import {
    ADMIN_UNDERWRITERS_ORDER_BY_DEFAULT_DIR,
    ADMIN_UNDERWRITERS_ORDER_BY_DEFAULT_FIELD,
} from '../../constants/api';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';

export const PREFIX = 'adminUnderwriters/';

export const FETCH_ADMIN_UNDERWRITERS_REQUESTED = `${PREFIX}FETCH_ADMIN_UNDERWRITERS`;
export const FETCH_ADMIN_UNDERWRITERS_SUCCEEDED = `${PREFIX}FETCH_ADMIN_UNDERWRITERS_SUCCEEDED`;
export const FETCH_ADMIN_UNDERWRITERS_FAILED = `${PREFIX}FETCH_ADMIN_UNDERWRITERS_FAILED`;

export const DELETE_ADMIN_UNDERWRITER_REQUESTED = `${PREFIX}DELETE_ADMIN_UNDERWRITER_REQUESTED`;
export const DELETE_ADMIN_UNDERWRITER_SUCCEEDED = `${PREFIX}DELETE_ADMIN_UNDERWRITER_SUCCEEDED`;
export const DELETE_ADMIN_UNDERWRITER_FAILED = `${PREFIX}DELETE_ADMIN_UNDERWRITER_FAILED`;

export const DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED = `${PREFIX}DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED`;
export const DELETE_MULTIPLE_ADMIN_UNDERWRITERS_SUCCEEDED = `${PREFIX}DELETE_MULTIPLE_ADMIN_UNDERWRITERS_SUCCEEDED`;
export const DELETE_MULTIPLE_ADMIN_UNDERWRITERS_FAILED = `${PREFIX}DELETE_MULTIPLE_ADMIN_UNDERWRITERS_FAILED`;

export const FETCH_ADMIN_UNDERWRITER_REQUESTED = `${PREFIX}FETCH_ADMIN_UNDERWRITER_REQUESTED`;
export const FETCH_ADMIN_UNDERWRITER_SUCCEEDED = `${PREFIX}FETCH_ADMIN_UNDERWRITER_SUCCEEDED`;
export const FETCH_ADMIN_UNDERWRITER_FAILED = `${PREFIX}FETCH_ADMIN_UNDERWRITER_FAILED`;

export const UPDATE_ADMIN_UNDERWRITER_REQUESTED = `${PREFIX}UPDATE_ADMIN_UNDERWRITER_REQUESTED`;
export const UPDATE_ADMIN_UNDERWRITER_SUCCEEDED = `${PREFIX}UPDATE_ADMIN_UNDERWRITER_SUCCEEDED`;
export const UPDATE_ADMIN_UNDERWRITER_FAILED = `${PREFIX}UPDATE_ADMIN_UNDERWRITER_FAILED`;

export const CREATE_ADMIN_UNDERWRITER_REQUESTED = `${PREFIX}CREATE_ADMIN_UNDERWRITER_REQUESTED`;
export const CREATE_ADMIN_UNDERWRITER_SUCCEEDED = `${PREFIX}CREATE_ADMIN_UNDERWRITER_SUCCEEDED`;
export const CREATE_ADMIN_UNDERWRITER_FAILED = `${PREFIX}CREATE_ADMIN_UNDERWRITER_FAILED`;

export const ADMIN_UNDERWRITER_DETAIL_FIELD_CHANGED = `${PREFIX}ADMIN_UNDERWRITER_DETAIL_FIELD_CHANGED`;
export const ADMIN_UNDERWRITER_DETAIL_FORM_CLEAR_REQUESTED = `${PREFIX}ADMIN_UNDERWRITER_DETAIL_FORM_CLEAR_REQUESTED`;

export function fetchAdminUnderwritersRequested (
    {
        pagination: {
            perPage = DEFAULT_ROWS_PER_PAGE,
            page = 1,
        } = {},
        order: {
            orderBy = ADMIN_UNDERWRITERS_ORDER_BY_DEFAULT_FIELD,
            orderByDir = ADMIN_UNDERWRITERS_ORDER_BY_DEFAULT_DIR,
        } = {},
        filters: {
            search = null,
        } = {},
    } = {},
) {
    return {
        type: FETCH_ADMIN_UNDERWRITERS_REQUESTED,
        pagination: { page, perPage },
        order: { orderBy, orderByDir },
        filters: { search },
    };
}

export function fetchAdminUnderwritersSucceeded (data, order = {}, filters = {}) {
    return {
        type: FETCH_ADMIN_UNDERWRITERS_SUCCEEDED,
        data,
        order,
        filters,
    };
}

export function fetchAdminUnderwritersFailed () {
    return { type: FETCH_ADMIN_UNDERWRITERS_FAILED };
}

export function deleteAdminUnderwriterRequested (underwriterId) {
    return {
        type: DELETE_ADMIN_UNDERWRITER_REQUESTED,
        underwriterId,
    };
}

export function deleteAdminUnderwriterSucceeded (underwriterId) {
    return {
        type: DELETE_ADMIN_UNDERWRITER_SUCCEEDED,
        underwriterId,
    };
}

export function deleteAdminUnderwriterFailed (underwriterId) {
    return {
        type: DELETE_ADMIN_UNDERWRITER_FAILED,
        underwriterId,
    };
}

export function deleteMultipleAdminUnderwritersRequested (underwriterIds) {
    return {
        type: DELETE_MULTIPLE_ADMIN_UNDERWRITERS_REQUESTED,
        underwriterIds,
    };
}

export function deleteMultipleAdminUnderwritersSucceeded (underwriterIds) {
    return {
        type: DELETE_MULTIPLE_ADMIN_UNDERWRITERS_SUCCEEDED,
        underwriterIds,
    };
}

export function deleteMultipleAdminUnderwritersFailed (underwriterIds) {
    return {
        type: DELETE_MULTIPLE_ADMIN_UNDERWRITERS_FAILED,
        underwriterIds,
    };
}

export function fetchAdminUnderwriterRequested (underwriterId) {
    return {
        type: FETCH_ADMIN_UNDERWRITER_REQUESTED,
        underwriterId,
    };
}

export function fetchAdminUnderwriterSucceeded (underwriterData) {
    return {
        type: FETCH_ADMIN_UNDERWRITER_SUCCEEDED,
        underwriterData,
    };
}

export function fetchAdminUnderwriterFailed () {
    return {
        type: FETCH_ADMIN_UNDERWRITER_FAILED,
    };
}

export function updateAdminUnderwriterRequested (underwriterId) {
    return {
        type: UPDATE_ADMIN_UNDERWRITER_REQUESTED,
        underwriterId,
    };
}

export function updateAdminUnderwriterSucceeded (underwriterData) {
    return {
        type: UPDATE_ADMIN_UNDERWRITER_SUCCEEDED,
        underwriterData,
    };
}

export function updateAdminUnderwriterFailed (fieldErrors = {}) {
    return {
        type: UPDATE_ADMIN_UNDERWRITER_FAILED,
        fieldErrors,
    };
}

export function createAdminUnderwriterRequested () {
    return {
        type: CREATE_ADMIN_UNDERWRITER_REQUESTED,
    };
}

export function createAdminUnderwriterSucceeded (underwriterData) {
    return {
        type: CREATE_ADMIN_UNDERWRITER_SUCCEEDED,
        underwriterData,
    };
}

export function createAdminUnderwriterFailed (fieldErrors = {}) {
    return {
        type: CREATE_ADMIN_UNDERWRITER_FAILED,
        fieldErrors,
    };
}

export function adminUnderwriterDetailFieldChanged (field, value) {
    return {
        type: ADMIN_UNDERWRITER_DETAIL_FIELD_CHANGED,
        field,
        value,
    };
}

export function adminUnderwriterDetailFormClearRequested () {
    return { type: ADMIN_UNDERWRITER_DETAIL_FORM_CLEAR_REQUESTED };
}
