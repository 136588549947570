import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import {
    addWatchlist,
    removeWatchlist,
    updateWatchlist,
} from '../../stores/watchlist/actions';
import {
    fetchPackagesForCurrentEditionRequested,
    fetchPackagesRequested,
} from '../../stores/packages/actions';
import { EDITION_SELECT, EDITION_STANDARD } from '../../constants/editions';
import PACKAGE_TYPES, {
    PACKAGE_TYPE_NAMES,
} from '../../constants/packageTypes';
import { getEdition, getEditionName } from '../../selectors/edition';
import TablesPropTypes from '../../propTypes/tables';
import BrowseTable from '../BrowseTable';
import styles from './styles';
import { getAvailableStatesForDataTableFilter } from '../../selectors/states';
import { getAvailableUnderwritersForDataTableFilter } from '../../selectors/underwriters';
import ApiPropTypes from '../../propTypes/api';
import {
    downloadMultiplePackages,
    downloadPackage,
} from '../../stores/download/actions';

class BrowseByType extends Component {
    static propTypes = {
        // Props passed from parent
        classes: PropTypes.object.isRequired,
        type: PropTypes.oneOf(PACKAGE_TYPES).isRequired,
        title: PropTypes.node.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        hasFilters: PropTypes.bool,

        // Store props
        editionName: PropTypes.string.isRequired,
        loadingUpdate: PropTypes.arrayOf(PropTypes.number).isRequired,
        availableStatesForDataTableFilter: PropTypes.arrayOf(PropTypes.object),
        availableUnderwritersForDataTableFilter: PropTypes.arrayOf(
            PropTypes.object,
        ),
        onAddWatchlistRequested: PropTypes.func.isRequired,
        onRemoveWatchlistRequested: PropTypes.func.isRequired,
        onUpdateWatchlistRequested: PropTypes.func.isRequired,
        onDownloadRequested: PropTypes.func.isRequired,
        onFetchPackagesRequested: PropTypes.func.isRequired,
        onFetchPackagesForAllEditionsRequested: PropTypes.func.isRequired,
        edition: PropTypes.string.isRequired,
    };

    static defaultProps = {
        hasFilters: false,
        availableStatesForDataTableFilter: [],
        availableUnderwritersForDataTableFilter: [],
    };

    componentDidMount () {
        this.props.onFetchPackagesForAllEditionsRequested();
    }

    render () {
        const {
            classes,
            type,
            title,
            tableData,
            tableContext,
            hasFilters,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            editionName,
            loadingUpdate,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onUpdateWatchlistRequested,
            onDownloadRequested,
            edition,
            onFetchPackagesRequested: onFetchDataRequested,
        } = this.props;

        const tableProps = {
            tableData,
            tableContext,
            hasFilters,
            loadingUpdate,
            onAddWatchlistRequested,
            onRemoveWatchlistRequested,
            onUpdateWatchlistRequested,
            onDownloadRequested,
            onFetchDataRequested,
            availableStatesForDataTableFilter,
            availableUnderwritersForDataTableFilter,
            title,
        };

        return (
            <div className={classes.container}>
                <BrowseTable
                    {...tableProps}
                    edition={edition}
                    emptyText={`There are no ${PACKAGE_TYPE_NAMES[type]} Packages for the ${editionName} Edition.`}
                    noResultsText={`There are no ${PACKAGE_TYPE_NAMES[type]} Packages that match the selected filters.`}
                />
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        editionName: getEditionName(state),
        loadingUpdate: state.watchlist.loadingUpdate,
        availableStatesForDataTableFilter:
            getAvailableStatesForDataTableFilter(state),
        availableUnderwritersForDataTableFilter:
            getAvailableUnderwritersForDataTableFilter(state),
        edition: getEdition(state),
    };
};

const mapDispatchToProps = function (dispatch, props) {
    return {
        onAddWatchlistRequested: (packageIds) =>
            dispatch(addWatchlist(packageIds, false)),
        onRemoveWatchlistRequested: (packageIds) =>
            dispatch(removeWatchlist(packageIds, false)),
        onUpdateWatchlistRequested: (...args) =>
            dispatch(updateWatchlist(...args)),
        onDownloadRequested: (packageIds) => {
            return packageIds && packageIds.length > 1
                ? dispatch(downloadMultiplePackages(packageIds))
                : dispatch(downloadPackage(packageIds[0]));
        },
        onFetchPackagesForAllEditionsRequested: () => {
            dispatch(fetchPackagesRequested(EDITION_STANDARD, props.type));
            dispatch(fetchPackagesRequested(EDITION_SELECT, props.type));
        },
        onFetchPackagesRequested: (context = {}) => {
            dispatch(
                fetchPackagesForCurrentEditionRequested(props.type, context),
            );
        },
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(BrowseByType);
