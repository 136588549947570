export const styles = (theme) => ({
    ...theme.softpro.tableColumns,
    root: theme.softpro.safeBox,
    bottomBorderTabs: {
        display: 'block',
        margin: '0 auto',
        width: '40%',
        borderBottom: '1px solid #6089BD',
    },
    topGrid: {
        width: 'auto',
        marginLeft: 0,
        marginTop: 20,
    },
    topToolbar: {
        borderBottom: '1px solid rgba(96, 137, 189, 0.7)',
    },
    statsHeader: {
        padding: 0,
        justifyContent: 'flex-start',
    },
    statsHeaderCountText: {
        fontWeight: 'bold',
        fontSize: 32,
        paddingLeft: 20,
        width: 100,
    },
    statsHeaderText: {
        marginLeft: 10,
    },
    stats: {
        marginBottom: 30,
    },
});
