export const EDITION_SELECT = 'select';
export const EDITION_STANDARD = 'standard';
export const EDITIONS = [
    EDITION_SELECT,
    EDITION_STANDARD,
];

export default EDITIONS;

export const EDITION_NAMES = {
    [EDITION_SELECT]: 'Select',
    [EDITION_STANDARD]: 'Standard/Enterprise',
};

export const DEFAULT_EDITION = EDITION_SELECT;
