import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Grid, Tab, Tabs, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styles } from './styles';
import DownloadsTab from '../DownloadsTab';
import UsersTab from '../UsersTab';
import NotificationsTab from '../NotificationsTab';
import { bindActionCreators, compose } from 'redux';
import { adminDashboardDataRequested, updateRangeAnalytics } from '../../stores/admin/actions';
import { connect } from 'react-redux';
import TimeRangeSelect from '../TimeRangeSelect';
import { DOWNLOADS, NOTIFICATIONS, USERS } from '../../constants/analytics';
import { Link, Routes } from 'react-router-dom';
import { withRouter } from '../../utils';
import { Navigate, Route } from 'react-router';
import TabContainer from '../TabContainer';
import {
    getDownloadsContext, getNotificationsContext, getUsersContext,
    getStatsDownloads, getStatsDownloadsSelect, getStatsDownloadsStandard, getStatsOrganizations,
    getStatsPackages, getStatsPackagesSelect, getStatsPackagesStandard, getStatsUsers,
} from '../../selectors/analytics';

class Analytics extends React.Component {
    state = {
        customDialogOpen: false,
        customDialogError: false,
        customRange: 0,
        customStartDate: '',
        customEndDate: '',
    };

    closeTimeRangeDialog = (value) => {
        if (value === 'cancel') {
            this.setState({
                customDialogOpen: false,
                customDialogError: false,
                customRange: 0,
                customStartDate: '',
                customEndDate: '',
            });
        } else {
            // Set the range to the custom since they submitted the dialog
            // eslint-disable-next-line no-useless-escape
            const validationRegex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
            const newCustomRangeStart = this.state.customStartDate;
            const newCustomRangeEnd = this.state.customEndDate;
            if (
                !newCustomRangeStart ||
                !newCustomRangeStart.match(validationRegex) ||
                !newCustomRangeEnd ||
                !newCustomRangeEnd.match(validationRegex)) {
                this.setState({
                    customDialogOpen: true,
                    customDialogError: true,
                });
            } else {
                const { pathname } = this.props.router.location;
                const dataType = pathname.substring(
                    pathname.lastIndexOf('/') + 1);

                const startDate = new Date(newCustomRangeStart);
                const endDate = new Date(newCustomRangeEnd);
                // Convert milliseconds to days
                const daysDifference = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
                if (daysDifference < 0) {
                    this.props.onRangeSelected(-1 * daysDifference, newCustomRangeEnd, dataType);
                    this.setState({
                        customDialogOpen: false,
                        customDialogError: false,
                    });
                } else {
                    this.props.onRangeSelected(daysDifference, newCustomRangeStart, dataType);
                    this.setState({
                        customDialogOpen: false,
                        customDialogError: false,
                    });
                }
            }
        }
    };

    storeCustomStartDateValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({
                customStartDate: value,
                customDialogError: false,
            });
        }
    };

    storeCustomEndDateValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({
                customEndDate: value,
                customDialogError: false,
            });
        }
    };

    storeCustomRangeValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({
                customRange: value,
                customDialogError: false,
            });
        }
    };

    selectTimeRange = () => {
        this.setState({
            customDialogOpen: true,
            customDialogError: false,
        });
    };

    getContext = (dataType) => {
        switch (dataType) {
            case DOWNLOADS:
                return this.props.downloadsContext;
            case USERS:
                return this.props.usersContext;
            case NOTIFICATIONS:
                return this.props.notificationsContext;
            default:
                return null;
        }
    };

    componentDidMount () {
        const { adminDashboardDataRequested, range } = this.props;
        adminDashboardDataRequested(range);
    }

    render () {
        const { customRange, customDialogError } = this.state;
        const {
            date, range, router, classes,
            statsDownloads, statsDownloadsSelect, statsDownloadsStandard,
            statsPackages, statsPackagesSelect, statsPackagesStandard,
            statsUsers, statsOrganizations,
        } = this.props;
        const { pathname } = router.location;
        return (
            <div className={classes.root}>
                <Grid container direction="column" className={classes.stats}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsDownloads}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Total Downloads</Typography>
                            </Grid>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsPackages}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Total Packages</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsOrganizations}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Total Organizations</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsDownloadsSelect}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Select Edition Downloads</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsPackagesSelect}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Select Edition Packages</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsUsers}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Total Users</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsDownloadsStandard}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Standard Edition Downloads</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                <Typography variant="h1" className={classes.statsHeaderCountText}>
                                    {statsPackagesStandard}
                                </Typography>
                                <Typography className={classes.statsHeaderText}>Standard Edition Packages</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="right"
                                wrap="nowrap"
                                className={classes.statsHeader}
                            >
                                { /* Intentionally blank for spacing */ }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        wrap="nowrap"
                        className={classes.topGrid}
                        alignItems="end"
                    >
                        <Grid item>
                            <AppBar position="static">
                                <Tabs
                                    value={pathname}
                                    indicatorColor="primary"
                                >
                                    <Tab
                                        label="Downloads"
                                        component={Link}
                                        to={`/admin/analytics/${DOWNLOADS}`}
                                        value={`/admin/analytics/${DOWNLOADS}`}
                                    />
                                    <Tab
                                        label="Users"
                                        component={Link}
                                        to={`/admin/analytics/${USERS}`}
                                        value={`/admin/analytics/${USERS}`}
                                    />
                                    <Tab
                                        label="Notifications"
                                        component={Link}
                                        to={`/admin/analytics/${NOTIFICATIONS}`}
                                        value={`/admin/analytics/${NOTIFICATIONS}`}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            className={classes.topToolbar}
                        >
                            <TimeRangeSelect
                                open={this.state.customDialogOpen}
                                closeTimeRangeDialog={this.closeTimeRangeDialog}
                                storeCustomRangeValue={this.storeCustomRangeValue}
                                storeCustomStartDateValue={this.storeCustomStartDateValue}
                                storeCustomEndDateValue={this.storeCustomEndDateValue}
                                customRange={customRange}
                                selectTimeRange={this.selectTimeRange}
                                date={date}
                                range={range}
                                error={customDialogError}
                            />
                        </Grid>
                    </Grid>
                    <Grid item component={TabContainer}>
                        <Routes>
                            <Route
                                path={`/${DOWNLOADS}`}
                                element={<DownloadsTab />}
                            />
                            <Route
                                path={`/${USERS}`}
                                element={<UsersTab />}
                            />
                            <Route
                                path={`/${NOTIFICATIONS}`}
                                element={<NotificationsTab />}
                            />
                            <Route path="/" element={<Navigate to={`${pathname}/${DOWNLOADS}`} />} />
                        </Routes>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Analytics.propTypes = {
    router: PropTypes.object.isRequired,
    adminDashboardDataRequested: PropTypes.func,
    downloadsContext: PropTypes.object,
    usersContext: PropTypes.object,
    notificationsContext: PropTypes.object,
    classes: PropTypes.object,
    range: PropTypes.number,
    date: PropTypes.string,
    updateRangeAnalytics: PropTypes.func.isRequired,
    onRangeSelected: PropTypes.func.isRequired,
    statsDownloads: PropTypes.number.isRequired,
    statsDownloadsSelect: PropTypes.number.isRequired,
    statsDownloadsStandard: PropTypes.number.isRequired,
    statsPackages: PropTypes.number.isRequired,
    statsPackagesSelect: PropTypes.number.isRequired,
    statsPackagesStandard: PropTypes.number.isRequired,
    statsUsers: PropTypes.number.isRequired,
    statsOrganizations: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    return {
        range: state.adminRanges.range,
        date: state.adminRanges.date,
        loading: state.adminDashboardData.loading,
        downloadsContext: getDownloadsContext(state),
        usersContext: getUsersContext(state),
        notificationsContext: getNotificationsContext(state),
        statsDownloads: getStatsDownloads(state),
        statsDownloadsSelect: getStatsDownloadsSelect(state),
        statsDownloadsStandard: getStatsDownloadsStandard(state),
        statsPackages: getStatsPackages(state),
        statsPackagesSelect: getStatsPackagesSelect(state),
        statsPackagesStandard: getStatsPackagesStandard(state),
        statsUsers: getStatsUsers(state),
        statsOrganizations: getStatsOrganizations(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...(bindActionCreators(
            { updateRangeAnalytics, adminDashboardDataRequested }, dispatch)),
        onRangeSelected: (range, date, dataType, context) => dispatch(
            updateRangeAnalytics(range, date, dataType, context)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(Analytics);
