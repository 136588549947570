import { LOGIN_FAILED, LOGIN_SUCCEEDED, LOGOUT } from './actions';

export const authInitialState = {
    token: null,
    error: '',
};

export function auth (state = authInitialState, action = {}) {
    switch (action.type) {
        case LOGIN_SUCCEEDED:
            return { ...state, token: action.token, error: '' };
        case LOGIN_FAILED: {
            const { error } = action;
            return {
                token: null,
                error,
            };
        }
        case LOGOUT:
            return authInitialState;
        default:
            return state;
    }
}
