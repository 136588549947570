export const PREFIX = 'changePassword/';
export const CHANGE_PASSWORD_REQUESTED = `${PREFIX}CHANGE_PASSWORD_REQUESTED`;
export const CHANGE_PASSWORD_SUCCEEDED = `${PREFIX}CHANGE_PASSWORD_SUCCEEDED`;
export const CHANGE_PASSWORD_FAILED = `${PREFIX}CHANGE_PASSWORD_FAILED`;
export const CHANGE_PASSWORD_FIELD_CHANGED = `${PREFIX}CHANGE_PASSWORD_FIELD_CHANGED`;
export const CHANGE_PASSWORD_FORM_CLEAR_REQUESTED = `${PREFIX}CHANGE_PASSWORD_FORM_CLEAR_REQUESTED`;

export const changePasswordRequested = () => ({ type: CHANGE_PASSWORD_REQUESTED });

export const changePasswordSucceeded = () => ({ type: CHANGE_PASSWORD_SUCCEEDED });

export const changePasswordFailed = (fieldErrors = {}) => ({ type: CHANGE_PASSWORD_FAILED, fieldErrors });

export const changePasswordFieldChanged = (field, value) => ({ type: CHANGE_PASSWORD_FIELD_CHANGED, field, value });

export const changePasswordFormClearRequested = () => ({ type: CHANGE_PASSWORD_FORM_CLEAR_REQUESTED });
