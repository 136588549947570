export const DEFAULT_PER_PAGE = 5;

export const WATCHLIST_PER_PAGE_DEFAULT = 5;
export const BROWSE_PER_PAGE_DEFAULT = 10;
export const RECENT_DOWNLOADS_PER_PAGE_DEFAULT = 10;

export const ORDER_BY_DIR_ASC = 'asc';
export const ORDER_BY_DIR_DESC = 'desc';
export const ORDER_BY_DIRS = [ORDER_BY_DIR_ASC, ORDER_BY_DIR_DESC];

export const PACKAGES_ORDER_BY_FIELD_UPDATED_AT = 'updated_at';
export const PACKAGES_ORDER_BY_FIELD_NAME = 'name';
export const PACKAGES_ORDER_BY_FIELD_TYPE = 'type';
export const PACKAGES_ORDER_BY_FIELD_UNDERWRITER_NAME = 'underwriter_name';
export const PACKAGES_ORDER_BY_FIELD_STATE_NAME = 'state_name';
export const PACKAGES_ORDER_BY_FIELD_DOWNLOAD_URL = 'download_url';
export const PACKAGES_ORDER_BY_FIELD_RELEASE_NOTES_URL = 'release_notes_url';
export const PACKAGES_ORDER_BY_FIELD_DOWNLOAD_NOTES = 'download_notes';

export const PACKAGES_ORDER_BY_FIELD_ENDORSEMENTS_LOOKUPS =
    'endorsement_lookups_included';
export const PACKAGES_FILTER_FIELD_STATE = 'state';
export const PACKAGES_FILTER_FIELD_TYPE = 'type';
export const PACKAGES_FILTER_FIELD_UNDERWRITER = 'underwriter';

export const WATCHLIST_ORDER_BY_DEFAULT_FIELD =
    PACKAGES_ORDER_BY_FIELD_UPDATED_AT;
export const WATCHLIST_ORDER_BY_DEFAULT_DIR = ORDER_BY_DIR_DESC;

export const BROWSE_ORDER_BY_DEFAULT_FIELD = PACKAGES_ORDER_BY_FIELD_UPDATED_AT;
export const BROWSE_ORDER_BY_DEFAULT_DIR = ORDER_BY_DIR_DESC;

export const ANALYTICS_ORDER_BY_FIELD_UPDATED_AT = 'updated_at';
export const ANALYTICS_ORDER_BY_FIELD_NAME = 'name';
export const ANALYTICS_ORDER_BY_FIELD_EMAIL = 'email';
export const ANALYTICS_ORDER_BY_FIELD_TYPE = 'type';
export const ANALYTICS_FILTER_FIELD_STATE = 'state';
export const ANALYTICS_FILTER_FIELD_TYPE = 'type';

export const ANALYTICS_ORDER_BY_DEFAULT_FIELD =
    ANALYTICS_ORDER_BY_FIELD_UPDATED_AT;
export const ANALYTICS_ORDER_BY_DEFAULT_DIR = ORDER_BY_DIR_DESC;

export const ADMIN_PACKAGES_ORDER_BY_DEFAULT_FIELD =
    PACKAGES_ORDER_BY_FIELD_UPDATED_AT;
export const ADMIN_PACKAGES_ORDER_BY_DEFAULT_DIR = ORDER_BY_DIR_DESC;

export const ADMIN_UNDERWRITERS_ORDER_BY_FIELD_NAME = 'name';
export const ADMIN_UNDERWRITERS_ORDER_BY_DEFAULT_FIELD =
    ADMIN_UNDERWRITERS_ORDER_BY_FIELD_NAME;
export const ADMIN_UNDERWRITERS_ORDER_BY_DEFAULT_DIR = ORDER_BY_DIR_ASC;
