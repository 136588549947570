import { createApiPageData } from '../../utils/api';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/admin';
import { LOGOUT } from '../auth/actions';
import {
    FETCH_ADMIN_UNDERWRITERS_FAILED,
    FETCH_ADMIN_UNDERWRITERS_REQUESTED,
    FETCH_ADMIN_UNDERWRITERS_SUCCEEDED,
} from './actions';
import { update } from '../../utils/immutable';

const initialState = createApiPageData({
    perPage: DEFAULT_ROWS_PER_PAGE,
    loading: false,
    order: {},
    filters: {},
});

export default (state = initialState, { type, ...payload }) => {
    if (type === FETCH_ADMIN_UNDERWRITERS_REQUESTED) {
        return update(state, {
            order: payload.order || {},
            filters: payload.filters || {},
            loading: true,
        });
    } else if (type === FETCH_ADMIN_UNDERWRITERS_SUCCEEDED) {
        return update(
            state,
            createApiPageData(payload.data.result),
            {
                order: payload.order || {},
                filters: payload.filters || {},
                loading: false,
            },
        );
    } else if (type === FETCH_ADMIN_UNDERWRITERS_FAILED) {
        return update(state, { loading: false });
    } else if (type === LOGOUT) {
        return initialState;
    }
    return state;
};
