import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';
import SoftProHeader from '../../views/Header';
import { isClosingPassportPreferred, noop } from '../../utils';
import { LoginHelp } from '../LoginHelp';
import { connect } from 'react-redux';
import { login } from '../../stores/auth/actions';
import myFile from './../../files/Documents_Portal_Flyer.pdf';

const closingPassportUrl = process.env.REACT_APP_CLOSING_PASSPORT_URL;

const withLoginPageStyles = withStyles(styles);

const Welcome = withLoginPageStyles(({ classes }) => {
    return (
        <div className={classes.intro}>
            <Typography gutterBottom variant="h2" className={classes.welcomeText}>
                Welcome to the <br /> SoftPro Documents Portal!
            </Typography>
            <Typography variant="subtitle1" className={classes.welcomeParagraph}>
                SoftPro is the industry leader in providing real estate closing and title insurance
                software solutions. With more than 14,000 customer sites and over 60,000 users
                nationwide, our
                software has
                been recognized by the American Business Awards every year since 2007 for winning
                awards in Superior
                Customer Service, Support and Product Development.
            </Typography>
            <Typography variant="subtitle1" fontWeight={700} className={classes.welcomeParagraph}>
            Click&nbsp;
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={myFile}
                >
                    <span style={{
                        color: 'white',
                        textDecoration: 'underline',
                        fontFamily: 'filson-soft, sans-serif',
                        fontSize: '16px',
                        fontWeight: '700',
                    }}
                    >
                        here
                    </span>
                </a> for
            instructions on how to get started with the SoftPro Documents Portal.
            </Typography>
        </div>
    );
});

const LoginFormTextField = withLoginPageStyles(({ classes, ...rest }) => (
    <TextField
        required={true}
        fullWidth
        className={classes.loginFormInputContainer}
        InputProps={{
            classes: {
                input: classes.loginFormInput,
            },
        }}
        InputLabelProps={{
            shrink: true,
            classes: {
                root: classes.loginFormInputLabel,
            },
        }}
        {...rest}
    />
));

const LoginForm = withLoginPageStyles(
    (
        {
            classes,
            onChangeEmail,
            onChangePassword,
            login,
            email,
            password,
            loginFailed,
            emailError,
            passwordError,
            loading,
        },
    ) => {
        return (
            <Grid container direction="column" spacing={0} className={classes.loginContainer}>
                <Grid item className={classes.loginContainerRow}>
                    <Typography className={classes.subheading}>
                        Login
                    </Typography>
                </Grid>
                <Grid item component={Divider} />
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.loginInnerContainer}
                    component="form"
                    onSubmit={loading
                        ? noop
                        : (event) => {
                            event.preventDefault();
                            login();
                        }}
                >
                    {loginFailed &&
                    <Grid item className={classes.loginContainerRow}>
                        <Typography className={classes.loginFailedText}>
                            Login Failed.
                        </Typography>
                    </Grid>}
                    <Grid item className={classes.loginContainerRow}>
                        <LoginFormTextField
                            label="Email Address"
                            name="email"
                            type="email"
                            onChange={onChangeEmail}
                            error={emailError}
                            helperText={emailError ? 'Please provide your email address.' : ''}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item className={classes.loginContainerRow}>
                        <LoginFormTextField
                            label="Password"
                            name="password"
                            type="password"
                            onChange={onChangePassword}
                            error={passwordError}
                            helperText={passwordError ? 'Please provide your password.' : ''}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item className={classes.loginContainerButtonRow}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'LOGIN'}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item component={LoginHelp} />
            </Grid>
        );
    }
);

class LoginPage extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        loginFailed: PropTypes.bool,
        doLogin: PropTypes.func.isRequired,
        direction: PropTypes.string.isRequired,
    };

    static defaultProps = {
        loading: false,
        loginFailed: false,
    };

    state = {
        email: '',
        password: '',
        emailError: false,
        passwordError: false,
    };

    componentDidMount () {
        if (isClosingPassportPreferred()) {
            if (typeof window.location.replace === 'function') {
                window.location.replace(closingPassportUrl);
            } else if (typeof window.location.href !== 'undefined') {
                window.location.href = closingPassportUrl;
            }
        }
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value });
    };

    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    };

    onLoginRequested = () => {
        const { doLogin } = this.props;
        const { email, password } = this.state;
        if (email !== '' && password !== '') {
            this.setState({
                ...this.state,
                emailError: false,
                passwordError: false,
            });
            doLogin(email, password);
        } else {
            this.setState({
                ...this.state,
                emailError: (email === ''),
                passwordError: (password === ''),
            });
        }
    };

    render () {
        const { loginFailed, loading, direction } = this.props;
        const { email, password, emailError, passwordError } = this.state;

        return (
            <Fragment>
                <SoftProHeader />
                <Grid
                    container
                    direction={direction}
                    className="backgroundContainer"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item component={Welcome} />
                    <Grid item>
                        <LoginForm
                            onChangeEmail={this.onChangeEmail}
                            onChangePassword={this.onChangePassword}
                            login={this.onLoginRequested}
                            email={email}
                            emailError={emailError}
                            password={password}
                            passwordError={passwordError}
                            loginFailed={loginFailed}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const mapStateToProps = function (state) {
    const {
        loginPage: { loginFailed, loading: loginLoading },
    } = state;
    return {
        loginFailed,
        loginLoading,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        doLogin: (email, password) => {
            dispatch(login(email, password));
        },
    };
};

const LoginPageFunction = (props) => {
    const direction = (useMediaQuery((theme) => theme.breakpoints.only('sm')) || useMediaQuery((theme) => theme.breakpoints.up('lg')))
        ? 'row'
        : 'column';
    return <LoginPage {...props} direction={direction} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageFunction);
