import { call, fork, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';
import { requestWithAuth } from '../utils/api';
import {
    ADD_PACKAGES_TO_WATCHLIST,
    FETCH_SUGGESTED_WATCHLIST,
    fetchSuggestedWatchlistFailed,
    fetchSuggestedWatchlistSucceeded,
    REDIRECT_TO_PORTAL,
    redirectToPortalAction,
} from '../stores/onBoardingWatchlist/actions';
import { onBoardingCompleted } from '../stores/auth/actions';
import { push } from 'connected-react-router';
import { getAuthToken } from '../selectors/auth';
import { Packages, parseAndNormalizeJsonResponse } from '../schemas';
import { fetchUnderwriters } from '../stores/underwriters/actions';

function * fetchSuggestedWatchlist ({ editions, underwriterIds, states }) {
    const token = yield select(getAuthToken);
    const response = yield call(
        requestWithAuth,
        '/suggestedWatchlist',
        'GET',
        token,
        { editions, underwriterIds, states },
    );
    if (response.ok) {
        const data = yield call(parseAndNormalizeJsonResponse, response, Packages);
        yield put(fetchSuggestedWatchlistSucceeded(data));
    } else {
        yield put(fetchSuggestedWatchlistFailed());
    }
}

export function * listenFetchSuggestedWatchlist () {
    yield takeEvery(FETCH_SUGGESTED_WATCHLIST, fetchSuggestedWatchlist);
}

function * addPackagesToWatchlist ({ packageIds = [] }) {
    let response = null;
    const token = yield select(getAuthToken);
    if (packageIds.length > 0) {
        const packages = {};
        packageIds.forEach(function (packageId) {
            packages[packageId] = '';
        });
        response = yield call(requestWithAuth, '/watchlist', 'POST', token, null, { packages });
    }
    if (packageIds.length === 0 || (response && response.ok)) {
        /*
         * Since they've successfully had watchlists added and/or they've not added any
         * We can set their on boarding completed to true now
         */
        const onboardingCompleteResponse = yield call(requestWithAuth,
            '/user/onBoardingCompleted', 'PUT', token);
        const user = yield call(
            [onboardingCompleteResponse, onboardingCompleteResponse.json]);
        yield putResolve(onBoardingCompleted(user));
        yield put(redirectToPortalAction());
    }
}

export function * listenAddPackagesToWatchlist () {
    yield takeEvery(ADD_PACKAGES_TO_WATCHLIST, addPackagesToWatchlist);
}

function * redirectToPortal () {
    yield put(push('/portal/watchlist'));
    yield put(fetchUnderwriters());
}

export function * listenRedirectToPortal () {
    yield take(REDIRECT_TO_PORTAL);
    yield fork(redirectToPortal);
}
